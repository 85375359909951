import { API_URL, getReqOptions } from "../config";
import {
  Action,
  ErrorAction,
  FundsAction,
  FundsWatchlistState,
  Watchlist,
} from "../types";
import { Observable, of } from "rxjs";
import { addErrorToast, addSuccessToast } from "../../ui/toasts";
import { catchError, filter, mergeMap, takeUntil } from "rxjs/operators";

import { ajax } from "rxjs/ajax";
import { lang } from "../../../lang/lang";
import { ofType } from "redux-observable";

const ADD_FUND_TO_WATCHLIST_REQUESTED = "ADD_FUND_TO_WATCHLIST_REQUESTED";
const ADD_FUND_TO_WATCHLIST_SUCCESSFUL = "ADD_FUND_TO_WATCHLIST_SUCCESSFUL";
const ADD_FUND_TO_WATCHLIST_REJECTED = "ADD_FUND_TO_WATCHLIST_REJECTED";
const FETCH_WATCHLIST_REQUESTED = "FETCH_WATCHLIST_REQUESTED";
const FETCH_WATCHLIST_SUCCESSFUL = "FETCH_WATCHLIST_SUCCESSFUL";
const FETCH_WATCHLIST_REJECTED = "FETCH_WATCHLIST_REJECTED";
const DELETE_FUND_FROM_WATCHLIST_REQUESTED =
  "DELETE_FUND_FROM_WATCHLIST_REQUESTED";
const DELETE_FUND_FROM_WATCHLIST_SUCCESSFUL =
  "DELETE_FUND_FROM_WATCHLIST_SUCCESSFUL";
const DELETE_FUND_FROM_WATCHLIST_REJECTED =
  "DELETE_FUND_FROM_WATCHLIST_REJECTED";

export const addFundToWatchlist = (id: number): FundsAction => ({
  type: ADD_FUND_TO_WATCHLIST_REQUESTED,
  payload: { id },
});

const addFundToWatchlistSuccessful = (): Action => ({
  type: ADD_FUND_TO_WATCHLIST_SUCCESSFUL,
});

const addFundToWatchlistRejected = (error?: TypeError): ErrorAction => ({
  type: ADD_FUND_TO_WATCHLIST_REJECTED,
  error,
});

export const fetchWatchlist = (isLookupOnly: boolean): FundsAction => ({
  type: FETCH_WATCHLIST_REQUESTED,
  payload: { isLookupOnly },
});

const fetchWatchlistSuccessful = (funds: Watchlist[]): FundsAction => ({
  type: FETCH_WATCHLIST_SUCCESSFUL,
  payload: { funds },
});

const fetchWatchlistRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_WATCHLIST_REJECTED,
  error,
});

export const deleteFundFromWatchlist = (id: number): FundsAction => ({
  type: DELETE_FUND_FROM_WATCHLIST_REQUESTED,
  payload: { id },
});

const deleteFundFromWatchlistSuccessful = (): Action => ({
  type: DELETE_FUND_FROM_WATCHLIST_SUCCESSFUL,
});

const deleteFundFromWatchlistRejected = (error?: TypeError): ErrorAction => ({
  type: DELETE_FUND_FROM_WATCHLIST_REJECTED,
  error,
});

export default (
  state: FundsWatchlistState = {
    watchlistFunds: [],
  },
  { type, payload }: FundsAction
) => {
  switch (type) {
    case FETCH_WATCHLIST_SUCCESSFUL:
      return {
        ...state,
        watchlistFunds: payload.funds,
      };
    default:
      return state;
  }
};

export const addFundToWatchlistEpic = (
  action$: Observable<FundsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(ADD_FUND_TO_WATCHLIST_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .post(`${API_URL}/watchlist/${payload.id}/`, {}, getReqOptions())
        .pipe(
          mergeMap(() =>
            of(
              addSuccessToast(lang.fund.watchlistAdded),
              fetchWatchlist(true),
              addFundToWatchlistSuccessful()
            )
          ),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === ADD_FUND_TO_WATCHLIST_REQUESTED)
            )
          ),
          catchError(error =>
            of(
              addErrorToast(lang.fund.watchlistAddError),
              addFundToWatchlistRejected(error)
            )
          )
        )
    )
  );

export const deleteFundFromWatchlistEpic = (
  action$: Observable<FundsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(DELETE_FUND_FROM_WATCHLIST_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .delete(`${API_URL}/watchlist/${payload.id}/`, {
          ...getReqOptions(),
          pragma: "no-cache",
        })
        .pipe(
          mergeMap(() =>
            of(
              addSuccessToast(lang.fund.watchlistRemoved),
              fetchWatchlist(true),
              deleteFundFromWatchlistSuccessful()
            )
          ),
          takeUntil(
            action$.pipe(
              filter(
                action => action.type === DELETE_FUND_FROM_WATCHLIST_REQUESTED
              )
            )
          ),
          catchError(error =>
            of(
              addErrorToast(lang.fund.watchlistDeleteError),
              deleteFundFromWatchlistRejected(error)
            )
          )
        )
    )
  );

export const fetchWatchlistFundsEpic = (
  action$: Observable<FundsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_WATCHLIST_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .get(
          `${API_URL}/watchlist/${payload.isLookupOnly ? "?lookup_only" : ""}`,
          {
            ...getReqOptions(),
            pragma: "no-cache",
          }
        )
        .pipe(
          mergeMap(({ response }) => of(fetchWatchlistSuccessful(response))),
          catchError(error => of(fetchWatchlistRejected(error)))
        )
    )
  );
