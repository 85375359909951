import LocalizedStrings from "react-localization";

export const lang = new LocalizedStrings({
  de: {
    app: {
      help: "Hilfe",
      save: "Speichern",
      confirm: "Bestätigen",
      cancel: "Abbrechen",
      edit: "Ändern",
      remove: "Entfernen",
      link: "Link",
      yes: "Ja",
      no: "Nein",
      uploadText1:
        "Klicken oder ziehen Sie eine Datei in diesen Bereich, um sie hochzuladen.",
      uploadText2: "Wir erlauben nur",
      uploadText3: "Formate mit einer max. Größe von ",
      and: "und",
      dataFor: "Data as of ",
      new: "Neu",
      expandAll: "Alle ausklappen",
      collapseAll: "Alle einklappen",
    },
    routes: {
      home: "/",
      confirm: "/confirm",
      reset: "/reset",
      unsubscribe: "/unsubscribe",
      fundsInFocus: "/funds-in-focus",
      ratingInFocus: "/rating-in-focus",
      watchlist: "/watchlist",
      news: "/news",
      newsDetails: "/news/:slug?/:id",
      reports: "/reports",
      reportsDetails: "/reports/:slug?/:id",
      oif: "/oif",
      oifDetails: "/oif/:slug?/:id",
      searchResults: "/search-results",
      filterSearchResults: "/filter-search-results",
      advSearchResults: "/advanced-search-results",
      details: "/details/:slug/:id",
      premium: "/premium",
      methodology: "/methodology",
      methodologies: "/methodologies",
      legalNotice: "/legal-notice",
      privacyPolicy: "/privacy-policy",
      contact: "/contact",
      savedSearches: "/saved-searches",
      rechtlicheHinweise: "/rechtliche-hinweise",
      termsOfUse: "/terms-of-use",
      termsAndConditions: "/terms-and-conditions",
      conferencesListLoading: "/conferences-loading",
      conferencesList: "/conferences",
      conferencesList2: "/conferences_list",
      conferenceDetails: "/conferences/:id",
      assetManagerDashboard: "/asset-manager-dashboard/:id",
      fundRatings: "/asset-manager-dashboard/:id/fund-ratings",
      teamRating: "/asset-manager-dashboard/:id/team-rating",
      profileEdit: "/asset-manager-dashboard/:id/profile",
      assetManagerStats: "/asset-manager-dashboard/:id/stats",
      assetManagerConferences: "/asset-manager-dashboard/:id/conferences",
      surveyEquityFund: "/survey/equity-fund",
      surveySovereignBondFund: "/survey/sovereign-bond-fund",
      surveyCorporateBondFund: "/survey/corporate-bond-fund",
      surveyAggregateBondFund: "/survey/aggregate-bond-fund",
      surveyMultiAssetFund: "/survey/multi-asset-fund",
      surveyEsgCompany: "/survey/company",
      surveyEsgFund: "/survey/fund",
      dekaSurvey: "/deka-survey",
      assetManagerDetails: "/asset-managers/:id",
      assetManagers: "/asset-managers",
      assetManagerNews: "/asset-manager-news/:slug/:id",
      editFund: "/edit-fund/:id",
      userProfile: "/profile",
      benchmarks: "/benchmarks/:id",
      aifPage: "/aif-page",
      wholesale: "/professional-investors",
      selectSurvey: "/select-survey",
      scopeAwards: "/scope-awards/:year([0-9]{4})",
      scopeInvestmentAwards: "/scope-awards/:year/investment",
      scopeAlternativeInvestmentAwards:
        "/scope-awards/:year/alternative-investment",
      scopeCertificateAwards: "/scope-awards/:year/certificate",
      scopeAwardsCategories: "/scope-awards/:year/:name/categories",
      scopeAwardsNominees: "/scope-awards/:year/:name/nominees",
      scopeAwardsWinners: "/scope-awards/:year/:name/winners",
      nominationAltInvestmentRequirements:
        "/nomination-alternative-investment-requirements",
      nominationInvestmentRequirements: "/nomination-investment-requirements",
      nominationCertificateRequirements: "/nomination-certificate-requirements",
      portrait: "/scope-fund-analysis",
    },
    meta: {
      home: "Fonds-Ratings, Rankings und Vergleiche | ScopeExplorer",
      homeDesc:
        "ScopeExplorer bietet einfache und unabhängige Entscheidungshilfen bei der Auswahl aus über 6000 Fonds mit Rating. Prüfen Sie jetzt Ihren Fonds!",
      searchResults: "Suchergebnisse | ScopeExplorer",
      searchResultsDesc:
        "Erfahren Sie, wo Ihr Fonds im Vergleichsranking steht. Auf Basis unseres unabhängigen Fondsratings die besten Fonds in der Vergleichsgruppe finden.",
      fundsInFocus:
        "Im Fokus: Auswahl innovativer, erfolgreicher Fonds | ScopeExplorer",
      fundsInFocusDesc:
        "Fondsauswahl unserer Rating Analysten mit herausragenden Ergebnissen oder innovativer Investmentideen und Managementansätze. Nutzen Sie unsere Erfahrung.",
      ratingInFocus: "Team Ratings | ScopeExplorer",
      premium: "Premium | ScopeExplorer",
      contact: "Kontakt | ScopeExplorer",
      methodology: "Unsere Bewertung Fonds Rating Methodik | ScopeExplorer",
      methodologyDesc:
        "Die Scope Fonds Rating Methodik, seit mehr als 20 Jahren etabliert. So bewerten wir Fonds transparent und nachvollziehbar für Ihre Investition.",
      methodologiesDesc:
        "In diesem Bereich finden Sie die vollständigen Dokumente zu den Methodiken und Ratingprozessen, nach denen Scope die Bewertung von Fonds, Asset Managern und Zertifikate-Anbietern vornimmt.",
      legalNotice: "Impressum | ScopeExplorer",
      privacy: "Datenschutzerklärung | Scope Explorer",
      news: "Aktuelle Nachrichten, Analysen und Veröffentlichungen | ScopeExplorer",
      newsDesc:
        "Aktuelle News, Analysen und Hintergründe zu Fonds und Investments der unabhängigen Ratingagentur Scope. Erfahren Sie, was den Markt bewegt!",
      scopeExplorer: "ScopeExplorer",
      notFound: "404 - Nicht gefunden | ScopeExplorer",
      fundDetails: "Fonds details",
      fundDetailsDesc:
        "Alle Fakten und Bewertungen zu Ihrem Fonds. Erfahren Sie im Detail wie sich der Fonds entwickelt und alle Faktoren für Ihre Anlageentscheidung.",
      newsDetails: "News details | ScopeExplorer",
      watchlist: "Watchlist | ScopeExplorer",
      privacyPolicy: "Datenschutzhinweise | ScopeExplorer",
      rechtlicheHinweise: "Rechtliche Hinweise | ScopeExplorer",
      termsOfUse: "Nutzungsbedingungen | ScopeExplorer",
      termsAndConditions:
        "Besondere Bestimmungen der Offene Immobilienfonds Studie 2020 | ScopeExplorer",
    },
    assetManagerCharts: {
      allMyProductsChart: "Views nach Produkten",
      myTop10FundsChart: "Meine Top 10 Fonds",
      myProductsInPeergroupChart: "Meine Produkte in Peergroups",
      top10FundsInSeChart: "Top 10 Fonds im ScopeExplorer",
      top10PeergroupsInSeChart: "Top 10 Peergroups im ScopeExplorer",
    },
    assetManagers: {
      listTitle: "Fondsanbieter",
      title: "Unternehmensprofil",
      profile: "Profil",
      profileSubtitle:
        "Hier können Sie Angaben zu Ihrem Unternehmensprofil erfassen und ändern. Diese Informationen werden Nutzern auf dem ScopeExplorer unter der Rubrik „Fondsanbieter“ präsentiert.",
      subtitle:
        "In diesem Bereich finden Sie ausführliche Asset Manager Portraits – unter anderem mit Scopes Einschätzung zur Qualität des Anbieters und mit den Bewertungen sämtlicher Fonds des jeweiligen Asset Managers.",
      address_1: "Adresszeile 1",
      address_2: "Adresszeile 2",
      email: "E-mail",
      phone: "Telefon",
      founded: "Gegründet",
      link: "Link",
      linkText: "Link text",
      description_short: "Kurzbeschreibung",
      description: "Beschreibung",
      descriptionFixedQuestion:
        "Ihre gesuchte Fondsgesellschaft ist noch nicht dabei?",
      descriptionFixedText:
        "Wir erweitern regelmäßig unsere Übersichten zu den Fondsanbietern. Über die Suche finden Sie heute bereits die Fonds aller Gesellschaften.",
      list: "Fondsliste des Unternehmens",
      employees: "Mitarbeiter des Unternehmens",
      dateFrom: "von:",
      dateTo: "bis: ",
      contact: "Kontakt",
      topCompanyFunds: "Top 10 Fonds",
      contactAssetManagerSuccess:
        "Kontaktaufnahme mit Asset Manager beauftragt. ",
      contactAssetManagerError: "Fehler beim Senden der Anfrage.",
      investmentFocus: "Anlageklasse",
      investmentRegion: "Investment Regionen",
      volume: "Anzahl der bewerteten Fonds",
      aum: "Verwaltetes Vermögen",
      companySize: "Anzahl Mitarbeiter",
      scopeEvalutaion: "Einschätzung von Scope",
      scopeFundRating: "Scope Fondsrating",
      topRated: "Top-Bewertungen",
      news: "Research des Fondsmanagers",
      esg: "Nachhaltigkeitsprofil",
      investorRating: "Einschätzungen von Investoren",
      amTeams: "Portfoliomanager",
      from: "Stand:",
      macroOverview: "Markteinschätzung",
      macroOverviewOf: "Markteinschätzung von",
      selectedFunds: "Fondsauswahl des Asset Manager",
      statistics: "Web-Statistiken",
      statisticsSubtitle:
        "Auf dieser Seite finden Sie verschiedene Auswertungen zur Nutzung der Produkte Ihres Hauses im ScopeExplorer. Sie sehen auf einen Blick, welche Ihrer Fonds in den letzten Monaten das größte Interesse gefunden haben.",
      conferences: "Portfolio-Manager-Konferenz",
      conferences2: "Portfolio-Manager-Konferenzen",
      moreStatistics: "Mehr Statistiken",
      add: "Hinzufügen",
      remove: "Löschen",
      apply: "Anwenden",
      selection: "Ihre Fondsauswahl",
      funds: "Fonds",
      text1:
        "Von der Gesamtzahl der zu Ihrer Auswahl passenden Fonds sind im Schaubild und in der Tabelle die",
      text2: "am besten bewerteten Fonds dargestellt.",
      requestSuccess: "Vielen Dank! Wir haben Ihre Anfrage erhalten.",
      fields: {
        image: "Bild",
        title: "Titel",
        language: "Sprache",
        pdfFile: "PDF Datei",
        first_name: "Vorname",
        last_name: "Nachname",
        position: "Position",
        department: "Abteilung",
        summary: "Summary (max. 500 Zeichen)",
      },
    },
    assetManagerDashboard: {
      title: "Asset Manager",
      subtitle: "",
      errors: {
        default: "Fehler beim Senden der Anfrage.",
        400: "Wrong request data",
        401: "Unauthorized",
        fillInFields: "Bitte sämtliche Pflichtfelder ausfüllen.",
      },
      newsSuccess: "News gespeichert.",
      newsRemoved: "News entfernt.",
      portfolioManagerSuccess: "Portfolio Manager gespeichert.",
      portfolioManagerRemoved: "Portfolio Manager entfernt.",
      documentSuccess: "Dokument gespeichert.",
      documentRemoved: "Dokument entfernt.",
      fundSuccess: "Fonds gespeichert.",
      noDocuments: "Aktuell keine Dokumente vorhanden.",
      selectedFundsSuccess: "Ausgewählte Fonds gespeichert.",
      success: "Fondsanbieter gespeichert.",
      docCategories: {
        "product-presentation": "Produktpräsentation",
        "product-flyer": "Produkt-Flyer",
        factsheet: "Factsheet",
        "product-research": "Produkt-Research",
        "product-analysis": "Produktanalyse",
      },
      macroOverviewOptions: {
        veryNegative: "Sehr negativ",
        negative: "Negativ",
        neutral: "Neutral",
        positive: "Positiv",
        veryPositive: "Sehr positiv",
      },
      volumeTooltip: "Diese Angabe wird von Scope eingetragen.",
      investorRatingTooltip: "Diese Angabe wird von Scope eingetragen.",
      investorRatingRequest: "Anfordern",
      status: "Status",
      conferenceStatuses: {
        pending: "In Bearbeitung",
        accepted: "Genehmigt",
        rejected: "Rejected",
      },
      registerForConferenceSuccess: "Ihre Anfrage wurde an Scope gesendet.",
      requestConferenceSuccess:
        "Die Verifizierungs-Email wurde an Scope gesendet.",
      requestError: "Fehler beim Senden der Anfrage.",
      macroOverviewDate: "Datum der Markteinschätzung",
      unsubscribedFromConference: "Unsubscribed from conference.",
    },
    assetManagerShareClassesTable: {
      accordionButton: "Anteilsklassen",
      shareClass: "Anteilsklasse",
      isin: "ISIN",
      ogc: "OGC",
      minInvestment: "Mindestanlage",
      currency: "Währung",
    },
    wholesale: {
      title: "Mit dem ScopeExplorer als professioneller Investor durchstarten",
      header1:
        "Professionelle Features der Scope Fondsselektion für Ihren Alltag",
      text1:
        "Mit dem ScopeExplorer haben Sie kostenlos Zugriff auf die gesamte Scope Fondsdatenbank.",
      text2:
        "Umfassende Selektionsfilter für über 160 Peergroups und individuelle Selektionslisten unterstützen Sie bei ihrem täglichen Research.",
      text3:
        "Nutzen Sie zusätzlich unsere qualitativen Analysen und Empfehlungslisten der Scope Fondsselektion.",
      header2: "Registrieren Sie sich jetzt für die kostenlose Nutzung",
      header3: "des ScopeExplorers als professioneller Anleger.",
      text4: "Kostenlos registrieren",
      header4: "Portfolio Manager Konferenzen",
      text5:
        "Mit den Portfolio Manager Konferenzen bieten wir Ihnen ein Online-Konferenzformat mit analytischem Tiefgang. Unsere Analysten aus der Scope Fondsselektion sprechen die Themen an, die in unserer Bewertung von Fonds relevant sind und widmen uns Ihren Fragen in der Diskussion mit dem Portfolio Manager.",
      text6: "Registrieren",
      header5: "COVID-19: Die Auswirkungen eingeordnet",
      text7:
        "Mit unseren Crash Drawdown wissen Sie auf einen Blick, wie sich Ihre Fonds historisch in der aktuellen Lage schlagen.",
      header6: "Wir zeigen was Fonds wirklich können",
      text8:
        "Nutzen Sie unsere Erfahrung aus über 20 Jahren in der Bewertung von Investmentfonds. Über 6.000 Fonds finden Sie mit Bewertung in unserer Datenbank, monatlich aktualisiert. Mit unserer etablierten Methodik fokussieren wir auf die konstante Wertentwicklung eines Fonds. In über 160 Peergroups zeigen wir Ihnen unser exklusives Ranking der besten Produkte in unserer Bewertung.",
      text9:
        "Profitieren Sie von unserer Erfahrung und nutzen Sie alle zusätzlichen Angebote für professionelle Investoren",
      text10: "mit Ihrer Registrierung.",
    },
    profile: {
      title: "Unternehmensprofil",
      subtitle:
        "Halten Sie die Basisinformationen zu Ihrer Gesellschaft immer auf dem aktuellen Stand",
      retail: "Privater Investor",
      wholesale: "Professioneller Investor",
      assetManager: "Asset Manager",
    },
    fundRatings: {
      title: "Fondsratings",
      subtitle: "Ratings für Ihre Fonds anzeigen oder anfordern.",
      subtitle2:
        "Auf dieser Seite sehen Sie sämtliche Fonds ihres Hauses, die auf dem ScopeExplorer derzeit verfügbar sind. Sie können zu jedem Fonds Dokumente hochladen und zu bestimmten Fonds Ratings anfordern.",
    },
    teamRating: {
      title: "Teamratings",
      subtitle: "Team Ratings anzeigen oder anfordern.",
    },
    selectSurvey: {
      esgQuestionnaire: "Scope ESG Fragebogen",
      esgQuestionnaires: "Scope ESG Fragebögen",
      esgFundQuestionnaire: "Scope ESG Fondsfragebogen",
      esgCompanyQuestionnaire: "Scope ESG Unternehmensfragebogen",
      dvmEsgQuestionnaire: "DVM ESG Fragebogen",
      downladQuestionnaires: "Download aller Fragebögen",
      downloadAMSummary: "Download aller Fragebögen Ihrer Company",
      uploadMarkedAsArticle89: "Kennzeichnung als Artikel 8/9 Produkt",
      downloadEsgFundSummary: "Alle ausgefüllten Fondsfragebögen herunterladen",
      downloadEsgCompanySummary:
        "Alle ausgefüllten Unternehmensfragebögen herunterladen",
      footerText1:
        "Für weitere Informationen oder bei Rückfragen können Sie uns unter folgender",
      footerText2: "Email-Adresse kontaktieren: ",
      dvmDescription: `Dieser Fragebogen ermittelt mit Hilfe von 16 Fragen die
      Ausrichtung von Asset Management Gesellschaften hinsichtlich der
      Ausrichtung aller drei E-, S- und G-Kriterien. Einzelne
      Produktstrategien und Filterkriterien werden hier nicht
      behandelt.`,
    },
    esgSurvey: {
      title: "ESG-Fragebögen",
      subtitle: "",
      filename: "ESG Questionnaire ",
    },
    esgSurveyInfoModal: {
      title: "Sehr geehrte(r) Umfrageteilnehmer/in,",
      text1:
        "Die im Wege dieser Umfrage erhobenen und nicht-personenbezogenen Daten werden für interne analytische Zwecke der Scope Fund Analysis GmbH, sowie zur Weitergabe an einen beschränken Kundenkreis der Scope Fund Analysis GmbH (darunter fallen ausschließlich institutionelle Investoren wie bspw. Dachfondsmanager, Pensionsfonds, etc.) verwendet.",
      text2:
        "Ihre Teilnahme an dieser Umfrage gilt als Einverständnis zur eingehend beschriebenen Verwendung der von Ihnen mitgeteilten Informationen.",
      text3:
        "Hinsichtlich personenbezogener Daten findet keine Verarbeitung dieser im Zusammenhang mit den sonstigen erhobenen Daten statt, sondern eine anonyme Auswertungen, die keinen Bezug zu Ihrer Person hat. Wir werden Ihre Antworten nicht zur Bewertung Ihrer Person nutzen und Ihre Antworten nicht zusammen mit den Sie identifizierenden Daten (z.B. Name, E-Mail Adresse) speichern. Selbstverständlich stehen Ihnen gegenüber der erhebenden Stelle (Scope Fund Analysis GmbH) das Recht auf Auskunft sowie der Löschung Ihrer personenbezogenen Daten unter Beachtung der gesetzlichen Vorgaben aus der DSGVO zu. Ihre personenbezogenen Daten werden für diese Umfrage aufgrund eines berechtigten Interesses nach Art. 6 Abs. 1 lit. 6 DSGVO erhoben.",
      button1: "Cancel",
      button2: "Ja, ich bin professioneller Anleger",
    },
    uploadArticle89Modal: {
      errors: {
        default: "Fehler beim Senden der Anfrage.",
        400: "Wrong request data",
        401: "Unauthorized",
        fillInFields: "Bitte sämtliche Pflichtfelder ausfüllen.",
      },
      title: "Article 8/9 upload",
      text1:
        "Bitte laden Sie hier eine Excel-Datei mit der Kennzeichnung Ihrer Fonds als Artikel 8 oder Artikel 9 Produkt hoch.",
      newArticle89Uploaded: "Upload erfolgreich",
    },
    removeFundKeyFactsModal: {
      text1: "Do you want to delete these fund key facts?",
      button1: "Yes",
      button2: "No",
    },
    eventsCaldendar: {
      title: "Eventkalender",
      subtitle: "Hier erwartet Sie bald neuer Inhalt",
    },
    conferenceInfoModal: {
      title: "Unser Konferenzangebot für professionelle Anleger",
      text1:
        "Diese Website richtet sich ausschließlich an Anleger, die professionelle Anleger im Sinne der Richtlinie 2014/65/EU (MiFID II) sind oder die Kriterien erfüllen, die nach der MiFID II Voraussetzung für eine Einstufung von Privatanlegern als professionelle Anleger sind, und an semiprofessionelle Anleger im Sinne des Kapitalanlagegesetzbuchs (KAGB). Die Website richtet sich nicht an Privatanleger, die die vorgenannten Voraussetzungen nicht erfüllen.",
      text2:
        "Bitte bestätigen Sie, dass Sie die o.g. Voraussetzungen für eine Einstufung als professioneller oder als semiprofessioneller Anleger erfüllen.",
      text3:
        "Scope Fund Analysis GmbH als Anbieter vom ScopeExplorer informiert lediglich über Produkte und Dienstleistungen und erteilt keine Anlageberatung unter Berücksichtigung individueller Umstände. Scope Fund Analysis GmbH übernimmt keine Haftung für die unberechtigte Nutzung der angebotenen Inhalte.",
      button1: "Zurück zur Startseite",
      button2: "Ja, ich bin professioneller Anleger",
    },
    assetManagerInfoModal: {
      title: "Für professionelle Anleger",
      text1:
        "Das Informationsmaterial von Comgest richtet sich ausschließlich an professionelle Anleger. Bitte bestätigen Sie, dass Sie professioneller Anleger sind um fortzufahren.",
      text2:
        "Scope übernimmt keine Haftung für Schäden, die aus der unberechtigten oder fehlerhaften Nutzung von Inhalten entstehen.",
      button1: "Zurück zur Startseite",
      button2: "Ja, ich bin professioneller Anleger",
    },
    conferences: {
      title: "Portfolio-Manager-Konferenz",
      subtitle: "Hier erwartet Sie bald neuer Inhalt",
      listTitle: "Portfolio Manager Konferenz",
      listTitle2: "Das Online-Eventformat mit analytischem Tiefgang.",
      listSubtitle:
        "Unsere Analysten aus der Scope Fondsselektion begrüßen ausgewählte Portfolio Manager zum Gespräch. Wir sprechen die Themen an, die in unserer Bewertung von Fonds relevant sind und widmen uns Ihren Fragen.",
      pastEventsTitle: "Video on Demand",
      pastEventsSubtitle:
        "Rufen Sie jetzt die Videoaufzeichnung ausgewählter Veranstaltungen jederzeit auf.",
      webinare: "Web-Konferenz",
      live: "Live",
      files: "Die angehangten Dateien",
      speakers: "Sprecher",
      date: "Datum",
      startTime: "Uhrzeit Beginn",
      endTime: "Uhrzeit Ende",
      info: "Informationen",
      moreInfo2: "Zur Aufzeichnung",
      additionalInfo: "Additional information",
      register: "Registrierung",
      editSuccess: "Die Konferenz wurde erfolgreich gespeichert.",
      signOutConfirmText:
        "Möchten Sie sich wirklich aus dieser Konferenz austragen?",
      signOutButtonText: "Austragen",
      furtherConferenceText1:
        "Das neue Online-Eventformat für den ScopeExplorer",
      furtherConferenceText2:
        "Wir ergänzen regelmäßig weitere Online-Events. Mit einer",
      furtherConferenceText3: "Registrierung",
      furtherConferenceText4:
        "als professioneller Investor erhalten Sie unsere Veranstaltung-Updates auch bequem per E-Mail.",
      conferencesFormModalTitle: "Hinzufügen einer Portfolio-Manager-Konferenz",
      addConference: "Eine neue Webinare hinzufugen",
    },
    savedSearches: {
      date: "Datum",
      link: "Link",
      remove: "Entfernen",
      goTo: "Zu den Suchergebnissen",
      addSuccess: "Suche erfolgreich gespeichert.",
      addError: "Fehler beim Speichern der Suche.",
      removeSuccess: "Suche erfolgreich entfernt.",
      removeError: "Fehler beim Entfernen der Suche.",
    },
    ratingInFocus: {
      overview: "Ausgewählte Teamratings",
      strategy: "Strategie:",
      rating: "Rating:",
      category: "Kategorie:",
      aum: "AuM:",
      team: "Teamleitung:",
      reportDownload: "Report Download",
      requestTeamRating: "Team Rating anfordern",
      name: "Name des Investment Teams",
    },
    cookieInfo: {
      text: "Diese Website verwendet Cookies, um die Nutzerfreundlichkeit zu verbessern. Durch die weitere Nutzung der Website stimmen Sie dem zu. Weitere Infos zu Cookies und deren Deaktivierung finden Sie ",
      link: "hier.",
      understand: "Verstanden",
    },
    menu: {
      home: "Suche",
      searchResults: "Suchergebnisse",
      login: "Anmelden",
      fundsInFocus: "Fonds im Fokus",
      ratingInFocus: "Team Ratings",
      publications: "Publikationen",
      news: "News",
      reports: "Reports",
      oif: "Offene Immobilienfonds",
      methodology: "Methodik",
      methodologies: "Weitere Methodiken",
      premium: "Premium",
      watchlist: "Watchlist",
      savedSearches: "Gespeicherte Suchen",
      logout: "Logout",
      logoutSuccess: "Logout erfolgreich.",
      register: "Registrierung",
      companies: "Fondsanbieter",
      assetManagers: "Dashboard",
      conferences: "Konferenzen",
      services: "Services",
      aif: "OIF Studie 2022",
      wholesale: "Für professionelle Investoren",
      survey: "ESG-Fragebogen",
      scopeAwards: "Scope Awards",
      scopeAwardsDetails: "Scope Awards Details",
      scopeAwardsCategories: "Kategorien",
      scopeAwardsNominees: "Nominierungen",
      scopeAwardsWinners: "Gewinner",
      nominationAltInvestmentRequirements: "Nominierungsbedingungen",
      nominationInvestmentsRequirements: "Nominierungsbedingungen & Methodik",
      nominationCertificateRequirements: "Nominierungsbedingungen",
      portrait: "Scope Fund Analysis",
    },
    footer: {
      privacyPolicy: "Datenschutzhinweise",
      rechtlicheHinweise: "Rechtliche Hinweise",
      termsOfUse: "Nutzungsbedingungen",
      legalNotice: "Impressum",
      methodology: "Methodik",
      methodologies: "Weitere Methodiken",
      contact: "Kontakt",
      copyright: `Copyright © ${new Date().getFullYear()}`,
      scopeAnalysis: "Scope Fund Analysis GmbH",
    },
    validation: {
      required: "Erforderlich.",
      email: "Ungültige E-Mail-Adresse.",
      passwordsMatch: "Passwörter stimmen nicht überein.",
      containLetter: "Muss mind. einen buchstaben beinhalten.",
      uppercaseLetter: "Muss mind. ein großbuchstabe beinhalten.",
      lowercaseLetter: "Muss mind. ein kleiner buchstabe beinhalten.",
      containNumber: "Muss mind. eine ziffer beinhalten.",
      specialCharacter: "Muss mind. ein besonderer charakter beinhalten.",
      maxValuePart1: "Maximal",
      maxValuePart2: "Zeichen erlaubt.",
      minValuePart1: "Muss mind.",
      minValuePart2: "Zeichen beinhalten.",
      uploadFile: "Bitte laden Sie die richtige Datei hoch.",
    },
    help: {
      of: "von",
    },
    unsubscribe: {
      title: "Abmeldebestätigung",
      subtitle: "Newsletter / Watchlist",
      text1:
        "Sie haben sich erfolgreich von unseren E-Mail Services abgemeldet.",
      text2:
        "Danke, dass Sie die Newsletter und Watchlist-Benachrichtigungen des ScopeExplorer genutzt haben.",
      return: "Zurück zur Startseite",
    },
    home: {
      header:
        "Das Portal bietet eine innovative und zugleich einfache Entscheidungshilfe bei der Auswahl von Fonds",
      noAccount: "Noch nicht registriert?",
      haveAccount: "Bereits registriert?",
      register: "Jetzt kostenfrei registrieren.",
      or: "kostenfrei an.",
      signIn: "Anmelden.",
      login: "Anmelden",
      close: "Schließen",
      email: "E-Mail",
      password: "Passwort",
      forgotPassword: "Passwort vergessen",
      logIn: "Anmelden",
      loginWithFacebook: "Login with Facebook",
      loginWithGoogle: "Login with Google",
      logInHere: "Anmelden",
      remember: "Anmeldedaten speichern",
      repeatPassword: "Passwort wiederholen",
      newPassword: "Neues Passwort",
      signUp: "Registrieren",
      registration: "Registrierung",
      wholesaleRegistration: "Registrierung - Professioneller Investor",
      retailUsers: "Privatanleger",
      wholesaleUsers: "Professioneller Investor",
      accountType: "Nutzertyp",
      name: "Name",
      firstName: "Vorname",
      lastName: "Nachname",
      companyName: "Unternehmen",
      unit: "Funktion",
      phoneNumber: "Telefon / Mobil",
      videoTitle:
        "ScopeExplorer unterstützt Sie darin, bessere Investmententscheidungen zu treffen. Wie genau zeigt das Video.",
      homeBoxTitle1: "Aktuelle Studien",
      homeBoxTitle2: "Aktuelle Ratings",
      homeBoxTitle3: "Fakten zu Fonds",
      homeBoxTitle4: "Warum Fondsratings?",
      homeBoxTitle5: "Fondsanalyse",
      homeBoxTitle6: "Das Unternehmen",
      homeBoxContent4:
        "Das Scope Fondsrating reflektiert die relevantesten Rendite- und Risikoaspekte eines Fonds. Durch das Rating wird ein systematischer Vergleich von thematisch ähnlichen Fonds ermöglicht.",
      homeBoxContent5:
        "Die Analysten von Scope bewerten seit mehr als 20 Jahren Investmentfonds - in Deutschland derzeit mehr als 6.000. Die Analysten-Teams sitzen in Frankfurt a.M. und in Berlin.",
      homeBoxContent6:
        "Die Scope Group ist mit mehr als 200 Mitarbeitern der größte europäische Anbieter von Ratings, Research und Analysen – sowohl in der Bewertung von Investmentfonds und Asset Managern als auch Kreditrisiken.",
      more: "Mehr",
      moreScopeAward: "Informieren",
      moreStudien: "Alle Studien",
      moreRatings: "Mehr Ratings",
      moreFacts: "Mehr Fakten",
      moreNews: "Alle News",
      homeNewsTitle: "News und aktuelle Analysen",
      scopeAwardWinnersText: "Die Gewinner stehen fest!",
      scopeAwardsNomineesText:
        "Die Nominierungeng für die Scope Investment Awards stehen fest",
    },
    search: {
      header: "PRÜFEN",
      subheader: "Gehört Ihr Fonds zu den Top-Angeboten?",
      search: "Suche",
      placeholder: "Fondssuche nach Namen, WKN oder ISIN",
      advancedSearch: "Erweiterte Suche",
      simpleSearch: "Einfache Suche",
      searchBox1: "Größte europäische Ratingagentur",
      searchBox2: "Über 6.000 Fonds bewertet",
      searchBox3: "Mehr als 20 Jahre Erfahrung",
      selectedItems: "Ausgewählte Elemente",
      searchTitle1: "Auswählen",
      searchText11: "Finden Sie den passenden Fonds",
      searchText12: "entsprechend Ihren Prioritäten.",
      searchTitle2: "Entdecken",
      searchText21: "Vorstellungen von Top-Fonds",
      searchText22: "aus den unterschiedlichsten Märkten.",
      searchTitle3: "Rankings",
      searchText31: "Die besten Fonds auf einen Blick.",
      searchText32: "",
      selected: "ausgewählt",
    },
    advancedSearch: {
      search: "Suche",
      reset: "Zurücksetzen",
      placeholder: "Fondssuche nach Namen, WKN oder ISIN",
      scopePeergroup: "Scope Vergleichsgruppe",
      investmentFocus: "Investmentfokus",
      company: "Unternehmen",
      fundSize: "Fonds Volumen",
      universe: "Universum",
      scopeRating: "Scope Rating",
      uncheckAll: "Auswahl löschen",
      fundsSelected: "Ausgewählte Fonds",
      searchResults: "Suchergebnisse",
      saveCustomFields: "Benutzerdefinierte Suche speichern",
      saveSearch: "Suche speichern",
      mainTranche: "Nur Haupttranchen anzeigen",
      addSuccess: "Benutzerdefinierte Felder erfolgreich gespeichert.",
      addError: "Fehler beim Speichern der benutzerdefinierten Feldern.",
      errors: {
        default:
          "Abruf von Fonds derzeit nicht möglich. Bitte versuchen Sie es später noch einmal.",
      },
      resetFormFilters: "Zurücksetzen, um Filtereinstellungen auszuwählen.",
      resetFormAutocomplete:
        "Bitte klicken Sie zuerst auf ,,Zurücksetzen‘‘ um dieses Suchfeld zu verwenden.",
    },
    filterSearch: {
      search: "Jetzt finden",
      step: "Schritt",
      step1: "In welche Anlageklasse wollen Sie investieren?",
      step2: "In welchen Regionen möchten Sie investieren?",
      step3: "Wählen Sie die Risikoklassen",
      low: "Niedrig",
      medium: "Mittel",
      high: "Hoch",
      found: "Treffer",
      apply: "Fondsliste anzeigen",
      reset: "Filter zurücksetzen",
      northAmerica: "Nordamerika",
      europe: "Europa",
      asia: "Asien",
      emergingMarkets: "Schwellenlände",
      germany: "Deutschland",
      world: "Global",
      applyAgain:
        "Die Filterkriterien wurden geändert. Bitte führen Sie die Suche erneut aus.",
    },
    advancedFundsList: {
      pleaseSelectFund:
        "Keine passenden Fonds für ausgewählte Kriterien gefunden.",
      filename: "Scope Fonds Export.xlsx",
      name: "Name",
      columnSelection: "Spaltenauswahl:",
      prev: "Zurück",
      next: "Weiter",
      of: "von",
      search: "Suche",
      requestRating: "Rating anfordern",
      exportSelected: "Auswahl exportieren",
      exportAll: "komplette Liste exportieren",
      downloadXls: "Download XLS",
      selectFunds: "Fonds auswählen zum Export.",
      selectFundsToCompare: "Fonds zum Vergleich auswählen.",
      selectFundsToRequest:
        "Bitte wählen Sie Fonds aus, für die sie ein Rating anfordern möchten.",
      selectFundsToExport: "Fonds für den Export auswählen.",
      selectMax3Funds: "Wählen Sie maximal 3 Fonds zum Vergleich aus.",
    },
    premium: {
      title: "Fondsselektion und Nachhaltigkeit",
      subtitle:
        "Scope Fund Analysis bietet eine von Produktanbietern unabhängige und objektive Fondsselektion sowie eine Bewertung der Nachhaltigkeit ausgewählter Fonds an.",
      peergroup: "Vergleichsgruppe",
      fundSelection: "Scope Empfehlungsliste ",
      scopeRecommendation: "Auszug - aktiv gemanagte Fonds",
      requestCustomizedSolution: "Anfrage: Individuelle Selektion",
      requestFullList: "Komplette Liste anfordern",
      esg: "Scope Nachhaltigkeitsanalyse",
      gid: "ESG - Greenness Indicator",
      checkYourFund: "Gehört Ihr Fonds zu den Top-Produkten?",
      requestEsgPortfolioCheck: "Request ESG Portfolio Check",
      requestEsgTeamRating: "ESG Team Rating anfordern",
      sendRequest: "Anfrage senden",
      recommendedListTooltip:
        "Scope Fund Analysis bietet eine unabhängige und objektive Fondsselektion. Dabei kommen quantitative und qualitative Bewertungskriterien zum Einsatz. Ziel ist es, Manager und Fonds zu identifizieren, die risikoadjustierte Überrenditen erzielen.",
      esgTooltip:
        "Der Scope ESG Greenness Indicator zeigt die jeweilige Ausprägung eines Fonds in Bezug auf sein Nachhaltigkeitsprofils. Dabei werden die Faktoren Environment = Umwelt, Social = Soziales und Governance = Unternehmensführung jeweils einzeln betrachtet.",
      success: "Anfrage erfolgreich gesendet",
      environment: "Umwelt",
      social: "Soziales",
      governance: "Unternehmensführung",
      active: "Aktiv",
      passive: "Passiv",
      fundSearch: "Fondssuche",
      fundSearchText:
        "Sie können hier sämtliche von Scope mit einer ESG-Bewertung versehene Fonds durchsuchen.",
      exclusion: "Ausschlusskriterien",
      exclusionShort: "Ausschluss",
      noSearchResults: "No results found. Please ",
      contactUs: "contact us.",
      fillInFields: "Pflichtfelder ausfüllen",
      subscribeListSuccess: "Erfolgreich abonniert.",
      unsubscribeListSuccess: "Erfolgreich abgemeldet.",
      subscribeListError: "Fehler beim Senden der Anfrage.",
      errors: {
        default: "Fehler beim Senden der Anfrage.",
      },
    },
    aifBenchmarks: {
      property_portfolio_short: "Immobilienportfolio",
      sustainability_short: "Nachhaltigkeit",
      financial_structure_short: "Finanzstruktur",
      asset_management_short: `Asset Management`,
      fund_management_short: "Fondsmanagement",
      rating_total_short: "Risikoscore",
      property_portfolio: "Scope Bewertung des Immobilienportfolios",
      sustainability: "Scope Bewertung der Nachhaltigkeit",
      financial_structure: "Scope Bewertung der Finanzstruktur",
      asset_management: "Scope Bewertung des Asset Managements",
      fund_management: "Scope Bewertung des Fondsmanagements",
      rating_total: "Scope Bewertung des Risikos",
      rating_total_2:
        "(Hinweis: je höher die Bewertung, desto geringer das Risiko)",
      ter: "TER",
      net_volume: "Nettofondsvermögen (in Mio.)",
      real_volume: "Immobilienvermögen (in Mio.)",
      objects_number: "Objektanzahl",
      liquidity_ratio: "Liquiditätsquote",
      credit_ratio: "Kreditquote",
      geographical_german: "Anteil deutscher Immobilien",
      geographical_european: "Anteil europäischer Immobilien",
      geographical_non_european: "Anteil globaler Immobilien",
      sectoral_office: "Anteil Nutzungsart Büro",
      sectoral_retail: "Anteil Nutzungsart Handel",
      sectoral_living: "Anteil Nutzungsart Wohnen",
      sectoral_hotel: "Anteil Nutzungsart Hotel",
      sectoral_logistics: "Anteil Nutzungsart Logistik",
      performance_1y: "Performance 1 Jahr",
      performance_5y: "Performance 5 Jahre p.a.",
      volatility_5y: "Volatilität 5 Jahre p.a.",
      occupancy: "Vermietungsquote",
      rating_esg: "Scope Bewertung der Nachhaltigkeit",
      wault: "durchschn. gewichtete Mietvertragsrestlaufzeit (WAULT)",
    },
    aifFunds: {
      excellent: "Exzellent",
      veryGood: "Sehr gut",
      good: "Gut",
      balanced: "Ausgewogen",
      modest: "Mäßig",
      veryModest: "Sehr mäßig",
      poor: "Schwach",
      likelyCapitalLost: "Schwach",
      substantialCapitalLost: "Schwach",
      negativeTurn: "Negative Rendite",
      benchmarks: "Benchmark-Tool",
      benchmarksSubtitle:
        "Die offenen Immobilien-Publikumsfonds in Deutschland",
      scopeCardParameters: "Score Card Parameter",
      yourParameters: "Ihre Parameter",
      exportToExcel: "Export to Excel",
      addParameters: "Parameter wählen",
      modalStep1:
        "Wählen Sie die Fonds, die Sie vergleichen möchten (Schritt1 von 2)",
      modalStep2:
        "Wählen Sie die Parameter, nach denen Sie die Fonds vergleichen möchten (Schritt 2 von 2)",
      selectAll: "Alle auswählen",
      next: "Weiter",
      submit: "Anwenden",
      selectFunds: "Select funds to see scale",
      sectoralAllocation: "Nutzungsartenverteilung",
      geographicalAllocation: "Geografische Verteilung",
      sectoralOffice: "Büro",
      sectoralRetail: "Einzelhandel",
      sectoralLiving: "Wohnen",
      sectoralHotel: "Hotel",
      sectoralLogistics: "Logistik",
      geographicalGerman: "Deutsche Immobilien",
      geographicalEuropean: "Europäische Immobilien",
      geographicalNonEuropean: "Globale Immobilien",
      others: "Sonstige",
      moreInformation: "Informieren",
      selectYourProduct: "Vorab registrieren",
      pleaseChoose: "Bitte wählen Sie ein Paket aus",
      buy: "Zahlungspflichtig bestellen",
      bannerText:
        "Die Offene Immobilienfonds Studie 2024 – Ratings & Research mit einer einzigartigen Daten- und Analysetiefe",
      signUpText: "Sign up or Log in to view",
      signUp: "Sign up",
      paidDocuments: "Bezahlte Dokumente",
      documents: "Fondsdokumente",
      views: "Views",
      clicks: "Besucher",
      register: "Registerung",
      registerNow:
        "Registrieren Sie sich bereits jetzt als Professioneller Investor und erhalten Sie die Studie 2022 kostenfrei.",
    },
    invoiceModal: {
      title: "Vielen Dank für die Bestellung.",
      text1: "Wir haben Ihnen die Rechnung per Email zugesendet.",
      text2:
        "Sie können bequem zwischen den in der Rechnung genannten Zahlungsmethoden wählen.",
      payOnline: "Direkt online bezahlen",
      downloadInvoice: "Per Rechnung bezahlen",
    },
    purchaseModal: {
      title: "Bestellung abschließen",
      yourOrder: "Ihre Bestellung",
      selectedLicense: "Gewähltes Produkt:",
      totalPrice: "Gesamtpreis:",
      agreement1:
        "Durch Klicken der Schaltfläche „Zahlungspflichtig bestellen“ geben Sie ein verbindliches Kaufangebot gemäß der besonderen Bedingungen der Offenen Immobilienfonds Studie 2020 ab, die Sie",
      agreement2: "hier",
      agreement3: "einsehen können.",
      selectProduct: "Please select product.",
      billingAddress: "Rechnungsadresse",
      company: "Unternehmen",
      street: "Straße, Nr.",
      postalCode: "Postleitzahl",
      city: "Ort",
      taxId: "Umsatzsteuer ID",
      contactPerson: "Ansprechpartner",
      purchase: "Bestellen",
      purchaseError: "You need to be wholesale user to buy license.",
    },
    fundFields: {
      logo: "Logo",
      wkn: "WKN",
      tranche: "Tranche",
      position_vs_peergroup: "Rang",
      tranche_currency: "Währung der Tranche",
      peergroup: "Vergleichsgruppe",
      primary: "Haupttranche",
      universe: "Universum",
      company: "Unternehmen",
      ter: "Laufende Kosten",
      volume: "Fondsvolumen",
      volume_currency: "Währung",
      initialCharge: "Erstausgabeaufschlag",
      accumulating: "Thesaurierend",
      firstRating: "Erstrating",
      perf_1m: "Rendite 1 Mon.",
      perf_3m: "Rendite 3 Mon.",
      perf_6m: "Rendite 6 Mon.",
      perf_1y: "Rendite 1 J.",
      perf_3y: "Rendite 3 J.",
      perf_5y: "Rendite 5 J.",
      perf_since_inception: "Rendite seit Auflage",
      perf_last_year: "Rendite Vorjahr",
      volatility_3y: "Volatilität 3 Jahre",
      initial_charge: "Erstausgabeaufschlag",
      isin: "ISIN",
      srri: "SRRI",
      rating_unchanged_since: "Rating unverändert seit",
      prob_loss_month_3y: "Verlustwahrscheinlichkeit 3 J.",
      prob_profit_month_3y: "Gewinnwahrscheinlichkeit 3 J.",
      max_loss_3y: "Max. Verlust 3 Jahre",
      max_profit_3y: "Max. Gewinn 3 Jahre",
      rank: "Rating",
      rating_points: "Ratingpunkte",
      risk_class: "Risikoklasse",
      fee: "Gebühren",
      documents: "Unterlagen",
      return_fee: "Rückgabeaufschlag",
      min_investment: "Mindestanlage",
      issue_date: "Auflagedatum",
      value_at_risk_3y: "Value at Risk 3 Jahre",
      is_award_nominated: "Is award nominated",
      is_focus: "Auswahl des Fondsanbieters",
      is_selection: "Fondsselektion",
    },
    compareFunds: {
      compareFunds: "Vergleiche Fonds",
      currentFund: "Aktueller Fonds",
      compare: "Vergleiche",
      selectFundToCompare: "Fondssuche",
    },
    fund: {
      amDocuments: "Asset Manager Dokumente",
      winners: "Gewinner",
      nominee: "Nominiert",
      dataFrom: "Daten zum ",
      ratingFrom: "Rating zum:",
      paidDocuments: "Bezahlte Dokumente",
      name: "Name",
      fundName: "Fondsname",
      wkn: "WKN",
      isin: "ISIN",
      nav: "NAV",
      ter: "Laufende Kosten",
      photo: "Photo",
      terTooltip:
        "Laufende Kosten: Summe der aus dem Fondsvermögen zu bestreitenden Kosten und Gebühren eines Fonds in Prozent des Fondsvermögens, inkl. der Kosten von Zielfonds im Fondsvermögen, ohne Transaktionskosten und erfolgsabhängige Vergütungen.",
      srri: "SRRI",
      sfdr: "SFDR",
      sfdrTooltip:
        "SFDR: Sustainable Finance Disclosure Regulation / EU Offenlegungsverordnung",
      srriTooltip:
        "Risikoklassifizierung auf Basis des “Synthetic Risk and Reward Indicator” (SRRI). Der SRRI reflektiert die Wertschwankungen eines Fonds und wird auf Basis EUweiter, einheitlicher Vorschriften ermittelt. Die Klassifizierung erfolgt auf einer Skala von 1 bis 7. Je höher der SRRI, desto höher das Risikoprofil eines Fonds",
      srriTooltip2:
        "Synthetischer Risiko-Rendite-Indikator. Einstufung des Risiko in eine von 7 Risikoklassen. Der Risikoindikator beruht auf der Volatilität des Fonds in den letzten fünf Jahren. Je höher die Risikoklasse ist, desto größeren Schwankungen unterliegt der Fonds.",
      price: "Kurs (NAV)",
      positionInPeergroup: "Position in Peergroup",
      positionInRanking: "Position im Rang: ",
      positionInTheRanking: "Die",
      position: "Position in Scope Peergroup",
      top: "Top",
      peergroup: "Vergleichsgruppe",
      rankInPeergroup: "Rang in der Vergleichsgruppe",
      performance: "Rendite",
      performancePercent: "Rendite %",
      performanceRisk: "Rendite / Risiko",
      performance_3y_long: "Rendite 3 jahre",
      fundPerformance: "Fonds Rendite",
      peergroupAverage: "Durchschnitt der Vergleichsgruppe",
      scopeFundRating: "Scope Fondsrating",
      ratingHistory: "Ratinghistorie",
      rating: "Rating",
      ratingTooltip:
        "Bewertung des Fonds entweder auf Basis eines qualitativen oder quantitativen Ratings. Die Skala reicht von A (“sehr gut”) bis hin zu E (“schwach”).",
      aifRatingTooltip:
        "Die Skala reicht von aaa (“Exzellent”) bis hin zu d (“Negative Rendite”).",
      rank: "Rang",
      fundRank: "Fonds Rang",
      fundRating: "Fonds Rang",
      fundRatingTooltip:
        "Bewertung des Fonds entweder auf Basis eines qualitativen oder quantitativen Ratings. Die Skala reicht von A (“sehr gut”) bis hin zu E (“schwach”).",
      fundPoints: "Fonds punkte",
      risk: "Risiko",
      costBar: "Kosten",
      riskTooltip: "Risiko gemessen als Volatilität des Fonds.",
      riskPercent: "Risiko %",
      volatility: "Volatilität",
      volatilityPercent: "Volatilität %",
      fundRisk: "Fonds risiko",
      safety: "Risiko- bewertung",
      performanceRiskChart: "Rendite / Risiko",
      riskClassification: "Risikoklassifizierung",
      maxLoss: "Max loss (p.a.)",
      moreInfo: "Mehr Info",
      back: "Zurück",
      fundsInFocus: "Fonds im Fokus",
      strategy: "Strategie",
      ongoingCharge: "Laufende Kosten",
      ongoingChargeTooltip:
        "Laufende Kosten: Summe der aus dem Fondsvermögen zu bestreitenden Kosten und Gebühren eines Fonds in Prozent des Fondsvermögens, inkl. der Kosten von Zielfonds im Fondsvermögen, ohne Transaktionskosten und erfolgsabhängige Vergütungen.",
      performanceFee: "Erfolgsabhängige Vergütung",
      fundFacts: "Fondsdaten",
      company: "Unternehmen",
      manager: "Portfoliomanager",
      launchDate: "Auflagedatum",
      accumulating: "Thesaurierend",
      quantitativeLabel: "Quantitatives Rating",
      qualitativeLabel: "Qualitatives Rating",
      quantitativeTooltip:
        "Rating basierend auf quantitativen Ratingkriterien.",
      qualitativeTooltip:
        "Rating schließt sowohl quantitative als auch qualitative Kriterien ein.",
      period: "Kennzahl und Periode",
      returnInPeriod: "Berechnungszeitraum",
      fund: "Fonds",
      noRatingAvailable: "Kein Rating vorhanden.",
      performance_1y: "1 Jahr (p.a.)",
      performance_3y: "3 Jahre (p.a.)",
      performance_5y: "5 Jahre (p.a.)",
      performance_10y: "10 Jahre (p.a.)",
      performance_since_inception: "Seit Auflage (p.a.)",
      performance_current_year: new Date().getFullYear(),
      max_drawdown_crash: "Crash Drawdown",
      crashDrawdownChart: "Crash Drawdown",
      crashDrawdownSubtitle:
        "Wie hat sich der Fonds in vergangenen Stressphasen geschlagen?",
      crashDrawdownTooltip:
        "Für viele Anleger ist es weniger relevant, wie gut ein Fonds performt, wenn die Börsen steigen, sondern wie er sich in turbulenten Marktphasen schlägt. Eine Antwort auf diese Frage bietet die Grafik unten. Darin sind fünf Stressphasen aus den vergangenen zehn Jahren eingezeichnet (grau). Zu jeder dieser Stressphasen sehen Sie den maximalen Verlust des ausgewählten Fonds in diesem Zeitraum im Vergleich mit dem Durchschnitt der Vergleichsgruppe sowie dem Vergleichsindex.",
      market: "MSCI World",
      marketLong: "MSCI World Standard (Markt)",
      max_drawdown_1y: "Max Drawdown 1 Jahr",
      max_drawdown_3y: "Max Drawdown 3 Jahre",
      max_drawdown_5y: "Max Drawdown 5 Jahre",
      max_drawdown_since_inception: "Max Drawdown seit Auflage",
      volatility_1y: "Volatilität 1 Jahr (p.a.)",
      volatility_3y: "Volatilität 3 Jahre (p.a.)",
      volatility_5y: "Volatilität 5 Jahre (p.a.)",
      volatility_since_inception: "Volatilität seit Auflagejahr",
      volume: "Fondsvolumen",
      volumeUnit: "(in Mio. EUR)",
      volumeUnitShort: "Mio. EUR",
      ratingPoints: "Ratingpunkte",
      ratingPointsShort: "Rating-punkte",
      ratingPointsTooltip:
        "Scope vergibt für jeden Fonds Ratingpunkte auf einer Skala von 1 bis 100 . Die Ratingpunkte zeigen die relative Bewertung eines Fonds im Vergleich zu seiner Vergleichsgruppe an. Je höher die Punktzahl, desto besser wird der Fonds von Scope eingeschätzt.",
      fundsInPeerGroup: "Fonds der Vergleichsgruppe",
      topFundsTitle: "Fonds im Fokus",
      topFundsSubtitle:
        "Unsere Analysten bestimmen regelmäßig eine Auswahl an Fonds, die aktuell besondere Aufmerksamkeit verdienen – entweder aufgrund herausragender Ergebnisse oder aufgrund innovativer Investmentideen und Managementansätze.",
      ratingInFocusSubtitle:
        "Unsere Analysten bewerten nicht nur Fonds, sondern auch Investment Teams einzelner Asset Manager. Das Rating reflektiert zum einen die Kompetenz, Erfahrung und den Track Record und zum anderen die Prozessqualität und Ressourcenausstattung eines Investment Teams.",
      requestTeamRatingText:
        "Sie vermissen die Bewertung eines bestimmten Asset Managers bzw. eines Investment Teams? Kein Problem. Einfach Name des Investment Teams eingeben und Rating anfordern. Dies ist für Sie kostenlos und unverbindlich.",
      driver: "Rating Treiber",
      content: "Weitere Infos und Analysen",
      news: "News",
      newsSubtitle: "News und Infos zu Fonds",
      readMore: "Lesen",
      proceedToPayment: "Weiter zur Zahlung",
      details: "Details",
      noResults: "Keine Ergebnisse verfügbar.",
      fundNotFound: "Kein Treffer für den ausgewählten Fonds.",
      notFound: "Nicht gefunden",
      pleaseSelectFund:
        "Keine passenden Fonds für ausgewählte Kriterien gefunden.",
      veryGood: "Sehr gut",
      good: "Gut",
      average: "Durchschnittlich",
      belowAverage: "Unter-durchschnittlich",
      belowAverage2: "Unterdurchschnittlich",
      poor: "Schwach",
      legend: "Legende",
      veryLow: "Very low",
      low: "Gering",
      mid: "Mittel",
      medium: "Mittel",
      high: "Hoch",
      veryHigh: "Very high",
      rowsPerPage: "Zeilen pro Seite",
      monthly_report: "Monatsreport",
      key_information: "Basisinformationsblatt",
      key_investor: "KIID – Key Investor Information Document",
      fund_prospect: "Fondsprospekt",
      annual_report: "Jahresbericht",
      semi_annual_report: "Halbjahresbericht",
      fundBars: "Einzelbewertung | Scope Rating",
      fundBarsTooltips: {
        performance:
          "Bewertung der Performance-Indikatoren eines Fonds. Hierzu gehören etwa die relative Performance eines Fonds im Vergleich zu seiner Peergroup sowie die Stabilität der Fondsperformance.",
        safety:
          "Risikobewertung des Fonds: Je risikoärmer ein Fonds von Scope eingeschätzt wird, desto mehr Ratingpunkte erhält dieser für das Kriterium Risikobewertung.",
        cost: 'Bewertung der laufenden Kosten ("Ongoing Charges") eines Fonds im Vergleich zu seiner Vergleichsgruppe. Eine hohe Bewertung für dieses Kriterium steht für niedrige Kosten.',
        esg: "Result of evaluation by ESG aspects",
        team: "Bewertung des Investmentteams auf Basis qualitativer Faktoren wie Erfahrung und Ausbildung der Investmentprofis.",
        investmentProcess:
          "Qualitative Bewertung des Investmentprozesses hinsichtlich Disziplin und Robustheit. ",
      },
      value: "Wert",
      noInformation: "Keine Information.",
      showMoreDetails: "Mehr Details anzeigen",
      hideMoreDetails: "Details ausblenden",
      avg: "Durchschnitt",
      print: "Drucken",
      watchlistSubtitle:
        "Sie möchten die Ratingveränderungen einzelner Fonds unkompliziert im Blick behalten? Dann setzen Sie die entsprechenden Fonds einfach auf Ihre 'Watchlist' – und wir informieren Sie über jede Ratingänderung per Email.",
      watchlist: "Watchlist",
      watchlistTitle: "Meine Fonds - Watchlist",
      watchlistAdded: "Fonds zur Watchlist hinzugefügt.",
      watchlistRemoved: "Fonds von Watchlist gelöscht.",
      watchlistAddError: "Fehler beim Hinzufügen zur Watchlist.",
      watchlistDeleteError: "Fehler beim Entfernen aus Watchlist.",
      noWatchlist: "Fonds zur Watchlist hinzufügen.",
      actualRating: "Aktuelles Rating",
      prevRating: "Letzte Ratingänderung",
      upgrade: "Upgrade",
      downgrade: "Downgrade",
      same: "Keine",
      showAvg: "Durchschnitt anzeigen",
      hideAvg: "Durchschnitt verbergen",
      date: "Datum",
      cost: "Kosten- bewertung",
      esg: "ESG Score",
      general: "Stammdaten",
      custom: "Angepasst",
      costs: "Gebühren",
      valueLong: "Wertentwicklung",
      team: "Team",
      scopeRating: "Scope Rating",
      scopeRanking: "Scope Ranking",
      of: "von",
      investmentProcess: "Investmentprozess",
      noRatingInfo:
        'Für diesen Fonds ist derzeit noch keine Bewertung verfügbar. Wenn Sie an einer qualitativen Bewertung interessiert sind, klicken Sie bitte auf "Bewertung anfordern". Dies ist kostenlos und unverbindlich.',
      noRatingInfoShort:
        "Für diesen Fonds steht noch keine qualitative Bewertung zur Verfügung. Sollten Sie an einer qualitativen Bewertung interessiert sein, dann klicken Sie bitte einfach auf „Rating anfordern“. Dies ist für Sie kostenlos und unverbindlich.",
      noFundBarsInfo:
        "Für diesen Fonds steht noch keine qualitative Bewertung zur Verfügung.",
      request: {
        rating: "Rating anfordern",
        award: "Request award nomination",
        focus: "Request focus",
        selection: "Request selection",
        teamRating: "Request team rating",
        sendRequest: "Anfordern",
        theme: "Themen anfordern",
        info: "Informationen anfordern",
      },
      requestQualitativeRating: "Qualitatives Rating kostenfrei anfordern",
      fundRequestSent:
        "Vielen Dank! Wir haben Ihre Anfrage erhalten. Scope wird für Fonds, für die besonders häufig ein Rating angefordert wird, ein qualitatives Rating erstellen.",
      teamRequestSent:
        "Vielen Dank! Wir haben Ihre Anfrage erhalten. Scope wird für Investment Teams, für die besonders häufig eine Bewertung angefordert wird, ein qualitatives Rating erstellen.",
      anonymous: "Anonymous",
      noContent:
        "Für diesen Fonds liegen derzeit keine weiteren Informationen vor. Sind Sie an Details zum Fonds, seiner Anlagestrategie und den Einschätzungen des Fondsmanagements interessiert? Dann klicken Sie bitte einfach auf „Informationen anfordern“. Dies ist für Sie kostenlos und unverbindlich. ",
      requestInfo: "Informationen anfordern",
      compareWithPeergroup: "Vergleich mit Peergroup Ø",
      worse: "Schlechter",
      equal: "Gleich",
      better: "Besser",
      pts: "Pkt.",
      all: "Alle",
      equity: "Aktien",
      multiasset: "Mischfonds",
      fixedIncome: "Anleihen",
      other: "Sonstige",
      ratio: "Ratio",
      ratioTooltip:
        "Diese Kennzahl drückt das einfache Verhältnis von Performance zu Risiko (gemessen an der Volatilität) eines Fonds aus. Je größer die Kennzahl ist, desto vorteilhafter ist das Verhältnis aus Rendite und Risiko aus Sicht eines Anlegers. In Abgrenzung zur „Sharpe Ratio“ wird bei dieser Kennzahl der risikolose Zins nicht berücksichtigt.",
      ratioDesc: "Verhältnis von Rendite zu Risiko",
      trancheRatingTooltip:
        "Der angezeigte Rang bezieht sich stets auf die Haupttranche eines Fonds. Die von Ihnen ausgewählte ISIN ist eine Nebentranche, für die kein separater Rang ermittelt wird.",
      institutional:
        "Die ausgewählte ISIN ist eine institutionelle Tranche des Fonds mit hohen Mindestanlagesummen. Sie steht institutionellen Investoren offen.",
      recordDateText:
        "Gegenstand der Bewertungen & Berechnungen sind Datenlieferungen der Kapitalverwaltungsgesellschaften & öffentlich zugängliche Daten.",
      recordDate1:
        "Das Rating sowie die Berechnung sämtlicher Kennzahlen basieren auf Daten zum ",
      recordDate2:
        "Gegenstand der Bewertungen & Berechnungen ist das Fondsuniversum in Deutschland.",
      recordDate3:
        "Gegenstand der Berechnungen sind öffentlich zugängliche Daten.",
      fundsListHint:
        "Die unten aufgeführten Fonds sind nach Ratingpunkten sortiert. Bei gleicher Ratingpunktanzahl ist das Risiko-Rendite-Verhältnis ausschlaggebend.",
      minInvestment: "Mindestanlage",
      recordDate: "Daten zum",
      currency: "Währung",
      professionalInfo: "Nur für professionelle Anleger",
      noRatingDriver:
        "Für diesen Fonds steht noch keine qualitative Bewertung zur Verfügung. Sollten Sie an einer qualitativen Bewertung interessiert sein, dann klicken Sie bitte einfach auf „Rating anfordern“. Dies ist für Sie kostenlos und unverbindlich.",
      winnerLabel: "Scope Award 2020: Gewinner",
      winnerTooltip:
        "Dieser Fonds war Gewinner bei den Scope Investment Awards 2022",
      views: "views",
      upload: "Hochladen",
      viewFunds: "View fonds",
      success_dependent_compensation: "Success dependent compensation",
      objects_number: "Objektanzahl",
      liquidity_ratio: "Liquiditätsquote",
      debt_ratio: "Fremdkapitalquote",
      currency2: "Fondswährung",
      rental_ratio: "Vermietungsquote",
      ratingScore: "Rating Scores",
      ratingJustification: "Ratingbegründung",
      ratingDriver: "Ratingtreiber",
      deleteDocumentConfirm:
        "Sind Sie sicher, dass Sie dieses Dokument löschen möchten?",
      deleteNewsConfirm:
        "Sind Sie sicher, dass Sie diesen News-Artikel löschen möchten?",
      deletePMConfirm:
        "Sind Sie sicher, dass Sie diesen Portfolio Manager entfernen möchten?",
      fundReport: "Fonds Report",
    },
    fundCharts: {
      showAll: "Alle Tabellen anzeigen",
      hideAll: "Alle Tabellen ausblenden",
      show: "Anzeigen",
      showRating: "Rating anzeigen",
      hide: "Ausblenden",
      year_1: "1 Jahr",
      year_3: "3 Jahre",
      year_5: "5 Jahre",
      volatility_1y: "Volatilität 1 Jahr (p.a.)",
      volatility_3y: "Volatilität 3 Jahre (p.a.)",
      volatility_5y: "Volatilität 5 Jahre (p.a.)",
      performance_1y: "Rendite 1 Jahr (p.a.)",
      performance_3y: "Rendite 3 Jahre (p.a.)",
      performance_5y: "Rendite 5 Jahre (p.a.)",
      perf_1y: "Rendite 1 J.",
      perf_3y: "Rendite 3 J.",
      perf_5y: "Rendite 5 J.",
      export: "Exportieren",
      numberOfFunds: "Anzahl Fonds",
      ratingClasses: "Ratingklassen",
      points: "Punkte",
      interpretation: "Bewertung",
      noData: "Keine Daten vorhanden.",
      noChartData: "Keine Daten vorhanden.",
      performanceChart: {
        title: "Rendite in EUR",
        date: "Datum",
        performance: "Rendite",
        fund: "Fonds",
        index: "Index",
        fundPerformance: "Fonds Rendite in %",
        indexPerformance: "Index Rendite in %",
        peergroupPerformance: "Vergleichsgruppe Rendite in %",
        peergroup: "Vergleichsgruppe Ø",
      },
      peergroupComparisonChart: {
        title: "Peergroup-Vergleich",
        highchartTitle: "Performance der Peergroup in EUR",
        highchartSubtitle: "Gleitende 3-Monats-Performance",
        date: "Datum",
        performance: "Rendite ",
        fundPerformance: "Fonds Rendite in %",
        bestFundPerformance: "Performance bester Fonds in %",
        worstFundPerformance: "Performance schlechtester Fonds in %",
        fund: "Fonds",
        bestFund: "Bester Fonds",
        worstFund: "Schlechtester Fonds",
      },
      fundRatingChart: {
        title: "Fondsrating-Historie",
        date: "Datum",
        performance: "Rendite %",
        rank: "Rang",
        rating: "Scope Fondsrating",
        privateText:
          "Für weitere Einzelheiten zum Rating, wenden Sie sich bitte an info@scopeanalysis.com",
      },
      distributionChart: {
        title: "Verteilung innerhalb der Peergroup",
        riskIndicator: "Risiko-Indikator",
        performanceIndicator: "Performance-Indikator",
        good: "Stark",
        bad: "Schwach",
        fund: "Fonds",
        fundsInPeergroup: "Fonds in der Peergroup",
      },
      riskReturnChart: {
        title: "Volatilität / Rendite Matrix",
        volatility: "Volatilität:",
        perf: "Rendite:",
        fundVolatility: "Fonds Volatilität",
        fundPerformance: "Fonds Rendite",
        indexVolatility: "Index Volatilität",
        indexPerformance: "Index Rendite",
        peergroupVolatility: "Vergleichsgruppe Volatilität",
        peergroupPerformance: "Vergleichsgruppe Rendite",
        fund: "Fonds",
        index: "Index",
        peergroup: "Vergleichsgruppe Ø",
      },
      crashDrawdownChart: {
        date: "Datum",
        return: "Rückgabe",
        financialCrisis: "Finanzkrise",
        creditCrisis: "Europäische Kreditkrise",
        recessionFears: "Weltweite Rezessionsängste",
        tradeConflicts: "Zinsängste & Handelskonflikt",
        covid19: "COVID-19",
        ukraineCrisis: "Russian invasion of Ukraine",
      },
      classDistributionChart: {
        title: "Verteilung der Ratingklassen",
      },
      evolutionOfRiskChart: {
        title: "Volatilitat im Zeitverlauf ",
        fund: "Fonds",
        bestFunds: "Bester Fonds",
        bestFund: "Bester Fonds",
        worstFund: "Schlechtester Fonds",
        date: "Datum",
        fundRisk: "Fonds risiko in %",
        bestFundRisk: "Risiko bester Fonds in %",
        worstFundRisk: "Risiko schlechtester Fonds in %",
      },
    },
    contact: {
      contact: "Kontakt",
      summary:
        "Haben Sie Fragen oder Anregungen? Kein Problem: Füllen Sie unser Kontaktformular unten aus und wir melden uns schnellstmöglich bei Ihnen.",
      name: "Name",
      email: "E-Mail",
      phone: "Mobil",
      message: "Nachricht",
      send: "E-Mail senden",
      success: "E-Mail erfolgreich versendet.",
      fillInFields: "Bitte sämtliche Pflichtfelder ausfüllen.",
      error: "E-Mail senden fehlgeschlagen. Bitte erneut versuchen.",
      scopeAnalysis: "Scope Fund Analysis GmbH",
      managingDirectors: "Geschäftsführer",
      headquartersBerlin: "Berlin",
      frankfurtOffice: "Frankfurt am Main",
    },
    privacy: {
      title: "Datenschutzerklärung",
      purposeOfPolicy: "1. Ziel der Datenschutzbestimmungen",
      website:
        "Die Webseite („Scope Fund Analysis“) wird von Scope Fund Analysis GmbH betrieben.",
      thisPrivacyPolicy:
        "Die Datenschutzbestimmungen dienen Scope Fund Analysis GmbH („Wir“ oder „der für die Verarbeitung Verantwortliche“) als Grundlage für das Erfassen und die Verarbeitung personenbezogener Daten.",
      personalDataMeans:
        "Personenbezogene Daten sind Einzelangaben über persönliche oder sachliche Verhältnisse einer bestimmten Person („Personenbezogene Daten“).",
      thisPolicySetsOut:
        "Die Datenschutzbestimmungen schaffen notwendige Rahmenbedingungen für die Verarbeitung personenbezogener Daten von Internetnutzern, Kunden oder Interessenten der Scope Fund Analysis Webseite („Sie“ oder „Betroffene Personen“) durch den für die Verarbeitung Verantwortlichen.",
      pleaseReadTheFollowing:
        "Bitte lesen Sie die folgenden Informationen sorgfältig durch, um unsere Richtlinien und die Verarbeitung Ihrer personenbezogenen Daten zu verstehen.",
      theProvisionsSetOut:
        "Die in dieser Datenschutzerklärung aufgeführten Richtlinien sind durch das Bundesdatenschutzgesetz gesetzlich festgelegt und an die Datenschutzprinzipien angepasst - EU-Verordnung 2016/679 („DSGVO“).",
      forMoreInformation:
        "Für nähere Ausführungen über den bestehenden Rechtsrahmen, lesen Sie bitte den DSGVOText unter (https://ec.europa.eu/info/law/law-topic/data-protection/data-protection-eu_de).",
      whichData: "2. Datenerfassung durch Scope Fund Analysis GmbH",
      theTypeOfData:
        "Die Art der aufgezeichneten Daten, die wir erheben müssen, variieren je nach Geschäftsbeziehung mit der betroffenen Person:",
      clientsAndSubscribers: "Kunden und Interessenten:",
      theDataController:
        "Der Verantwortliche wird ausschließlich solche personenbezogene Daten verlangen, die für die Erfüllung des Vertrages zwischen einem Kunden und dem Verantwortlichen und die Erbringung seiner Leistungen unabdingbar sind; beispielsweise benötigt der für die Verarbeitung Verantwortliche folgende Informationen:",
      nameAndSurname: "Vor- und Nachname",
      emailAddress: "E-Mail-Adresse",
      telephoneNumber: "Telefonnummer/Faxnummer",
      companyAndBusiness: "Firmen- und Geschäftsadresse",
      password: "Passwort",
      gender: "Geschlecht",
      websiteUsers: "Webseiten-Nutzer:",
      theDataControllersWebsite:
        "Es werden einige Cookies auf der Webseite des Verantwortlichen eingesetzt, die in Einzelfällen die Besuchervorgänge des Nutzers verfolgen. Ziel ist es, dem Kunden beim Besuch der Scope Fund Analysis Plattformen ein besseres und reibungsloses Kundenerlebnis zu bieten; für weitere Einzelheiten zu Cookies, lesen Sie bitte den Abschnitt „Verwendung von Cookies“.",
      whyScopeAnalysis:
        "3. Warum Scope Fund Analysis GmbH personenbezogene Daten erfasst",
      theCollectionOfPersonal:
        "Die Erfassung von personenbezogenen Daten durch Scope Fund Analysis muss im Zusammenhang mit dem „Vertragszweck“ stehen: jegliche personenbezogene Angaben, die der für die Verarbeitung Verantwortliche von der betroffenen Person erfasst, sind für die Erfüllung der spezifischen, festgelegten und rechtmäßigen Anliegen notwendig. Diese wurden im Vorfeld im Vertrag zwischen dem für die Verarbeitung Verantwortlichen und dem Betroffenen deutlich festgelegt.",
      asAnExample: "Als Beispiel, bitten wir um personenbezogene Daten, um:",
      provideRegular:
        "Unseren Interessenten regelmäßige Informationen und Updates zu übermitteln",
      performAThorough:
        "Eine umfassende Analyse der Fördermittel und der Vermögensverwaltungsgesellschaften durchzuführen, so wie es im Vertrag mit der/ den betroffenen Person(en) festgelegt wurde",
      providingTechnical:
        "Auf den Scope Fund Analysis Onlineplattformen technische Unterstützung anzubieten",
      sendClientsRelevant:
        "Den Kunden relevante Marketingmaterialien (falls zutreffend) zukommen zu lassen",
      dealWithClients: "Auf Fragen von Kunden oder Interessenten einzugehen",
      howScopeAnalysis:
        "4. Wie Scope Fund Analysis GmbH personenbezogene Daten erfasst",
      weCollectPerseonal:
        "Wir erfassen personenbezogene Angaben von verschiedenen Quellen und hauptsächlich:",
      directlyFromTheData:
        "Unmittelbar vom Betroffenen durch Kontaktformulare, Vertragsanträge sowie Vertragserklärungen",
      fromThirdParties:
        "Von Dritten: zum Beispiel, wenn ein Kunde eine weitere Kontaktstelle für ein bestimmtes Anliegen oder eine bestimmte Dienstleistung angibt",
      fromTheDataController:
        "Von Plattformen des Verantwortlichen, entweder über Nutzeranmeldung oder der Überprüfung der Besuchervorgänge, die mit Hilfe von Cookies gespeichert werden.",
      whateverMethodIsUsed:
        "Unabhängig von der Verarbeitungsmethode, ist der für die Verarbeitung Verantwortliche dazu verpflichtet, immer genau und unverzüglich den Betroffenen zu informieren und seine ausdrückliche Einwilligung einzuholen.",
      howScopeAnalysisHandles:
        "5. Wie Scope Fund Analysis GmbH mit personenbezogene Daten umgeht",
      thePersonalDataCollected:
        "Die Verarbeitung und Nutzung von personenbezogenen Daten, die von Scope Fund Analysis Webseite erfasst wurden, sind nur unter den folgenden Grundprinzipien zulässig:",
      minimization: "Datensparsamkeit:",
      onlyThosePersonal:
        " Verarbeitet werden ausschließlich personenbezogene Daten, die mit einem Vertragszweck, welcher im Vorfeld eines Vertrages zwischen dem Verantwortlichen und dem Betroffenen festgelegt wurden, einhergehen; die Art der benötigten personenbezogenen Angaben werden von Fall zu Fall festgelegt.",
      integrity: "Vertraulichkeit:",
      theInformationCollected:
        " Personenbezogene Daten des Betroffenen werden vom Verantwortlichen jederzeit vertraulich behandelt; im Falle der Weitergabe von personenbezogenen Daten von einer Einheit zu einer anderen innerhalb der Scope Group, wird der Betroffene im Vorfeld eines Vertrages mit dem Verantwortlichen umgehend informiert (in Form einer Einwilligungserklärung) oder sobald die Weitergabe erforderlich ist; das Datengeheimnis bleibt weiterhin bestehen",
      limitedStorage: "Aufbewahrungsfrist:",
      theDataControllerWill:
        " Personenbezogene Daten der betroffenen Person werden nur so lange vom Verantwortlichen im Datenbestand gespeichert, bis alle geschäftsbezogenen Prozesse abgewickelt wurden, so wie im Vertrag zwischen den Parteien vorgesehen; nach Beendigung des Vertrages, werden personenbezogene Daten nach Ablauf der Aufbewahrungsfrist, die zwischen sechs Monaten und zehn Jahren je nach Fall variieren kann, vollständig gelöscht",
      security: "Datensicherheit:",
      theDataControllerIsCommited:
        " Der für die Verarbeitung Verantwortliche ist dazu verpflichtet, personenbezogene Daten jederzeit gegen interne und externe Bedrohungen zu schützen, einschließlich aber nicht begrenzt auf versehentlichen Verlust, unberechtigten Zugriff und unrechtmäßige Verarbeitung; Dennoch kann nicht gewährleistet werden, dass die Datenübertragung über das Internet oder anderen Netzwerken 100% sicher ist. Trotz unseres Bestrebens, Ihre personenbezogenen Angaben zu schützen, können und werden wir folglich keine Garantie für die Sicherheit jeglicher Angaben übernehmen, die Sie auf oder über die Plattformen oder Dienstleistungen weitergeben; so tun Sie dies auf eigene Gefahr",
      transferability: "6. Übermittlung",
      whilePerforming:
        "Bei der Durchführung von einzelnen Geschäftstätigkeiten kann der für die Verarbeitung Verantwortliche externe Dienstleister, die hauptsächlich für den technischen Bereich zuständig sind, nutzen.",
      theDataControllerIsCommitedTo:
        "Im Falle einer Datenübermittlung zu den genannten Dienstleistern, ist der Verantwortliche dazu verpflichtet, die betroffene Person darüber zu informieren und den Grund, den Zweck und die Dauer der Datenübermittlung ausführlich zu erklären.",
      theDataControllerIsAlso:
        "Der für die Verarbeitung Verantwortliche muss sicherstellen, dass externe Dienstleister ein zu dieser Datenschutzerklärung gleichwertiges Datenschutzniveau für die personenbezogenen Daten seiner Kunden/Anbieter gewährleisten kann.",
      theDataControllerOperates:
        "Der für die Verarbeitung Verantwortliche ist hauptsächlich in der EU und in den Mitgliedsstaaten des EWR tätig, was den Rechtsvorschriften der DSGVO zum Schutz personenbezogener Daten entspricht.",
      confidentiality: "7. Vertraulichkeit und Datensicherheit",
      theDataControllerIsCommitedToKeep:
        "Der für die Verarbeitung Verantwortliche ist dazu verpflichtet, personenbezogene Daten von Betroffenen zu sichern und diese jederzeit vertraulich zu behandeln.",
      toEnsurePersonalData:
        "Für die Sicherstellung der sorgfältigen Verarbeitung von personenbezogenen Daten, gewährleistet der für die Verarbeitung Verantwortliche, dass:",
      personalDataAreHandled:
        "Personenbezogene Daten von Mitarbeitern verarbeitet werden, die dafür ausgebildet sind",
      theProcessingAndHandling:
        "Die Verarbeitung und der Umgang mit personenbezogenen Daten angemessen überwacht ist",
      personalDataProcessing:
        "Die Verarbeitungsverfahren der personenbezogenen Daten des Verantwortlichen regelmäßig überprüft und begutachtet sind",
      scopeEmployees:
        "Scope Mitarbeiter über das Datenschutzgesetz unterrichtet und über Folgen und mögliche Auswirkungen bei einer Verletzung des Datenschutzes aufgeklärt sind.",
      inCaseABreach:
        "Im Falle einer Verletzung der Verordnung über die Verarbeitung der personenbezogenen Daten, muss der Verantwortliche die Betroffenen sofort benachrichtigen sowie effiziente und unverzügliche Maßnahmen ergreifen, die erforderlich sind, um die Auswirkungen der Verletzung zu verringern.",
      specifically: "Bei einer konkreten Verletzung des Datenschutzes:",
      theDPOMustBe:
        "Im Falle einer Verletzung des Datenschutzes muss der DSB binnen 24 Stunden, nachdem die Verletzung bekannt wurde, schriftlich benachrichtigt werden.",
      theDPOWillTakeCare:
        "Der DSB muss die Verletzung sowohl der zuständigen Aufsichtsbehörde als auch allen relevanten Anspruchspersonen melden und die Verletzung näher untersuchen.",
      theDPOWillNotify:
        "Der DSB informiert den Kunden und die zuständige Aufsichtsbehörde über die Ergebnisse der Untersuchungen.",
      theDataControllerIsAlsoCommited:
        "Darüber hinaus ist der für die Verarbeitung Verantwortliche verpflichtet, eine umfassende und gründliche, interne sowie externe Untersuchung zur Bestimmung der grundlegenden Ursache durchzuführen (falls nötig unter Beteiligung der zuständigen Aufsichtsbehörde). Erforderliche Maßnahmen muss der Verantwortliche zeitnah ergreifen, damit zukünftig Verletzungen verhindert werden können.",
      useOfCookies: "8. Verwendung von Cookies",
      theDataControllerUsesCookies:
        "Der für die Verarbeitung Verantwortliche verwendet Cookies beim Betreiben seiner Internetplattformen: Die Funktion solcher Cookies ist die Besuchervorgänge von Nutzern auf Plattformen/Webseiten teilweise zu verfolgen, mit dem Ziel, ein besseres Kundenerlebnis zu gewährleisten; in der Regel kann der Nutzer die Verwendung von Cookies an jedem PC über die Einstellungsseite anpassen.",
      belowIsTheList:
        "Im Folgenden finden Sie eine Liste der verwendeten Cookies, die von der Scope Fund Analysis Webseite genutzt werden:",
      cookieName: "Cookie name",
      function: "Funktion",
      _ga: "Cookie von Google Analytics, um Nutzer zu unterscheiden (https://developers.google.com/analytics/devguides/collection/analyticsjs/cookieusage)",
      _gat: "Cookie von Google Analytics, um die Anzahl der Beiträge zu drosseln (https://developers.google.com/analytics/devguides/collection/analyticsjs/cookieusage)",
      _gid: "Cookie von Google Analytics, um Nutzer zu unterscheiden (https://developers.google.com/analytics/devguides/collection/analyticsjs/cookieusage)",
      socialMedia: "9. Social Media Plugins",
      scopeAnalysisWebsiteUses:
        "Scope Fund Analysis verwendet Plugins von Social-Media-Plattformen, die Ihnen ermöglichen, Informationen zu teilen oder uns mit Ihrem Social-Media-Profil zu folgen.",
      whenYouClick:
        "Wenn Sie auf ein Plugin klicken, wird die Social Media über die Internetseite, die Sie besucht haben, und dessen Inhalt informiert.",
      forMoreDetails:
        "Für ausführlichere Informationen über die Datenschutzbestimmungen der einzelnen Social Media, gehen Sie bitte auf die folgenden Internetseiten:",
      forTwitter: "Für Twitter:",
      twitterPlugins: "Plugins",
      twitterPolicy: "Datenschutzbestimmung",
      forLinkedIn: "Für LinkedIn:",
      linkedInPlugins: "Plugins",
      linkedInPolicy: "Datenschutzbestimmung",
      forBloomberg: "Für Bloomberg:",
      bloombergPolicy: "Datenschutzbestimmung",
      dataSubjectsRights: "10. Rechte und Pflichten der Betroffene",
      althoughSome:
        "Betroffene sind und bleiben die Urheber der personenbezogenen Daten, auch wenn diese vom Verantwortlichen verarbeitet und gespeichert werden. Aus diesem Grund kann jeder Betroffene folgende Rechte geltend machen:",
      rightToAccess: "Zugriffsrecht:",
      dataSubjectsHaveTheRight:
        " Betroffene haben das Recht auf jegliche Informationen, die durch den Verantwortlichen gespeichert wurden, zuzugreifen; außerdem muss der für die Verarbeitung Verantwortliche alle ihm möglichen Schritte einleiten, um die Aktualität und Richtigkeit der personenbezogenen Daten sicherzustellen",
      rightOfRevocation: "Widerspruchsrecht:",
      atAnyTime:
        " Die betroffene Person hat das Recht jederzeit gegen die Verarbeitung seiner/ihrer gespeicherten personenbezogenen Daten durch den Verantwortlichen Widerspruch einzulegen; Die betroffene Person hat das Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, beispielsweise kann die Anwendbarkeit der Daten begrenzt werden, anstatt allen Bereichen zu widersprechen. Widerspruchsschreiben gegen die Verarbeitung von personenbezogenen Daten müssen per E-Mail an privacy@scopegroup.com gesendet werden",
      rightOfErasure: "Recht auf Löschung:",
      dataSubjectsHaveTheRightTo:
        " Die betroffene Person hat das Recht, von dem Verantwortlichen zu verlangen, dass betreffende personenbezogene Daten unverzüglich gelöscht werden: Anträge auf Löschung der personenbezogenen Daten müssen per E-Mail an privacy@scopegroup.com gesendet werden; Dieses Recht gilt nicht, wenn aus rechtlichen Gründen oder aus Gründen des öffentlichen Interesses eine Archivierung erforderlich ist",
      rightOfDataPortability: "Recht auf Datenübertragbarkeit:",
      peresonalDataCanBeTransferred:
        " Die betroffene Person hat das Recht eine Übertragung der personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen Dienstleister zu erwirken; dieses Recht gilt ausschließlich, wenn die Verarbeitung auf einer Einwilligung der betroffenen Person beruht",
      rightOfComplain: "Beschwerderecht:",
      dataSubjectsHaveTheRightToObject:
        " Die betroffene Person hat das Recht die Art und Weise, wie seine/ihre personenbezogenen Daten durch den Verantwortlichen verarbeitet werden, zu beanstanden und das Recht eine Beschwerde direkt an die zuständige Aufsichtsbehörde einzureichen.",
      theDataControllerIsCommitedToKeepThePersonal:
        "Der für die Verarbeitung Verantwortliche ist verpflichtet gespeicherte personenbezogene Daten richtig, vollständig und auf dem neusten Stand zu speichern. Bei entsprechenden Änderungen der personenbezogenen Daten, die einen möglichen Einfluss auf die persönlichen Aufzeichnungen haben könnten, muss die betroffene Person den Verantwortlichen davon in Kenntnis setzen.",
      updateOfThePolicy:
        "11. Aktualität der Datenschutzbestimmungen und Anlaufstelle",
      theDataControllerIsCommitedToPeriodically:
        "Die Aktualität der Datenschutzbestimmungen muss in regelmäßigen Abständen vom für die Verarbeitung Verantwortlichen überprüft werden, mit dem Ziel, der betroffenen Person einen umfassenden und bestmöglichen Schutz zu gewährleisten: Auf der Webseite von Scope Fund Analysis wird, ohne Vorankündigung, die aktualisierte Version der Datenschutzbestimmungen hinterlegt. Den Nutzern wird daher dringend empfohlen, die offizielle Internetseite von Scope Fund Analysis regelmäßig auf aktualisierte Versionen zu prüfen.",
      forFutherInformation:
        "Für weitere Informationen oder Rückfragen bezüglich der Datenschutzbestimmungen von Scope Fund Analysis Webseite, kontaktieren Sie uns unter privacy@scopegroup.com",
      additionalContacts: "12. Weitere Ansprechpartner",
      dataProtectionOfficer: "Datenschutzbeauftragter: ",
      dataController: "Verantwortliche für die Verarbeitung:",
      managingDirector: "Geschäftsführung: Lars Edler, Christian Werner",
      commercialRegister: "Handelsregister Berlin HRB 97933 B",
      supervisoryAuthority: "Aufsichtsbehörde",
    },
    legalNotice: {
      title: "Impressum",
      managingDirector: "Geschäftsführung: Lars Edler, Christian Werner",
      commercialRegister: "Handelsregister: Amtsgericht Berlin HRB 97933 B",
      responsibleForTheContent:
        "Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:",
      disputeSettlement: "Streitschlichtung",
      theEuropeanCommision:
        "Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: ",
      weAreNotWilling:
        "Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.",
      disclaimer: "Haftungshinweis",
      allInformation:
        "Alle Angaben dieses Internetangebotes wurden sorgfältig geprüft. Wir bemühen uns, dieses Informationsangebot stetig zu erweitern und zu aktualisieren. Eine Garantie für die Vollständigkeit, Richtigkeit und letzte Aktualität kann jedoch nicht übernommen werden. Die Scope Fund Analysis GmbH stellt diese Informationen ohne jegliche Zusicherung oder Gewährleistung jedweder Art, sei sie ausdrücklich oder stillschweigend, zur Verfügung. Die Scope Fund Analysis GmbH schließt jegliche Haftung für Schäden, die direkt oder indirekt aus der Benutzung dieser Website entstehen, aus, soweit diese nicht auf Vorsatz oder grober Fahrlässigkeit von der Scope Fund Analysis GmbH beruhen. Die Scope Fund Analysis GmbH ist als Inhaltsanbieter nach § 7 Abs. 1 TMG für die eigenen Inhalte, die auf dieser Website zur Nutzung bereitgehalten werden, nach den allgemeinen Gesetzen verantwortlich. Von diesen eigenen Inhalten sind Querverweise („Links“) auf die von anderen Anbietern bereitgehaltenen Inhalte zu unterscheiden. Durch den Querverweis hält die Scope Fund Analysis GmbH insofern fremde Inhalte zur Nutzung bereit. Diese fremden Inhalte wurden bei der erstmaligen Link-Setzung daraufhin überprüft, ob durch sie eine mögliche zivilrechtliche oder strafrechtliche Verantwortlichkeit ausgelöst wird. Es ist jedoch nicht auszuschließen, dass die Inhalte im Nachhinein von den jeweiligen Anbietern verändert werden. Die Scope Fund Analysis GmbH überprüft die Inhalte, auf die sie in ihrem Angebot verweist, nicht ständig auf Veränderungen, die eine Verantwortlichkeit neu begründen könnten. Sollten Sie der Ansicht sein, dass die verlinkten externen Seiten gegen geltendes Recht verstoßen oder sonst unangemessene Inhalte haben, so teilen Sie uns dies bitte mit.",
      theLaws:
        "Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des Übereinkommens der Vereinten Nationen über Verträge über den internationalen Warenkauf (CISG).",
      ifTheClient:
        "Ist der Kunde Kaufmann oder eine juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen oder hat er keinen allgemeinen Gerichtsstand in Deutschland, ist ausschließlicher Gerichtsstand für alle Rechtsstreitigkeiten, die aus oder im Zusammenhang mit der Benutzung der Website resultieren, Berlin, Deutschland.",
      copyright: "Urheberrecht",
      copyrightContent: `Copyright ${new Date().getFullYear()} / Unternehmensgruppe „Scope SE & Co. KGaA“ und die Tochtergesellschaften „Scope Ratings GmbH“, „Scope Ratings UK Ltd.“, „Scope Fund Analysis GmbH“, „Scope Innovation Lab GmbH“ und „Scope ESG Analysis GmbH“ (auch als „SCOPE“ oder „Scope Group“ bezeichnet). Alle Rechte vorbehalten. Alle Inhalte (Texte, Bilder, Grafiken, Ton-, Video- und Animationsdateien sowie deren Anordnung u. a.) auf dieser Website unterliegen dem Schutz des Marken- und/oder Urheberrechts sowie anderer Schutzgesetze. Der Rechtsschutz gilt auch gegenüber Datenbanken und ähnlichen Einrichtungen. Sofern nicht anderweitig ausdrücklich erlaubt, sind die Inhalte nur für den bestimmungsgemäßen Abruf im Internet frei nutzbar. Die Inhalte dieser Website dürfen außerhalb der Grenzen des Urheberrechts ohne schriftliche Genehmigung nicht in irgendeiner Form vervielfältigt, verbreitet, verändert oder Dritten zugänglich gemacht werden. Soweit nicht anders angegeben, sind alle Markenzeichen auf dieser Website markenrechtlich geschützt.`,
    },
    methodology: {
      title: "Methodik",
      subtitle:
        "Hier finden Sie detaillierte Informationen zu den verwendeten Methodiken und Ratingprozessen. Die Scope Fondsrating Methodik ist seit mehr als 20 Jahren im Markt fest etabliert.",
      scopeFundsRating: "1.	Scope Mutual Fund Rating",
      introduction: "Einleitung",
      introductionText1:
        "Das Scope Mutual Fund Rating ist ein etabliertes Verfahren zur Bewertung der Managementqualität von offenen Investmentfonds hinsichtlich Performancebeitrag und Risikominderungsmaßnahmen gegenüber der Peergroup (Vergleichsgruppe). Ein ",
      introductionText2: "qualitatives Rating, ",
      introductionText3:
        "das eine tiefgehende Einsicht in die Arbeits-prozesse der Fondsgesellschaft voraussetzt, wird nur im Auftrag der jeweiligen Fondsgesellschaft vorgenommen. Für Fonds mit einer Historie von über 60 Monaten wird von Scope ohne Auftrag der Fondsgesellschaft ein rein ",
      introductionText4: "quantitatives Rating ",
      introductionText5: "ermittelt.",
      methodology: "Methodik",
      methodologyText1:
        "Grundsätzlich setzt sich das Scope Scope Mutual Fund Rating immer aus einem Performance- und einem Risiko-Indikator zusammen, welche die gewichtete Summe weiterer Sub-Indikatoren darstellen.",
      methodologyText2:
        "Bei jungen Fonds mit einer Historie von weniger als sechs Monaten erfolgt die Evaluierung der Indikatoren zunächst ausschließlich qualitativ mittels eines Kriterienkatalogs. Bei dem Scope Mutual Fund Rating handelt es sich um ein relatives Rating, d.h. der Fonds wird immer im Vergleich zu einer vorher festgelegten Peergroup bewertet. Eine Peergroup sollte aus mindestens 20 vergleichbaren und von Scope Analysten ausgewählten Fonds bestehen.",
      methodologyText3: "Die qualitative Bewertung ",
      methodologyText4:
        "wird einmal jährlich durchgeführt. Ab einem Track-Record des Fonds von sechs Monaten werden die qualitativen Bewertungskriterien im Zeitablauf linear durch ein quantitatives Kennzahlensystem substituiert – bis zu einem Anteil der qualitativen Kriterien von 40% nach 60 Monaten. Ab einer Fondshistorie von über 60 Monaten beträgt der quantitative Anteil konstant 60%, während qualitative Bewertungskriterien zu einem Anteil von 40% in das Bewertungsergebnis einfließen.",
      methodologyText5:
        "Abbildung: Grafische Darstellung der qualitativen Rating-Methodik bei Auftrag durch die Fondsgesellschaft.",
      methodologyText6:
        "Sollte kein Auftrag der jeweiligen Fondsgesellschaft vorliegen, wird von Scope ohne Abstimmung mit der Gesellschaft für Fonds mit einer Historie von mindestens 60 Monaten ein rein ",
      methodologyText7: "quantitatives Rating",
      methodologyText8: "ermittelt.",
      ratingProcess: "Rating-Prozess",
      ratingProcessText1:
        "Grundlage für die Bewertung der Fondsqualität ist ein mehrdimensionales Bewertungsmodell, in das sowohl Performance als auch Risiko-Indikatoren einfließen. Der Performance-Indikator (70% Gewichtung) berücksichtigt die relative Performance, die langfristige Ertragskraft und die Stabilität der Fondsperformance. Der Risiko-Indikator (30% Gewichtung) bewertet das Timing-Risiko, das Verlustrisiko und das Verhaltensrisiko des Fonds. Sowohl bei der quantitativen wie auch qualitativen Bewertung existieren Interdependenzen zwischen den Performance- und Risiko-Indikatoren. Üblicherweise geht eine hohe Outperformance mit einem hohen Tracking-Error einher. Dieser wirkt sich jedoch wiederum bewertungsschwächend auf das Verhaltensrisiko aus. Je aktiver der Manager agieren kann, umso schwächer ist in der Regel das Verhaltensrisiko bewertet.",
      ratingProcessText2: "Abbildung: Bewertungskategorien",
      ratingProcessLabel1: "Performance-Beitrag",
      ratingProcessLabel2: "Stabilität",
      ratingProcessLabel3: "Relative Performance",
      ratingProcessLabel4: "Langfristige Ertragskraft",
      ratingProcessLabel5: "Risikominderung",
      ratingProcessLabel6: "Timing-Risiko",
      ratingProcessLabel7: "Verhaltensrisiko",
      ratingProcessLabel8: "Verlustrisiko",
      ratingClasses: "Ratingklassen",
      ratingClassesText1:
        "Nach Zusammenfassung der Kriterien für die einzelnen Komponenten und einer Gesamtbewertung des Fonds wird die erreichte Punktzahl einer von fünf Bewertungsklassen zugeteilt, die von A („sehr gut“) bis E („schwach“) reichen. Für jede Kennzahl wird dem jeweiligen Fonds im Vergleich mit seinen Mitbewerbern (der gleichen Peergroup) je nach Schwäche bzw. Stärke eine Punktzahl von 1 bis 100 gegeben. Ein Fonds, dessen Ausprägung einer bestimmten Kennzahl dem Median des entsprechenden Segments entspricht, erhält für diese Kennzahl 50 Punkte. Die für das Gesamt-Rating relevante Gesamtpunktzahl ergibt sich aus den gewichteten Punktzahlen der einzelnen Kennzahlen.",
      ratingClassesText2:
        "Im Falle einer wesentlichen personellen Veränderung im Fondsmanagement wird das Scope Mutual Fund Rating für den entsprechenden Fonds mit dem Zusatz „ur“ für „under review“ – also „unter Beobachtung“ – dargestellt.",
      ratingClassesText3:
        "Ein sogenannter Top-Fonds, also ein mit „A“ oder „B“ bewerteter Fonds, zeigt dabei an, dass der entsprechende Fonds über einen mittleren Zeithorizont eine stabile überdurchschnittliche Performance mit relativ niedrigem Risiko aufweisen sollte.",
      investmentTeamRating: "2.	Investment Team Rating",
      intro: "1.	Einführung",
      introText1:
        "Scope Fund Analysis (Scope) vergibt Ratings für die Qualität und die Kompetenz von Investment Teams, deren verwaltetes Vermögen überwiegend in liquide Anlageinstrumente investiert ist. Die Analyse greift einzelne Investmentteams mit eigenen Produkten und differenzierten Strategien aus dem Kontext des Unternehmens heraus und konzentriert sich bei der Bewertung auf wichtige Aspekte des Asset Managements, die direkt im Einflussbereich des Teams liegen und unmittelbar Auswirkung auf den Investmenterfolg des Teams haben.",
      introText2:
        "Das Rating bietet damit einen wesentlichen Beitrag für den Entscheidungsprozess von Investoren und gibt Investmentteams die Möglichkeit, die vielschichtigen Aspekte von Teamkompetenz und Track Record sowie der Prozessqualität und Ressourcenausstattung in einer Ratingnote zu verdichten.",
      ratingProcess2: "2.	Ratingprozess",
      ratingProcess2Text1:
        "Das Asset Management Rating (AMR) für Investment Teams – kurz: Investment Team Rating – wird auf einer Skala von AAA",
      ratingProcess2Text2: " bis C",
      amr: "AMR",
      ratingProcess2Text3:
        " abgebildet. Die Bewertung erfolgt anhand eines Scorecard-Ansatzes, mit Hilfe dessen eine Vielzahl von qualitativen und quantitativen Einzelkriterien geprüft werden. Entsprechend der Ausprägung der Qualität werden für jedes Kriterium ein bis vier Punkte vergeben, wobei ein Punkt dem institutionellen Mindeststandard entspricht und vier Punkte für exzellente Qualität stehen.",
      ratingProcess2Text4:
        "Die Bewertung erfolgt stets im Kontext der relevanten Peergroup, so dass eine hohe Konsistenz und Vergleichbarkeit über ein breites Spektrum von Investmentteams mit ähnlichem Investment-Fokus gewährleistet werden kann. Scope nimmt die Einordnung bei Einzelkriterien der Methodik stets im Sinne des Grundgedankens des jeweiligen Einzelkriteriums vor, um der Vielfalt der Branche gerecht zu werden.",
      ratingProcess2Text5:
        "Die Scorecard-Analyse umfasst folgende vier Bereiche, genannt Panels:",
      investmentProfessionals: "Investment Professionals",
      investmentProfessionalsText1:
        "Scope analysiert die Investmentkompetenzen der Teammitglieder, den für das Investmentgebiet einschlägigen Erfahrungsschatz des Führungspersonals sowie der Teammitglieder, die Teamorganisation sowie die Incentivierung der Investmentprofessionals.",
      investmentAndResearch: "Investmentprozess und Research",
      investmentAndResearchText1:
        "Scope bewertet die Stringenz und Plausibilität des Investmentprozesses. Der Stellenwert und die Qualität des zur Verfügung stehenden Researchs, das die Grundlage für angemessene Investmententscheidungen bilden sollte, wird von Scope ebenso beleuchtet wie die Einbettung und Umsetzung von Richtlinien des Investmentrisikomanagements.",
      marketAndPerformance: "Marktstellung und Performance",
      marketAndPerformanceText1:
        "In diesem Panel analysiert Scope die Alleinstellungsmerkmale des Produktportfolios und der Investmentstrategien. Die Entwicklung der Assets under Management sowie des Investment Track Records des Teams mit seinen Strategien wird ebenfalls bewertet, da sie vom Funktionieren des Investmentansatzes zeugen.",
      further: "Weitere interne und externe Ressourcen",
      furtherText1:
        "Wichtige Funktionsbereiche, die zwar außerhalb des unmittelbaren Teameinflusses liegen, sich aber stark auf den Investmenterfolg auswirken, sind daher aus Sicht von Scope ebenfalls relevant für eine Beurteilung von Qualität und Kompetenz des Investmentmanagements im Rahmen eines Investment Team Ratings. Daher beleuchtet Scope die Ausstattung des Investmentteams mit modernen IT-Systemen, den Auswahlprozess und die Leistungsüberprüfung etwaiger externer Dienstleister sowie weitere Aspekte des Risikomanagements im Unternehmen.",
      furtherText2:
        "Die vollständige Investment Team Rating-Methodik finden Sie ",
      furtherText3: "hier.",
      ratincScale: "Bewertungskala für Investment Team Ratings",
      mutualFunds: "Investmentfonds (UCITS)",
      realEstateFunds: "Offene Fonds (AIF)",
      closeEndFunds: "Geschlossene Fonds (AIF)",
      amAndIssuersOfCert: "Asset Manager & Zertifikate-Emittenten",
    },
    notFound: {
      title: "404",
      subtitle: "Seite nicht gefunden.",
    },
    news: {
      title: "Fondsrating & Research News",
      subtitle:
        "Die Ratingagentur Scope veröffentlicht regelmäßig Analysen und Research zu einzelnen Fonds und zu Investmentthemen. Folgend eine aktuelle Auswahl.",
      back: "Zurück",
      previous: "Zurück",
      next: "Weiter",
      search: "Suche",
      category: "Category",
      document: "Document",
      video: "Video",
      podcast: "Podcast",
      more: "Mehr",
      error:
        "Abruf von Nachrichten derzeit nicht möglich. Bitte versuchen Sie es später noch einmal.",
      newsNotFound: "Nachrichten nicht gefunden.",
      noMachingNews: "Keine passenden Nachrichten gefunden.",
    },
    reports: {
      title: "Aktuelle Reports und Studien",
      subtitle:
        "Hier finden Sie die vollständigen Rating- und Research Reports. Sie enthalten sämtliche Grafiken, Daten der Ratingtabellen der jeweiligen Publikation.",
      back: "Zurück",
      previous: "Zurück",
      next: "Weiter",
      more: "Mehr",
      download: "Download Report",
      error:
        "Abruf von Nachrichten derzeit nicht möglich. Bitte versuchen Sie es später noch einmal.",
      reportsNotFound: "Nachrichten nicht gefunden.",
      noMachingNews: "Keine passenden Nachrichten gefunden.",
    },
    oif: {
      title: "Offene Immobilienfonds",
      subtitle:
        "Die Ratingagentur Scope veröffentlicht regelmäßig Analysen und Research zu einzelnen Fonds und zu Investmentthemen. Folgend eine aktuelle Auswahl.",
      back: "Zurück",
      previous: "Zurück",
      next: "Weiter",
      search: "Suche",
      category: "Category",
      document: "Document",
      video: "Video",
      podcast: "Podcast",
      more: "Mehr",
      error:
        "Abruf von Nachrichten derzeit nicht möglich. Bitte versuchen Sie es später noch einmal.",
      newsNotFound: "Nachrichten nicht gefunden.",
      noMachingNews: "Keine passenden Nachrichten gefunden.",
    },
    login: {
      text: "Melden Sie sich jetzt an und erhalten Sie vollen Zugriff auf sämtliche Fondsdaten und weitere Tools.",
      aifText1:
        "Sollten Sie noch keinen Zugang zu scopeexplorer.com haben, registrieren Sie sich bitte hier:",
      aifText2:
        "Sie möchten Zugang zur Studie erhalten, ohne sich zu registrieren? Dann klicken Sie bitte",
      aifText3: "hier.",
      fillInFields: "Pflichtfelder ausfüllen.",
      errors: {
        default: "Login fehlgeschlagen. Bitte erneut versuchen.",
        401: "Login fehlgeschlagen. Benutzername oder Passwort stimmen nicht.",
      },
      success: "Erfolgreich eingeloggt.",
    },
    register: {
      text: "Mit Ihrer Registrierung erhalten Sie kostenlosen Zugriff auf umfassende Fondsdaten und Services wie Ihre persönliche Watchlist.",
      text2:
        "Im Anschluss senden wir Ihnen eine E-Mail mit dem Bestätigungslink. Mit Klick auf den Link in dieser Mail schließen Sie Ihre Registrierung ab.",
      text3:
        "Die Scope Fund Analysis GmbH wird an Ihre E-Mail-Adresse regelmäßig Informationen zu den im ScopeExplorer enthaltenen Produkten und Unternehmen senden („Newsletter“). Sie können der Verwendung jederzeit widersprechen. Am einfachsten geht dies über Ihr Nutzerprofil.",
      text4:
        "Zudem verwenden wir Ihre E-Mail-Adresse für unseren Benachrichtigungsservice, mit dem wir Sie über aktuelle Entwicklungen zu Produkten in Ihrer „Watchlist“ informieren.",
      text5:
        "Ich bin damit einverstanden, dass die Scope Fund Analysis GmbH den Empfang und meine Nutzung von Newsletter und Benachrichtigungsservice (z.B. Klick auf bestimmte Links) analysiert, um ihre Produkte zu verbessern. Hierzu werden auch sog. Tracking-Pixel eingesetzt. Die Einwilligung ist freiwillig und kann jederzeit für die Zukunft widerrufen werden.",
      text6: "Es gelten unsere",
      text7:
        "Bitte drücken Sie auf „Jetzt registrieren“ wenn Sie damit einverstanden sind. Unsere Datenschutzhinweise finden Sie",
      link3: "/terms-of-use",
      link3Text: "Allgemeinen Geschäftsbedingungen.",
      link4: "/privacy-policy",
      link4Text: "hier.",
      fillInFields: "Pflichtfelder ausfüllen.",
      errors: {
        default: "Registrierung fehlgeschlagen. Bitte erneut versuchen.",
        404: "Eingabe eines Pflichtfelds erforderlich.",
        409: "Diese Email ist bereits einem Nutzer zugeordnet.",
        500: "Serverfehler.",
      },
      success: "Benutzer erfolgreich angelegt.",
      finishRegistration: "Registrierung abschließen",
      iam: "Wählen Sie Ihren Anlegertyp",
      retail: "Privatanleger",
      wholesale: "Professioneller Investor",
      sector: "Industrie/ Branche",
      additinalSectorQuestion: "Zusatzfragen zu Industrie/Branche",
      division: "Bereich",
    },
    registerSuccess: {
      retailText1:
        "Vielen Dank für Ihre Registrierung. Um die Registrierung abzuschließen, haben wir Ihnen eine E-Mail an die von Ihnen genannte Adresse geschickt. Bitte schauen Sie auch einmal in Ihrem Spam-Ordner nach, sollten Sie die E-Mail nicht direkt in Ihrem Posteingang finden.",
      retailText2:
        "Sobald Sie mit einem Klick auf den Link in dieser E-Mail Ihre Anmeldung bestätigen, ist Ihre Registrierung abgeschlossen.",
      wholesaleText1:
        "Vielen Dank für Ihre Registrierung. Um die Registrierung abzuschließen, haben wir Ihnen eine E-Mail an die von Ihnen genannte Adresse geschickt. Bitte schauen Sie auch einmal in Ihrem Spam-Ordner nach, sollten Sie die E-Mail nicht direkt in Ihrem Posteingang finden.",
      wholesaleText2:
        "Sobald Sie mit einem Klick auf den Link in dieser E-Mail Ihre Anmeldung bestätigen, können Sie sich beim ScopeExplorer anmelden. Nach einer Prüfung durch uns werden wir Ihren Zugang um die Funktionen für professionelle Investoren erweitern. Dazu senden wir Ihnen eine gesonderte Benachrichtigung per E-Mail.",
    },
    confirm: {
      title: "Benutzername bestätigen",
      success: "Benutzername wurde bestätigt. Login ist nun möglich.",
      error: "Erfassung konnte nicht bestätigt werden.",
    },
    resetPassword: {
      title: "Passwort zurücksetzen",
      send: "Passwort zurücksetzen",
      errors: {
        requestDefault:
          "E-Mail konnte nicht versendet werden. Bitte erneut versuchen.",
        default: "Passwortänderung fehlgeschlagen. Bitte erneut versuchen.",
      },
      fillInFields: "Pflichtfelder ausfüllen.",
      requestSuccess:
        "Sofern Sie bei uns registriert sind, erhalten Sie nun eine Email um Ihr Passwort neu zu vergeben.",
      success: "Passwort wurde erfolgreich geändert.",
    },
    fundsListModal: {
      title: "Übrigens: Sind Sie professioneller Anleger?",
      subtitle1: "Dann",
      subtitle2: "registrieren",
      subtitle3: "Sie sich jetzt und erhalten",
      subtitle4: "kostenfrei",
      subtitle5: "Zugang:",
      listItem1: "zur vollständigen Scope Fonds-Datenbank",
      listItem2:
        "zu umfangreichen Suchfunktionen zum Filtern und Auswählen von Fonds",
      listItem3: "zu langjährigen Zeitreihen inkl. Exportfunktion",
      listItem4: "zu vollständigen Vergleichsgruppen-Rankings",
      listItem5: "zu Scopes Fonds-Empfehlungsliste",
      listItem6: "zu Scopes Nachhaltigkeitsanalyse einzelner Fonds",
      ignore: "Ignorieren",
      dontShow: "Nicht wieder anzeigen",
      register: "Registrieren",
    },
    privacyPolicy: {
      title: "Datenschutzhinweise",
      subtitle: "für Scope Explorer",
      section1Title: "Datenschutz ist Vertrauenssache",
      section1Text:
        "Egal, ob Sie unser Kunde sind oder sich aus anderen Gründen für unsere Angebote interessieren: Der Schutz Ihrer Privatsphäre ist uns wichtig. Hier erfahren Sie, welche personenbezogenen Daten wir von Ihnen erheben, was wir damit machen und wie wir sie schützen. Außerdem informieren wir darüber, welche Rechte Ihnen zum Schutz Ihrer Daten zustehen.",
      section2Title: "1. Wer sind wir?",
      section2Text1:
        "Die Scope Fund Analysis GmbH ist ein deutsches Unternehmen mit Hauptsitz in Berlin und Verantwortlicher im Sinne des Datenschutzrechts für die Datenverarbeitung bei der Registrierung beim und die Nutzung des ScopeExplorer auf der Website  www.scopeexplorer.com",
      section2Text4: "1.1	Unsere Kontaktdaten lauten:",
      section2Text5:
        "1.2 Haben Sie Fragen zu dieser Datenschutzerklärung oder zum Schutz Ihrer Daten durch uns? Dann können Sie sich jederzeit auch an unseren Datenschutzbeauftragten wenden:",
      section3Title: "2. Worum geht es?",
      section3Text1:
        "2.1 Wenn wir personenbezogene Daten verarbeiten, bedeutet das, dass wir diese erheben, speichern, übermitteln, löschen oder in anderer Form nutzen. Mit personenbezogenen Daten meinen wir Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen, zum Beispiel zu",
      section3List1: "Kunden der Scope Fund Analysis GmbH,",
      section3List2:
        "Interessenten, die sich über unsere Scope Explorer-Website über unser Angebot informieren,",
      section3List3:
        "allen anderen Personen, die in Kontakt mit uns stehen, z.B. Vermittler, Bevollmächtigte, Boten sowie Vertreter oder Mitarbeiter juristischer Personen oder sonstiger Gesellschaften.",
      section3Text2:
        "2.2 Wir verarbeiten personenbezogene Daten, die wir durch den Kontakt mit Ihnen erhalten. Solche Daten entstehen insbesondere, wenn Sie sich online über unsere Dienste informieren, bei Scope Explorer registrieren und anmelden, online Kontaktformulare ausfüllen, sich schriftlich, per E-Mail oder telefonisch an uns wenden oder wenn Sie unsere Angebote nutzen.",
      section4Title:
        "3.	Wie verarbeiten wir Ihre Daten beim Besuch unserer Website?",
      section4Text1:
        "3.1 Bei der Nutzung unserer Website werden durch uns und durch Dritte automatisiert Daten erhoben, die Ihr Browser übermittelt.",
      section4Text2:
        "3.2 Bei der bloß informatorischen Nutzung der Website verarbeiten wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten:",
      section4List1: "verkürzte IP-Adresse des zugreifenden Rechners",
      section4List2: "Name der abgerufenen Datei",
      section4List3: "Datum und Uhrzeit des Abrufs",
      section4List4: "übertragene Datenmenge",
      section4List5: "Meldung über erfolgreichen Abruf",
      section4List6:
        "Browsertyp nebst Version sowie das Betriebssystem, das Sie nutzen",
      section4List7: "Referrer URL",
      section4List8: "anfragender Provider",
      section4List9: "Bildschirmauflösung",
      section4Text3:
        "3.3 Die Protokolldaten werden von uns zudem für statistische Auswertungen im Rahmen des Betriebs unseres Angebots verwendet. Im Falle der rechtswidrigen Nutzung unseres Angebots dienen die Protokolldaten auch dazu, in Betracht kommende Rechtsverletzungen aufzuklären. Die Verarbeitung erfolgt auf Grundlage einer Interessenabwägung, die immer auch Ihre Interessen berücksichtigt (Art. 6 Abs. 1 UAbs. 1 lit. f DSGVO).",
      section5Title: "4. Cookies und Analysedienste",
      section5Text1:
        "4.1 Diese Website verwendet Cookies. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Bei Cookies handelt es sich um Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf Ihrem Computer gespeichert werden. Der Cookie enthält eine Zeichenfolge, die eine eindeutige Identifizierung Ihres Systems beim erneuten Aufrufen der Website ermöglicht.",
      section5Text2:
        "4.2 Die meisten der von uns verwendeten Cookies („Session-Cookies“) und die darin gespeicherten und übermittelten Daten werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies („Persistente Cookies“) bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen.",
      section5Text3:
        "4.3 Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bereits gespeicherte Cookies können Sie jederzeit löschen. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.",
      section5Text4: "Technisch notwendige Cookies",
      section5Text5:
        "4.4 Einige Elemente unserer Website erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann. Einige Cookies bleiben gespeichert und ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.",
      section5Text6:
        "4.5 In den Cookies werden die folgenden Daten gespeichert und übermittelt:",
      section5List1: "aktuelle Session-ID,",
      section5List2: "Spracheinstellungen,",
      section5List3:
        "Kenntnisnahme bestimmter Inhalte der Website, z.B. Fondratings,",
      section5List4:
        "Informationen dazu, ob Sie in die Verwendung technisch nicht notwendiger Cookies eingewilligt haben.",
      section5Text7:
        "4.6 Die mithilfe dieser technisch notwendigen Cookies von Ihnen erhobenen Daten werden nicht zur Erstellung von Nutzerprofilen verwendet.",
      section5Text8:
        "4.7 Die Verarbeitung erfolgt auf Grundlage einer Interessenabwägung, die immer auch Ihre Interessen berücksichtigt (Art. 6 Abs. 1 UAbs. 1 lit. f DSGVO).",
      section5Text9: "Analyse-Cookies",
      section5Text10:
        "4.8 Auf unserer Website verwenden wir außerdem Cookies zur pseudonymisierten Reichweitenmessung und Werbezwecken. Cookies werden hierzu entweder von unserem Server oder dem Server Dritter an Ihren Browser übertragen. In diesem Zusammenhang verarbeiten wir Ihre Daten zur Wahrung berechtigter Interessen auf Grundlage einer Interessenabwägung, die immer auch Ihre Interessen berücksichtigt (Art. 6 Abs. 1 UAbs. 1 lit. f DSGVO). Unser berechtigtes Interesse in der Verarbeitung liegt in den folgenden Zwecken:",
      section5List5: "Analyse und statistische Auswertung der Website-Nutzung",
      section5List6: "Optimierung der Website",
      section5List7: "Bereitstellung personalisierter Angebote und Inhalte",
      section5List8: "Erfolgsmessung von Werbekampagnen",
      section5Text11:
        "4.9 Sie können dem Einsatz von Analyse-Cookies widersprechen. Hinweise zu spezifischen Widerspruchsmöglichkeiten finden Sie in der entsprechenden Beschreibung in den nachfolgenden Absätzen.",
      section5Text12:
        "4.10	Sofern erforderlich, holen wir für die Verarbeitung Ihre Einwilligung nach Art. 6 Abs. 1 UAbs. 1 lit. a DSGVO ein.",
      section5Text13: "Google Analytics",
      section5Text14:
        "4.11	Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet ebenfalls sog. „Cookies“, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Rahmen der für diese Website aktivierten IP-Anonymisierung wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. In unserem Auftrag wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Website-Aktivitäten zusammenzustellen und um weitere mit der Website-Nutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Website-Betreiber zu erbringen.",
      section5Text15:
        "4.12	Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.",
      section5Text16:
        "4.13	Für die Ausnahmefälle, in denen personenbezogene Daten in die USA übertragen werden, hat sich Google dem EU-US Privacy Shield unterworfen,",
      section5Link1: "https://www.privacyshield.gov/EU-US-Framework",
      section5Text17:
        "4.14	Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plug-in herunterladen und installieren: ",
      section5Link2: "http://tools.google.com/dlpage/gaoptout?hl=de",
      section5Text18:
        "4.15	Alternativ zum Browser-Add-On oder innerhalb von Browsern auf mobilen Geräten, klicken Sie bitte diesen Link, um die Erfassung durch Google Analytics innerhalb dieser Website zukünftig zu verhindern: ",
      section5Link3: "https://tools.google.com/dlpage/gaoptout/eula.html",
      section5Text19:
        "4.16	Dabei wird ein Opt-Out-Cookie auf Ihrem Gerät abgelegt. Löschen Sie Ihre Cookies, müssen Sie diesen Link erneut klicken.",
      section5Text20:
        "4.17	Diese Website verwendet Google Analytics mit der Erweiterung „_anonymizeIp()“. Dadurch werden IP-Adressen gekürzt weiterverarbeitet, eine Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die personenbezogenen Daten damit umgehend gelöscht.",
      section5Text21:
        "4.18	Wir nutzen Google Analytics, um die Nutzung unserer Website analysieren und regelmäßig verbessern zu können. Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten.",
      section5Text22a:
        "4.19	Informationen des Anbieters von Google Analytics finden Sie hier: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001. Die Nutzerbedingungen können Sie hier einsehen",
      section5Text22b: "http://www.google.com/analytics/terms/de.html",
      section5Text22c:
        "Informationen zum Datenschutz bietet die Datenschutzerklärung unter",
      section5Text22d: "http://www.google.de/intl/de/policies/privacy.",
      section5Text23:
        "4.20	Sie können dem Einsatz von Google Analytics widersprechen. Hinweise zu spezifischen Widerspruchsmöglichkeiten finden Sie in der entsprechenden Beschreibung in den nachfolgenden Absätzen.",
      section6Title: "5. Newsletter und Benachrichtigungsservice",
      section6Text1:
        "5.1	Die Scope Fund Analysis GmbH sendet an registrierte Kunden regelmäßig Informationen zu den im ScopeExplorer enthaltenen Produkten und Unternehmen („Newsletter). Diese Verarbeitung erfolgt auf Grundlage einer Interessenabwägung gemäß Art. 6 Abs. 1 UAbs. 1 lit. f DSGVO, die immer auch Ihre Interessen berücksichtigt.",
      section6Text2:
        "5.2	Zudem wird registrierten Kunden ein sog. Benachrichtigungsservice angeboten, mit dem per E-Mail über aktuelle Entwicklungen zu Produkten auf der „Watchlist“ des Kunden informiert wird. Diese Verarbeitung erfolgt zur Erfüllung eines Vertrages gemäß Art. 6 Abs. 1 UAbs. 1 lit. b DSGVO.",
      section6Text3:
        "5.3	Der Empfang des Newsletters und der Benachrichtigungsservice können in Ihrem Nutzerprofil deaktiviert werden.",
      section6Text4:
        "5.4	Wir analysieren Daten zu Ihrem Empfang und Ihrer Nutzung von Newsletter und Benachrichtigungsservice, um unsere Produkte und unsere Werbung zu verbessern. So stellen wir mithilfe eines sog. Tracking-Pixels fest, ob unsere E-Mail geöffnet wurde und wir speichern, welche Links Sie in einer E-Mail geöffnet haben. Wir verarbeiten diese Daten ausschließlich aufgrund Ihrer ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 UAbs. 1 lit. a DSGVO.",
      section6Text5:
        "5.5	Wenn Sie nicht in die Analyse von Newsletter und Benachrichtigungsservice einwilligen bzw. Ihre Einwilligung widerrufen haben, kann es sein, dass wir Ihnen deswegen aus technischen Gründen keinen Newsletter und/oder keinen Benachrichtigungsservice mehr anbieten können.",
      section6Text6:
        "5.6	Für den Versand und die Analyse von E-Mails greifen wir auf die Dienste von SendGrid, Inc. 1801 California Street, Suite 500, Denver, Colorado 80202, USA als Auftragsverarbeiter gemäß Art. 28 DSGVO zurück. Dabei werden Daten auch in den USA und damit in Drittländern im Sinne der DSGVO verarbeitet. Die SendGrid, Inc. ist nach dem EU-US-Privacy Shield verpflichtet",
      section6Text7:
        "https://www.privacyshield.gov/participant?id=a2zt0000000TRktAAG&status=Active",
      section6Text8: ").",
      section7Title: "5. Informationsanwendungen anderer Anbieter",
      section7Text1:
        "6.1 Wir nutzen auf unsere Website Informationsanwendungen anderer Anbieter. Dadurch können wir Inhalte und Funktionen aus anderen Quellen (z.B. Videos) direkt in unsere Website einbinden. Sofern wir dabei personenbezogene Daten verarbeiten, erfolgt dies auf Grundlage einer Interessenabwägung, die immer auch Ihre Interessen mitberücksichtigt (Art. 6 Abs. 1 UAbs. 1 lit. f DSGVO).",
      section7Text2: "YouTube",
      section7Text3:
        "6.2 Wir haben YouTube-Videos in unsere Website eingebunden, die auf",
      section7Link1: "http://www.youtube.com",
      section7Text4:
        "gespeichert und von unserer Website aus direkt abspielbar sind. Diese sind alle im „erweiterten Datenschutz-Modus“ eingebunden, dies bedeutet, dass keine Daten über Sie als Nutzer an YouTube übertragen werden, wenn Sie die Videos nicht abspielen. Erst wenn Sie die Videos abspielen, werden die nachfolgend beschriebenen Daten übertragen. Auf diese Datenübertragung haben wir keinen Einfluss.",
      section7Text5:
        "6.3 Durch das Abspielen eines Videos auf der Website erhält YouTube die Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Dies erfolgt unabhängig davon, ob YouTube ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei YouTube eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei YouTube nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. YouTube speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an YouTube richten müssen.",
      section7Text6:
        "6.4 Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch YouTube erhalten Sie in der Datenschutzerklärung. Dort erhalten Sie auch weitere Informationen zu Ihren Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre:",
      section7Link2: "https://www.google.de/intl/de/policies/privacy",
      section7Text7:
        ". Google verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich dem EU-US-Privacy-Shield unterworfen,",
      section7Link3: "https://www.privacyshield.gov/EU-US-Framework",
      section8Title:
        "7.	Wie verarbeiten wir Ihre Daten bei der Registrierung und Anmeldung auf unserer Website?",
      section8Text1:
        "7.1 Sie können sich, um gewisse Funktionen von Scope Explorer in Anspruch zu nehmen, bei uns registrieren. Die dabei angegebenen Daten werden für die Nutzung unseres Angebots verwendet. Die Verwendung erfolgt außerdem, um Sie über Ratingänderungen von den auf der von Ihnen erstellten Watchlist enthaltenen Fonds per E-Mail zu informieren und bei Bedarf Ihre Suchen zu speichern. Die Registrierung umfasst die Eingabe der folgenden Daten:",
      section8List1: "Vor- und Nachname",
      section8List2: "E-Mail-Adresse",
      section8Text2:
        "7.2 Handelt es sich um einen professionellen Investor benötigen wir zudem folgende Daten:",
      section8List3: "Telefon- oder Mobilnummer",
      section8List4: "Angaben zum Unternehmen",
      section8List5: "Daten des Ansprechpartners",
      section8List6: "Funktion des Ansprechpartners",
      section8Text3:
        "7.3 Hierüber hinaus werden die bei der Registrierung verwendete IP-Adresse und das Datum der Registrierung gespeichert, um einem Missbrauch der Funktion vorzubeugen.",
      section8Text4:
        "7.4 Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 UAbs. 1 lit. a und lit. f DSGVO.",
      section9Title:
        "8.	Wie verarbeiten wir Ihre Daten im Falle der Kontaktaufnahme mit uns?",
      section9Text1:
        "8.1 Unser Angebot ermöglicht es Ihnen, Kontakt zu uns aufzunehmen. Dies ist etwa telefonisch, anhand der zur Verfügung gestellten Telefonnummern oder durch den Versand einer E-Mail möglich. Daneben stellen wir auf unserer Website ein Kontaktformular für die elektronische Kontaktaufnahme zur Verfügung.",
      section9Text2:
        "8.2 Bei Absendung einer Nachricht über das Kontaktformular werden neben den von Ihnen eingegebenen Informationen zudem folgende Daten gespeichert, um Missbrauch vorzubeugen:",
      section9List1: "IP-Adresse",
      section9List2: "Datum und Uhrzeit",
      section9List3: "Betriebssystem",
      section9List4: "Browser",
      section9List5: "besuchte interne Seiten",
      section9Text3:
        "8.3 Bei einer Kontaktaufnahme über Telefon oder die bereitgestellten E-Mail-Adressen werden die übermittelten personenbezogenen Daten des Nutzers gespeichert, um die Anfrage und eine gegebenenfalls folgende Korrespondenz zu bearbeiten.",
      section9Text4:
        "8.4 Rechtsgrundlage für die Verarbeitung der Daten ist Art. 6 Abs. 1 UAbs. 1 lit. a oder lit. f DSGVO.",
      section10Title: "9.	Nutzen wir Ihre Daten zu Sicherheitszwecken?",
      section10Text1:
        "9.1 Wir nutzen Ihre Daten zudem, um Ihre Sicherheit sowie die Sicherheit unserer Leistungen und Geschäftsabläufe zu gewährleisten. Die in diesem Abschnitt beschriebene Verarbeitung erfolgt auf Grundlage einer Interessenabwägung, die immer auch Ihre Interessen berücksichtigt.",
      section10Text2:
        "9.2 Auf Grundlage einer Interessenabwägung verarbeiten wir Daten um Ihre Sicherheit sowie die Sicherheit unserer Geschäftsabläufe zu gewährleisten, insbesondere in den folgenden Fällen:",
      section10List1:
        "Um im Falle rechtlicher Auseinandersetzungen Sachverhalte prüfen und Ansprüche geltend machen zu können.",
      section10List2:
        "Um IT-Sicherheit und den IT-Betrieb der Scope Fund Analysis GmbH gewährleisten zu können.",
      section10List3: "Um Straftaten zu verhindern und aufklären zu können.",
      section10List4:
        "Um eine ordnungsgemäße Geschäftssteuerung zu gewährleisten.",
      section11Title: "10. Wer erhält Ihre Daten?",
      section11Text1:
        "10.1	Informationen über Sie dürfen wir nur weitergeben, wenn gesetzliche Bestimmungen dies erlauben oder Sie eingewilligt haben.",
      section11Text2: "10.2	Zu den weitergegebenen Daten können gehören:",
      section11List1: "Vor- und Nachname",
      section11List2: "E-Mail-Adresse",
      section11List3: "Unternehmen",
      section11List4: "Funktion",
      section11Text3:
        "10.3	Auch von uns eingesetzte Dienstleister können von uns Daten erhalten, wenn diese unsere besonderen Vertraulichkeitsanforderungen erfüllen. Dies können insbesondere Unternehmen in den Kategorien IT-Dienstleistungen, Logistik, Druckdienstleistungen, Telekommunikation, Inkasso, Beratung sowie Vertrieb und Marketing sein.",
      section12Title: "11. Wann löschen wir Ihre Daten?",
      section12Text1:
        "11.1	Wir verarbeiten und speichern Ihre personenbezogenen Daten grundsätzlich so lange, wie wir in einer geschäftlichen Beziehung zu Ihnen stehen. ",
      section12Text2:
        "11.2	Benötigen wir Ihre personenbezogenen Daten für den ursprünglichen Zweck nicht mehr, löschen wir diese. Etwas anderes gilt nur dann, wenn ihre – befristete – Weiterverarbeitung zu anderen Zwecken erforderlich ist. Solche Zwecke können sein:",
      section12List1:
        "Die Erfüllung von Aufbewahrungs- und Dokumentationspflichten, die sich unter anderem aus dem Handelsgesetzbuch (HGB) ergeben.",
      section12List2:
        "Die Erhaltung von Beweismitteln im Rahmen der Verjährungsvorschriften. Diese können nach den §§ 195 ff. des Bürgerlichen Gesetzbuches (BGB) in der Regel drei Jahre, in gewissen Fällen aber auch bis zu dreißig Jahre betragen.",
      section13Title:
        "12. Werden Ihre Daten in ein Drittland oder an eine internationale Organisation übermittelt?",
      section13Text1:
        "12.1	Eine Datenübermittlung an internationale Organisationen oder in Drittstaaten (Staaten außerhalb des Europäischen Wirtschaftsraums – EWR) findet grundsätzlich nicht statt. Zur Übermittlung pseudonymisierter Daten durch Analysedienste siehe oben unter ",
      section13Text2: "„4. Cookies und Analysedienste“",
      section13Text3: "sowie ",
      section13Text4: "„5. Informationsanwendungen anderer Anbieter“.",
      section14Title:
        "13. Sind Sie verpflichtet uns bestimmte personenbezogene Daten zu geben?",
      section14Text1:
        "13.1	Unter Umständen ja. Im Rahmen unseres Online-Angebots benötigen wir Ihre personenbezogenen Daten zur Durchführung einer Registrierung und zur Kontaktierung im Falle einer Ratingveränderung der von Ihnen gespeicherten Fonds. Ohne diese Daten können wir zum Teil unser Angebot nicht erbringen.",
      section15Title:
        "14. Welche Rechte haben Sie, wenn es um die Verarbeitung Ihrer Daten geht?",
      section15Text1:
        "14.1	Sie haben uns gegenüber folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:",
      section15List1: "Recht auf Auskunft,",
      section15List2: "Recht auf Berichtigung oder Löschung,",
      section15List3: "Recht auf Einschränkung der Verarbeitung,",
      section15List4: "Recht auf Datenübertragbarkeit,",
      section15List5: "Recht auf Widerruf von erteilten Einwilligungen.",
      section15Text2:
        "14.2	Ihnen steht im Zusammenhang mit der Verarbeitung Ihrer personenbezogenen Daten außerdem ein Beschwerderecht bei der folgenden, für uns in Bezug auf den Schutz personenbezogener Daten, zuständigen Aufsichtsbehörde zu:",
      section16Title:
        "15. Inwieweit gibt es eine automatisierte Entscheidungsfindung im Einzelfall?",
      section16Text1:
        "15.1	Im Rahmen unserer Website sowie zur Begründung und Durchführung der Geschäftsbeziehung nutzen wir grundsätzlich keine vollautomatisierte Entscheidungsfindung gemäß Art. 22 DSGVO.",
      section17Title:
        "16. Ihr Widerspruchsrecht nach Artikel 21 Datenschutz-Grundverordnung (DSGVO)",
      section17Text1: "16.1	Einzelfallbezogenes Widerspruchsrecht",
      section17Text2:
        "Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 e DSGVO (Datenverarbeitung im öffentlichen Interesse) und Artikel Art. 6 Abs. 1 f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Artikel 4 Nr. 4 DSGVO. ",
      section17Text3:
        "Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.",
      section17Text4:
        "16.2	Widerspruch gegen die Verarbeitung Ihrer Daten für unsere Direktwerbung ",
      section17Text5:
        "In Einzelfällen verarbeiten wir Ihre personenbezogenen Daten für unsere Direktwerbung. Sie haben das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.",
      section17Text6:
        "Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden wir Ihre personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.",
      section17Text7:
        "Der Widerspruch kann formfrei erfolgen und sollte möglichst gerichtet werden an die unter Ziffer 1 genannten Kontaktdaten an uns gesendet werden.",
    },
    rechtlicheHinweise: {
      title: "Rechtliche Hinweise zur nutzung des Scope Explorer",
      subtitle:
        "Die Nutzung des über die Internetseite www.scopeexplorer.com erreichbaren Informationsportals „SCOPE EXPLORER“ darf ausschließlich nach Maßgabe der folgenden Bestimmungen und Hinweise erfolgen:",
      section1Title: "1. Angebot nur im Inland",
      section1Text1:
        "Der SCOPE EXPLORER richtet sich an Personen mit Wohnsitz in der Bundesrepublik Deutschland.",
      section2Title: "2. Keine Beratung",
      section2Text1:
        "SCOPE erbringt keine Rechtsberatungs-, Steuerberatungs-, Beratungs-, oder Unternehmensdienstleistungen und berät nicht bei der Strukturierung von Transaktionen, der Erstellung oder Verhandlung von Transaktionsdokumenten.",
      section3Title: "3. Keine Finanzdienstleistungen",
      section3Text1:
        "Über den SCOPE EXPLORER werden keine Finanzdienstleistungen erbracht. Das Portal dient ausschließlich der Bereitstellung von Informationen. SCOPE bietet keine Beratung bei der Vermittlung und/oder Verwaltung von Finanzinstrumenten an.",
      section4Title:
        "4. Keine Ratings nach der EU-Verordnung über Ratingagenturen",
      section4Text1:
        "Die auf dem SCOPE EXPLORER angebotenen Fondsratings stellen kein Rating im Sinne der Verordnung (EG) Nr. 1060/2009 über Ratingagenturen in der Fassung der Änderungsverordnungen (EU) Nr. 513/2011 und (EU) Nr. 462/2013 dar. Die von SCOPE zur Verfügung gestellten Fondsratings sind Anlagestrategieempfehlungen. Diese enthalten aber keine Anlageempfehlungen oder Anlageberatung, da sie keine Beurteilung des Wertes des Finanzinstruments enthalten und auch den Anlagehorizont, die individuellen Anlageziele oder die Finanzlage einzelner Anleger nicht berücksichtigen.",
      section5Title: "5. Kein Angebot von Finanzinstrumenten",
      section5Text1:
        "Der SCOPE EXPLORER enthält keine Kauf- und/oder Verkaufsangebote für Finanzinstrumente. Insbesondere sind alle Inhalte zu Fonds, Wertpapieren und/oder anderen Finanzinstrumenten nicht als individuelle Anlageempfehlungen für Finanzinstrumente zu verstehen.",
      section6Title: "6. Download von Inhalten",
      section6Text1:
        "Der Kunde ist nur dann zum Herunterladen und Ausdrucken von Inhalten berechtigt, wenn auf dem Portal eine Möglichkeit zum Herunterladen oder Ausdrucken als Funktion zur Verfügung steht (z. B. über einen Download-Button).",
      section7Title: "7. Haftung für Links",
      section7Text1:
        "Verweise und Links auf Websites Dritter bedeuten nicht, dass sich SCOPE die hinter dem Verweis oder Link stehenden Inhalte zu eigen macht. Die Inhalte begründen keine Verantwortung von SCOPE für die dort bereitgestellten Daten und Informationen. SCOPE hat keinen Einfluss auf die Inhalte hinter dem Link. Für illegale, fehlerhafte oder unvollständige Inhalte und für Schäden, die durch die Nutzung der verlinkten Inhalte entstehen, haftet SCOPE nicht.",
    },
    termsOfUse: {
      title: "Nutzungsbedingungen",
      subtitle: "",
      section1Title: "1.	Anwendungsbereich",
      section1Text1:
        "1.1	Diese Nutzungsbedingungen gelten für die Registrierung und die Nutzung des nur nach Registrierung zugänglichen Bereichs des über die Internetseite www.scopeexplorer.com erreichbaren Informationsportals ",
      section1Text2: "„SCOPE EXPLORER“.",
      section1Text3:
        "1.2	Nutzer des SCOPE EXPLORER im Sinne dieser Nutzungsbedingungen sind private  Nutzer und professionelle Nutzer, deren Registrierung bestätigt wurde (auch ",
      section1Text4: ",,Kunden‘‘",
      section1Text5: ").",
      section1Text6:
        "1.3	Der SCOPE EXPLORER wird durch die Scope Fund Analysis GmbH, Lennéstraße 5, 10785 Berlin (",
      section1Text7: "„SCOPE“",
      section1Text8:
        ") betrieben. Diese ist erreichbar telefonisch unter +49 30 27891-0 oder per E-Mail an info@scopeanalysis.com.",
      section1Text9:
        "1.4	Andere Geschäftsbedingungen finden keine Anwendung, auch wenn SCOPE ihrer Geltung im Einzelfall nicht gesondert widerspricht. Selbst wenn SCOPE auf ein Schreiben Bezug nimmt, das andere Geschäftsbedingungen enthält oder auf solche verweist, liegt darin kein Einverständnis mit der Geltung jener Geschäftsbedingungen.",
      section1Text10:
        "1.5 Der SCOPE EXPLORER richtet sich an Personen mit Wohnsitz in der Bundesrepublik Deutschland.",
      section2Title: "2. Registrierung",
      section2Text1:
        "2.1	Bei der Registrierung sind Vor- und Nachname, eine gültige E-Mail-Adresse und ein Passwort anzugeben.",
      section2Text2:
        "2.2	Registriert sich eine Person als professioneller Nutzer sind zudem eine Telefon- oder Mobilnummer, das Unternehmen sowie der Vor- und Nachname und die Funktion des Ansprechpartners anzugeben.",
      section2Text3:
        "2.3	Voraussetzung für die erfolgreiche Registrierung ist die Verifikation der angegebenen E-Mail-Adresse durch Klick auf den entsprechenden Link in der versendeten Bestätigungs-E-Mail.",
      section2Text4:
        "2.4	SCOPE ist berechtigt, die bereitgestellten Informationen auf Vollständigkeit und Plausibilität zu prüfen. Die Registrierung wird erst wirksam, wenn diese durch SCOPE bestätigt worden ist. Die Bestätigung der Registrierung steht im freien Ermessen von SCOPE.",
      section2Text5:
        "2.5	Die Zugangsdaten einschließlich des Passworts sind vertraulich zu behandeln und dürfen unbefugten Dritten nicht zugänglich gemacht werden. Besteht Grund zur Annahme, dass Dritte unbefugt Kenntnis von den Zugangsdaten erhalten haben oder erlangen werden, ist SCOPE unverzüglich hierüber informieren.",
      section3Title: "3. Leistungen von SCOPE",
      section3Text1:
        "3.1	Kunden haben die Möglichkeit, sog. Fondsratings zum Rendite-Risiko-Profil von Investmentfonds (dazu gehören sowohl individuelle als auch alternative Investmentfonds oder ähnliche Konstruktionen in der Position eines Eigenkapitalgebers) einzusehen. ",
      section3Text2:
        "3.2	Ferner erhalten Kunden Zugriff auf eine so genannte „Watchlist“. Durch die Watchlist kann der Kunde selbst ausgewählte Fonds in einer Liste speichern und sich über Ratingänderungen informieren",
      section3Text3:
        "3.3	Dem professionellen Nutzer stehen über die in 3.1 und 3.2 beschriebenen Leistungen  hinaus Informationen zu Zeitreihen in grafischer und tabellarischer Darstellung, ein Auszug der von SCOPE ausgewählten Fonds und so genannte ESG-Analysen auf Fondsebene zur Verfügung.",
      section3Text4:
        "3.4	SCOPE analysiert die Nutzung von SCOPE EXPLORER durch den Kunden und zeigt ihm auf dieser Grundlage insbesondere solche Informationen an, die besonders interessant für ihn sind. Voraussetzung für die Inanspruchnahme dieser Analyse ist, dass der Kunde in den Einsatz bestimmter Analyse-Cookies gegebenenfalls einwilligt.",
      section3Text5:
        "3.5	SCOPE wird angemessene Anstrengungen unternehmen, um die Verfügbarkeit des Portals aufrechtzuerhalten. Der Kunde erkennt an, dass SCOPE keinen definierten Grad an Verfügbarkeit oder die Einhaltung eines Service Levels schuldet.",
      section3Text6:
        "3.6	SCOPE garantiert nicht, dass das Portal fehlerfrei genutzt werden kann und/oder dass es frei von Viren und anderen schädlichen Komponenten ist.",
      section4Title: "4. Ausschlüsse und Disclaimer",
      section4Text1:
        "4.1	Eine Garantie für Richtigkeit, Aktualität, Eignung für einen bestimmten Zweck und Vollständigkeit der Inhalte wird von SCOPE nicht übernommen.",
      section4Text2:
        "4.2	SCOPE erbringt keine Rechtsberatungs-, Steuerberatungs-, Beratungs-, oder Unternehmensdienstleistungen und berät nicht bei der Strukturierung von Transaktionen, der Erstellung oder Verhandlung von Transaktionsdokumenten.",
      section4Text3:
        "4.3	Über den SCOPE EXPLORER werden keine Finanzdienstleistungen erbracht. Das Portal dient ausschließlich der Bereitstellung von Informationen. SCOPE bietet keine Beratung bei der Vermittlung und/oder Verwaltung von Finanzinstrumenten an.",
      section4Text4:
        "4.4	Die auf dem SCOPE EXPLORER angebotenen Fondsratings stellen kein Rating im Sinne der Verordnung (EG) Nr. 1060/2009 über Ratingagenturen in der Fassung der Änderungsverordnungen (EU) Nr. 513/2011 und (EU) Nr. 462/2013 dar. Die von SCOPE zur Verfügung gestellten Fondsratings sind Anlagestrategieempfehlungen. Diese enthalten aber keine Anlageempfehlungen oder Anlageberatung, da sie keine Beurteilung des Wertes des Finanzinstruments enthalten und auch den Anlagehorizont, die individuellen Anlageziele oder die Finanzlage einzelner Anleger nicht berücksichtigen.",
      section4Text5:
        "4.5	Der SCOPE EXPLORER enthält keine Kauf- und/oder Verkaufsangebote für Finanzinstrumente. Insbesondere sind alle Inhalte zu Fonds, Wertpapieren und/oder anderen Finanzinstrumenten nicht als individuelle Anlageempfehlungen für Finanzinstrumente zu verstehen.",
      section4Text6:
        "4.6	Verweise und Links auf Websites Dritter bedeuten nicht, dass sich SCOPE die hinter dem Verweis oder Link stehenden Inhalte zu eigen macht. Die Inhalte begründen keine Verantwortung von SCOPE für die dort bereitgestellten Daten und Informationen. SCOPE hat keinen Einfluss auf die Inhalte hinter dem Link. Für illegale, fehlerhafte oder unvollständige Inhalte und für Schäden, die durch die Nutzung der verlinkten Inhalte entstehen, haftet SCOPE nicht.",
      section5Title: "5. Änderungen dieser Nutzungsbedingungen",
      section5Text1:
        "5.1	SCOPE kann von Zeit zu Zeit redaktionelle Änderungen an diesen Nutzungsbedingungen vornehmen, die das laufende Vertragsverhältnis mit SCOPE nicht betreffen. Zu diesen Änderungen gehören beispielsweise die Korrektur von Tippfehlern oder die Aktualisierung von Kontaktinformationen.",
      section5Text2:
        "5.2	SCOPE ist darüber hinaus berechtigt, gelegentlich und nach eigenem Ermessen Änderungen an diesen Nutzungsbedingungen aus triftigen Gründen vorzunehmen, die das laufende Vertragsverhältnis betreffen, insbesondere aufgrund neuer technischer Entwicklungen, rechtlicher Änderungen, einer Anordnung oder eines Antrags einer Regulierungsbehörde, einer Gerichtsentscheidung oder anderer gleichwertiger Gründe wie Änderungen in der Vertriebs- oder Produktpolitik.",
      section5Text3:
        "5.3	Will SCOPE solche Änderungen vornehmen, die das aktuelle Vertragsverhältnis mit dem Kunden betreffen, wird SCOPE den Kunden spätestens zwei Monate vor dem vorgeschlagenen Inkrafttreten in auffälliger und angemessener Weise informieren. Dies kann eine auffällige Meldung im Portal, d.h. beim nächsten Login, oder eine E-Mail sein. Diese Nachricht enthält Informationen über die von SCOPE gewünschten Änderungen, das Recht des Kunden, sie abzulehnen, und die Folgen, wenn der Kunde sie nicht vor Ablauf der Frist ablehnt. Die Änderungen gelten als genehmigt, wenn der Kunde entweder direkt zustimmt oder wenn er die Änderungen nicht vor Ablauf der Frist ablehnt. Wenn der Kunde dem Änderungsvorschlag widerspricht, kann jede Partei die von der mitgeteilten Änderung betroffene Vereinbarung vor dem vorgeschlagenen Inkrafttreten mit sofortiger Wirkung und ohne zusätzliche Kosten kündigen. In der Mitteilung wird SCOPE die von der vorgeschlagenen Änderung betroffene Vereinbarung identifizieren und den Kunden ausdrücklich auf dieses Kündigungsrecht hinweisen. Wird der Vertrag gekündigt, unterliegen Verträge mit SCOPE, die nicht von der vorgeschlagenen Änderung betroffen sind, nicht diesem Kündigungsrecht.",
      section6Title: "6. Sperrung des Zugangs zum Portal ",
      section6Text1:
        "SCOPE kann den Zugang zum Portal vorübergehend oder dauerhaft sperren, wenn es konkrete Anhaltspunkte dafür gibt, dass der Kunde gegen diese Nutzungsbedingungen und/oder geltendes Recht verstoßen hat, oder wenn eine Sperrung erforderlich ist, um die IT-Sicherheit oder andere berechtigte Interessen von SCOPE zu wahren. SCOPE wird bei der Entscheidung über die Sperrung die berechtigten Interessen des Kunden so weit wie möglich und zumutbar berücksichtigen.",
      section7Title: "7. Kündigung und Verbraucher-Widerrufsrecht",
      section7Text1:
        "7.1	Der Vertrag über die Nutzung des SCOPE EXPLORER unter Geltung dieser Nutzungsbedingungen wird auf unbestimmte Zeit geschlossen und ist durch beide Parteien mit einer Frist von vier Wochen zum jeweiligen Monatsende kündbar. Hiervon unberührt bleibt das Recht beider Parteien zur sofortigen Kündigung aus wichtigem Grund. ",
      section7Text2:
        "7.2	Die Erklärung der Kündigung hat in Textform zu erfolgen.",
      section7Text3:
        "7.3	Nur wenn der Kunde ein Verbraucher ist, steht ihm ein Verbraucher-Widerrufsrecht zu. Einzelheiten sind der diesen Nutzungsbedingungen angefügten Widerrufsbelehrung zu entnehmen.",
      section7Text4:
        "7.4	Die Beendigung des Vertrages über die Nutzung des SCOPE EXPLORER berührt nicht bestehende sonstige Vereinbarungen über Dienstleistungen und Produkte mit SCOPE.",
      section8Title: "8. Nutzungsrecht",
      section8Text1:
        "8.1	Für die Dauer des  Vertrages über die Nutzung des SCOPE EXPLORER gewährt SCOPE dem Kunden ein nicht ausschließliches, geografisch unbegrenztes, nicht unterlizenzierbares und nicht übertragbares Recht, auf die auf dem SCOPE EXPLORER verfügbaren Inhalte zuzugreifen und diese nur für den eigenen Gebrauch anzuzeigen.",
      section8Text2:
        "8.2	Der Kunde ist nur dann zum Herunterladen und Ausdrucken von Inhalten berechtigt, wenn auf dem Portal eine Möglichkeit zum Herunterladen oder Ausdrucken als Funktion zur Verfügung steht (z. B. über einen Download-Button).",
      section9Title: "9. Haftungsbeschränkung",
      section9Text1:
        "9.1	SCOPE haftet unbeschränkt, wenn die Schadensursache auf Vorsatz oder grober Fahrlässigkeit beruht. Des Weiteren haftet Scope Fund Analysis für die leicht fahrlässige Verletzung wesentlicher Pflichten, deren Verletzung die Erreichung des Vertragszwecks gefährdet, oder für die Verletzung von Pflichten, deren Erfüllung die ordnungsgemäße Durchführung es Vertrages erst ermöglicht und auf deren Einhaltung sich die Vertragsparteien regelmäßig verlassen. In diesem Fall haftet SCOPE jedoch nur für den vorhersehbaren, vertragstypischen Schaden. SCOPE haftet nicht für leicht fahrlässige Verletzungen anderer als der in den vorstehenden Sätzen genannten Verpflichtungen.",
      section9Text2:
        "9.2	Die vorstehenden Haftungsbeschränkungen gelten nicht für Verletzungen von Leben, Körper oder Gesundheit, für arglistig verschwiegene Mängel und für Mängel bezüglich derer eine Beschaffenheitsgarantie übernommen wurde. Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.",
      section9Text3:
        "9.3	Soweit die Haftung von SCOPE ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung von Arbeitnehmern, gesetzlichen Vertretern und Erfüllungsgehilfen der SCOPE.",
      section9Text4:
        "9.4	Sollte dieser Vertrag ausnahmsweise eine Schutzwirkung zugunsten Dritter entfalten, gelten die vorstehenden Haftungsbeschränkungen auch gegenüber Dritten.",
      section10Title: "10. Richtlinien / Schlussbestimmungen ",
      section10Text2:
        "10.2	Diese Nutzungsbedingungen sind in deutscher Sprache verfasst. Übersetzungen dieser Nutzungsbedingungen in andere Sprachen dienen ausschließlich Informationszwecken. Im Falle von Widersprüchen zwischen der deutschen Version und Übersetzungen geht die deutsche Version vor.",
      section10Text3:
        "10.3	Es gilt deutsches Recht unter Ausschluss des Übereinkommens der Vereinten Nationen über Verträge über den internationalen Warenkauf (CISG) und des deutschen Kollisionsrechts. Ist der Kunde Verbraucher und hat er seinen gewöhnlichen Aufenthalt in einem Mitgliedstaat der Europäischen Union, so gilt ebenfalls deutsches Recht, wobei zwingende Bestimmungen des Staates, in dem der Nutzer seinen gewöhnlichen Aufenthalt hat, unberührt bleiben.",
      section10Text4:
        "10.4	Ist der Kunde Kaufmann oder eine juristische Person des öffentlichen Rechts oder ein Sondervermögen des öffentlichen Rechts oder hat er keinen allgemeinen Gerichtsstand in Deutschland, ist ausschließlicher Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit diesen Nutzungsbedingungen Berlin.",
      section10Text5:
        "10.5	Die Europäische Kommission bietet eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform), die unter www.ec.europa.eu/consumers/odr zugänglich ist. Unsere E-Mail-Adresse finden Sie in unserem Impressum. Wir sind weder verpflichtet noch bereit, uns an dem Streitbeilegungsverfahren zu beteiligen.",
      section10Text6:
        "10.6	Der Kunde ist nicht berechtigt, ohne vorherige schriftliche Zustimmung von SCOPE Rechte oder Ansprüche aus oder im Zusammenhang mit der Nutzung des SCOPE EXPLORER, eines über den SCOPE EXPLORER geschlossenen Vertrages und/oder dieser Nutzungsbedingungen an Dritte abzutreten.",
      section10Text7:
        "10.7	Sollte eine einzelne Bestimmung dieser Nutzungsbedingungen ganz oder teilweise unwirksam sein, so wird dadurch die Gültigkeit der übrigen Bestimmungen nicht berührt.",
      section10Text8:
        "10.8	Sofern in diesen Nutzungsbedingungen nichts anderes bestimmt ist, sind Änderungen oder Ergänzungen dieser Nutzungsbedingungen nur gültig, wenn sie in Textform erfolgen. Dies gilt auch für jede Änderung oder Aufhebung dieser Bestimmung.",
    },
    termsAndConditions: {
      title: "Besondere Bestimmungen der Offene Immobilienfonds Studie 2022",
      subtitle: "",
    },
    scopeAwards: {
      scopeAwards: "Scope Award",
      title: "Scope Award",
      investmentAwards: "Scope Investment Awards",
      alternativeInvestmentAwards: "Scope Alternative Investment Awards",
      certificateAwards: "Scope Zertifikate Awards",
      findOutMore:
        "Erfahren Sie mehr zu den Kategorien, den Nominierten und der Award Methodik​",
      scopeAward: "Der Scope Award",
      scopeAwardText1:
        "Die Analysten der Scope Fund Analysis bewerten täglich die Risiko- und Renditeprofile von Investmentfonds und die Qualität von Asset Managern. Mit ihren Ratings, Rankings und Research-Reports unterstützen sie Investoren bei ihren Anlage- und Selektionsentscheidungen.",
      scopeAwardText2:
        "Einmal im Jahr lassen unsere Analysten ihre Arbeit Revue passieren und bestimmen herausragende Asset Management Leistungen und die überzeugendsten Fondskonzepte, um sie mit dem Scope Award auszuzeichnen.",
      scopeAwardText3:
        "Der Scope Award ist die traditionsreichste und bedeutendste Auszeichnung für Fonds- und Asset Manager im deutschsprachigen Raum – sowohl für Aktien-, Renten- und Mischfonds (Scope Investment Awards) als auch für Sachwerte und alternative Investments (Scope Alternative Investment Awards). Die Scope Investment Awards blicken auf eine Historie von mehr als 10 Jahren zurück. Die Scope Alternative Investment Awards werden seit 2005 vergeben.",
      scopeAwardText4:
        "Eines der Merkmale des Scope Awards ist die etablierte Medienpartnerschaft mit dem Handelsblatt. Bereits seit 2013 findet die Preisverleihung gemeinsam mit dem Handelsblatt statt.",
      scopeAwardText5:
        "Ein weiterer Aspekt: Während sämtliche Auszeichnungen am Markt ausschließlich auf quantitative Kriterien setzen, werden beim Scope Award auch qualitative Kriterien umfassend berücksichtigt. Die Verwendung qualitativer Aspekte erhöht den Aufwand für unsere Analysten deutlich, ermöglicht dafür jedoch auf die Zukunft gerichtete Aussagen zur Qualität von Fonds und Managern.",
      scopeAwardText6:
        "Was unterscheidet eigentlich den Scope Award von einem Scope Rating? Zwar geht es bei beiden darum, qualitativ hochwertige Fonds und Asset Manager aus der Masse herauszuheben, dennoch gibt es einen gravierenden Unterschied: Ein Rating können Fondsanbieter und Asset Manager erwerben, für einen Award müssen sie sich im Rahmen der Teilnahme- und Nominierungsbedingungen qualifizieren.",
      awardSculpture: "Die Award Skulptur​",
      awardSculptureText1:
        "Der Scope Award gibt als Skulptur sowohl der Identität des Unternehmens als auch der Zielsetzung der Auszeichnung eine greifbare Gestalt.",
      awardSculptureText2:
        "Die Scope Group steht für Unabhängigkeit, Kompetenz, Transparenz und Kontinuität. Mit der Verleihung des Investment Awards zeichnet die deutsche Ratingagentur die Qualität von Asset Managern von Publikumsfonds und institutionellen Fonds aus.",
      awardSculptureText3:
        "Die Suche nach einer Skulptur, die als Auszeichnung die Leistungen der Preisträger würdigt und gleichzeitig die Grundsätze der Scope Group symbolisiert, führte zu einer Kombination aus Metall und Glas.",
      awardSculptureText4:
        "Durchdringung ist die gestalterische Grundidee. Der Scope Award setzt sich aus geometrischen Formen zusammen. Die Linien sind klar und eindeutig. In Anlehnung an das Logo der Scope Group durchdringt ein Metallzylinder eine Stele aus Glas. Beide Elemente fußen auf einem quadratischen Fundament aus Edelstahl, der dem Objekt Gewicht und Standfestigkeit verleiht.",
      awardSculptureText5:
        "Metall steht für Integrität und messbaren Wert. Glas verbindet Transparenz mit einem hohen Anspruch an Sorgfalt. Die Skulptur vereint so die Durchdringung des Marktes mit der Unabhängigkeit des Urteils.",
      awardSculptureText6:
        "Die Auszeichnung hebt als Ganzes die Leistung des Preisträgers hervor. ",
      saveTheDate: "Save the date",
      saveTheDateHost: " Moderation: Barbara Schöneberger​",
      nominees: "Nominierungen",
      nomineesTextTooltip:
        "Die Nominierungen werden von der Jury Anfang September bekannt gegeben​",
      videoBannerText:
        "Impressionen vom Scope Award 2022 in Berlin // Moderation Barbara Schöneberger",
      winner: "Gewinner",
      winner2: "Die Gewinner",
      winners: "Gewinner",
      winnerMoreBtn: "Mehr",
      winnerText:
        "Die Gewinner werden im Rahmen der Award Verleihung am 18. November bekannt gegeben. Im Handelsblatt werden die Gewinner am Folgetag veröffentlicht​​",
      nominationAltInvestmentRequirements: "Nominierungsbedingungen & Methodik",
      nominationAltInvestmentRequirementsText:
        "Teilnahmekriterien und Auswahlprozess​",
      categories: "Kategorien",
      assetManagersFunds: "Asset Manager / Fonds",
      categoriesInvestment: "Kategorien Investment",
      categoriesAlternativeInvestment: "Kategorien Alternative Investment",
      categoriesCertificate: "Kategorien Zertifikate",
      showLess: "Weniger anzeigen",
      mediaPartner: "Medienpartner:",
      country: "Land:",
      germany: "DE",
      austria: "AT",
      switzerland: "CH",
      awards2022: {
        date: "18. November 2021 in Berlin",
        subtitle:
          "Die traditionsreichste und bedeutendste Auszeichnung für ​Fonds und Asset Manager​",
        subtitleAlternative:
          "Die traditionsreichste und bedeutendste Auszeichnung für Asset Manager​",
        subtitleCertificate:
          "Die traditionsreichste und bedeutendste Auszeichnung für ​Zertifikate Emittenten",
        investment: {
          categoriesText:
            "In insgesamt 28 Kategorien werden Scope Investment Awards 2022 verliehen:",
          categoriesSubItem1: "16 Asset Manager Kategorien",
          categoriesSubItem2: "12 Fonds Kategorien",
          nomineesText:
            "In 28 Kategorien für Aktien-, Renten- und Mischfonds werden die überzeugendsten Fondskonzepte und besten Asset Management Leistungen des Jahres mit dem Scope Award ausgezeichnet.",
          winnersText1:
            "Scope Awards – Die besten Asset Manager und Fonds ausgezeichnet",
          winnersText2:
            "Gemeinsam mit dem Medienpartner Handelsblatt hat die Ratingagentur Scope die 16. Scope Awards verliehen.",
        },
        alternativeInvestment: {
          categoriesText:
            "In insgesamt 20 Kategorien werden die Scope Alternative Investment Awards 2022 verliehen:",
          categoriesSubItem1: "Institutional & Retail Real Estate",
          categoriesSubItem2: "ESG",
          categoriesSubItem3: "Alternative Investments",
          categoriesSubItem4: "Special Award",
          nomineesText:
            "In 21 Kategorien für Real Estate, ESG und Alternative Investments werden die besten Asset Management Leistungen des Jahres mit dem Scope Award ausgezeichnet.",
          winnersText1: "Scope Awards – Die besten Asset Manager ausgezeichnet",
          winnersText2:
            "Gemeinsam mit dem Medienpartner Handelsblatt hat die Ratingagentur Scope die 16. Scope Awards verliehen.",
        },
        certificate: {
          categoriesText:
            "In vier Kategorien werden die Scope Zertifikate Awards 2022 verliehen.",
          nomineesText:
            "In vier Kategorien für Primärmarkt, Sekundärmarkt, Trading und ESG werden die besten Zertifikate Emittenten des Jahres mit dem Scope Award ausgezeichnet.",
          winnersText1:
            "Scope Awards – Die besten Zertifikateanbieter ausgezeichnet",
          winnersText2:
            "Gemeinsam mit dem Medienpartner Handelsblatt hat die Ratingagentur Scope die 16. Scope Awards verliehen.",
        },
        requirements: {
          text: "Teilnahmekriterien und Auswahlprozess",
        },
      },
      awards2023: {
        date: "17. November 2022 in Berlin",
        subtitle:
          "Die traditionsreichste und bedeutendste Auszeichnung für Fonds und Asset Manager.",
        subtitleAlternative:
          "Die traditionsreichste und bedeutendste Auszeichnung für Fonds und Asset Manager.",
        subtitleCertificate:
          "Die traditionsreichste und bedeutendste Auszeichnung für Fonds und Asset Manager.",
        investment: {
          categoriesText:
            "In insgesamt 30 Kategorien werden Scope Investment Awards 2023 verliehen:",
          categoriesSubItem1: "17 Asset Manager Kategorien",
          categoriesSubItem2: "13 Fonds Kategorien",
          nomineesText:
            "In 30 Kategorien für Aktien-, Renten- und Mischfonds werden die überzeugendsten Fondskonzepte und besten Asset Management Leistungen des Jahres mit dem Scope Award ausgezeichnet.",
          winnersText1:
            "Scope Awards – Die besten Asset Manager und Fonds ausgezeichnet",
          winnersText2:
            "Gemeinsam mit dem Medienpartner Handelsblatt hat die Ratingagentur Scope die 17. Scope Awards verliehen.",
        },
        alternativeInvestment: {
          categoriesText:
            "In insgesamt 21 Kategorien werden die Scope Alternative Investment Awards 2023 verliehen:",
          categoriesSubItem1: "Institutional & Retail Real Estate",
          categoriesSubItem2: "ESG",
          categoriesSubItem3: "Alternative Investments",
          categoriesSubItem4: "Special Award",
          nomineesText:
            "In 21 Kategorien für Real Estate, ESG und Alternative Investments werden die besten Asset Management Leistungen des Jahres mit dem Scope Award ausgezeichnet.",
          winnersText1: "Scope Awards – Die besten Asset Manager ausgezeichnet",
          winnersText2:
            "Gemeinsam mit dem Medienpartner Handelsblatt hat die Ratingagentur Scope die 17. Scope Awards verliehen.",
        },
        certificate: {
          categoriesText:
            "In vier Kategorien werden die Scope Zertifikate Awards 2023 verliehen.",
          nomineesText:
            "In vier Kategorien für Primärmarkt, Sekundärmarkt, Trading und ESG werden die besten Zertifikate Emittenten des Jahres mit dem Scope Award ausgezeichnet.",
          winnersText1:
            "Scope Awards – Die besten Zertifikateanbieter ausgezeichnet",
          winnersText2:
            "Gemeinsam mit dem Medienpartner Handelsblatt hat die Ratingagentur Scope die 17. Scope Awards verliehen.",
        },
        requirements: {
          text: "Teilnahmekriterien und Auswahlprozess.​",
        },
      },
      awards2024: {
        date: "16. November 2023,  Frankfurt am Main",
        subtitle: "16. November 2023,  Frankfurt am Main",
        subtitleAlternative: "16. November 2023,  Frankfurt am Main",
        subtitleCertificate: "16. November 2023,  Frankfurt am Main",
        investment: {
          categoriesText:
            "In insgesamt 30 Kategorien werden Scope Investment Awards 2024 verliehen:",
          categoriesSubItem1: "16 Asset Manager Kategorien",
          categoriesSubItem2: "13 Fonds Kategorien",
          categoriesSubItem3: "Sonderkategorie: Fund Innovations",
          nomineesText:
            "In 30 Kategorien für Aktien-, Renten- und Mischfonds werden die überzeugendsten Fondskonzepte und besten Asset Management Leistungen des Jahres mit dem Scope Award ausgezeichnet.",
          nomineesInfo:
            "Die Nominierungen werden Anfang September bekanntgegeben 2023.",
          winnersText1:
            "Scope Awards – Auszeichnung der besten Asset Manager und Fonds",
          winnersText2:
            "Gemeinsam mit dem Medienpartner Handelsblatt hat die Ratingagentur Scope die 18. Scope Awards verliehen.",
          winnersInfo:
            "Die Gewinner werden beim Scope Award 2024 am 16. November 2023 in Frankfurt am Main bekanntgegeben.",
        },
        alternativeInvestment: {
          categoriesText:
            "In insgesamt 20 Kategorien werden die Scope Alternative Investment Awards 2024 verliehen:",
          categoriesSubItem1: "Real Estate",
          categoriesSubItem2: "ESG",
          categoriesSubItem3: "Alternative Investments",
          categoriesSubItem4: "Special Award",
          nomineesText:
            "In 20 Kategorien für Real Estate, ESG und Alternative Investments werden die besten Asset Management Leistungen des Jahres mit dem Scope Award ausgezeichnet.",
          nomineesInfo:
            "Die Nominierungen werden Anfang Oktober bekanntgegeben 2023.",
          winnersText1: "Scope Awards – Auszeichnung der besten Asset Manager",
          winnersText2:
            "Gemeinsam mit dem Medienpartner Handelsblatt hat die Ratingagentur Scope die 18. Scope Awards verliehen.",
          winnersInfo:
            "Die Gewinner werden beim Scope Award 2024 am 16. November 2023 in Frankfurt am Main bekanntgegeben.",
        },
        certificate: {
          categoriesText:
            "In vier Kategorien werden die Scope Zertifikate Awards 2024 verliehen.",
          nomineesText:
            "In vier Kategorien für Primärmarkt, Sekundärmarkt, Trading und ESG werden die besten Zertifikate Emittenten des Jahres mit dem Scope Award ausgezeichnet.",
          nomineesInfo:
            "Die Nominierungen werden Mitte Oktober 2023 bekanntgegeben.",
          winnersText1:
            "Scope Awards – Auszeichnung der besten Zertifikate Emittenten",
          winnersText2:
            "Gemeinsam mit dem Medienpartner Handelsblatt hat die Ratingagentur Scope die 18. Scope Awards verliehen.",
          winnersInfo:
            "Die Gewinner werden beim Scope Award 2024 am 16. November 2023 in Frankfurt am Main bekanntgegeben.",
        },
        requirements: {
          text: "Teilnahmekriterien und Auswahlprozess.​",
        },
      },
      awards2025: {
        date: "",
        subtitle: "",
        subtitleAlternative: "",
        subtitleCertificate: "",
        investment: {
          categoriesText:
            "In insgesamt 30 Kategorien werden Scope Investment Awards 2025 verliehen:",
          categoriesSubItem1: "15 Asset Manager Kategorien",
          categoriesSubItem2: "15 Fonds Kategorien",
          categoriesSubItem3: "Sonderkategorie: Fund Innovations",
          nomineesText:
            "In 30 Kategorien für Aktien-, Renten- und Mischfonds werden die überzeugendsten Fondskonzepte und besten Asset Management Leistungen des Jahres mit dem Scope Award ausgezeichnet.",
          nomineesInfo:
            "Die Nominierungen werden Anfang September 2024 bekanntgegeben.",
          winnersText1:
            "Scope Awards – Auszeichnung der besten Asset Manager und Fonds",
          winnersText2:
            "Gemeinsam mit dem Medienpartner Handelsblatt verleiht die Ratingagentur Scope die 19. Scope Awards verliehen.",
          winnersInfo:
            "Die Gewinner werden am 21. November 2024 bekanntgegeben.",
        },
        alternativeInvestment: {
          categoriesText:
            "In insgesamt 25 Kategorien werden die Scope Alternative Investment Awards 2025 verliehen:",
          categoriesSubItem1: "Institutional Real Estate",
          categoriesSubItem2: "Retail Real Estate",
          categoriesSubItem3: "Real Estate",
          categoriesSubItem4: "ESG",
          categoriesSubItem5: "Alternative Investments",
          categoriesSubItem6: "ELTIF",
          categoriesSubItem7: "Special Award",
          nomineesText:
            "In 25 Kategorien für Real Estate, ESG, Alternative Investments und ELTIF werden die besten Asset Management Leistungen des Jahres mit dem Scope Award ausgezeichnet.",
          nomineesInfo:
            "Die Nominierungen werden Anfang Oktober 2024 bekanntgegeben.",
          winnersText1: "Scope Awards – Auszeichnung der besten Asset Manager",
          winnersText2:
            "Gemeinsam mit dem Medienpartner Handelsblatt verleiht die Ratingagentur Scope die 19. Scope Awards.",
          winnersInfo:
            "Die Gewinner werden am 21. November 2024 bekanntgegeben.",
        },
        certificate: {
          categoriesText:
            "In vier Kategorien werden die Scope Zertifikate Awards 2025 verliehen.",
          nomineesText:
            "In vier Kategorien für Primärmarkt, Sekundärmarkt, Trading und ESG werden die besten Zertifikate Emittenten des Jahres mit dem Scope Award ausgezeichnet.",
          nomineesInfo:
            "Die Nominierungen werden Mitte Oktober 2024 bekanntgegeben.",
          winnersText1:
            "Scope Awards – Auszeichnung der besten Zertifikate Emittenten",
          winnersText2:
            "Gemeinsam mit dem Medienpartner Handelsblatt hat die Ratingagentur Scope die 19. Scope Awards verliehen.",
          winnersInfo:
            "Die Gewinner werden am 21. November 2024 bekanntgegeben.",
        },
        requirements: {
          text: "Teilnahmekriterien und Auswahlprozess.​",
        },
      },
    },
    scopeAwardsCategories: {
      assetManager: "Asset Manager",
      funds: "Fonds",
      institutionalRealEstate: "Institutional Real Estate",
      retailRealEstate: "Retail Real Estate",
      realEstate: "Real Estate",
      alternativeInvestments: "Alternative Investments",
      specialAward: "Special Award",
      bestFundEquitiesGermany: "Aktien Deutschland",
      bestEquitiesEurope: "Aktien Europa",
      bestFundEquitiesNorthAmerica: "Aktien Nordamerika",
      bestFundEquitiesJapan: "Aktien Japan",
      bestFundEquitiesGlobal: "Aktien Welt",
      bestFundEquitiesEmergingMarkets: "Aktien Emerging Markets (Fonds)",
      bestFundBalancedGlobalFlexible: "Mischfonds Global Flexibel",
      bestFundBalancedGlobalDynamic: "Mischfonds Global Dynamisch",
      bestFundBalancedGlobalConservative: "Mischfonds Global Konservativ",
      bestFundInnovations: "Innovations",
      innovationsDisclaimer:
        "In der Kategorie gibt es keine Nominierungen, der Gewinner wird am 16.11.2023 ausgezeichnet",
      completeProductRangeIssuer: "Universal-Emittent",
      bestAmCompleteProductRanges: "Universalanbieter",
      bestAmCompleteProductRange: "Universalanbieter",
      bestAmBoutiques: "Spezialanbieter",
      bestAmBoutique: "Spezialanbieter",
      bestAmExchangeTradeFunds: "Exchange Traded Funds (ETFs)",
      bestAmEsgCompleteProductRanges: "ESG Universalanbieter",
      bestAmEsgBoutiques: "ESG Spezialanbieter",
      bestAmEquities: "Aktienfonds",
      bestAmEsgEquities: "ESG Aktienfonds",
      bestAmThematicFunds: "Themenfonds Aktien",
      bestAmBonds: "Rentenfonds",
      bestAmEsgCompleteProductRange: "ESG Universalanbieter",
      bestAmEsgBoutique: "ESG Spezialanbieter",
      bestFundBondsGlobalCurrency: "Renten Globale Währungen",
      bestFundBondsEur: "Renten EURO",
      bestFundBondsEurCorpHighYield: "Renten EURO Corp. High Yield",
      bestFundBondsUsdCorpHighYield: "Renten USD Corp. High Yield",
      bestFundBondsGlobalCorpHighYield: "Renten Global Corp. High Yield",
      bestBalancedGlobalBalanced: "Mischfonds Global Ausgewogen",
      bestFundBondsEmergingMarketsHardCurrency:
        "Renten Emerging Markets Hard Currency",
      bestAmEsgFixedIncome: "ESG Rentenfonds",
      bestAmMultiStrategy: "Multi Strategie",
      bestAmEquitiesEmergingMarkets: "Aktien Emerging Markets (AM)",
      bestAmMultiAsset: "Multi Asset",
      bestAmEmergingMarketsBonds: "Renten Emerging Markets",
      bestAmEmergingMarketsEquities: "Aktien Emerging Markets",
      bestAmConvertibleBonds: "Wandelanleihen",
      bestAmDividendEquities: "Aktien Dividende",
      bestAmEtf: "Exchange Traded Funds (ETFs)",
      bestAmSmallCaps: "Aktien Nebenwerte",
      equitiesGermany: "Aktien Deutschland",
      equitiesEurope: "Aktien Europa",
      equitiesNorthAmerica: "Aktien Nordamerika",
      equitiesGlobal: "Aktien Welt",
      equitiesThematicFunds: "Aktien Themenfonds",
      esgFunds: "ESG Fonds",
      esgImpactFunds: "ESG Impact Fonds",
      equitiesEmergingMarkets: "Aktien Emerging Markets",
      bondsEUR: "Renten EURO",
      bondsEURCorp: "Renten EURO Corp. High Yield",
      bondsUsCorp: "Renten US Corp. High Yield",
      bondsEmergingMarkets: "Renten Emerging Markets Hard Currency",
      bondsGlobalCurrency: "Renten Globale Währungen",
      balancedGlobalFlexible: "Mischfonds Global Flexibel",
      fundInnovations: "Fund Innovations",
      institutionalReGlobal: "Institutional Real Estate Global",
      institutionalReEurope: "Institutional Real Estate Europe",
      institutionalReGermany: "Institutional Real Estate Germany",
      institutionalReResidential: "Institutional Real Estate Residential",
      institutionalReSpecialist: "Institutional Real Estate Specialist",
      retailReGlobal: "Retail Real Estate Global",
      realEstateGlobal: "Real Estate Global",
      retailReEurope: "Retail Real Estate Europe",
      retailReGermany: "Retail Real Estate Germany",
      retailReResidential: "Retail Real Estate Residential",
      retailReSpecialist: "Retail Real Estate Specialist",
      realEstateSpecialist: "Real Estate Specialist",
      realEstateLogistics: "Real Estate Logistics",
      realEstateEssentialRetail: "Real Estate Essential Retail",
      esgInfrastructure: "ESG Infrastructure",
      esgRealEstate: "ESG Real Estate",
      esgCommercialRealEstate: "ESG Commercial Real Estate",
      esgResidentialRealEstate: "ESG Residential Real Estate",
      privateDebt: "Private Debt",
      privateEquity: "Private Equity",
      infrastructureDebt: "Infrastructure Debt",
      infrastructureEquity: "Infrastructure Equity",
      infrastructureEquityRenewableEnergy: "Infrastructure Equity - New Energy",
      transportation: "Transportation",
      eltif: "ELTIF",
      eltifPrivateMarketsPe: "ELTIF - Private Markets PE",
      eltifPrivateMarketsPd: "ELTIF - Private Markets PD",
      eltifPrivateMarketsInfrastructure:
        "ELTIF - Private Markets Infrastructure",
      eltifPrivateMarketsMixed: "ELTIF - Private Markets Mixed",
      eltifPrivateMarketsDiversified: "ELTIF - Private Markets Diversified",
      innovation: "Innovation",
      innovationRealAssets: "Innovation – Real Assets",
      innovationAif: "Innovation – Alternative Investments",
      primaryMarket: "Primärmarkt",
      secondaryMarket: "Sekundärmarkt",
      trading: "Trading",
      esg: "ESG",
      specialMentionFundInnovations: "Sonderkategorie Fund Innovations",
      rentenUsCorpHighYield: "Renten US Corp. High Yield",
      commodity: "Rohstofffonds",
      balancedGlobalConservative: "Mischfonds Global Konservativ",
    },
    scopeAwardsNominees: {
      degussaBank: "Degussa Bank für Nachhaltigkeitfonds Akzentuiert",
      hanetfFundSpectrum: "HANetf für gesamtes Fondsspektrum",
      unionInvestment: "Union Investment für KinderZukunftsFonds",
    },
    nominationAltInvestmentRequirements: {
      title: "Nominierungsbedingungen",
      subtitle: "Alternative Investments",
      section1Title: "Teilnahmekriterien und Auswahlprozess",
      section1Text1:
        "Nominiert und ausgezeichnet werden die besten Asset Manager von Publikumsfonds und institutionellen Anlageprodukten im Bereich Alternative Investments.",
      section1Text2:
        "Eine Teilnahme am Award erfolgt unentgeltlich für das Universum der Anbieter von Alternativen Investments mit europäischer Zulassung auf Ansprache durch die Scope Fund Analysis GmbH oder aufgrund eigener Bewerbung.",
      section2Title: "Ihre Bewerbung",
      section2Text1: "Der Bewerbungsprozess beginnt Mitte Juli 2024.",
      section3Title: "Bewertungskriterien sind unter anderem:",
      investmentManagement: "Investment Management",
      operationalAssetManagement: "Operatives Asset Management",
      trackRecord: "Track Record",
      esg: "ESG",
      eltif: "ELTIF",
      eltifPrivateMarketsPe: "ELTIF - Private Markets PE",
      eltifPrivateMarketsPd: "ELTIF - Private Markets PD",
      eltifPrivateMarketsInfrastructure:
        "ELTIF - Private Markets Infrastructure",
      eltifPrivateMarketsMixed: "ELTIF - Private Markets Mixed",
      innovation: "Innovationskraft",
      transparency: "Transparenz",
      section3Text1:
        "Die vorgenannten Bewertungskriterien unterteilen sich in zahlreiche Unterkriterien, deren Einordnung in einer Scorecard durch die Analysten der Scope Fund Analysis vorgenommen wird. ",
      section3Text2:
        "Die Bewertung basiert dabei auf der Beantwortung der Fragebögen und weiteren vom Asset Manager zur Verfügung gestellten sowie öffentlich zugänglichen Informationen.",
      section3Text3: "Die Bewerbungsunterlagen erhalten Sie auf Anfrage unter:",
      email: "alternative-investment-awards@scopeanalysis.com.",
      section3ButtonText: "Download der Methodik 2025",
      file: "/Methodik_Scope_Alternative Investment_Award 2025_DE.pdf",
    },
    nominationInvestmentRequirements: {
      title: "Nominierungsbedingungen & Methodik",
      subtitle: "Investment Fonds",
      section1Title: "Teilnahmekriterien und Auswahlprozess",
      section1Text1:
        "Nominiert und ausgezeichnet werden die besten Aktien-, Renten- und Mischfonds in 14 Kategorien plus die Sonderkategorie „Fund Innovations“ sowie Asset Manager in 15 Kategorien.",
      section1Text2:
        "Eine Teilnahme am Award erfolgt für die Anbieter unentgeltlich im jeweiligen Fonds-Universum (Deutschland, Österreich und die Schweiz) unter Berücksichtigung der jeweils zum öffentlichen Vertrieb zugelassenen Fonds.",
      section1Text3: "Download der Methodik 2025",
      section2Title: "Bewertungskriterien sind unter anderem:",
      section2List1: "Fondsmanagement",
      section2List2: "Managementumfeld",
      section2List3: "Gestaltung des Investment Prozesses",
      section2List4: "Implementierung des Investment Prozesses",
      section2List5: "Scope Fondsrating",
      section2Text1:
        "Die vorgenannten Bewertungskriterien unterteilen sich in zahlreiche Unterkriterien, deren Einordnung in einer Scorecard durch die Analysten der Scope Fund Analysis vorgenommen wird.",
      section2Text2:
        "Die Bewertung basiert dabei auf der Beantwortung der Fragebögen und weiteren vom Asset Manager zur Verfügung gestellten sowie öffentlich zugänglichen Informationen.",
      section2Text3: "Für Anfragen diesbezüglich wenden Sie sich bitte an:",
      email: "investment-awards@scopeanalysis.com.",
      file: "/Methodik Scope Investment Awards 2025.pdf",
    },
    nominationCertificateRequirements: {
      title: "Nominierungsbedingungen",
      subtitle: "Zertifikate-Emittenten",
      section1Title: "Teilnahmekriterien und Auswahlprozess",
      section2Title: "Bester Emittent Primärmarkt / Sekundärmarkt",
      section2Text1:
        "Nominiert und ausgezeichnet werden die besten Zertifikate-Emittenten der letzten zwölf Monate in den Kategorien Primärmarktemittenten und Sekundärmarktemittenten.",
      section2Text2:
        "Die Kriterien für die Nominierung setzen sich aus den folgenden Elementen zusammen:",
      section2List1: "Marktstellung",
      section2List2: "Innovationskraft",
      section2List3: "Produktangebot",
      section2List4: "Digitalisierungsstrategie",
      section2List5: "Alleinstellungsmerkmale",
      section2List6: "Kundenservice",
      section2Text3:
        "Die vorgenannten Bewertungskriterien unterteilen sich zum Teil in Unterkriterien, deren Einordnung in einer Scorecard durch die Analysten der Scope Fund Analysis vorgenommen wird.",
      section3Title: "Bester Emittent Trading",
      section3Text1:
        "Scope nominiert und zeichnet mit dem Trading Award Emittenten mit der besten Preisstellung und dem bestem Leistungsspektrum bei Hebelprodukten und Discountzertifikaten aus. Basis für die Nominierung von Scope ist zum einen eine quantitative Auswertung der Preisstellung der Emittenten im Monat September 2024. Zum anderen fließen in die Entscheidung weitere Faktoren wie Produktangebot, Marktanteile und Kundenservice ein.",
      section4Title: "Bester Emittent ESG",
      section4Text1:
        "Mit dem ESG Award nominiert und zeichnet Scope Emittenten aus, die im letzten Jahr unter ESG Gesichtspunkten am besten abgeschnitten haben. Basis für die Nominierung von Scope ist zum einen, wie nachhaltig der Emittent als Produktanbieter an sich ist. Zum anderen fließen in die Entscheidung weitere Faktoren wie die Erweiterung des Angebots um nachhaltige Produkte sowie die Bereitstellung von Informationen unter nachhaltigen Aspekten ein.",
      section5Title: "Teilnahmebedingungen",
      section5Text1:
        "Eine Teilnahme am Award erfolgt unentgeltlich für das Universum der im Bundesverband für strukturierte Wertpapiere (BSW) organisierten Emittenten auf Ansprache durch die Scope Fund Analysis GmbH. ",
      section5Text2: "Für Anfragen wenden Sie sich bitte an:",
      email: "zertifikate-awards@scopeanalysis.com",
    },
    portrait: {
      subtitle:
        "Scope Fund Analysis ist Teil der Scope Gruppe und bewertet Investmentfonds, Asset Manager und Zertifikate-Anbieter. Die aktuell mehr als 10.000 analysierten Anlageprodukte verwalten ein Vermögen von rund 5,3 Billionen Euro. Der überwiegende Teil der Bewertungen ist auf dem ScopeExplorer frei zugänglich.",
      subtitle2:
        " Das Unternehmen hat mehr als 20 Jahre Erfahrung mit der Beurteilung von Investments und Asset Managern. Die mehr als 25 Analystinnen und Analysten verteilen sich auf die Standorte Frankfurt am Main und Berlin.",
      section1Title: "Die Analyse-Aktivitäten gruppieren sich in vier Bereiche",
      section2Title: "Das Fonds Rating von Scope – zum Hintergrund",
      section2Text1:
        "Das Fonds Rating von Scope Fund Analysis ist ein objektives Gütesiegel, das Investoren bei der Einschätzung der Qualität eines Fonds unterstützt. Die meisten Fonds werden quantitativ beurteilt auf Grundlage ihrer bisher gezeigten Leistungen. Bei ausgewählten Fonds erfolgt zusätzlich eine qualitative Bewertung.",
      section2Text2:
        "Das Scope Fonds Rating hat einen prognostischen Charakter und trifft eine in die Zukunft gerichtete Aussage über die Qualität eines Produkts. Grundlage des Ratings ist ein mehrdimensionales Bewertungsmodell, das sowohl Performance- als auch Risiko-Aspekte reflektiert. Darüber hinaus können weitere Aspekte wie ESG oder die Qualität des Fondsmanagements in die Bewertung einfließen.",
      methodologies: "Methodiken",
    },
    subscriptionRating: {
      text1:
        "Ausführliche Informationen inklusive Ratingnoten und Ratingreport stehen angemeldeten Nutzern zur Verfügung. Bitte loggen Sie sich mit Ihren Nutzerdaten ein oder registrieren sich jetzt kostenfrei.",
    },
  },
  en: {
    app: {
      help: "Help",
      save: "Save",
      confirm: "Confirm",
      cancel: "Cancel",
      edit: "Edit",
      remove: "Remove",
      link: "Link",
      yes: "Yes",
      no: "No",
      uploadText1: "Click or drag image file to this area to upload.",
      uploadText2: "We only allow",
      uploadText3: "formats with a maximum size of ",
      and: "and",
      dataFor: "Data as of ",
      new: "New",
      expandAll: "Expand all",
      collapseAll: "Collapse all",
    },
    routes: {
      home: "/en/",
      confirm: "/en/confirm",
      reset: "/en/reset",
      unsubscribe: "/en/unsubscribe",
      fundsInFocus: "/en/funds-in-focus",
      ratingInFocus: "/en/rating-in-focus",
      watchlist: "/en/watchlist",
      news: "/en/news",
      newsDetails: "/en/news/:slug?/:id",
      reports: "/en/reports",
      reportsDetails: "/en/reports/:slug?/:id",
      oif: "/en/oif",
      oifDetails: "/en/oif/:slug?/:id",
      searchResults: "/en/search-results",
      filterSearchResults: "/en/filter-search-results",
      advSearchResults: "/en/advanced-search-results",
      details: "/en/details/:slug/:id",
      premium: "/en/premium",
      methodology: "/en/methodology",
      methodologies: "/en/methodologies",
      legalNotice: "/en/legal-notice",
      privacyPolicy: "/en/privacy-policy",
      contact: "/en/contact",
      savedSearches: "/en/saved-searches",
      rechtlicheHinweise: "/en/rechtliche-hinweise",
      termsOfUse: "/en/terms-of-use",
      termsAndConditions: "/en/terms-and-conditions",
      conferencesListLoading: "/en/conferences-loading",
      conferencesList: "/en/conferences",
      conferencesList2: "/en/conferences_list",
      conferenceDetails: "/en/conferences/:id",
      assetManagerDashboard: "/en/asset-manager-dashboard/:id/",
      fundRatings: "/en/asset-manager-dashboard/:id/fund-ratings",
      teamRating: "/en/asset-manager-dashboard/:id/team-rating",
      profileEdit: "/en/asset-manager-dashboard/:id/profile",
      assetManagerStats: "/en/asset-manager-dashboard/:id/stats",
      assetManagerConferences: "/en/asset-manager-dashboard/:id/conferences",
      surveyEquityFund: "/en/survey/equity-fund",
      surveySovereignBondFund: "/en/survey/sovereign-bond-fund",
      surveyCorporateBondFund: "/en/survey/corporate-bond-fund",
      surveyAggregateBondFund: "/en/survey/aggregate-bond-fund",
      surveyMultiAssetFund: "/en/survey/multi-asset-fund",
      surveyEsgCompany: "/en/survey/company",
      surveyEsgFund: "/en/survey/fund",
      dekaSurvey: "/en/deka-survey",
      assetManagerDetails: "/en/asset-managers/:id",
      assetManagers: "/en/asset-managers",
      assetManagerNews: "/en/asset-manager-news/:slug/:id",
      editFund: "/en/edit-fund/:id",
      userProfile: "/en/profile",
      benchmarks: "/en/benchmarks/:id",
      aifPage: "/en/aif-page",
      wholesale: "/en/professional-investors",
      selectSurvey: "/en/select-survey",
      scopeAwards: "/en/scope-awards/:year([0-9]{4})",
      scopeInvestmentAwards: "/en/scope-awards/:year/investment",
      scopeAlternativeInvestmentAwards:
        "/en/scope-awards/:year/alternative-investment",
      scopeCertificateAwards: "/en/scope-awards/:year/certificate",
      scopeAwardsCategories: "/en/scope-awards/:year/:name/categories",
      scopeAwardsNominees: "/en/scope-awards/:year/:name/nominees/:categoryId?",
      scopeAwardsWinners: "/en/scope-awards/:year/:name/winners",
      nominationAltInvestmentRequirements:
        "/en/nomination-alternative-investment-requirements",
      nominationInvestmentRequirements:
        "/en/nomination-investment-requirements",
      nominationCertificateRequirements:
        "/en/nomination-certificate-requirements",
      portrait: "/en/scope-fund-analysis",
    },
    meta: {
      home: "Fund ratings, rankings and comparisons | ScopeExplorer",
      homeDesc:
        "ScopeExplorer is a simple and independent tool to help you choose from over 6000 rated funds. Check your fund now!",
      searchResults: "Search results | ScopeExplorer",
      searchResultsDesc:
        "Find your fund's position in the comparative ranking. See which funds top their peer groups based on our independent fund rating.",
      fundsInFocus:
        "In focus: a selection of innovative, successful funds | ScopeExplorer | ScopeExplorer",
      fundsInFocusDesc:
        "Funds selected by our rating analysts due to their outstanding results or innovative investment ideas and management approaches. Benefit from our experience!",
      ratingInFocus: "Team Ratings | ScopeExplorer",
      premium: "Premium | ScopeExplorer",
      contact: "Contact | ScopeExplorer",
      methodology: "Our assessment: fund rating methodology | ScopeExplorer",
      methodologyDesc:
        "The Scope fund rating methodology, established for over 20 years, allows us to evaluate funds transparently for your investment needs",
      methodologiesDesc:
        "In this section you find full documents on methodologies and rating processes that Scope uses to evaluate funds, asset manager and issuers of certificates.",
      legalNotice: "Legal notice | ScopeExplorer",
      privacy: "Privacy | ScopeExplorer",
      news: "The latest news, analysis and publications | ScopeExplorer",
      newsDesc:
        "The latest news, analysis and background information on funds and investments from Scope, the independent rating agency. Discover what's driving the market!",
      scopeExplorer: "ScopeExplorer",
      notFound: "404 - Not found | ScopeExplorer",
      fundDetails: "Fund details",
      fundDetailsDesc:
        "All the facts and ratings about your fund. Detailed information on how the fund is performing and all the factors you need for your investment decision.",
      newsDetails: "News details | ScopeExplorer",
      watchlist: "Watchlist | ScopeExplorer",
      privacyPolicy: "Privacy policy | ScopeExplorer",
      rechtlicheHinweise: "Rechtliche Hinweise | ScopeExplorer",
      termsOfUse: "Terms of use | ScopeExplorer",
      termsAndConditions:
        "Besondere Bestimmungen der Offene Immobilienfonds Studie 2020 | ScopeExplorer",
    },
    assetManagerCharts: {
      allMyProductsChart: "All my products",
      myTop10FundsChart: "My Top 10 Funds",
      myProductsInPeergroupChart: "My Products in Peergroups",
      top10FundsInSeChart: "Top 10 Funds in SE",
      top10PeergroupsInSeChart: "Top 10 Peergroups in SE",
    },
    assetManagers: {
      listTitle: "Asset Manager",
      title: "Company profile",
      profile: "Profile",
      profileSubtitle:
        "Update your company profile here. Company profiles can be viewed by ScopeExplorer users under the 'Asset Manager' heading.",
      subtitle:
        "In this section you will find detailed portraits of asset managers - including Scope's assessment of their quality and the ratings of all funds of the asset manager.",
      address_1: "Address 1",
      address_2: "Address 2",
      email: "E-mail",
      phone: "Phone",
      founded: "Founded",
      link: "Link",
      linkText: "Link text",
      description_short: "Short description",
      description: "Description",
      descriptionFixedQuestion:
        "The asset manager you are looking for is not yet listed?",
      descriptionFixedText:
        "We regularly expand our overviews of asset manager. You can find the funds of all companies using our search function.",
      editProfile: "Edit profile",
      list: "Fund list of the asset manager",
      employees: "Company employees",
      dateFrom: "From:",
      dateTo: "To: ",
      contact: "Contact",
      topCompanyFunds: "Top 10 Funds",
      contactAssetManagerSuccess:
        "Succesfully requested contact with Asset Manager.",
      contactAssetManagerError: "Error: unable to send your request.",
      investmentFocus: "Investment focus",
      investmentRegion: "Region",
      volume: "Number of rated funds",
      aum: "Assets under management",
      companySize: "Number of employees",
      scopeEvalutaion: "Scope assessment",
      scopeFundRating: "Scope Fund Rating",
      topRated: "Top Rated",
      news: "Research of Asset Manager",
      esg: "ESG sustainability analysis",
      investorRating: "Opinion of investors",
      amTeams: "Portfolio Manager",
      from: "From:",
      macroOverview: "Macro Overview",
      macroOverviewOf: "Macro Overview of",
      selectedFunds: "Selection of Asset Manager",
      statistics: "Statistics",
      statisticsSubtitle:
        "Auf dieser Seite finden Sie verschiedene Auswertungen zur Nutzung der Produkte Ihres Hauses im ScopeExplorer. Sie sehen auf einen Blick, welche Ihrer Fonds in den letzten Monaten das größte Interesse gefunden haben.",
      conferences: "Portfolio Manager Conferences",
      conferences2: "Portfolio Manager Conferences",
      moreStatistics: "More statistics",
      add: "Add",
      remove: "Remove",
      apply: "Apply",
      selection: "Your fund selection",
      funds: "funds",
      text1: "The chart and table show the",
      text2:
        "best-rated funds from the total number of funds matching your selection.",
      requestSuccess: "Thank you so much! We have received your request.",
      fields: {
        image: "Image",
        title: "Title",
        language: "Language",
        pdfFile: "PDF File",
        first_name: "First name",
        last_name: "Last name",
        position: "Position",
        department: "Department",
        summary: "Summary (max. 500 characters)",
      },
    },
    assetManagerDashboard: {
      title: "Asset Manager",
      subtitle: "",
      errors: {
        default: "Request failed. Please try again.",
        400: "Wrong request data",
        401: "Unauthorized",
        fillInFields: "Fill in required fields",
      },
      newsSuccess: "News saved.",
      newsRemoved: "News removed.",
      fundSuccess: "Fund saved.",
      portfolioManagerSuccess: "Portfolio Manager saved.",
      portfolioManagerRemoved: "Portfolio Manager removed.",
      documentSuccess: "Document saved.",
      documentRemoved: "Document removed.",
      noDocuments: "No documents yet.",
      selectedFundsSuccess: "Selected funds saved.",
      success: "Asset Manager saved.",
      docCategories: {
        "product-presentation": "Product presentation",
        "product-flyer": "Product flyer",
        factsheet: "Factsheet",
        "product-research": "Product research",
        "product-analysis": "Product analysis",
      },
      macroOverviewOptions: {
        veryNegative: "Very negative",
        negative: "Negative",
        neutral: "Neutral",
        positive: "Positive",
        veryPositive: "Very positive",
      },
      volumeTooltip: "This information is entered by Scope.",
      investorRatingTooltip: "This information is entered by Scope.",
      investorRatingRequest: "Request",
      status: "Status",
      conferenceStatuses: {
        pending: "Pending",
        accepted: "Accepted",
        rejected: "Rejected",
      },
      registerForConferenceSuccess: "Your request was sent to Scope.",
      requestConferenceSuccess: "Verification e-mail was sent to Scope.",
      requestError: "Error: unable to send your request.",
      macroOverviewDate: "Macro Overview date",
      unsubscribedFromConference: "Unsubscribed from conference.",
    },
    assetManagerShareClassesTable: {
      accordionButton: "Share classes",
      shareClass: "Share class",
      isin: "ISIN",
      ogc: "OGC",
      minInvestment: "Min. Investment",
      currency: "Currency",
    },
    wholesale: {
      title: "Get started as a professional investor with the ScopeExplorer",
      header1:
        "Professional features of Scope fund selection for your everyday life",
      text1:
        "The ScopeExplorer gives you free access to the entire Scope fund database.",
      text2:
        "Comprehensive selection filters for over 160 peer groups and individual selection lists support you in your daily research.",
      text3:
        "You can also use our qualitative analyses and recommendation lists for Scope fund selection.",
      header2: "Register now for free use",
      header3: "of ScopeExplorer as a professional investor.",
      text4: "Register for free",
      header4: "Portfolio Manager Conferences",
      text5:
        "With the Portfolio Manager Conferences we offer you an online conference format with analytical depth. Our analysts from the Scope fund selection address the topics that are relevant to our fund valuation and address your questions in discussions with the Portfolio Manager.",
      text6: "Register",
      header5: "COVID-19: The effects classified",
      text7:
        "With our Crash Drawdown you know at a glance how your funds are performing historically in the current situation.",
      header6: "We show what funds can really do",
      text8:
        "Benefit from our experience of over 20 years in the valuation of investment funds. You will find over 6,000 funds with valuation in our database, updated monthly. With our established methodology we focus on the constant performance of a fund. In over 160 peer groups we show you our exclusive ranking of the best products in our evaluation.",
      text9:
        "Benefit from our experience and take advantage of all additional offers for professional investors",
      text10: "with your registration.",
    },
    profile: {
      title: "Profile",
      subtitle:
        "Always keep the basic information about your company up to date",
      retail: "Private investor",
      wholesale: "Professional investor",
      assetManager: "Asset Manager",
    },
    fundRatings: {
      title: "Fund Ratings",
      subtitle: "Check or request company funds ratings",
      subtitle2:
        "On this page you can view all of your funds currently available on ScopeExplorer, upload fund documentation and request ratings for certain funds.",
    },
    teamRating: {
      title: "Team rating",
      subtitle: "Check or request team rating",
    },
    selectSurvey: {
      esgQuestionnaire: "Scope ESG questionnaire",
      esgCompanyQuestionnaire: "Scope ESG company questionnaire",
      dvmEsgQuestionnaire: "DVM ESG questionnaire",
      downladQuestionnaires: "Download of all questionnaires",
      downloadAMSummary: "Download all questionnaires of your Company",
      uploadMarkedAsArticle89: "Upload marked as article 8/9",
      downloadEsgFundSummary: "Alle ausgefüllten Fondsfragebögen herunterladen",
      downloadEsgCompanySummary:
        "Alle ausgefüllten Unternehmensfragebögen herunterladen",
      footerText1:
        "For further questions or in case of queries, please feel free to contact us at the following",
      footerText2: "e-mail address: ",
      dvmDescription: `This RfP asks with 16 questions the approach of an asset manager
      regarding all three E-, S- and G-criteria. Specific questions 
      to single funds and their strategies and investment criteria are not included.`,
    },
    esgSurvey: {
      title: "ESG-Fragebögen",
      subtitle: "",
      filename: "ESG Fragebögen ",
    },
    esgSurveyInfoModal: {
      title: "Sehr geehrte(r) Umfrageteilnehmer/in,",
      text1:
        "Die im Wege dieser Umfrage erhobenen und nicht-personenbezogenen Daten werden für interne analytische Zwecke der Scope Fund Analysis GmbH, sowie zur Weitergabe an einen beschränken Kundenkreis der Scope Fund Analysis GmbH (darunter fallen ausschließlich institutionelle Investoren wie bspw. Dachfondsmanager, Pensionsfonds, etc.) verwendet.",
      text2:
        "Ihre Teilnahme an dieser Umfrage gilt als Einverständnis zur eingehend beschriebenen Verwendung der von Ihnen mitgeteilten Informationen.",
      text3:
        "Hinsichtlich personenbezogener Daten findet keine Verarbeitung dieser im Zusammenhang mit den sonstigen erhobenen Daten statt, sondern eine anonyme Auswertungen, die keinen Bezug zu Ihrer Person hat. Wir werden Ihre Antworten nicht zur Bewertung Ihrer Person nutzen und Ihre Antworten nicht zusammen mit den Sie identifizierenden Daten (z.B. Name, E-Mail Adresse) speichern. Selbstverständlich stehen Ihnen gegenüber der erhebenden Stelle (Scope Fund Analysis GmbH) das Recht auf Auskunft sowie der Löschung Ihrer personenbezogenen Daten unter Beachtung der gesetzlichen Vorgaben aus der DSGVO zu. Ihre personenbezogenen Daten werden für diese Umfrage aufgrund eines berechtigten Interesses nach Art. 6 Abs. 1 lit. 6 DSGVO erhoben.",
      button1: "Cancel",
      button2: "Ja, ich bin professioneller Anleger",
    },
    uploadArticle89Modal: {
      errors: {
        default: "Request failed. Please try again.",
        400: "Wrong request data",
        401: "Unauthorized",
        fillInFields: "Fill in required fields",
      },
      title: "Article 8/9 upload",
      text1:
        "Please upload here an Excel file identifying your funds as an Article 8 or Article 9 product.",
      newArticle89Uploaded: "File uploaded",
    },
    removeFundKeyFactsModal: {
      text1: "Do you want to delete these fund key facts?",
      button1: "Yes",
      button2: "No",
    },
    eventsCaldendar: {
      title: "Events calendar",
      subtitle: "Available soon",
    },
    conferenceInfoModal: {
      title: "Our conference offer for professional investors",
      text1:
        "This website is aimed exclusively at the following classes of investors: professional investors as defined by Directive 2014/65/EU (MiFID II); investors who meet the criteria under MiFID II for private investors to be classified as professional investors; semi-professional investors defined according to the German Investment Code (Kapitalanlagegesetzbuch - KAGB). The website is not aimed at private investors who fail to meet the aforementioned requirements.",
      text2:
        "Please confirm that you meet the above requirements for classification as a professional or semi-professional investor.",
      text3:
        "Scope Fund Analysis GmbH as the owner of ScopeExplorer provides only information on products and services. Scope Fund Analysis GmbH does not provide investment advice that takes into account individual investors’ circumstances. Scope Fund Analysis GmbH accepts no liability for the unauthorised use of the content provided.",
      button1: "Back to start page",
      button2: "Yes, I am a professional investor",
    },
    assetManagerInfoModal: {
      title: "For professional investors",
      text1:
        "Comgest's information material is aimed exclusively at professional investors. Please confirm that you are a professional investor to continue.",
      text2:
        "Scope assumes no liability for damages resulting from the unauthorized or incorrect use of content.",
      button1: "Back to start page",
      button2: "Yes, I am a professional investor",
    },
    conferences: {
      title: "Portfolio-Manager-Conferences",
      subtitle: "Available soon",
      listTitle: "Portfolio Manager Conference",
      listTitle2: "The online event format with analytical depth.",
      listSubtitle:
        "Our analysts from the Scope fund selection welcome selected portfolio managers for an interview. We address the topics that are relevant in our evaluation of funds and focus on your questions.",
      pastEventsTitle: "Video on Demand",
      pastEventsSubtitle:
        "View the video recordings of select live events at any time.",
      webinare: "Web conference",
      live: "Live",
      files: "Files",
      speakers: "Speakers",
      date: "Date",
      startTime: "Start time",
      endTime: "End time",
      info: "Information",
      moreInfo2: "View Recording",
      additionalInfo: "Additional information",
      register: "Register",
      editSuccess: "Conference saved successfully.",
      signOutConfirmText:
        "Do you really want to sign out from this conference?",
      signOutButtonText: "Sign out",
      furtherConferenceText1: "The new online event format for ScopeExplorer",
      furtherConferenceText2: "We regularly add further online events. If you",
      furtherConferenceText3: "register",
      furtherConferenceText4:
        "as a professional investor, you will also receive our event updates conveniently by e-mail.",
      conferencesFormModalTitle: "Hinzufügen einer Portfolio-Manager-Konferenz",
      addConference: "Add conference",
    },
    savedSearches: {
      date: "Date",
      link: "Link",
      goTo: "Go to search results",
      remove: "Delete",
      addSuccess: "Your search has been saved.",
      addError: "Error: unable to save search.",
      removeSuccess: "Your search has been deleted.",
      removeError: "Error: unable to delete search.",
    },
    ratingInFocus: {
      overview: "Selected team ratings",
      strategy: "Strategy:",
      rating: "Rating:",
      category: "Category:",
      aum: "AuM:",
      team: "Team management:",
      reportDownload: "Download report",
      requestTeamRating: "Request team rating",
      name: "Name of the Investment Team",
    },
    cookieInfo: {
      text: "This website uses cookies to improve user friendliness. By continuing to use the website, you consent to this. Further information on cookies and their deactivation can be found at ",
      link: "here.",
      understand: "Understood",
    },
    menu: {
      home: "Search",
      searchResults: "Search results",
      login: "Login",
      fundsInFocus: "Funds in focus",
      ratingInFocus: "Team Ratings",
      publications: "Publications",
      news: "News",
      reports: "Reports",
      oif: "Offene Immobilienfonds",
      methodology: "Methodology",
      methodologies: "Methodologies",
      premium: "Premium",
      watchlist: "Watchlist",
      savedSearches: "Saved searches",
      logout: "Log out",
      logoutSuccess: "Logged out successfully.",
      register: "Register",
      companies: "Asset Manager",
      assetManagers: "Dashboard",
      conferences: "Conferences",
      services: "Services",
      aif: "OIF Studie 2022",
      wholesale: "For professional investors",
      survey: "Survey",
      scopeAwards: "Scope Awards",
      scopeAwardsDetails: "Scope Awards Details",
      scopeAwardsCategories: "Categories",
      scopeAwardsNominees: "Nominees",
      scopeAwardsWinners: "Winners",
      nominationAltInvestmentRequirements: "Nomination Requirements",
      nominationInvestmentRequirements: "Nomination Requirements & Methodology",
      nominationCertificateRequirements: "Nomination Requirements",
      portrait: "Scope Fund Analysis",
    },
    footer: {
      privacyPolicy: "Privacy policy",
      rechtlicheHinweise: "Rechtliche Hinweise",
      termsOfUse: "Terms of use",
      legalNotice: "Legal notice",
      methodology: "Methodology",
      methodologies: "Methodologies",
      contact: "Contact",
      copyright: `Copyright © ${new Date().getFullYear()}`,
      scopeAnalysis: "Scope Fund Analysis GmbH",
    },
    validation: {
      required: "Required.",
      email: "Invalid e-mail address.",
      passwordsMatch: "Passwords do not match.",
      containLetter: "Must contain at least one letter.",
      uppercaseLetter: "Must contain at least one uppercase letter.",
      lowercaseLetter: "Must contain at least one lowercase letter.",
      containNumber: "Must contain at least one digit.",
      specialCharacter: "Must contain at least one special character.",
      maxValuePart1: "Must contain maximum",
      maxValuePart2: "characters.",
      minValuePart1: "Must contain at least",
      minValuePart2: "characters.",
      uploadFile: "Please upload the correct file.",
    },
    help: {
      of: "of",
    },
    unsubscribe: {
      title: "Unsubscribe confirmation",
      subtitle: "Newsletter / Watchlist",
      text1: "You have successfully unsubscribed from our email services.",
      text2:
        "Thank you for using the ScopeExplorer newsletter and watchlist notifications.",
      return: "Return to homepage",
    },
    home: {
      header:
        "Scope Explorer: the quick, easy and innovative way to choose investment funds",
      noAccount: "Not registered yet?",
      haveAccount: "Already a member?",
      register: "Register now free of charge.",
      or: "or",
      signIn: "Log in.",
      login: "Log in",
      close: "Close",
      email: "E-mail",
      password: "Password",
      forgotPassword: "Forgot your password?",
      logIn: "Log in",
      loginWithFacebook: "Login with Facebook",
      loginWithGoogle: "Login with Google",
      logInHere: "Log in here",
      remember: "Save login details",
      repeatPassword: "Confirm password",
      newPassword: "New password",
      signUp: "Register",
      registration: "Registration",
      wholesaleRegistration: "Registration - professional investor",
      retailUsers: "Private investor",
      wholesaleUsers: "Professional investor",
      accountType: "Account type",
      name: "Name",
      firstName: "First name",
      lastName: "Surname",
      companyName: "Company name",
      unit: "Unit",
      videoTitle:
        "ScopeExplorer helps you make better investment decisions. This video will show you how.",
      phoneNumber: "Phone number",
      homeBoxTitle1: "Recent studies",
      homeBoxTitle2: "Current ratings",
      homeBoxTitle3: "Fund facts",
      homeBoxTitle4: "Why Fund Ratings?",
      homeBoxTitle5: "Fund Analysis",
      homeBoxTitle6: "The company",
      homeBoxContent4:
        "A Scope fund rating reflects the most relevant yield and risk aspects of a fund. Our ratings allow you to make a systematic comparison of funds with similar profiles.",
      homeBoxContent5:
        "Scope’s Frankfurt- and Berlin-based analysts have a more than 20-year track record in analysing investment funds – of which there are currently more than 6,000 in Germany.",
      homeBoxContent6:
        "Scope Group is the largest European provider of ratings, research and analysis - for investment funds and asset managers as well as credit risks – with a staff of more than 200.",
      more: "More",
      moreScopeAward: "More Information",
      moreStudien: "More studies",
      moreRatings: "More ratings",
      moreFacts: "More facts",
      moreNews: "All news",
      homeNewsTitle: "Latest news and analysis",
      scopeAwardWinnersText: "The winners have been determined!",
      scopeAwardsNomineesText:
        "The nominations for the Scope Investment Award have been determined",
    },
    search: {
      header: "CHECK",
      subheader: "Is your fund a top-ranked investment product?",
      search: "Search",
      placeholder: "Fund search using name, WKN or ISIN",
      advancedSearch: "Advanced Search",
      simpleSearch: "Basic Search",
      searchBox1: "Europe’s largest rating agency",
      searchBox2: "Over 6,000 funds rated",
      searchBox3: "More than 20 years of experience",
      selectedItems: "Selected items",
      searchTitle1: "Select",
      searchText11: "Search for and select funds",
      searchText12: "that meet your investment priorities",
      searchTitle2: "Discover",
      searchText21: "Seek out investment ideas",
      searchText22: "across different market segments",
      searchTitle3: "Rankings",
      searchText31: "The best funds at a glance.",
      searchText32: "",
      selected: "selected",
    },
    advancedSearch: {
      search: "Search",
      reset: "Reset",
      placeholder: "Fund search using name, WKN or ISIN",
      scopePeergroup: "Scope peergroup",
      investmentFocus: "Investment focus",
      company: "Company",
      fundSize: "Fund size",
      universe: "Universe",
      scopeRating: "Scope Rating",
      uncheckAll: "Uncheck all",
      fundsSelected: "funds selected.",
      searchResults: "Search results",
      saveCustomFields: "Save customised search",
      saveSearch: "Save search",
      mainTranche: "Show only primary tranches",
      addSuccess: "Your customised fields have been saved.",
      addError: "Error: unable to save customised fields.",
      errors: {
        default: "Currently unable to access funds. Please try again later.",
      },
      resetFormFilters: "Reset form to select filters.",
      resetFormAutocomplete: "Reset form to use autocomplete.",
    },
    filterSearch: {
      search: "Find now",
      step: "Step",
      step1: "Which asset class do you want to invest in?",
      step2: "Which regions would you like to invest in?",
      step3: "Choose your risk classes",
      low: "Low",
      medium: "Medium",
      high: "High",
      found: "results",
      apply: "Show fund list",
      reset: "Reset filters",
      northAmerica: "North America",
      europe: "Europe",
      asia: "Asia",
      emergingMarkets: "Emerging Markets",
      germany: "Germany",
      world: "Global",
      applyAgain:
        "The filter criteria have been changed. Please apply search again.",
    },
    advancedFundsList: {
      pleaseSelectFund:
        "Unable to find funds which match the selected criteria.",
      filename: "Scope funds export.xlsx",
      name: "Name",
      columnSelection: "Column selection:",
      prev: "Prev",
      next: "Next",
      of: "of",
      search: "Search",
      requestRating: "Request rating",
      exportSelected: "Export selection",
      exportAll: "Export all",
      downloadXls: "Download XLS",
      selectFunds: "Select funds to export.",
      selectFundsToCompare: "Select funds to compare.",
      selectFundsToRequest: "Select funds to send request.",
      selectFundsToExport: "Select funds to export.",
      selectMax3Funds: "Select 3 funds maximum to compare.",
    },
    premium: {
      title: "Fund selection and sustainability",
      subtitle:
        "Scope Fund Analysis offers objective fund selection, independent of product providers, as well as an evaluation of the sustainability of selected funds.",
      peergroup: "Peergroup",
      fundSelection: "Scope list of recommendations",
      scopeRecommendation: "Excerpt - actively managed funds",
      requestCustomizedSolution: "Request: individual selection",
      requestFullList: "Request complete list",
      esg: "Scope sustainability analysis",
      gid: "ESG - Greenness Indicator",
      checkYourFund: "Is your fund a top-ranked investment product?",
      requestEsgPortfolioCheck: "Request ESG portfolio check",
      requestEsgTeamRating: "Request ESG team rating",
      sendRequest: "Send request",
      recommendedListTooltip:
        "Scope Fund Analysis offers independent and objective fund selection using quantitative and qualitative evaluation criteria. Our analysis aims to identify managers and funds that generate risk-adjusted excess returns.",
      esgTooltip:
        "The Scope Greenness Indicator provides information on a fund's sustainability profile. ESG (= environmental, social and corporate governance) factors are considered individually.",
      environment: "Environmental factors",
      social: "Social factors",
      governance: "Corporate governance",
      active: "Active",
      passive: "Passive",
      fundSearch: "Fund search",
      fundSearchText: "You can browse all of Scope's ESG rated funds here.",
      exclusion: "Exclusion criteria",
      exclusionShort: "Exclusion",
      success: "Request sent successfully",
      noSearchResults: "No results found. Please ",
      contactUs: "contact us.",
      fillInFields: "Fill in required fields",
      subscribeListSuccess: "Subscribed successfully.",
      unsubscribeListSuccess: "Unsubscribed successfully.",
      subscribeListError: "Error: unable to send your request.",
      errors: {
        default: "Error: unable to send your request.",
      },
    },
    aifBenchmarks: {
      property_portfolio_short: "Property portfolio",
      sustainability_short: "Sustainability",
      financial_structure_short: "Financial structure",
      asset_management_short: `Asset Management`,
      fund_management_short: "Fund Management",
      rating_total_short: "Rating total",
      property_portfolio: "Scope Bewertung Qualität des Immobilienportfolios",
      sustainability: "Scope Bewertung der Nachhaltigkeit",
      financial_structure: "Scope Bewertung der Finanzstruktur",
      asset_management: "Scope Bewertung des Asset Managements",
      fund_management: "Scope Bewertung des Fondsmanagements",
      rating_total: "Scope Bewertung des Risikos",
      rating_total_2:
        "(Hinweis: je höher die Bewertung, desto geringer das Risiko)",
      ter: "TER",
      net_volume: "Net fund volume",
      real_volume: "Real estate volume",
      objects_number: "Number of properties",
      liquidity_ratio: "Liquidity ratio",
      credit_ratio: "Debt ratio",
      geographical_german: "Share of german properties",
      geographical_european: "Share of european properties",
      geographical_non_european: "Share of global properties",
      sectoral_office: "Share of usage type office",
      sectoral_retail: "Share of usage type retail",
      sectoral_living: "Share of usage type residential",
      sectoral_hotel: "Share of usage type hotel",
      sectoral_logistics: "Share of usage type ligistics",
      performance_1y: "Performance 1y",
      performance_5y: "Performance 5y p.a.",
      volatility_5y: "Volatility 5y p.a.",
      occupancy: "Occupancy rate",
      rating_esg: "Scope Bewertung der Nachhaltigkeit",
      wault: "WAULT",
    },
    aifFunds: {
      excellent: "Excellent",
      veryGood: "Very good",
      good: "Good",
      balanced: "Balanced",
      modest: "Modest",
      veryModest: "Very modest",
      poor: "Poor",
      likelyCapitalLost: "Likely capital lost",
      substantialCapitalLost: "Substantial capital lost",
      negativeTurn: "Negative turn",
      benchmarks: "Benchmark-Tool",
      benchmarksSubtitle:
        "Die offenen Immobilien-Publikumsfonds in Deutschland",
      scopeCardParameters: "Score card parameters",
      yourParameters: "Your parameters",
      exportToExcel: "Export to Excel",
      addParameters: "Add parameters",
      modalStep1: "Select the funds to compare (Step 1 of 2)",
      modalStep2: "Choose your parameters to compare the Funds (Step 2 of 2)",
      selectAll: "Select all",
      next: "Next",
      submit: "Submit",
      selectFunds: "Select funds to see scale",
      sectoralAllocation: "Sectoral Allocation",
      geographicalAllocation: "Geographical Allocation",
      sectoralOffice: "Office",
      sectoralRetail: "Retail",
      sectoralLiving: "Living",
      sectoralHotel: "Hotel",
      sectoralLogistics: "Logistics/Industry",
      geographicalGerman: "German properties",
      geographicalEuropean: "European properties",
      geographicalNonEuropean: "Non-European properties",
      others: "Others",
      moreInformation: "More information",
      selectYourProduct: "Vorab registrieren",
      pleaseChoose: "Please choose a package",
      buy: "Zahlungspflichtig bestellen",
      bannerText:
        "The Alternative Investment Study 2024 - Ratings & Research with a Unique Depth of Data and Analysis",
      signUpText: "Sign up or Log in to view",
      signUp: "Sign up",
      paidDocuments: "Paid Documents",
      documents: "Documents",
      views: "Views",
      clicks: "Visitors",
      register: "Register",
      registerNow:
        "Register now as a Professional Investor and receive the 2022 study free of charge.",
    },
    invoiceModal: {
      title: "Invoice",
      text1: "Invoice was created and sent to your e-mail address.",
      text2:
        "You can pay using one of the payement methods given in the invoice or using bank transfer given in the invoice.",
      payOnline: "Pay online",
      downloadInvoice: "Download invoice",
    },
    purchaseModal: {
      title: "Get premium",
      yourOrder: "Your order:",
      selectedLicense: "Selected license:",
      totalPrice: "Total price:",
      agreement1:
        "Durch Klicken der Schaltfläche „Zahlungspflichtig bestellen“ geben Sie ein verbindliches Kaufangebot gemäß der besonderen Bedingungen der Offenen Immobilienfonds Studie 2020 ab, die Sie",
      agreement2: "hier",
      agreement3: "einsehen können.",
      selectProduct: "Please select product.",
      billingAddress: "Billing address",
      company: "Company",
      street: "Street",
      postalCode: "Postal code",
      city: "City",
      taxId: "Tax ID",
      contactPerson: "Contact person",
      purchase: "Purchase",
      purchaseError: "You need to be wholesale user to buy license.",
    },
    fundFields: {
      logo: "Logo",
      wkn: "WKN",
      position_vs_peergroup: "Rank",
      tranche: "Tranche",
      tranche_currency: "Tranche currency",
      peergroup: "Peergroup",
      primary: "Main tranche",
      universe: "Universe",
      company: "Company",
      ter: "Ongoing charges",
      volume: "Volume",
      volume_currency: "Volume currency",
      initialCharge: "Issue surcharge",
      accumulating: "Accumulating",
      firstRating: "First-time rating",
      perf_1m: "1 month return",
      perf_3m: "3 month return",
      perf_6m: "6 month return",
      perf_1y: "1 year return",
      perf_3y: "3 year return",
      perf_5y: "5 year return",
      perf_since_inception: "Return since inception",
      perf_last_year: "Return previous year",
      volatility_3y: "Volatility 3 Yr.",
      initial_charge: "Issue surcharge",
      isin: "ISIN",
      srri: "SRRI",
      rating_unchanged_since: "Rating unchanged since",
      prob_loss_month_3y: "Prob. Loss. Month 3 Yr.",
      prob_profit_month_3y: "Prob. Profit Month 3 yr.",
      max_loss_3y: "Max. loss 3 Yr.",
      max_profit_3y: "Max. Profit 3 Yr.",
      rank: "Rating",
      rating_points: "Rating points",
      risk_class: "Risk class",
      fee: "Fees",
      documents: "Documents",
      return_fee: "Redemption fee",
      min_investment: "Min. Investment",
      issue_date: "Date of launch",
      value_at_risk_3y: "Value at risk 3 Yr.",
      is_award_nominated: "Is award nominated",
      is_focus: "Selection of Asset Manager",
      is_selection: "Funds selection",
    },
    compareFunds: {
      compareFunds: "Compare funds",
      currentFund: "Current fund",
      compare: "Compare",
      selectFundToCompare: "Select fund to compare",
    },
    fund: {
      amDocuments: "Asset Manager Documents",
      winner: "Winner",
      winners: "Winners",
      nominee: "Nominee",
      dataFrom: "Data from ",
      ratingFrom: "Rating from:",
      paidDocuments: "Paid documents",
      name: "Name",
      fundName: "Fund name",
      wkn: "WKN",
      isin: "ISIN",
      nav: "NAV",
      ter: "Ongoing charges",
      photo: "Photo",
      terTooltip:
        "Sum of expenses and fees of a fund in per cent of the assets, including expenses of target funds, not containing transaction costs and performance fees.",
      srri: "SRRI",
      sfdr: "SFDR",
      sfdrTooltip: "SFDR: Sustainable Finance Disclosure Regulation",
      srriTooltip:
        "Risk classification based on the Synthetic Risk and Reward Indicator (SRRI). The SRRI reflects the fluctuations in the value of a fund and is determined based on uniform EU-wide regulations. Classification uses a scale of 1 to 7. The higher the SRRI, the higher the risk profile of a fund.",
      srriTooltip2:
        "Risk classification based on the Synthetic Risk and Reward Indicator (SRRI). The SRRI reflects the fluctuations in the value of a fund and is determined based on uniform EU-wide regulations. Classification uses a scale of 1 to 7. The higher the SRRI, the higher the risk profile of a fund.",
      price: "Market value (NAV)",
      positionInPeergroup: "Position in Peergroup",
      positionInRanking: "Rank position: ",
      positionInTheRanking: "Rank position and",
      position: "Position in Scope Peergroup",
      top: "Top",
      peergroup: "Peergroup",
      rankInPeergroup: "Rank in Peergroup",
      performance: "Return",
      performancePercent: "Return %",
      performanceRisk: "Return / Risk",
      performance_3y_long: "Performance 3 years",
      fundPerformance: "Fund performance",
      peergroupAverage: "Peergroup average",
      rating: "Rating",
      ratingTooltip:
        "Valuation of the fund on the basis of either a qualitative or quantitative rating. The scale ranges from A („very good“) to E („weak“).",
      aifRatingTooltip:
        "Die Skala reicht von aaa (“Exzellent”) bis hin zu d (“Negative Rendite”).",
      rank: "Rank",
      fundRank: "Fund rank",
      scopeFundRating: "Scope Fund Rating",
      ratingHistory: "Rating history",
      fundRating: "Fund rating",
      fundRatingTooltip:
        "Valuation of the fund on the basis of either a qualitative or quantitative rating. The scale ranges from A („very good“) to E („weak“).",
      fundPoints: "Fund points",
      risk: "Risk",
      costBar: "Cost",
      riskTooltip: "Risk measured as volatility of the fund.",
      riskPercent: "Risk %",
      volatility: "Volatility",
      volatilityPercent: "Volatility %",
      fundRisk: "Fund risk",
      safety: "Risk assessment",
      performanceRiskChart: "Performance / risk overview",
      riskClassification: "Risk classification",
      maxLoss: "Max loss (p.a.)",
      moreInfo: "More info",
      back: "Back",
      fundsInFocus: "Funds in focus",
      strategy: "Strategy",
      ongoingCharge: "Running costs",
      ongoingChargeTooltip:
        "Sum of expenses and fees of a fund in per cent of the assets, including expenses of target funds, not containing transaction costs and performance fees.",
      performanceFee: "Performance-based remuneration",
      fundFacts: "Fund facts",
      company: "Company",
      manager: "Fund Manager",
      launchDate: "Launch date",
      accumulating: "Accumulating",
      quantitativeLabel: "Quantitative rating",
      qualitativeLabel: "Qualitative rating",
      quantitativeTooltip: "Rating based on quantitative rating criteria.",
      qualitativeTooltip:
        "Rating includes both quantitative and qualitative criteria.",
      period: "Indicator and period",
      returnInPeriod: "Calculation period",
      fund: "Fund",
      noRatingAvailable: "No rating available",
      performance_1y: "1 year (p.a.)",
      performance_3y: "3 year (p.a.)",
      performance_5y: "5 year (p.a.)",
      performance_10y: "10 year. (p.a.)",
      performance_since_inception: "Since inception (p.a.)",
      performance_current_year: new Date().getFullYear(),
      max_drawdown_crash: "Crash drawdown",
      crashDrawdownChart: "Crash drawdown",
      crashDrawdownSubtitle: "How has the fund performed in the past crisis?",
      crashDrawdownTooltip:
        "Many investors are more interested in how well a fund performs in turbulent markets rather than when stock markets are rising. The chart below provides an answer to this question. It shows four stress phases from the past 10 years (grey). For each of these stress phases, you can see the maximum loss of the selected fund during the period compared with the peer group average and the benchmark.",
      market: "MSCI World",
      marketLong: "MSCI World Standard (Market)",
      max_drawdown_1y: "Max drawdown 1 year",
      max_drawdown_3y: "Max drawdown 3 year",
      max_drawdown_5y: "Max drawdown 5 year",
      max_drawdown_since_inception: "Max drawdown since inception",
      volatility_1y: "Volatility 1 year (p.a.)",
      volatility_3y: "Volatility 3 year (p.a.)",
      volatility_5y: "Volatility 5 year (p.a.)",
      volatility_since_inception: "Volatility since inception",
      volume: "Fund volume",
      volumeUnit: "(in M. EUR)",
      volumeUnitShort: "M. EUR",
      ratingPoints: "Rating points",
      ratingPointsShort: "Rating points",
      ratingPointsTooltip:
        "Scope awards rating points on a scale from 1 to 100 for each fund. The rating points indicate the relative rating of a fund compared to its peergroup. The higher the score, the better Scope considers the fund to be.",
      fundsInPeerGroup: "funds in peergroup",
      topFundsTitle: "Funds in focus",
      topFundsSubtitle:
        "Our analysts regularly select funds which stand out, based on performance, innovative investment ideas and distinctive strategies.",
      ratingInFocusSubtitle:
        "In addition to funds, our analysts evaluate asset managers' investment teams. The rating reflects an investment team's skill, experience and track record as well as their process quality and resources.",
      requestTeamRatingText:
        "Can't find a rating on a specific asset manager or investment team? No problem! Just enter the name of the investment team and request a rating. It's free of charge and without obligation.",
      driver: "Rating drivers",
      content: "Further details and analyses",
      news: "News",
      newsSubtitle: "News and fund information",
      readMore: "Read more",
      proceedToPayment: "Proceed to payment",
      details: "Details",
      noResults: "No results available.",
      fundNotFound: "No matches for the selected fund.",
      notFound: "Not found",
      pleaseSelectFund:
        "Unable to find funds which match the selected criteria.",
      veryGood: "Very good",
      good: "Good",
      average: "Average",
      belowAverage: "Below average",
      belowAverage2: "Below average",
      poor: "Weak",
      legend: "Legend",
      veryLow: "Very low",
      low: "Low",
      mid: "Mid",
      medium: "Medium",
      high: "High",
      veryHigh: "Very high",
      rowsPerPage: "Rows per page",
      monthly_report: "Monthly report",
      key_information: "PRIIPs KID",
      key_investor: "KIID – Key Investor Information Document",
      fund_prospect: "Fund prospectus",
      annual_report: "Annual report",
      semi_annual_report: "Semi annual report",
      fundBars: "Fund rating | Scope Rating",
      fundBarsTooltips: {
        performance:
          "The assessment of a fund's performance indicators. These include the relative performance of a fund compared with its peergroup and the stability of the fund's performance.",
        safety:
          "Risk assessment of the fund: The lower Scope considers the fund's risk to be, the more rating points it receives for the criterion of risk assessment.",
        cost: "Evaluation of a fund's running costs (Ongoing Charges) compared to its peergroup. A high rating for this criterion stands for low costs.",
        esg: "Result of evaluation by ESG aspects",
        team: "Evaluation of the investment team using qualitative factors such as the experience and qualifications of its investment professionals.",
        investmentProcess:
          "Qualitative assessment of the investment process in terms of discipline and robustness.",
      },
      value: "Value",
      noInformation: "No information.",
      showMoreDetails: "Show more details",
      hideMoreDetails: "Hide details",
      avg: "Avg",
      print: "Print",
      watchlist: "Watchlist",
      watchlistTitle: "My funds - watchlist",
      watchlistSubtitle:
        "Are you looking for a simple way of keeping an eye on changes to the ratings of individual funds? Then just add the relevant funds to your „watchlist“ - and we will inform you of any rating changes by email.",
      watchlistAdded: "Fund added to watchlist.",
      watchlistRemoved: "Fund deleted from watchlist.",
      watchlistAddError: "Error: unable to add to watchlist.",
      watchlistDeleteError: "Error: unable to delete from watchlist.",
      noWatchlist: "Add fund to watchlist.",
      actualRating: "Current rating",
      prevRating: "Last rating change",
      upgrade: "Upgrade",
      downgrade: "Downgrade",
      same: "None",
      showAvg: "Show average",
      hideAvg: "Hide average",
      date: "Date",
      cost: "Cost assessment",
      esg: "ESG Score",
      general: "General",
      custom: "Custom",
      costs: "Costs",
      valueLong: "Value",
      team: "Team",
      scopeRating: "Scope Rating",
      scopeRanking: "Scope Ranking",
      of: "of",
      investmentProcess: "Investment process",
      noRatingInfo:
        "No evaluation is available for this fund at present. If you are interested in a qualitative evaluation, please click on „Request rating“. This is free of charge and without obligation.",
      noRatingInfoShort:
        "A qualitative evaluation is not available for this fund. If you are interested in a qualitative rating, please click on „Request rating“. This is free of charge and without obligation.",
      noFundBarsInfo:
        "A qualitative evaluation is not available for this fund. If you are interested in a qualitative rating, please click on „Request rating“. This is free of charge and without obligation.",
      request: {
        rating: "Request rating",
        award: "Request award nomination",
        focus: "Request focus",
        selection: "Request selection",
        teamRating: "Request team rating",
        sendRequest: "Send request",
        theme: "Request themes",
        info: "Request information",
      },
      requestQualitativeRating: "Request rating",
      fundRequestSent:
        "Thank you so much! We have received your request. Scope will create a qualitative rating for funds for which a rating is requested most frequently.",
      teamRequestSent:
        "Thank you so much! We have received your request. Scope will provide a qualitative rating to investment teams for which a valuation is requested most frequently.",
      anonymous: "Anonymous",
      noContent:
        "No further information is available for this fund at present. Are you interested in details on the fund, its investment strategy and an assessment of the fund's management? Then simply click on „Request information. This is free of charge and without obligation.",
      requestInfo: "Request information",
      compareWithPeergroup: "Comparison with Peergroup Ø",
      worse: "Worse",
      equal: "Equal",
      better: "Better",
      pts: "Pts",
      all: "All",
      equity: "Equities",
      multiasset: "Multi Asset",
      fixedIncome: "Bonds",
      other: "Other",
      ratio: "Ratio",
      ratioTooltip:
        "This ratio expresses the ratio of performance to risk (measured by volatility) of a fund. The higher the ratio, the better from an investor's perspective. In contrast to the „Sharpe Ratio“, the risk-free interest rate is not taken into account in this ratio.",
      ratioDesc: "Risk / return ratio",
      trancheRatingTooltip:
        "The rank displayed always refers to the main tranche of a fund. The ISIN you select is a subtranche for which no separate rank is calculated.",
      institutional:
        "The ISIN selected is an institutional tranche of the fund with a high minimum investment. It is open to institutional investors.",
      recordDateText:
        "The subject of the valuations & calculations are data provided by the capital management companies & publicly available data.",
      recordDate1:
        "The rating and the calculation of all key figures are based on data as of ",
      recordDate2:
        "The subject of ratings & calculations is the fund universe in Germany.",
      recordDate3:
        "The subject of the calculations are publicly available data.",
      fundsListHint:
        "The funds listed below are sorted by rating points. With the same number of rating points, the risk-return ratio is decisive.",
      minInvestment: "Minimum investment",
      recordDate: "Data as at",
      currency: "Currency",
      professionalInfo: "For professional investors only",
      noRatingDriver:
        "A qualitative evaluation is not available for this fund. If you are interested in a qualitative rating, please click on “Request rating”. This is free of charge and without obligation.",
      winnerLabel: "Scope Award 2020: Winner",
      winnerTooltip: "This fund was winner in Scope Investment Awards 2022",
      views: "views",
      upload: "Upload",
      viewFunds: "View funds",
      success_dependent_compensation: "Success dependent compensation",
      objects_number: "Objects number",
      liquidity_ratio: "Liquidity ratio",
      debt_ratio: "Debt ratio",
      currency2: "Currency",
      rental_ratio: "Rental ratio",
      ratingScore: "Rating Scores",
      ratingJustification: "Ratingbegründung",
      ratingDriver: "Rating Driver",
      deleteDocumentConfirm: "Are you sure to delete this document?",
      deleteNewsConfirm: "Are you sure to delete this news?",
      deletePMConfirm: "Are you sure to delete Portfolio Manager?",
      fundReport: "Fund Report",
    },
    fundCharts: {
      showAll: "Show all charts",
      hideAll: "Hide all charts",
      show: "Show",
      showRating: "Show rating",
      hide: "Hide",
      year_1: "1 year",
      year_3: "3 years",
      year_5: "5 years",
      volatility_1y: "Volatility 1 year (p.a.)",
      volatility_3y: "Volatility 3 years (p.a.)",
      volatility_5y: "Volatility 5 years (p.a.)",
      performance_1y: "Return 1 year (p.a.)",
      performance_3y: "Return 3 years (p.a.)",
      performance_5y: "Return 5 years (p.a.)",
      perf_1y: "Return 1 yr",
      perf_3y: "Return 3 yr",
      perf_5y: "Return 5 yr",
      export: "Export",
      numberOfFunds: "Number of funds",
      ratingClasses: "Rating classes",
      points: "Points",
      interpretation: "Assessment",
      noData: "No data available.",
      noChartData: "No chart data available.",
      performanceChart: {
        title: "Return in EUR",
        date: "Date",
        performance: "Return",
        fund: "Fund",
        index: "Index",
        fundPerformance: "Fund return in %",
        indexPerformance: "Index return in %",
        peergroupPerformance: "Peergroup return in %",
        peergroup: "Peergroup Ø",
      },
      peergroupComparisonChart: {
        title: "Peergroup comparison",
        highchartTitle: "Peergroup performance in EUR",
        highchartSubtitle: "Rolling 3-months performance",
        date: "Date",
        performance: "Performance",
        fundPerformance: "Fund performance in %",
        bestFundPerformance: "performance in %",
        worstFundPerformance: "Worst fund performance in %",
        fund: "Fund",
        bestFund: "Best fund",
        worstFund: "Worst fund",
      },
      fundRatingChart: {
        title: "Fund rating history",
        date: "Date",
        performance: "Performance %",
        rank: "Rating",
        rating: "Scope fund rating",
        privateText:
          "For futher rating detail, please contact info@scopeanalysis.com",
      },
      distributionChart: {
        title: "Distribution within peergroup",
        riskIndicator: "Risk indicator",
        performanceIndicator: "Performance indicator",
        good: "Strong",
        bad: "Weak",
        fund: "Fund",
        fundsInPeergroup: "Funds in the peergroup",
      },
      riskReturnChart: {
        title: "Volatility / Return Matrix",
        volatility: "Volatility:",
        perf: "Return:",
        fundVolatility: "Fund volatility",
        fundPerformance: "Fund return",
        indexVolatility: "Index volatility",
        indexPerformance: "Index return",
        peergroupVolatility: "Peergroup volatility",
        peergroupPerformance: "Peergroup return",
        fund: "Fund",
        index: "Index",
        peergroup: "Peergroup Ø",
      },
      crashDrawdownChart: {
        date: "Date",
        return: "Return",
        financialCrisis: "Great Financial Crisis",
        creditCrisis: "European Credit Crisis",
        recessionFears: "Global Recession Fears",
        tradeConflicts: "Fear of interest rate hikes & trade conflicts",
        covid19: "COVID-19",
        ukraineCrisis: "Russian invasion of Ukraine",
      },
      classDistributionChart: {
        title: "Distribution of rating classes",
      },
      evolutionOfRiskChart: {
        title: "Volatility over time",
        fund: "Fund",
        bestFunds: "Best funds",
        bestFund: "Best fund",
        worstFund: "Worst fund",
        date: "Date",
        fundRisk: "Fund risk in %",
        bestFundRisk: "risk in %",
        worstFundRisk: "Worst fund risk in %",
      },
    },
    contact: {
      contact: "Contact",
      summary:
        "Do you have any questions or suggestions? Just fill in our contact form below and we will get back to you as soon as possible.",
      name: "Name",
      email: "E-mail",
      phone: "Phone",
      message: "Message",
      send: "Send an e-mail",
      success: "Your e-mail has been sent",
      fillInFields: "Please fill in all mandatory fields.",
      error: "Unable to send e-mail. Please try again.",
      scopeAnalysis: "Scope Fund Analysis GmbH",
      managingDirectors: "Managing Directors",
      headquartersBerlin: "Headquarters Berlin",
      frankfurtOffice: "Frankfurt Office",
    },
    privacy: {
      title: "Privacy",
      purposeOfPolicy: "1. Purpose of the Policy",
      website:
        "This website (“Scope Fund Analysis”) is operated by Scope Fund Analysis GmbH.",
      thisPrivacyPolicy:
        "This Privacy Policy explains the basis upon which Scope Fund Analysis GmbH (“We” or “Data Controller”) collects and uses personal data.",
      personalDataMeans:
        "Personal data means any information which relates to a specific person (“Personal Data”).",
      thisPolicySetsOut:
        "This Policy sets out the basis on which any Personal Data collected from or provided by the users, clients or subscribers of Scope Fund Analysis website (“You” or “Data Subjects”), will be processed by the Data Controller.",
      pleaseReadTheFollowing:
        "Please read the following carefully to understand Our views and practices regarding Your personal data and how they will be treated.",
      theProvisionsSetOut:
        "The provisions set out in this Policy are regulated by and aligned with the General Data Protection Regulation – Regulation (EU) 2016/679 (“GDPR”).",
      forMoreInformation:
        "For more information on the legal framework of this policy please consult the GDPR text at (https://ec.europa.eu/info/law/law-topic/data-protection/data-protection-eu_en).",
      whichData: "2. Which data Scope Fund Analysis GmbH collects",
      theTypeOfData:
        "The type of data that We may need to collect vary according to the business relationship with the Data Subject:",
      clientsAndSubscribers: "Clients and Subscribers:",
      theDataController:
        "the Data Controller will request only information that is strictly necessary for the fulfillment of the contract that a client signs with the Data Controller and for the provision of its services; for example, some of the information the Data Controller may need are:",
      nameAndSurname: "Name and Surname",
      emailAddress: "Email address",
      telephoneNumber: "Telephone number/fax number",
      companyAndBusiness: "Company and business address",
      password: "Password",
      gender: "Gender",
      websiteUsers: "Website Users:",
      theDataControllersWebsite:
        "the Data Controller’s website uses few types of Cookies, which in some cases track Users activity with the aim to provide them with a better and smoother customer experience, whenever they are visiting Scope Fund Analysis platform; for further details on Cookies, please read the section on “Use of Cookies” under this Policy",
      whyScopeAnalysis:
        "3. Why Scope Fund Analysis GmbH collects personal data",
      theCollectionOfPersonal:
        "The collection of personal data through Scope Fund Analysis website is driven by the principle of “contractual necessity”: any personal information that the Data Controller collects from the Data Subjects is necessary and functional to achieve and fulfil the specific, defined and legitimate purposes which are determined and made explicit in the contract between the Data Controller and the Data Subject.",
      asAnExample: "As an example, We will request personal information to:",
      provideRegular:
        "Provide regular information and updates to its subscribers",
      performAThorough:
        "Perform a thorough analysis of funds and asset management companies as per contract with the Data Subject(s)",
      providingTechnical:
        "Providing technical support in the use of Scope Ratings online platform",
      sendClientsRelevant:
        "Send clients relevant marketing material (where applicable)",
      dealWithClients: "Deal with clients or suppliers’ queries.",
      howScopeAnalysis:
        "4. How Scope Fund Analysis GmbH collects personal data",
      weCollectPerseonal:
        "We collect personal information from a variety of sources and mainly:",
      directlyFromTheData:
        "Directly from the Data Subject through subscription forms and contract applications or declarations",
      fromThirdParties:
        "From third parties: for example, when a client provides an alternative point of contact for a specific matter/service",
      fromTheDataController:
        "From the Data Controller platforms either via subscription or by reviewing tracking activities made by Cookies.",
      whateverMethodIsUsed:
        "Whatever method is used to collect personal information, the Data Controller is committed to always accurately and promptly inform the Data Subject and to request his explicit consent.",
      howScopeAnalysisHandles:
        "5. How Scope Fund Analysis GmbH handles personal data",
      thePersonalDataCollected:
        "The personal data collected through Scope Ratings website are processed and handled following the key principles highlighted below:",
      minimization: "Minimization:",
      onlyThosePersonal:
        " only those personal data which fit the purpose of the contract between the Data Controller and the Data Subject will be collected; the type of personal data that will be required will be determined on a case by case basis",
      integrity: "Integrity:",
      theInformationCollected:
        " the information collected from the Data Subject will be kept confidential by the Data Controller at any time; in case of personal data being transferred from one entity to another within Scope Group, the Data Subject will be promptly informed when signing a contract with the Data Controller (through a Declaration of Consent) or as soon as the transfer becomes necessary; the standards of data confidentiality will be maintained unchanged",
      limitedStorage: "Limited storage:",
      theDataControllerWill:
        " the Data Controller will only keep personal data from the Data Subject as long as they are needed for the fulfilment of the business purpose as indicated in the contract between the parties; once the business purpose ceases, personal data will be permanently deleted after a retention period that varies from a minimum of six months to a maximum of ten years depending on each specific case",
      security: "Security",
      theDataControllerIsCommited:
        " the Data Controller is committed to keep personal data it stores secure at any time against internal and external threats such as, but not limited to, accidental loss, unauthorised access and use; however, no data transmission over the Internet or other network can be guaranteed to be 100% secure. As a result, while we strive to protect information transmitted on or through the Properties or services, we cannot and do not guarantee the security of any information you transmit on or through the Properties or services, and you do so at your own risk",
      transferability: "6. Transferability",
      whilePerforming:
        "While performing some of its business activities, the Data Controller may use external service providers who mostly operate in the technology environment.",
      theDataControllerIsCommitedTo:
        "The Data Controller is committed to promptly notify the Data Subject in case his/her personal data are transferred to any of these external providers and to accurately explain the nature, purpose and duration of the transfer.",
      theDataControllerIsAlso:
        "The Data Controller is also accountable for granting that the same standard of protection on personal data belonging to its clients/suppliers is also observed by its external service providers.",
      theDataControllerOperates:
        "The Data Controller operates in EU and EEA Member States, which are subject to the legal provisions set out in the GDPR on personal data protection.",
      confidentiality: "7. Confidentiality and security",
      theDataControllerIsCommitedToKeep:
        "The Data Controller is committed to keep personal data from Data Subjects secure and to treat them confidentially at all times.",
      toEnsurePersonalData:
        "To ensure personal data are handled accurately, the Data Controller is accountable of granting that:",
      personalDataAreHandled:
        "Personal data are handled by employees who are fully trained to do so",
      theProcessingAndHandling:
        "The processing and handling of personal data is properly supervised",
      personalDataProcessing:
        "Personal data processing practice within the Data Controller is regularly reviewed and audited",
      scopeEmployees:
        "Scope employees are aware of the policies regulating personal data processing and of the impact and consequences of potential breaches in data protection.",
      inCaseABreach:
        "In case a breach of personal data occurs, the Data Controller is responsible of notifying the Data Subject(s) affected promptly and efficiently and to swiftly take any necessary actions that could help reducing the impact of the breach.",
      specifically: "Specifically, in the event of a data privacy breach:",
      theDPOMustBe:
        "The DPO must be notified in writing within 24 hours from the data privacy breach",
      theDPOWillTakeCare:
        "The DPO will take care of notifying the Supervisory Authority as well as any relevant stakeholder and to investigate the breach further where required",
      theDPOWillNotify:
        "The DPO will notify the client and the Supervisory Authority of the outcome of the investigation.",
      theDataControllerIsAlsoCommited:
        "The Data Controller is also committed to perform an exhaustive and thorough investigation both internally and externally (with the involvement of the supervisory authority if needed) on the root cause of the breach and to take any corrective measure in a timely manner, to help preventing the breach to occur in the future.",
      useOfCookies: "8. Use of Cookies",
      theDataControllerUsesCookies:
        "The Data Controller uses Cookies while running its web platform: the function of these Cookies is to partially track the activities of platform/websites users with the aim of providing a better customer experience; usually, it is possible to customize the use of Cookies on each PC through the settings page.",
      belowIsTheList:
        "Below is the list of the Cookies used by Scope Ratings website:",
      cookieName: "Cookie name",
      function: "Function",
      _ga: "Cookie used by Google Analytics to distinguish users (https://developers.google.com/analytics/devguides/collection/analyticsjs/cookieusage)",
      _gat: "Cookie used by Google Analytics to throttle request rate (https://developers.google.com/analytics/devguides/collection/analyticsjs/cookieusage)",
      _gid: "Cookie used by Google Analytics to distinguish user(https://developers.google.com/analytics/devguides/collection/analyticsjs/cookieusage)",
      socialMedia: "9. Social Media Plug-Ins",
      scopeAnalysisWebsiteUses:
        "Scope Fund Analysis website uses plug-ins for social media platforms which allow You to share information or to follow Us on Your social media’s profile.",
      whenYouClick:
        "When You click on the plug-in button, the social media will automatically receive the information on the page You visited and on the content You viewed.",
      forMoreDetails:
        "For more details on data protection policy for each social media, please visit the following pages:",
      forTwitter: "For Twitter:",
      twitterPlugins: "Plug-Ins",
      twitterPolicy: "Privacy Policy",
      forLinkedIn: "For LinkedIn",
      linkedInPlugins: "Plug-Ins",
      linkedInPolicy: "Privacy Policy",
      forBloomberg: "For Bloomberg:",
      bloombergPolicy: "Privacy Policy",
      dataSubjectsRights: "10. Data Subjects rights and duties",
      althoughSome:
        "Although some of the personal data are held and handled by the Data Controller, Data Subjects remain the owner of this information and, as such, they keep the following rights:",
      rightToAccess: "Right to access the data:",
      dataSubjectsHaveTheRight:
        "Data Subjects have the right to access any information concerning them held by the Data Controller; additionally, the Data Controller takes any reasonable steps to ensure that the personal data it holds for its customers are kept up to date and accurate",
      rightOfRevocation: "Right of revocation:",
      atAnyTime:
        " at any time, the Data Subject can withdraw the consent for the handling and processing of his/her personal data by the Data Controller; the Data Subject can also change the level of consent, i.e. not withdrawing it entirely, but restricting its applicability; requests to revoke personal data should be sent via email to privacy@scopegroup.com",
      rightOfErasure: "Right of erasure: ",
      dataSubjectsHaveTheRightTo:
        " Data Subjects have the right to request the complete deletion of their personal records held by the Data Controller: requests to delete personal data must be submitted via email to privacy@scopegroup.com; this right does not apply if there is a legal or official obligation to store this data",
      rightOfDataPortability: "Right of data portability:",
      peresonalDataCanBeTransferred:
        " personal data can be transferred to another service provider upon request from the Data Subject; this right is only applicable to information which the Data Subject he or she has provided to the Data Controller",
      rightOfComplain: "Right of complain:",
      dataSubjectsHaveTheRightToObject:
        " Data Subjects have the right to object the way personal data are processed by the Data Controller and they have the right to raise a complain directly with the local supervisory authority",
      theDataControllerIsCommitedToKeepThePersonal:
        "The Data Controller is committed to keep the personal data it stores up to date and accurate, nevertheless it is the Data Subject’s responsibility to promptly notify the Company of any relevant change that may affect the personal records it keeps.",
      updateOfThePolicy: "11. Update of the Policy and point of contact",
      theDataControllerIsCommitedToPeriodically:
        "The Data Controller is committed to periodically review its Privacy Policy with the aim of granting Data Subjects the best and more extensive level of protection: updates of the Policy will be automatically published on Scope Fund Analysis website without any previous notice, therefore users are strongly recommended to regularly check Scope Fund Analysis official online page for the latest version.",
      forFutherInformation:
        "For further information or questions regarding Scope Ratings website Privacy Policy, please contact us at privacy@scopegroup.com",
      additionalContacts: "12. Additional contacts",
      dataProtectionOfficer: "Data Protection Officer:",
      dataController: "Data controller:",
      managingDirector: "Managing Director: Lars Edler, Christian Werner",
      commercialRegister: "Commercial Register Berlin: HRB 97933 B",
      supervisoryAuthority: "Supervisory Authority",
    },
    legalNotice: {
      title: "Legal Notice",
      managingDirector: "Managing Director: Lars Edler, Christian Werner",
      commercialRegister: "Commercial Register Berlin HRB 97933 B",
      responsibleForTheContent:
        "Responsible for the content according to Section 55 (2) RStV:",
      disputeSettlement: "Dispute Settlement",
      theEuropeanCommision:
        "The European Commission provides a platform for online dispute resolution (OS): ",
      weAreNotWilling:
        "We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.",
      disclaimer: "Disclaimer",
      allInformation:
        "All information on this website has been carefully checked. We make every effort to continually expand and update the information it contains, but cannot guarantee its completeness, accuracy and complete up-to-dateness. Scope SE &amp; Co. KGaA provides this information without any assurances or warranties of any kind, either express or implied. Scope SE &amp; Co. KGaA excludes all liability for damages arising directly or indirectly from the use of this website, provided they are not based on intent or gross negligence on the part of Scope SE &amp; Co. KGaA. Our websites contain links to other websites. These links serve a purely informational purpose. We have no control over the future development of the content on these linked websites. Therefore, the operators of the linked sites are solely responsible for their content. As a content provider within the meaning of § 7 (1) TMG, Scope SE &amp; Co. KGaA is responsible in accordance with the general laws for its own content which is made available for use on this website. Cross-references (“links”) to content provided by other parties are to be distinguished from this “own” content. By providing such links, Scope SE &amp; Co. KGaA makes third-party content available for use. This external content was checked for possible civil or criminal liability when the link was first created. However, it cannot be ruled out that the content is subsequently changed by its respective provider. Scope SE &amp; Co. KGaA does not constantly review the content it refers to in its offer for changes that could re-establish accountability. If you believe that a linked external site violates applicable law or includes content that is otherwise inappropriate, please let us know.",
      theLaws:
        "The laws of the Federal Republic of Germany, excluding the United Nations Convention on Contracts for the International Sale of Goods (CISG), shall apply.",
      ifTheClient:
        " If the Client is a merchant (Kaufmann) or a legal person under public law or a separate estate (Sondervermögen) under public law or has no general venue in Germany, the exclusive place of jurisdiction for all disputes arising out of or in connection with the use of this website shall be Berlin, Germany.",
      copyright: "Copyright",
      copyrightContent: `Copyright © ${new Date().getFullYear()} / Business Group “Scope SE & Co. KGaA” and its affiliates “Scope Ratings GmbH”, “Scope Ratings UK Ltd.”, “Scope Fund Analysis GmbH”, “Scope Innovation Lab GmbH” and “Scope ESG Analysis GmbH” (also referred to as “SCOPE” or “Scope Group”). All rights reserved. All content (text, images, graphics, sound, video and animation files as well as their grouping etc.) on this website is protected by trademark and/or copyright law as well as other protective laws. This legal protection also extends to databases and similar facilities. Unless otherwise expressly permitted, the contents are freely usable only for their intended purpose of being viewed on the Internet. Beyond the limits of copyright law, the content of this website may not be reproduced, disseminated, altered or made accessible to third parties in any form without written permission. Unless otherwise specified, all brand names and logos on this website are trademarked.`,
    },
    methodology: {
      title: "Methodology",
      subtitle:
        "Detailed information on our rating methodologies and rating processes is available here. Scope’s fund rating methodology has been an industry standard for more than 20 years.",
      scopeFundsRating: "1.	Funds",
      introduction: "Introduction",
      introductionText1:
        "Scope’s Mutual Fund Rating is an established method of evaluating the management quality of open-end funds and how this contributes to outperformance and risk minimisation compared to peers. A",
      introductionText2: "qualitative rating, ",
      introductionText3:
        "which requires in-depth insight into the work processes of the investment company, is only provided if we receive a mandate from the respective investment company",
      introductionText4: "",
      introductionText5: ".",
      methodology: "Methodology",
      methodologyText1:
        "The Scope fund rating always consists of a performance and a risk indicator, which represent the weighted sum of other sub-indicators.",
      methodologyText2:
        "In the case of young funds with a history of less than six months, the indicators are initially only evaluated qualitatively using a list of criteria. The Scope fund rating is a relative rating, i.e. the fund is always assessed by comparing it to a predefined peergroup. A peergroup consists of at least 20 comparable funds selected by Scope analysts. ",
      methodologyText3: "A qualitative assessment ",
      methodologyText4:
        "is performed once a year. If the fund has a track record of six months or more, the qualitative evaluation criteria are replaced linearly over time by a sytem of quantitative metrics -with up to 40% of the qualitative criteria replaced after 60 months. For funds with a history of over 60 months, the quantitative share is constant at 60%, while qualitative criteria make up 40% of our assessment.",
      methodologyText5:
        "Figure: Graphical representation of our qualitative rating methodology when mandated by the investment company.",
      methodologyText6:
        " If there is no mandate from the respective investment company, Scope will, without consultation with the company, provide a purely quantitative rating for funds with a history of at least 60 months.",
      methodologyText7: "",
      methodologyText8: "",
      ratingProcess: "The rating process",
      ratingProcessText1:
        "The assessment of a fund’s quality is based on a multi-dimensional model that uses both performance and risk indicators. The performance indicator has a 70 per cent weighting and comprises relative performance, long-term earning power and the stability of performance. The risk indicator (30 per cent weighting) assesses the timing risk, loss risk and behavioural risk of the fund. There are interdependencies between performance and risk indicators in both quantitative and qualitative valuations. High outperformance is usually accompanied by a high tracking error. However, this in turn has a negative effect on behavioural risk. In general, the more active a manager is, the weaker its behavioural risk assessment is.",
      ratingProcessText2: "Figure: Assessment categories",
      ratingProcessLabel1: "Performance contribution",
      ratingProcessLabel2: "Stability",
      ratingProcessLabel3: "Relative performance",
      ratingProcessLabel4: "Long-term earnings power",
      ratingProcessLabel5: "Risk minimisation",
      ratingProcessLabel6: "Timing risk",
      ratingProcessLabel7: "Behavioural risk",
      ratingProcessLabel8: "Loss risk",
      ratingClasses: "Rating classes",
      ratingClassesText1:
        "Following a summary of the criteria for the individual components and the overall evaluation of the funds, the score is allocated to one of the five rating classes, ranging from A (very good) to E (poor). For each criterion, the fund is assigned a score of 1-100 relative to its competitors (in the same peergroup). If a specific indicator for a fund is in line with the median for its segment, it scores 50 for this indicator. The total score of relevance for the rating comprises the weighted score for the individual criteria.",
      ratingClassesText2:
        "If there is a major change in the fund management team, the fund rating will be flagged “ur” for “under review”.",
      ratingClassesText3:
        "A highly rated fund, i.e. at A or B, is expected to achieve a stable, above-average performance with relatively low risk in the medium term.",
      investmentTeamRating: "2.	Investment teams",
      intro: "1. Introduction",
      introText1:
        "Scope Fund Analysis (Scope) assigns ratings which assess the quality and competence of investment teams whose managed assets are predominantly invested in liquid instruments. The analysis evaluates individual investment teams with their own products and differentiated strategies, compared with the rest of the asset management company. Scope examines important asset management aspects directly under the team’s influence as well as indirect aspects which impact the team’s investment success.",
      introText2:
        "The rating therefore makes a significant contribution to investors’ decision-making process and enables investment teams to summarise the complex aspects of team competence, track record, resources and quality of processes in one rating.",
      ratingProcess2: "2. Rating process",
      ratingProcess2Text1:
        "The asset management rating (AMR) for investment teams - or investment team rating - scale ranges from AAA",
      ratingProcess2Text2: " to C",
      amr: "AMR",
      ratingProcess2Text3:
        ". For the evaluation, Scope uses a scorecard approach with a variety of qualitative and quantitative individual criteria. Depending on the level of quality, one to four points are awarded for each criterion. One point corresponds to the minimum institutional standard and four points represents excellent quality.",
      ratingProcess2Text4:
        "The evaluation is carried out in the context of the relevant peergroup to ensure a high level of consistency and comparability across a wide spectrum of investment teams with a similar investment focus. Scope classifies the individual criteria according to the core idea of each individual criterion in order to effectively represent the diversity of the sector.",
      ratingProcess2Text5:
        "The scorecard analysis covers the following four areas, or panels:",
      investmentProfessionals: "Investment professionals",
      investmentProfessionalsText1:
        "Scope analyses the investment skills of the team members, the experience of management personnel and relevant team members, the organisation of the team as well as the incentivisation of investment professionals.",
      investmentAndResearch: "Investment process and research",
      investmentAndResearchText1:
        "Scope evaluates the stringency and plausibility of the investment process. The value and quality of the research used to make investment decisions is assessed as well as the incorporation and implementation of investment risk management guidelines.",
      marketAndPerformance: "Market position and performance",
      marketAndPerformanceText1:
        "In this panel, Scope analyses the unique selling points of the product portfolio and the investment strategies. The development of assets under management as well as the team’s investment track record are also evaluated as they demonstrate the effectiveness of the investment approach.",
      further: "Other internal and external resources",
      furtherText1:
        "In Scope’s view, important functions which lie outside of the team’s direct influence, yet significantly impact its investment success, are also relevant to an Investment Team Rating. Scope therefore examines: IT resources; the selection process and performance review of external service providers; and other aspects of risk management in the company.",
      furtherText2:
        "The complete investment team rating methodology is provided ",
      furtherText3: "here.",
      ratincScale: "Rating scale for investment team ratings",
      mutualFunds: "Mutual Funds (UCITS)",
      realEstateFunds: "Open-ended Real Estate Funds (AIF)",
      closeEndFunds: "Closed-end funds (AIF)",
      amAndIssuersOfCert: "Asset Manager & Issuer of Certificates",
    },
    notFound: {
      title: "404",
      subtitle: "Page not found.",
    },
    news: {
      title: "Ratings and research from Scope",
      subtitle:
        "We regularly publish analysis and research on individual funds, asset managers, investment trends and industry sectors.",
      back: "Back",
      previous: "Previous",
      next: "Next",
      search: "Search",
      category: "Category",
      document: "Document",
      video: "Video",
      podcast: "Podcast",
      more: "More",
      error: "Currently unable to retrieve news. Please try again later.",
      newsNotFound: "Unable to find news.",
      noMachingNews: "No matching news for selected criteria.",
    },
    reports: {
      title: "Latest reports and studies",
      subtitle:
        "Here you will find the complete rating and research reports. They contain all graphics, data of the rating tables of the respective publication.",
      back: "Back",
      previous: "Previous",
      next: "Next",
      more: "More",
      download: "Download as PDF",
      error: "Currently unable to retrieve news. Please try again later.",
      reportsNotFound: "Unable to find news.",
      noMachingNews: "No matching news for selected criteria.",
    },
    oif: {
      title: "Offene Immobilienfonds",
      subtitle:
        "Die Ratingagentur Scope veröffentlicht regelmäßig Analysen und Research zu einzelnen Fonds und zu Investmentthemen. Folgend eine aktuelle Auswahl.",
      back: "Zurück",
      previous: "Zurück",
      next: "Weiter",
      search: "Suche",
      category: "Category",
      document: "Document",
      video: "Video",
      podcast: "Podcast",
      more: "Mehr",
      error:
        "Abruf von Nachrichten derzeit nicht möglich. Bitte versuchen Sie es später noch einmal.",
      newsNotFound: "Nachrichten nicht gefunden.",
      noMachingNews: "Keine passenden Nachrichten gefunden.",
    },
    login: {
      text: "Register now and get full access to all fund data and other tools.",
      aifText1:
        "Sollten Sie noch keinen Zugang zu scopeexplorer.com haben, registrieren Sie sich bitte hier:",
      aifText2:
        "Sie möchten Zugang zur Studie erhalten, ohne sich zu registrieren? Dann klicken Sie bitte",
      aifText3: "hier.",
      fillInFields: "Please fill in all mandatory fields.",
      errors: {
        default: "Login failed. Please try again.",
        401: "Login failed. User name or password are incorrect.",
      },
      success: "Login successful.",
    },
    register: {
      text: "Register now and get full access to all fund data and other tools.",
      text2:
        "Im Anschluss senden wir Ihnen eine E-Mail mit dem Bestätigungslink. Mit Klick auf den Link in dieser Mail schließen Sie Ihre Registrierung ab.",
      text3:
        "Die Scope Fund Analysis GmbH wird an Ihre E-Mail-Adresse regelmäßig Informationen zu den im ScopeExplorer enthaltenen Produkten und Unternehmen senden („Newsletter“). Sie können der Verwendung jederzeit widersprechen. Am einfachsten geht dies über Ihr Nutzerprofil.",
      text4:
        "Zudem verwenden wir Ihre E-Mail-Adresse für unseren Benachrichtigungsservice, mit dem wir Sie über aktuelle Entwicklungen zu Produkten in Ihrer „Watchlist“ informieren.",
      text5:
        "Ich bin damit einverstanden, dass die Scope Fund Analysis GmbH den Empfang und meine Nutzung von Newsletter und Benachrichtigungsservice (z.B. Klick auf bestimmte Links) analysiert, um ihre Produkte zu verbessern. Hierzu werden auch sog. Tracking-Pixel eingesetzt. Die Einwilligung ist freiwillig und kann jederzeit für die Zukunft widerrufen werden.",
      text6: "Es gelten unsere",
      text7:
        "Bitte drücken Sie auf „Jetzt registrieren“ wenn Sie damit einverstanden sind. Unsere Datenschutzhinweise finden Sie",
      link3: "/terms-of-use",
      link3Text: "Allgemeinen Geschäftsbedingungen.",
      link4: "/privacy-policy",
      link4Text: "here.",
      fillInFields: "Please fill in all mandatory fields.",
      errors: {
        default: "Registration failed. Please try again.",
        404: "Missing mandatory field in request.",
        409: "This e-mail address has already been assigned to a user..",
        500: "Server error.",
      },
      success: "User successfully created.",
      finishRegistration: "Finish registration",
      iam: "Select your investor type",
      retail: "Private Investor",
      wholesale: "Professional Investor",
      sector: "Sector",
      additinalSectorQuestion: "Additional information",
      division: "Division",
    },
    registerSuccess: {
      retailText1:
        "Vielen Dank für Ihre Registrierung. Um die Registrierung abzuschließen, haben wir Ihnen eine E-Mail an die von Ihnen genannte Adresse geschickt. Bitte schauen Sie auch einmal in Ihrem Spam-Ordner nach, sollten Sie die E-Mail nicht direkt in Ihrem Posteingang finden.",
      retailText2:
        "Sobald Sie mit einem Klick auf den Link in dieser E-Mail Ihre Anmeldung bestätigen, ist Ihre Registrierung abgeschlossen.",
      wholesaleText1:
        "Vielen Dank für Ihre Registrierung. Um die Registrierung abzuschließen, haben wir Ihnen eine E-Mail an die von Ihnen genannte Adresse geschickt. Bitte schauen Sie auch einmal in Ihrem Spam-Ordner nach, sollten Sie die E-Mail nicht direkt in Ihrem Posteingang finden.",
      wholesaleText2:
        "Sobald Sie mit einem Klick auf den Link in dieser E-Mail Ihre Anmeldung bestätigen, können Sie sich beim ScopeExplorer anmelden. Nach einer Prüfung durch uns werden wir Ihren Zugang um die Funktionen für professionelle Investoren erweitern. Dazu senden wir Ihnen eine gesonderte Benachrichtigung per E-Mail.",
    },
    confirm: {
      title: "Confirm user",
      success: "User name confirmed. You can now log in.",
      error: "Unable to confirm entry.",
    },
    resetPassword: {
      title: "Reset password",
      send: "Reset password",
      errors: {
        requestDefault: "Unable to send e-mail. Please try again.",
        default: "Unable to change password. Please try again.",
      },
      fillInFields: "Please fill in all mandatory fields.",
      requestSuccess:
        "If you are already registered, you will receive now an email through which you can reset your password.",
      success: "Your password has been changed.",
    },
    fundsListModal: {
      title: "Are you a professional investor?",
      subtitle1: "Then",
      subtitle2: "register",
      subtitle3: "now and get",
      subtitle4: "free",
      subtitle5: "access to:",
      listItem1: "Scope's extensive funds database",
      listItem2:
        "Comprehensive search functions for filtering and selecting funds",
      listItem3: "Generating and exporting long-term time series",
      listItem4: "Comprehensive peerg group rankings",
      listItem5: "Scope's list of fund recommendation",
      listItem6: "Scope's ESG analysis of invidual funds",
      ignore: "Ignore",
      dontShow: "Do not show again",
      register: "Register",
    },
    privacyPolicy: {
      title: "Datenschutzhinweise",
      subtitle: "für Scope Explorer",
      section1Title: "Datenschutz ist Vertrauenssache",
      section1Text:
        "Egal, ob Sie unser Kunde sind oder sich aus anderen Gründen für unsere Angebote interessieren: Der Schutz Ihrer Privatsphäre ist uns wichtig. Hier erfahren Sie, welche personenbezogenen Daten wir von Ihnen erheben, was wir damit machen und wie wir sie schützen. Außerdem informieren wir darüber, welche Rechte Ihnen zum Schutz Ihrer Daten zustehen.",
      section2Title: "1. Wer sind wir?",
      section2Text1:
        "Die Scope Fund Analysis GmbH ist ein deutsches Unternehmen mit Hauptsitz in Berlin und Verantwortlicher im Sinne des Datenschutzrechts für die Datenverarbeitung bei der Registrierung beim und die Nutzung des ScopeExplorer auf der Website  www.scopeexplorer.com",
      section2Text4: "1.1	Unsere Kontaktdaten lauten:",
      section2Text5:
        "1.2 Haben Sie Fragen zu dieser Datenschutzerklärung oder zum Schutz Ihrer Daten durch uns? Dann können Sie sich jederzeit auch an unseren Datenschutzbeauftragten wenden:",
      section3Title: "2. Worum geht es?",
      section3Text1:
        "2.1 Wenn wir personenbezogene Daten verarbeiten, bedeutet das, dass wir diese erheben, speichern, übermitteln, löschen oder in anderer Form nutzen. Mit personenbezogenen Daten meinen wir Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen, zum Beispiel zu",
      section3List1: "Kunden der Scope Fund Analysis GmbH,",
      section3List2:
        "Interessenten, die sich über unsere Scope Explorer-Website über unser Angebot informieren,",
      section3List3:
        "allen anderen Personen, die in Kontakt mit uns stehen, z.B. Vermittler, Bevollmächtigte, Boten sowie Vertreter oder Mitarbeiter juristischer Personen oder sonstiger Gesellschaften.",
      section3Text2:
        "2.2 Wir verarbeiten personenbezogene Daten, die wir durch den Kontakt mit Ihnen erhalten. Solche Daten entstehen insbesondere, wenn Sie sich online über unsere Dienste informieren, bei Scope Explorer registrieren und anmelden, online Kontaktformulare ausfüllen, sich schriftlich, per E-Mail oder telefonisch an uns wenden oder wenn Sie unsere Angebote nutzen.",
      section4Title:
        "3.	Wie verarbeiten wir Ihre Daten beim Besuch unserer Website?",
      section4Text1:
        "3.1 Bei der Nutzung unserer Website werden durch uns und durch Dritte automatisiert Daten erhoben, die Ihr Browser übermittelt.",
      section4Text2:
        "3.2 Bei der bloß informatorischen Nutzung der Website verarbeiten wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten:",
      section4List1: "verkürzte IP-Adresse des zugreifenden Rechners",
      section4List2: "Name der abgerufenen Datei",
      section4List3: "Datum und Uhrzeit des Abrufs",
      section4List4: "übertragene Datenmenge",
      section4List5: "Meldung über erfolgreichen Abruf",
      section4List6:
        "Browsertyp nebst Version sowie das Betriebssystem, das Sie nutzen",
      section4List7: "Referrer URL",
      section4List8: "anfragender Provider",
      section4List9: "Bildschirmauflösung",
      section4Text3:
        "3.3 Die Protokolldaten werden von uns zudem für statistische Auswertungen im Rahmen des Betriebs unseres Angebots verwendet. Im Falle der rechtswidrigen Nutzung unseres Angebots dienen die Protokolldaten auch dazu, in Betracht kommende Rechtsverletzungen aufzuklären. Die Verarbeitung erfolgt auf Grundlage einer Interessenabwägung, die immer auch Ihre Interessen berücksichtigt (Art. 6 Abs. 1 UAbs. 1 lit. f DSGVO).",
      section5Title: "4. Cookies und Analysedienste",
      section5Text1:
        "4.1 Diese Website verwendet Cookies. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Bei Cookies handelt es sich um Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf Ihrem Computer gespeichert werden. Der Cookie enthält eine Zeichenfolge, die eine eindeutige Identifizierung Ihres Systems beim erneuten Aufrufen der Website ermöglicht.",
      section5Text2:
        "4.2 Die meisten der von uns verwendeten Cookies („Session-Cookies“) und die darin gespeicherten und übermittelten Daten werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies („Persistente Cookies“) bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen.",
      section5Text3:
        "4.3 Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bereits gespeicherte Cookies können Sie jederzeit löschen. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.",
      section5Text4: "Technisch notwendige Cookies",
      section5Text5:
        "4.4 Einige Elemente unserer Website erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann. Einige Cookies bleiben gespeichert und ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.",
      section5Text6:
        "4.5 In den Cookies werden die folgenden Daten gespeichert und übermittelt:",
      section5List1: "aktuelle Session-ID,",
      section5List2: "Spracheinstellungen,",
      section5List3:
        "Kenntnisnahme bestimmter Inhalte der Website, z.B. Fondratings,",
      section5List4:
        "Informationen dazu, ob Sie in die Verwendung technisch nicht notwendiger Cookies eingewilligt haben.",
      section5Text7:
        "4.6 Die mithilfe dieser technisch notwendigen Cookies von Ihnen erhobenen Daten werden nicht zur Erstellung von Nutzerprofilen verwendet.",
      section5Text8:
        "4.7 Die Verarbeitung erfolgt auf Grundlage einer Interessenabwägung, die immer auch Ihre Interessen berücksichtigt (Art. 6 Abs. 1 UAbs. 1 lit. f DSGVO).",
      section5Text9: "Analyse-Cookies",
      section5Text10:
        "4.8 Auf unserer Website verwenden wir außerdem Cookies zur pseudonymisierten Reichweitenmessung und Werbezwecken. Cookies werden hierzu entweder von unserem Server oder dem Server Dritter an Ihren Browser übertragen. In diesem Zusammenhang verarbeiten wir Ihre Daten zur Wahrung berechtigter Interessen auf Grundlage einer Interessenabwägung, die immer auch Ihre Interessen berücksichtigt (Art. 6 Abs. 1 UAbs. 1 lit. f DSGVO). Unser berechtigtes Interesse in der Verarbeitung liegt in den folgenden Zwecken:",
      section5List5: "Analyse und statistische Auswertung der Website-Nutzung",
      section5List6: "Optimierung der Website",
      section5List7: "Bereitstellung personalisierter Angebote und Inhalte",
      section5List8: "Erfolgsmessung von Werbekampagnen",
      section5Text11:
        "4.9 Sie können dem Einsatz von Analyse-Cookies widersprechen. Hinweise zu spezifischen Widerspruchsmöglichkeiten finden Sie in der entsprechenden Beschreibung in den nachfolgenden Absätzen.",
      section5Text12:
        "4.10	Sofern erforderlich, holen wir für die Verarbeitung Ihre Einwilligung nach Art. 6 Abs. 1 UAbs. 1 lit. a DSGVO ein.",
      section5Text13: "Google Analytics",
      section5Text14:
        "4.11	Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet ebenfalls sog. „Cookies“, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Rahmen der für diese Website aktivierten IP-Anonymisierung wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. In unserem Auftrag wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Website-Aktivitäten zusammenzustellen und um weitere mit der Website-Nutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Website-Betreiber zu erbringen.",
      section5Text15:
        "4.12	Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.",
      section5Text16:
        "4.13	Für die Ausnahmefälle, in denen personenbezogene Daten in die USA übertragen werden, hat sich Google dem EU-US Privacy Shield unterworfen,",
      section5Link1: "https://www.privacyshield.gov/EU-US-Framework",
      section5Text17:
        "4.14	Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plug-in herunterladen und installieren: ",
      section5Link2: "http://tools.google.com/dlpage/gaoptout?hl=de",
      section5Text18:
        "4.15	Alternativ zum Browser-Add-On oder innerhalb von Browsern auf mobilen Geräten, klicken Sie bitte diesen Link, um die Erfassung durch Google Analytics innerhalb dieser Website zukünftig zu verhindern: ",
      section5Link3: "https://tools.google.com/dlpage/gaoptout/eula.html",
      section5Text19:
        "4.16	Dabei wird ein Opt-Out-Cookie auf Ihrem Gerät abgelegt. Löschen Sie Ihre Cookies, müssen Sie diesen Link erneut klicken.",
      section5Text20:
        "4.17	Diese Website verwendet Google Analytics mit der Erweiterung „_anonymizeIp()“. Dadurch werden IP-Adressen gekürzt weiterverarbeitet, eine Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die personenbezogenen Daten damit umgehend gelöscht.",
      section5Text21:
        "4.18	Wir nutzen Google Analytics, um die Nutzung unserer Website analysieren und regelmäßig verbessern zu können. Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten.",
      section5Text22:
        "4.19	Informationen des Anbieters von Google Analytics finden Sie hier: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001. Die Nutzerbedingungen können Sie hier einsehen http://www.google.com/analytics/terms/de.html. Informationen zum Datenschutz bietet die Datenschutzerklärung unter http://www.google.de/intl/de/policies/privacy.",
      section5Text22a:
        "4.19	Informationen des Anbieters von Google Analytics finden Sie hier: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001. Die Nutzerbedingungen können Sie hier einsehen",
      section5Text22b: "http://www.google.com/analytics/terms/de.html",
      section5Text22c:
        "Informationen zum Datenschutz bietet die Datenschutzerklärung unter",
      section5Text22d: "http://www.google.de/intl/de/policies/privacy.",
      section5Text23:
        "4.20	Sie können dem Einsatz von Google Analytics widersprechen. Hinweise zu spezifischen Widerspruchsmöglichkeiten finden Sie in der entsprechenden Beschreibung in den nachfolgenden Absätzen.",
      section6Title: "5. Newsletter und Benachrichtigungsservice",
      section6Text1:
        "5.1	Die Scope Fund Analysis GmbH sendet an registrierte Kunden regelmäßig Informationen zu den im ScopeExplorer enthaltenen Produkten und Unternehmen („Newsletter). Diese Verarbeitung erfolgt auf Grundlage einer Interessenabwägung gemäß Art. 6 Abs. 1 UAbs. 1 lit. f DSGVO, die immer auch Ihre Interessen berücksichtigt.",
      section6Text2:
        "5.2	Zudem wird registrierten Kunden ein sog. Benachrichtigungsservice angeboten, mit dem per E-Mail über aktuelle Entwicklungen zu Produkten auf der „Watchlist“ des Kunden informiert wird. Diese Verarbeitung erfolgt zur Erfüllung eines Vertrages gemäß Art. 6 Abs. 1 UAbs. 1 lit. b DSGVO.",
      section6Text3:
        "5.3	Der Empfang des Newsletters und der Benachrichtigungsservice können in Ihrem Nutzerprofil deaktiviert werden.",
      section6Text4:
        "5.4	Wir analysieren Daten zu Ihrem Empfang und Ihrer Nutzung von Newsletter und Benachrichtigungsservice, um unsere Produkte und unsere Werbung zu verbessern. So stellen wir mithilfe eines sog. Tracking-Pixels fest, ob unsere E-Mail geöffnet wurde und wir speichern, welche Links Sie in einer E-Mail geöffnet haben. Wir verarbeiten diese Daten ausschließlich aufgrund Ihrer ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 UAbs. 1 lit. a DSGVO.",
      section6Text5:
        "5.5	Wenn Sie nicht in die Analyse von Newsletter und Benachrichtigungsservice einwilligen bzw. Ihre Einwilligung widerrufen haben, kann es sein, dass wir Ihnen deswegen aus technischen Gründen keinen Newsletter und/oder keinen Benachrichtigungsservice mehr anbieten können.",
      section6Text6:
        "5.6	Für den Versand und die Analyse von E-Mails greifen wir auf die Dienste von SendGrid, Inc. 1801 California Street, Suite 500, Denver, Colorado 80202, USA als Auftragsverarbeiter gemäß Art. 28 DSGVO zurück. Dabei werden Daten auch in den USA und damit in Drittländern im Sinne der DSGVO verarbeitet. Die SendGrid, Inc. ist nach dem EU-US-Privacy Shield verpflichtet",
      section6Text7:
        "https://www.privacyshield.gov/participant?id=a2zt0000000TRktAAG&status=Active",
      section6Text8: ").",
      section7Title: "5. Informationsanwendungen anderer Anbieter",
      section7Text1:
        "6.1 Wir nutzen auf unsere Website Informationsanwendungen anderer Anbieter. Dadurch können wir Inhalte und Funktionen aus anderen Quellen (z.B. Videos) direkt in unsere Website einbinden. Sofern wir dabei personenbezogene Daten verarbeiten, erfolgt dies auf Grundlage einer Interessenabwägung, die immer auch Ihre Interessen mitberücksichtigt (Art. 6 Abs. 1 UAbs. 1 lit. f DSGVO).",
      section7Text2: "YouTube",
      section7Text3:
        "6.2 Wir haben YouTube-Videos in unsere Website eingebunden, die auf",
      section7Link1: "http://www.youtube.com",
      section7Text4:
        "gespeichert und von unserer Website aus direkt abspielbar sind. Diese sind alle im „erweiterten Datenschutz-Modus“ eingebunden, dies bedeutet, dass keine Daten über Sie als Nutzer an YouTube übertragen werden, wenn Sie die Videos nicht abspielen. Erst wenn Sie die Videos abspielen, werden die nachfolgend beschriebenen Daten übertragen. Auf diese Datenübertragung haben wir keinen Einfluss.",
      section7Text5:
        "6.3 Durch das Abspielen eines Videos auf der Website erhält YouTube die Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Dies erfolgt unabhängig davon, ob YouTube ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei YouTube eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei YouTube nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. YouTube speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an YouTube richten müssen.",
      section7Text6:
        "6.4 Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch YouTube erhalten Sie in der Datenschutzerklärung. Dort erhalten Sie auch weitere Informationen zu Ihren Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre:",
      section7Link2: "https://www.google.de/intl/de/policies/privacy",
      section7Text7:
        ". Google verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich dem EU-US-Privacy-Shield unterworfen,",
      section7Link3: "https://www.privacyshield.gov/EU-US-Framework",
      section8Title:
        "7.	Wie verarbeiten wir Ihre Daten bei der Registrierung und Anmeldung auf unserer Website?",
      section8Text1:
        "7.1 Sie können sich, um gewisse Funktionen von Scope Explorer in Anspruch zu nehmen, bei uns registrieren. Die dabei angegebenen Daten werden für die Nutzung unseres Angebots verwendet. Die Verwendung erfolgt außerdem, um Sie über Ratingänderungen von den auf der von Ihnen erstellten Watchlist enthaltenen Fonds per E-Mail zu informieren und bei Bedarf Ihre Suchen zu speichern. Die Registrierung umfasst die Eingabe der folgenden Daten:",
      section8List1: "Vor- und Nachname",
      section8List2: "E-Mail-Adresse",
      section8Text2:
        "7.2 Handelt es sich um einen professionellen Investor benötigen wir zudem folgende Daten:",
      section8List3: "Telefon- oder Mobilnummer",
      section8List4: "Angaben zum Unternehmen",
      section8List5: "Daten des Ansprechpartners",
      section8List6: "Funktion des Ansprechpartners",
      section8Text3:
        "7.3 Hierüber hinaus werden die bei der Registrierung verwendete IP-Adresse und das Datum der Registrierung gespeichert, um einem Missbrauch der Funktion vorzubeugen.",
      section8Text4:
        "7.4 Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 UAbs. 1 lit. a und lit. f DSGVO.",
      section9Title:
        "8.	Wie verarbeiten wir Ihre Daten im Falle der Kontaktaufnahme mit uns?",
      section9Text1:
        "8.1 Unser Angebot ermöglicht es Ihnen, Kontakt zu uns aufzunehmen. Dies ist etwa telefonisch, anhand der zur Verfügung gestellten Telefonnummern oder durch den Versand einer E-Mail möglich. Daneben stellen wir auf unserer Website ein Kontaktformular für die elektronische Kontaktaufnahme zur Verfügung.",
      section9Text2:
        "8.2 Bei Absendung einer Nachricht über das Kontaktformular werden neben den von Ihnen eingegebenen Informationen zudem folgende Daten gespeichert, um Missbrauch vorzubeugen:",
      section9List1: "IP-Adresse",
      section9List2: "Datum und Uhrzeit",
      section9List3: "Betriebssystem",
      section9List4: "Browser",
      section9List5: "besuchte interne Seiten",
      section9Text3:
        "8.3 Bei einer Kontaktaufnahme über Telefon oder die bereitgestellten E-Mail-Adressen werden die übermittelten personenbezogenen Daten des Nutzers gespeichert, um die Anfrage und eine gegebenenfalls folgende Korrespondenz zu bearbeiten.",
      section9Text4:
        "8.4 Rechtsgrundlage für die Verarbeitung der Daten ist Art. 6 Abs. 1 UAbs. 1 lit. a oder lit. f DSGVO.",
      section10Title: "9.	Nutzen wir Ihre Daten zu Sicherheitszwecken?",
      section10Text1:
        "9.1 Wir nutzen Ihre Daten zudem, um Ihre Sicherheit sowie die Sicherheit unserer Leistungen und Geschäftsabläufe zu gewährleisten. Die in diesem Abschnitt beschriebene Verarbeitung erfolgt auf Grundlage einer Interessenabwägung, die immer auch Ihre Interessen berücksichtigt.",
      section10Text2:
        "9.2 Auf Grundlage einer Interessenabwägung verarbeiten wir Daten um Ihre Sicherheit sowie die Sicherheit unserer Geschäftsabläufe zu gewährleisten, insbesondere in den folgenden Fällen:",
      section10List1:
        "Um im Falle rechtlicher Auseinandersetzungen Sachverhalte prüfen und Ansprüche geltend machen zu können.",
      section10List2:
        "Um IT-Sicherheit und den IT-Betrieb der Scope Fund Analysis GmbH gewährleisten zu können.",
      section10List3: "Um Straftaten zu verhindern und aufklären zu können.",
      section10List4:
        "Um eine ordnungsgemäße Geschäftssteuerung zu gewährleisten.",
      section11Title: "10. Wer erhält Ihre Daten?",
      section11Text1:
        "10.1	Informationen über Sie dürfen wir nur weitergeben, wenn gesetzliche Bestimmungen dies erlauben oder Sie eingewilligt haben.",
      section11Text2: "10.2	Zu den weitergegebenen Daten können gehören:",
      section11List1: "Vor- und Nachname",
      section11List2: "E-Mail-Adresse",
      section11List3: "Unternehmen",
      section11List4: "Funktion",
      section11Text3:
        "10.3	Auch von uns eingesetzte Dienstleister können von uns Daten erhalten, wenn diese unsere besonderen Vertraulichkeitsanforderungen erfüllen. Dies können insbesondere Unternehmen in den Kategorien IT-Dienstleistungen, Logistik, Druckdienstleistungen, Telekommunikation, Inkasso, Beratung sowie Vertrieb und Marketing sein.",
      section12Title: "11. Wann löschen wir Ihre Daten?",
      section12Text1:
        "11.1	Wir verarbeiten und speichern Ihre personenbezogenen Daten grundsätzlich so lange, wie wir in einer geschäftlichen Beziehung zu Ihnen stehen. ",
      section12Text2:
        "11.2	Benötigen wir Ihre personenbezogenen Daten für den ursprünglichen Zweck nicht mehr, löschen wir diese. Etwas anderes gilt nur dann, wenn ihre – befristete – Weiterverarbeitung zu anderen Zwecken erforderlich ist. Solche Zwecke können sein:",
      section12List1:
        "Die Erfüllung von Aufbewahrungs- und Dokumentationspflichten, die sich unter anderem aus dem Handelsgesetzbuch (HGB) ergeben.",
      section12List2:
        "Die Erhaltung von Beweismitteln im Rahmen der Verjährungsvorschriften. Diese können nach den §§ 195 ff. des Bürgerlichen Gesetzbuches (BGB) in der Regel drei Jahre, in gewissen Fällen aber auch bis zu dreißig Jahre betragen.",
      section13Title:
        "12. Werden Ihre Daten in ein Drittland oder an eine internationale Organisation übermittelt?",
      section13Text1:
        "12.1	Eine Datenübermittlung an internationale Organisationen oder in Drittstaaten (Staaten außerhalb des Europäischen Wirtschaftsraums – EWR) findet grundsätzlich nicht statt. Zur Übermittlung pseudonymisierter Daten durch Analysedienste siehe oben unter ",
      section13Text2: "„4. Cookies und Analysedienste“",
      section13Text3: "sowie ",
      section13Text4: "„5. Informationsanwendungen anderer Anbieter“.",
      section14Title:
        "13. Sind Sie verpflichtet uns bestimmte personenbezogene Daten zu geben?",
      section14Text1:
        "13.1	Unter Umständen ja. Im Rahmen unseres Online-Angebots benötigen wir Ihre personenbezogenen Daten zur Durchführung einer Registrierung und zur Kontaktierung im Falle einer Ratingveränderung der von Ihnen gespeicherten Fonds. Ohne diese Daten können wir zum Teil unser Angebot nicht erbringen.",
      section15Title:
        "14. Welche Rechte haben Sie, wenn es um die Verarbeitung Ihrer Daten geht?",
      section15Text1:
        "14.1	Sie haben uns gegenüber folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:",
      section15List1: "Recht auf Auskunft,",
      section15List2: "Recht auf Berichtigung oder Löschung,",
      section15List3: "Recht auf Einschränkung der Verarbeitung,",
      section15List4: "Recht auf Datenübertragbarkeit,",
      section15List5: "Recht auf Widerruf von erteilten Einwilligungen.",
      section15Text2:
        "14.2	Ihnen steht im Zusammenhang mit der Verarbeitung Ihrer personenbezogenen Daten außerdem ein Beschwerderecht bei der folgenden, für uns in Bezug auf den Schutz personenbezogener Daten, zuständigen Aufsichtsbehörde zu:",
      section16Title:
        "15. Inwieweit gibt es eine automatisierte Entscheidungsfindung im Einzelfall?",
      section16Text1:
        "15.1	Im Rahmen unserer Website sowie zur Begründung und Durchführung der Geschäftsbeziehung nutzen wir grundsätzlich keine vollautomatisierte Entscheidungsfindung gemäß Art. 22 DSGVO.",
      section17Title:
        "16. Ihr Widerspruchsrecht nach Artikel 21 Datenschutz-Grundverordnung (DSGVO)",
      section17Text1: "16.1	Einzelfallbezogenes Widerspruchsrecht",
      section17Text2:
        "Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 e DSGVO (Datenverarbeitung im öffentlichen Interesse) und Artikel Art. 6 Abs. 1 f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Artikel 4 Nr. 4 DSGVO. ",
      section17Text3:
        "Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.",
      section17Text4:
        "16.2	Widerspruch gegen die Verarbeitung Ihrer Daten für unsere Direktwerbung ",
      section17Text5:
        "In Einzelfällen verarbeiten wir Ihre personenbezogenen Daten für unsere Direktwerbung. Sie haben das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.",
      section17Text6:
        "Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden wir Ihre personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.",
      section17Text7:
        "Der Widerspruch kann formfrei erfolgen und sollte möglichst gerichtet werden an die unter Ziffer 1 genannten Kontaktdaten an uns gesendet werden.",
    },
    rechtlicheHinweise: {
      title: "Rechtliche Hinweise zur nutzung des Scope Explorer",
      subtitle:
        "Die Nutzung des über die Internetseite www.scopeexplorer.com erreichbaren Informationsportals „SCOPE EXPLORER“ darf ausschließlich nach Maßgabe der folgenden Bestimmungen und Hinweise erfolgen:",
      section1Title: "1. Angebot nur im Inland",
      section1Text1:
        "Der SCOPE EXPLORER richtet sich an Personen mit Wohnsitz in der Bundesrepublik Deutschland.",
      section2Title: "2. Keine Beratung",
      section2Text1:
        "SCOPE erbringt keine Rechtsberatungs-, Steuerberatungs-, Beratungs-, oder Unternehmensdienstleistungen und berät nicht bei der Strukturierung von Transaktionen, der Erstellung oder Verhandlung von Transaktionsdokumenten.",
      section3Title: "3. Keine Finanzdienstleistungen",
      section3Text1:
        "Über den SCOPE EXPLORER werden keine Finanzdienstleistungen erbracht. Das Portal dient ausschließlich der Bereitstellung von Informationen. SCOPE bietet keine Beratung bei der Vermittlung und/oder Verwaltung von Finanzinstrumenten an.",
      section4Title:
        "4. Keine Ratings nach der EU-Verordnung über Ratingagenturen",
      section4Text1:
        "Die auf dem SCOPE EXPLORER angebotenen Fondsratings stellen kein Rating im Sinne der Verordnung (EG) Nr. 1060/2009 über Ratingagenturen in der Fassung der Änderungsverordnungen (EU) Nr. 513/2011 und (EU) Nr. 462/2013 dar. Die von SCOPE zur Verfügung gestellten Fondsratings sind Anlagestrategieempfehlungen. Diese enthalten aber keine Anlageempfehlungen oder Anlageberatung, da sie keine Beurteilung des Wertes des Finanzinstruments enthalten und auch den Anlagehorizont, die individuellen Anlageziele oder die Finanzlage einzelner Anleger nicht berücksichtigen.",
      section5Title: "5. Kein Angebot von Finanzinstrumenten",
      section5Text1:
        "Der SCOPE EXPLORER enthält keine Kauf- und/oder Verkaufsangebote für Finanzinstrumente. Insbesondere sind alle Inhalte zu Fonds, Wertpapieren und/oder anderen Finanzinstrumenten nicht als individuelle Anlageempfehlungen für Finanzinstrumente zu verstehen.",
      section6Title: "6. Download von Inhalten",
      section6Text1:
        "Der Kunde ist nur dann zum Herunterladen und Ausdrucken von Inhalten berechtigt, wenn auf dem Portal eine Möglichkeit zum Herunterladen oder Ausdrucken als Funktion zur Verfügung steht (z. B. über einen Download-Button).",
      section7Title: "7. Haftung für Links",
      section7Text1:
        "Verweise und Links auf Websites Dritter bedeuten nicht, dass sich SCOPE die hinter dem Verweis oder Link stehenden Inhalte zu eigen macht. Die Inhalte begründen keine Verantwortung von SCOPE für die dort bereitgestellten Daten und Informationen. SCOPE hat keinen Einfluss auf die Inhalte hinter dem Link. Für illegale, fehlerhafte oder unvollständige Inhalte und für Schäden, die durch die Nutzung der verlinkten Inhalte entstehen, haftet SCOPE nicht.",
    },
    termsOfUse: {
      title: "Nutzungsbedingungen",
      subtitle: "",
      section1Title: "1.	Anwendungsbereich",
      section1Text1:
        "1.1	Diese Nutzungsbedingungen gelten für die Registrierung und die Nutzung des nur nach Registrierung zugänglichen Bereichs des über die Internetseite www.scopeexplorer.com erreichbaren Informationsportals ",
      section1Text2: "„SCOPE EXPLORER“.",
      section1Text3:
        "1.2	Nutzer des SCOPE EXPLORER im Sinne dieser Nutzungsbedingungen sind private  Nutzer und professionelle Nutzer, deren Registrierung bestätigt wurde (auch ",
      section1Text4: ",,Kunden‘‘",
      section1Text5: ").",
      section1Text6:
        "1.3	Der SCOPE EXPLORER wird durch die Scope Fund Analysis GmbH, Lennéstraße 5, 10785 Berlin (",
      section1Text7: "„SCOPE“",
      section1Text8:
        ") betrieben. Diese ist erreichbar telefonisch unter +49 30 27891-0 oder per E-Mail an info@scopeanalysis.com.",
      section1Text9:
        "1.4	Andere Geschäftsbedingungen finden keine Anwendung, auch wenn SCOPE ihrer Geltung im Einzelfall nicht gesondert widerspricht. Selbst wenn SCOPE auf ein Schreiben Bezug nimmt, das andere Geschäftsbedingungen enthält oder auf solche verweist, liegt darin kein Einverständnis mit der Geltung jener Geschäftsbedingungen.",
      section1Text10:
        "1.5 Der SCOPE EXPLORER richtet sich an Personen mit Wohnsitz in der Bundesrepublik Deutschland.",
      section2Title: "2. Registrierung",
      section2Text1:
        "2.1	Bei der Registrierung sind Vor- und Nachname, eine gültige E-Mail-Adresse und ein Passwort anzugeben.",
      section2Text2:
        "2.2	Registriert sich eine Person als professioneller Nutzer sind zudem eine Telefon- oder Mobilnummer, das Unternehmen sowie der Vor- und Nachname und die Funktion des Ansprechpartners anzugeben.",
      section2Text3:
        "2.3	Voraussetzung für die erfolgreiche Registrierung ist die Verifikation der angegebenen E-Mail-Adresse durch Klick auf den entsprechenden Link in der versendeten Bestätigungs-E-Mail.",
      section2Text4:
        "2.4	SCOPE ist berechtigt, die bereitgestellten Informationen auf Vollständigkeit und Plausibilität zu prüfen. Die Registrierung wird erst wirksam, wenn diese durch SCOPE bestätigt worden ist. Die Bestätigung der Registrierung steht im freien Ermessen von SCOPE.",
      section2Text5:
        "2.5	Die Zugangsdaten einschließlich des Passworts sind vertraulich zu behandeln und dürfen unbefugten Dritten nicht zugänglich gemacht werden. Besteht Grund zur Annahme, dass Dritte unbefugt Kenntnis von den Zugangsdaten erhalten haben oder erlangen werden, ist SCOPE unverzüglich hierüber informieren.",
      section3Title: "3. Leistungen von SCOPE",
      section3Text1:
        "3.1	Kunden haben die Möglichkeit, sog. Fondsratings zum Rendite-Risiko-Profil von Investmentfonds (dazu gehören sowohl individuelle als auch alternative Investmentfonds oder ähnliche Konstruktionen in der Position eines Eigenkapitalgebers) einzusehen. ",
      section3Text2:
        "3.2	Ferner erhalten Kunden Zugriff auf eine so genannte „Watchlist“. Durch die Watchlist kann der Kunde selbst ausgewählte Fonds in einer Liste speichern und sich über Ratingänderungen informieren",
      section3Text3:
        "3.3	Dem professionellen Nutzer stehen über die in 3.1 und 3.2 beschriebenen Leistungen  hinaus Informationen zu Zeitreihen in grafischer und tabellarischer Darstellung, ein Auszug der von SCOPE ausgewählten Fonds und so genannte ESG-Analysen auf Fondsebene zur Verfügung.",
      section3Text4:
        "3.4	SCOPE analysiert die Nutzung von SCOPE EXPLORER durch den Kunden und zeigt ihm auf dieser Grundlage insbesondere solche Informationen an, die besonders interessant für ihn sind. Voraussetzung für die Inanspruchnahme dieser Analyse ist, dass der Kunde in den Einsatz bestimmter Analyse-Cookies gegebenenfalls einwilligt.",
      section3Text5:
        "3.5	SCOPE wird angemessene Anstrengungen unternehmen, um die Verfügbarkeit des Portals aufrechtzuerhalten. Der Kunde erkennt an, dass SCOPE keinen definierten Grad an Verfügbarkeit oder die Einhaltung eines Service Levels schuldet.",
      section3Text6:
        "3.6	SCOPE garantiert nicht, dass das Portal fehlerfrei genutzt werden kann und/oder dass es frei von Viren und anderen schädlichen Komponenten ist.",
      section4Title: "4. Ausschlüsse und Disclaimer",
      section4Text1:
        "4.1	Eine Garantie für Richtigkeit, Aktualität, Eignung für einen bestimmten Zweck und Vollständigkeit der Inhalte wird von SCOPE nicht übernommen.",
      section4Text2:
        "4.2	SCOPE erbringt keine Rechtsberatungs-, Steuerberatungs-, Beratungs-, oder Unternehmensdienstleistungen und berät nicht bei der Strukturierung von Transaktionen, der Erstellung oder Verhandlung von Transaktionsdokumenten.",
      section4Text3:
        "4.3	Über den SCOPE EXPLORER werden keine Finanzdienstleistungen erbracht. Das Portal dient ausschließlich der Bereitstellung von Informationen. SCOPE bietet keine Beratung bei der Vermittlung und/oder Verwaltung von Finanzinstrumenten an.",
      section4Text4:
        "4.4	Die auf dem SCOPE EXPLORER angebotenen Fondsratings stellen kein Rating im Sinne der Verordnung (EG) Nr. 1060/2009 über Ratingagenturen in der Fassung der Änderungsverordnungen (EU) Nr. 513/2011 und (EU) Nr. 462/2013 dar. Die von SCOPE zur Verfügung gestellten Fondsratings sind Anlagestrategieempfehlungen. Diese enthalten aber keine Anlageempfehlungen oder Anlageberatung, da sie keine Beurteilung des Wertes des Finanzinstruments enthalten und auch den Anlagehorizont, die individuellen Anlageziele oder die Finanzlage einzelner Anleger nicht berücksichtigen.",
      section4Text5:
        "4.5	Der SCOPE EXPLORER enthält keine Kauf- und/oder Verkaufsangebote für Finanzinstrumente. Insbesondere sind alle Inhalte zu Fonds, Wertpapieren und/oder anderen Finanzinstrumenten nicht als individuelle Anlageempfehlungen für Finanzinstrumente zu verstehen.",
      section4Text6:
        "4.6	Verweise und Links auf Websites Dritter bedeuten nicht, dass sich SCOPE die hinter dem Verweis oder Link stehenden Inhalte zu eigen macht. Die Inhalte begründen keine Verantwortung von SCOPE für die dort bereitgestellten Daten und Informationen. SCOPE hat keinen Einfluss auf die Inhalte hinter dem Link. Für illegale, fehlerhafte oder unvollständige Inhalte und für Schäden, die durch die Nutzung der verlinkten Inhalte entstehen, haftet SCOPE nicht.",
      section5Title: "5. Änderungen dieser Nutzungsbedingungen",
      section5Text1:
        "5.1	SCOPE kann von Zeit zu Zeit redaktionelle Änderungen an diesen Nutzungsbedingungen vornehmen, die das laufende Vertragsverhältnis mit SCOPE nicht betreffen. Zu diesen Änderungen gehören beispielsweise die Korrektur von Tippfehlern oder die Aktualisierung von Kontaktinformationen.",
      section5Text2:
        "5.2	SCOPE ist darüber hinaus berechtigt, gelegentlich und nach eigenem Ermessen Änderungen an diesen Nutzungsbedingungen aus triftigen Gründen vorzunehmen, die das laufende Vertragsverhältnis betreffen, insbesondere aufgrund neuer technischer Entwicklungen, rechtlicher Änderungen, einer Anordnung oder eines Antrags einer Regulierungsbehörde, einer Gerichtsentscheidung oder anderer gleichwertiger Gründe wie Änderungen in der Vertriebs- oder Produktpolitik.",
      section5Text3:
        "5.3	Will SCOPE solche Änderungen vornehmen, die das aktuelle Vertragsverhältnis mit dem Kunden betreffen, wird SCOPE den Kunden spätestens zwei Monate vor dem vorgeschlagenen Inkrafttreten in auffälliger und angemessener Weise informieren. Dies kann eine auffällige Meldung im Portal, d.h. beim nächsten Login, oder eine E-Mail sein. Diese Nachricht enthält Informationen über die von SCOPE gewünschten Änderungen, das Recht des Kunden, sie abzulehnen, und die Folgen, wenn der Kunde sie nicht vor Ablauf der Frist ablehnt. Die Änderungen gelten als genehmigt, wenn der Kunde entweder direkt zustimmt oder wenn er die Änderungen nicht vor Ablauf der Frist ablehnt. Wenn der Kunde dem Änderungsvorschlag widerspricht, kann jede Partei die von der mitgeteilten Änderung betroffene Vereinbarung vor dem vorgeschlagenen Inkrafttreten mit sofortiger Wirkung und ohne zusätzliche Kosten kündigen. In der Mitteilung wird SCOPE die von der vorgeschlagenen Änderung betroffene Vereinbarung identifizieren und den Kunden ausdrücklich auf dieses Kündigungsrecht hinweisen. Wird der Vertrag gekündigt, unterliegen Verträge mit SCOPE, die nicht von der vorgeschlagenen Änderung betroffen sind, nicht diesem Kündigungsrecht.",
      section6Title: "6. Sperrung des Zugangs zum Portal ",
      section6Text1:
        "SCOPE kann den Zugang zum Portal vorübergehend oder dauerhaft sperren, wenn es konkrete Anhaltspunkte dafür gibt, dass der Kunde gegen diese Nutzungsbedingungen und/oder geltendes Recht verstoßen hat, oder wenn eine Sperrung erforderlich ist, um die IT-Sicherheit oder andere berechtigte Interessen von SCOPE zu wahren. SCOPE wird bei der Entscheidung über die Sperrung die berechtigten Interessen des Kunden so weit wie möglich und zumutbar berücksichtigen.",
      section7Title: "7. Kündigung und Verbraucher-Widerrufsrecht",
      section7Text1:
        "7.1	Der Vertrag über die Nutzung des SCOPE EXPLORER unter Geltung dieser Nutzungsbedingungen wird auf unbestimmte Zeit geschlossen und ist durch beide Parteien mit einer Frist von vier Wochen zum jeweiligen Monatsende kündbar. Hiervon unberührt bleibt das Recht beider Parteien zur sofortigen Kündigung aus wichtigem Grund. ",
      section7Text2:
        "7.2	Die Erklärung der Kündigung hat in Textform zu erfolgen.",
      section7Text3:
        "7.3	Nur wenn der Kunde ein Verbraucher ist, steht ihm ein Verbraucher-Widerrufsrecht zu. Einzelheiten sind der diesen Nutzungsbedingungen angefügten Widerrufsbelehrung zu entnehmen.",
      section7Text4:
        "7.4	Die Beendigung des Vertrages über die Nutzung des SCOPE EXPLORER berührt nicht bestehende sonstige Vereinbarungen über Dienstleistungen und Produkte mit SCOPE.",
      section8Title: "8. Nutzungsrecht",
      section8Text1:
        "8.1	Für die Dauer des  Vertrages über die Nutzung des SCOPE EXPLORER gewährt SCOPE dem Kunden ein nicht ausschließliches, geografisch unbegrenztes, nicht unterlizenzierbares und nicht übertragbares Recht, auf die auf dem SCOPE EXPLORER verfügbaren Inhalte zuzugreifen und diese nur für den eigenen Gebrauch anzuzeigen.",
      section8Text2:
        "8.2	Der Kunde ist nur dann zum Herunterladen und Ausdrucken von Inhalten berechtigt, wenn auf dem Portal eine Möglichkeit zum Herunterladen oder Ausdrucken als Funktion zur Verfügung steht (z. B. über einen Download-Button).",
      section9Title: "9. Haftungsbeschränkung",
      section9Text1:
        "9.1	SCOPE haftet unbeschränkt, wenn die Schadensursache auf Vorsatz oder grober Fahrlässigkeit beruht. Des Weiteren haftet Scope Fund Analysis für die leicht fahrlässige Verletzung wesentlicher Pflichten, deren Verletzung die Erreichung des Vertragszwecks gefährdet, oder für die Verletzung von Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages erst ermöglicht und auf deren Einhaltung sich die Vertragsparteien regelmäßig verlassen. In diesem Fall haftet SCOPE jedoch nur für den vorhersehbaren, vertragstypischen Schaden. SCOPE haftet nicht für leicht fahrlässige Verletzungen anderer als der in den vorstehenden Sätzen genannten Verpflichtungen.",
      section9Text2:
        "9.2	Die vorstehenden Haftungsbeschränkungen gelten nicht für Verletzungen von Leben, Körper oder Gesundheit, für arglistig verschwiegene Mängel und für Mängel bezüglich derer eine Beschaffenheitsgarantie übernommen wurde. Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.",
      section9Text3:
        "9.3	Soweit die Haftung von SCOPE ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung von Arbeitnehmern, gesetzlichen Vertretern und Erfüllungsgehilfen der SCOPE.",
      section9Text4:
        "9.4	Sollte dieser Vertrag ausnahmsweise eine Schutzwirkung zugunsten Dritter entfalten, gelten die vorstehenden Haftungsbeschränkungen auch gegenüber Dritten.",
      section10Title: "10. Richtlinien / Schlussbestimmungen ",
      section10Text2:
        "10.2	Diese Nutzungsbedingungen sind in deutscher Sprache verfasst. Übersetzungen dieser Nutzungsbedingungen in andere Sprachen dienen ausschließlich Informationszwecken. Im Falle von Widersprüchen zwischen der deutschen Version und Übersetzungen geht die deutsche Version vor.",
      section10Text3:
        "10.3	Es gilt deutsches Recht unter Ausschluss des Übereinkommens der Vereinten Nationen über Verträge über den internationalen Warenkauf (CISG) und des deutschen Kollisionsrechts. Ist der Kunde Verbraucher und hat er seinen gewöhnlichen Aufenthalt in einem Mitgliedstaat der Europäischen Union, so gilt ebenfalls deutsches Recht, wobei zwingende Bestimmungen des Staates, in dem der Nutzer seinen gewöhnlichen Aufenthalt hat, unberührt bleiben.",
      section10Text4:
        "10.4	Ist der Kunde Kaufmann oder eine juristische Person des öffentlichen Rechts oder ein Sondervermögen des öffentlichen Rechts oder hat er keinen allgemeinen Gerichtsstand in Deutschland, ist ausschließlicher Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit diesen Nutzungsbedingungen Berlin.",
      section10Text5:
        "10.5	Die Europäische Kommission bietet eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform), die unter www.ec.europa.eu/consumers/odr zugänglich ist. Unsere E-Mail-Adresse finden Sie in unserem Impressum. Wir sind weder verpflichtet noch bereit, uns an dem Streitbeilegungsverfahren zu beteiligen.",
      section10Text6:
        "10.6	Der Kunde ist nicht berechtigt, ohne vorherige schriftliche Zustimmung von SCOPE Rechte oder Ansprüche aus oder im Zusammenhang mit der Nutzung des SCOPE EXPLORER, eines über den SCOPE EXPLORER geschlossenen Vertrages und/oder dieser Nutzungsbedingungen an Dritte abzutreten.",
      section10Text7:
        "10.7	Sollte eine einzelne Bestimmung dieser Nutzungsbedingungen ganz oder teilweise unwirksam sein, so wird dadurch die Gültigkeit der übrigen Bestimmungen nicht berührt.",
      section10Text8:
        "10.8	Sofern in diesen Nutzungsbedingungen nichts anderes bestimmt ist, sind Änderungen oder Ergänzungen dieser Nutzungsbedingungen nur gültig, wenn sie in Textform erfolgen. Dies gilt auch für jede Änderung oder Aufhebung dieser Bestimmung.",
    },
    termsAndConditions: {
      title: "Besondere Bestimmungen der Offene Immobilienfonds Studie 2022",
      subtitle: "",
    },
    scopeAwards: {
      scopeAwards: "Scope Award",
      title: "Scope Award",
      investmentAwards: "Scope Investment Awards",
      alternativeInvestmentAwards: "Scope Alternative Investment Awards",
      certificateAwards: "Scope Certificate Awards",
      findOutMore:
        "Find out more about categories, nominees and the award methodology",
      scopeAward: "The Scope Award",
      scopeAwardText1:
        "Every day, the analysts at Scope Fund Analysis evaluate the risk/return profiles of investment funds and the quality of asset managers. Their ratings, rankings and research reports help investors to make informed investment and selection decisions.",
      scopeAwardText2:
        "Once a year, our analysts revue their work to select the most outstanding asset management achievements and strongest fund concepts which we honour with the Scope Awards.",
      scopeAwardText3:
        "The Scope Awards are the most established and distinguished awards presented to fund and asset managers in German-speaking countries – for equity, pension and mixed funds (the Scope Investment Awards) as well as for tangible assets and alternative investments (the Scope Alternative Investment Awards). We have been holding the Scope Alternative Investment Awards since 2005, while the Scope Investment Awards have a more than 10-year history.",
      scopeAwardText4:
        "Our media partnership with the Handelsblatt is a distinguishing feature of the Scope Awards. The award ceremony has been jointly held together with the Handelsblatt since 2013.",
      scopeAwardText5:
        "Moreover, while all other market awards focus exclusively on quantitative criteria, the Scope Awards also take qualitative criteria fully into consideration. Although attention to qualitative factors makes analysis considerably more time-consuming, it also allows forward-looking statements to be made regarding the quality of funds and managers.",
      scopeAwardText6:
        "So what distinguishes a Scope Award from a Scope rating? While both allow high-quality funds and asset managers to stand out from the crowd, there is one significant difference: fund providers and asset managers can acquire ratings, whereas they must qualify for an award in accordance with participation and nomination conditions.",
      awardSculpture: "The Award Sculpture",
      awardSculptureText1:
        "The sculpture gives tangible form to both the rating agency’s identity and the purpose of the awards.",
      awardSculptureText2:
        "The Scope Group stands for independence, expertise, transparency and continuity. The Investment Awards are presented by the German rating agency to honour the quality of retail fund and institutional fund asset managers.",
      awardSculptureText3:
        "The search for a sculpture which reflected the achievements of award holders while, at the same time, symbolising the Scope Group’s basic principles, led to a combination of metal and glass.",
      awardSculptureText4:
        "The underlying design concept was penetration. The Scope Award is made up of geometric forms with clear, defined lines. A metal cylinder penetrates a tablet of glass, in the style of the Scope Group logo. Both elements are set on a square, stainless-steel base, providing weight and stability.",
      awardSculptureText5:
        "Metal represents integrity and measurable value, while glass combines transparency with a high standard of diligence. The sculpture thus brings together market penetration with independent judgement.",
      awardSculptureText6:
        "The award honours the overall achievement of its inners. ",
      saveTheDate: "Save the date",
      saveTheDateHost: "Evening host: Barbara Schöneberger",
      nominees: "Nominees",
      nomineesTextTooltip:
        "The nominees will be announced by the jury in the beginning of September​",
      videoBannerText:
        "Impressions of the Scope Award 2022 in Berlin // Moderation Barbara Schöneberger",
      winner: "Winner",
      winner2: "Winner",
      winners: "Winners",
      winnerMoreBtn: "More",
      winnerText:
        "The winners will be announced at the award ceremony on 18th of November. On the following day the winners will be published in Handelsblatt ​",
      nominationAltInvestmentRequirements:
        "Nomination Requirements and Methodology",
      nominationAltInvestmentRequirementsText:
        "Get to know more about the participation criteria, the selection process and download the methodology",
      categories: "Categories",
      assetManagersFunds: "Asset Manager / Funds",
      categoriesInvestment: "Categories Investment",
      categoriesAlternativeInvestment: "Categories Alternative Investment",
      categoriesCertificate: "Categories Certificate",
      showLess: "Show less",
      mediaPartner: "Media partner:",
      country: "Country:",
      germany: "GER",
      austria: "AUT",
      switzerland: "SUI",
      awards2022: {
        date: "18th of November 2021 in Berlin",
        subtitle:
          "The most established and distinguished award for funds and asset managers",
        subtitleAlternative:
          "The most established and distinguished award for asset managers",
        subtitleCertificate:
          "The most established and distinguished award for certificate issuer",
        investment: {
          categoriesText:
            "Scope Investment Awards 2022 will be presented in a total of 28 categories:",
          categoriesSubItem1: "16 asset manager categories",
          categoriesSubItem2: "12 fund categories",
          nomineesText:
            "In 28 categories for equity, bond and mixed funds, the most convincing fund concepts and best asset management performances of the year are honored with the Scope Award.",
          winnersText1:
            "Scope Awards - The best asset managers and funds honoured",
          winnersText2:
            "Together with the media partner Handelsblatt, the rating agency Scope presented the 16th Scope Awards.",
        },
        alternativeInvestment: {
          categoriesText:
            "Scope Investment Awards 2022 will be presented in a total of 28 categories:",
          categoriesSubItem1: "Institutional & Retail Real Estate",
          categoriesSubItem2: "ESG",
          categoriesSubItem3: "Alternative Investments",
          categoriesSubItem4: "Special Award",
          nomineesText:
            "In 21 categories for real estate, ESG and alternative investments the best asset management performances of the year are honored with the Scope Award.",
          winnersText1: "Scope Awards - The best asset managers honoured",
          winnersText2:
            "Together with the media partner Handelsblatt, the rating agency Scope presented the 16th Scope Awards.",
        },
        certificate: {
          categoriesText:
            "Scope Certificate Awards 2022 will be presented in a total of four categories.",
          nomineesText:
            "In four categories for primary market, secondary market, trading and ESG the best certificate issuers of the year are honored with the Scope Award.",
          winnersText1: "Scope Awards - The best certificate issuers honoured",
          winnersText2:
            "Together with the media partner Handelsblatt, the rating agency Scope presented the 16th Scope Awards.",
        },
        requirements: {
          text: "Nomination Requirements and Methodology",
        },
      },
      awards2023: {
        date: "17th of November 2022 in Berlin",
        subtitle:
          "The most established and distinguished award for funds and asset managers.",
        subtitleAlternative:
          "The most established and distinguished award for funds and asset managers.",
        subtitleCertificate:
          "The most established and distinguished award for funds and asset managers.",
        investment: {
          categoriesText:
            "Scope Investment Awards 2023 will be presented in a total of 30 categories:",
          categoriesSubItem1: "17 asset manager categories",
          categoriesSubItem2: "13 fund categories",
          nomineesText:
            "In 30 categories for equity, bond and mixed funds, the most convincing fund concepts and best asset management performances of the year are honored with the Scope Award.",
          winnersText1:
            "Scope Awards - The best asset managers and funds honoured",
          winnersText2:
            "Together with the media partner Handelsblatt, the rating agency Scope presented the 17th Scope Awards.",
        },
        alternativeInvestment: {
          categoriesText:
            "Scope Alternative Investment Awards 2023 will be presented in a total of 21 categories:",
          categoriesSubItem1: "Institutional & Retail Real Estate",
          categoriesSubItem2: "ESG",
          categoriesSubItem3: "Alternative Investments",
          categoriesSubItem4: "Special Award",
          nomineesText:
            "In 21 categories for real estate, ESG and alternative investments the best asset management performances of the year are honored with the Scope Award.",
          winnersText1: "Scope Awards - The best asset managers honoured",
          winnersText2:
            "Together with the media partner Handelsblatt, the rating agency Scope presented the 17th Scope Awards.",
        },
        certificate: {
          categoriesText:
            "Scope Certificate Awards 2023 will be presented in a total of four categories.",
          nomineesText:
            "In four categories for primary market, secondary market, trading and ESG the best certificate issuers of the year are honored with the Scope Award.",
          winnersText1: "Scope Awards - The best certificate issuers honoured",
          winnersText2:
            "Together with the media partner Handelsblatt, the rating agency Scope presented the 17th Scope Awards.",
        },
        requirements: {
          text: "Nomination Requirements and Methodology",
        },
      },
      awards2024: {
        date: "16. November 2023,  Frankfurt am Main",
        subtitle: "16. November 2023,  Frankfurt am Main",
        subtitleAlternative: "16. November 2023,  Frankfurt am Main",
        subtitleCertificate: "16. November 2023,  Frankfurt am Main",
        investment: {
          categoriesText:
            "Scope Investment Awards 2024 will be presented in a total of 30 categories:",
          categoriesSubItem1: "16 asset manager categories",
          categoriesSubItem2: "13 fund categories",
          categoriesSubItem3: "Special category: Fund Innovations",
          nomineesText:
            "In 30 categories for equity, bond and mixed funds, the most convincing fund concepts and best asset management performances of the year are honored with the Scope Award.",
          nomineesInfo:
            "The nominations will be announced at the beginning of September 2023.",
          winnersText1:
            "Scope Awards - The best asset managers and funds honoured",
          winnersText2:
            "Together with the media partner Handelsblatt, the rating agency Scope presented the 18th Scope Awards.",
          winnersInfo:
            "The winners will be announced at the Scope Award 2024 on 16 November 2023 in Frankfurt am Main.",
        },
        alternativeInvestment: {
          categoriesText:
            "Scope Alternative Investment Awards 2024 will be presented in a total of 20 categories:",
          categoriesSubItem1: "Real Estate",
          categoriesSubItem2: "ESG",
          categoriesSubItem3: "Alternative Investments",
          categoriesSubItem4: "Special Award",
          nomineesText:
            "In 20 categories for real estate, ESG and alternative investments the best asset management performances of the year are honored with the Scope Award.",
          nomineesInfo:
            "The nominations will be announced at the beginning of October 2023.",
          winnersText1: "Scope Awards - The best asset managers honoured",
          winnersText2:
            "Together with the media partner Handelsblatt, the rating agency Scope presented the 18th Scope Awards.",
          winnersInfo:
            "The winners will be announced at the Scope Award 2024 on 16 November 2023 in Frankfurt am Main.",
        },
        certificate: {
          categoriesText:
            "Scope Certificate Awards 2024 will be presented in a total of four categories.",
          nomineesText:
            "In four categories for primary market, secondary market, trading and ESG the best certificate issuers of the year are honored with the Scope Award.",
          nomineesInfo:
            "The nominations will be announced in the middle of October 2023.",
          winnersText1: "Scope Awards - The best certificate issuers honoured",
          winnersText2:
            "Together with the media partner Handelsblatt, the rating agency Scope presented the 18th Scope Awards.",
          winnersInfo:
            "The winners will be announced at the Scope Award 2024 on 16 November 2023 in Frankfurt am Main.",
        },
        requirements: {
          text: "Nomination Requirements and Methodology",
        },
      },
      awards2025: {
        date: "",
        subtitle: "",
        subtitleAlternative: "",
        subtitleCertificate: "",
        investment: {
          categoriesText:
            "Scope Investment Awards 2025 will be presented in a total of 30 categories:",
          categoriesSubItem1: "15 asset manager categories",
          categoriesSubItem2: "15 fund categories",
          categoriesSubItem3: "Special category: Fund Innovations",
          nomineesText:
            "In 30 categories for equity, bond and mixed funds, the most convincing fund concepts and best asset management performances of the year are honored with the Scope Award.",
          nomineesInfo:
            "The nominations will be announced at the beginning of September 2024",
          winnersText1:
            "Scope Awards - The best asset managers and funds honoured",
          winnersText2:
            "Together with the media partner Handelsblatt, the rating agency Scope presents the 19th Scope Awards.",
          winnersInfo: "The winners will be announced on 21. November 2024.",
        },
        alternativeInvestment: {
          categoriesText:
            "Scope Alternative Investment Awards 2025 will be presented in a total of 25 categories:",
          categoriesSubItem1: "Institutional Real Estate",
          categoriesSubItem2: "Retail Real Estate",
          categoriesSubItem3: "Real Estate",
          categoriesSubItem4: "ESG",
          categoriesSubItem5: "Alternative Investments",
          categoriesSubItem6: "ELTIF",
          categoriesSubItem7: "Special Award",
          nomineesText:
            "In 25 categories for real estate, ESG, alternative investments and ELTIF the best asset management performances of the year are honored with the Scope Award.",
          nomineesInfo:
            "The nominations will be announced at the beginning of October 2024.",
          winnersText1: "Scope Awards - The best asset managers honoured",
          winnersText2:
            "Together with the media partner Handelsblatt, the rating agency Scope presents the 19th Scope Awards.",
          winnersInfo: "The winners will be announced on 21 November 2024.",
        },
        certificate: {
          categoriesText:
            "Scope Certificate Awards 2025 will be presented in a total of four categories.",
          nomineesText:
            "In four categories for primary market, secondary market, trading and ESG the best certificate issuers of the year are honored with the Scope Award.",
          nomineesInfo:
            "The nominations will be announced in the middle of October 2024.",
          winnersText1: "Scope Awards - The best certificate issuers honoured",
          winnersText2:
            "Together with the media partner Handelsblatt, the rating agency Scope presented the 19th Scope Awards.",
          winnersInfo: "The winners will be announced on 21 November 2024.",
        },
        requirements: {
          text: "Nomination Requirements and Methodology",
        },
      },
    },
    scopeAwardsCategories: {
      assetManager: "Asset Manager",
      funds: "Funds",
      institutionalRealEstate: "Institutional Real Estate",
      retailRealEstate: "Retail Real Estate",
      realEstate: "Real Estate",
      alternativeInvestments: "Alternative Investments",
      specialAward: "Special Award",
      completeProductRangeIssuer: "Complete Product Range Issuer",
      bestAmCompleteProductRanges: "Complete Product Ranges",
      bestAmCompleteProductRange: "Complete Product Range",
      bestAmBoutiques: "Boutiques",
      bestAmBoutique: "Boutique",
      bestAmEsgCompleteProductRanges: "ESG Complete Product Ranges",
      bestFundEquitiesGermany: "Equities Germany",
      bestFundEquitiesNorthAmerica: "Equities North America",
      bestFundEquitiesJapan: "Equities Japan",
      bestEquitiesEurope: "Equities Europe",
      bestFundEquitiesGlobal: "Equities Global",
      bestFundEquitiesEmergingMarkets: "Equities Emerging Markets (Funds)",
      bestFundBalancedGlobalFlexible: "Balanced Global Flexible",
      bestFundBalancedGlobalDynamic: "Balanced Global Dynamic",
      bestFundBalancedGlobalConservative: "Balanced Global Conservative",
      bestAmEsgBoutiques: "ESG Boutique",
      bestAmEquities: "Equities",
      bestAmEsgEquities: "ESG Equities",
      bestAmThematicFunds: "Thematic Funds Equity",
      bestAmExchangeTradeFunds: "Exchange Traded Funds (ETFs)",
      bestFundBondsGlobalCurrency: "Bonds Global Currency",
      bestFundBondsEmergingMarketsHardCurrency:
        "Bonds Emerging Markets Hard Currency",
      bestAmBonds: "Bonds",
      bestAmEsgCompleteProductRange: "ESG Complete Product Range",
      bestAmEsgBoutique: "ESG Boutique",
      bestFundBondsEur: "Bonds EUR",
      bestFundInnovations: "Innovations",
      innovationsDisclaimer:
        "There are no nominations in the category, the winner will be awarded on 16.11.2023",
      bestFundBondsEurCorpHighYield: "Bonds EUR Corp. High Yield",
      bestFundBondsUsdCorpHighYield: "Bonds USD Corp. High Yield",
      bestFundBondsGlobalCorpHighYield: "Bond global Corp. High Yield",
      bestBalancedGlobalBalanced: "Balanced Global Balanced",
      bestAmEsgFixedIncome: "ESG Fixed Income",
      bestAmMultiStrategy: "Multi Strategy",
      bestAmEquitiesEmergingMarkets: "Equities Emerging Markets (AM)",
      bestAmMultiAsset: "Multi Asset",
      bestAmEmergingMarketsBonds: "Emerging Markets Bonds",
      bestAmEmergingMarketsEquities: "Emerging Markets Equities",
      bestAmConvertibleBonds: "Convertible Bonds",
      bestAmDividendEquities: "Dividend Equities",
      bestAmEtf: "Exchange Traded Funds (ETFs)",
      bestAmSmallCaps: "Small Caps",
      equitiesGermany: "Equities Germany",
      equitiesEurope: "Equities Europe",
      equitiesNorthAmerica: "Equities North America",
      equitiesGlobal: "Equities Global",
      equitiesThematicFunds: "Equities Thematic Funds",
      esgFunds: "ESG Funds",
      esgImpactFunds: "ESG Impact Funds",
      equitiesEmergingMarkets: "Equities Emerging Markets",
      bondsEUR: "Bonds EUR",
      bondsEURCorp: "Bonds EUR Corp. High Yield",
      bondsUsCorp: "Renten US Corp. High Yield",
      bondsEmergingMarkets: "Bonds Emerging Markets Hard Currency",
      bondsGlobalCurrency: "Bonds Global Currency",
      balancedGlobalFlexible: "Balanced Global Flexible",
      fundInnovations: "Fund Innovations",
      institutionalReGlobal: "Institutional Real Estate Global",
      institutionalReEurope: "Institutional Real Estate Europe",
      institutionalReGermany: "Institutional Real Estate Germany",
      institutionalReResidential: "Institutional Real Estate Residential",
      institutionalReSpecialist: "Institutional Real Estate Specialist",
      retailReGlobal: "Retail Real Estate Global",
      realEstateGlobal: "Real Estate Global",
      retailReEurope: "Retail Real Estate Europe",
      retailReGermany: "Retail Real Estate Germany",
      retailReResidential: "Retail Real Estate Residential",
      retailReSpecialist: "Retail Real Estate Specialist",
      realEstateSpecialist: "Real Estate Specialist",
      realEstateLogistics: "Real Estate Logistics",
      realEstateEssentialRetail: "Real Estate Essential Retail",
      esgInfrastructure: "ESG Infrastructure",
      esgRealEstate: "ESG Real Estate",
      esgCommercialRealEstate: "ESG Commercial Real Estate",
      esgResidentialRealEstate: "ESG Residential Real Estate",
      privateDebt: "Private Debt",
      privateEquity: "Private Equity",
      infrastructureDebt: "Infrastructure Debt",
      infrastructureEquity: "Infrastructure Equity",
      infrastructureEquityRenewableEnergy: "Infrastructure Equity - New Energy",
      transportation: "Transportation",
      eltif: "ELTIF",
      eltifPrivateMarketsPe: "ELTIF - Private Markets PE",
      eltifPrivateMarketsPd: "ELTIF - Private Markets PD",
      eltifPrivateMarketsInfrastructure:
        "ELTIF - Private Markets Infrastructure",
      eltifPrivateMarketsMixed: "ELTIF - Private Markets Mixed",
      eltifPrivateMarketsDiversified: "ELTIF - Private Markets Diversified",
      innovation: "Innovation",
      innovationRealAssets: "Innovation – Real Assets",
      innovationAif: "Innovation – Alternative Investments",
      primaryMarket: "Primary Market",
      secondaryMarket: "Secondary Market",
      trading: "Trading",
      esg: "ESG",
      specialMentionFundInnovations: "Special Mention Fund Innovations",
      rentenUsCorpHighYield: "Renten US Corp. High Yield",
      commodity: "Commodity",
      balancedGlobalConservative: "Balanced Global Conservative",
    },
    scopeAwardsNominees: {
      degussaBank: "Degussa Bank for Nachhaltigkeitfonds Akzentuiert",
      hanetfFundSpectrum: "HANetf for entire fund spectrum",
      unionInvestment: "Union Investment for KinderZukunftsFonds",
    },
    nominationAltInvestmentRequirements: {
      title: "Nomination Requirements",
      subtitle: "Alternative Investments",
      section1Title: "Participation criteria and selection process",
      section1Text1:
        "The best asset managers of retail and institutional investments in the alternative investments sector will be nominated and awarded",
      section1Text2:
        "Participation in the award is free of charge for the universe of alternative investments providers with European approval, either by invitation from Scope Fund Analysis GmbH or via their own application.",
      section2Title: "Your application",
      section2Text1:
        "The application process starts in the middle of July 2024.",
      section3Title: "The assessment criteria are as follows:",
      investmentManagement: "Investment Management",
      operationalAssetManagement: "Operational Asset Management",
      trackRecord: "Track Record",
      esg: "ESG",
      innovation: "Innovation",
      transparency: "Transparency",
      section3Text1:
        "The above evaluation criteria are subdivided into numerous sub-criteria, which are classified in a scorecard by the analysts of Scope Fund Analysis.",
      section3Text2:
        "The information is based on the answers to the questionnaires and other information provided by the asset manager as well as publicly available information.",
      section3Text3: "The application documents are available on request at:",
      email: "alternative-investment-awards@scopeanalysis.com.",
      section3ButtonText: "Download of Methodology 2025",
      file: "/Methodik_Scope_Alternative Investment_Award 2025_EN.pdf",
    },
    nominationInvestmentRequirements: {
      title: "Nomination Requirements & Methodology",
      subtitle: "Investment Fonds",
      section1Title: "Participation criteria and selection process",
      section1Text1:
        "The best equity, bonds and mixed funds are nominated and awarded in 14 categories plus the category “Fund Innovations” as well as asset managers in 15 categories.",
      section1Text2:
        "Participation in the award is free of charge for the providers in the respective fund universe (Germany, Austria and Switzerland), taking into account the funds approved for public sale.",
      section1Text3: "Download of Methodology 2025",
      section2Title: "The assessment criteria are among others:",
      section2List1: "Fund Management",
      section2List2: "Management Environment",
      section2List3: "Development of Investment Process",
      section2List4: "Implementation of Investment Process",
      section2List5: "Scope Fund Rating",
      section2Text1:
        "The above evaluation criteria are subdivided into numerous sup-criteria, which are classified in a scorecard by the analysts of Scope Fund Analysis.",
      section2Text2:
        "The assessment is based on the answers to the questionnaires and other information provided by the asset manager as well as publicly available information.",
      section2Text3: "For enquiries please contact:",
      email: "investment-awards@scopeanalysis.com.",
      file: "/Methodology Scope Investment Awards 2025.pdf",
    },
    nominationCertificateRequirements: {
      title: "Nomination Requirements",
      subtitle: "Certificate Fonds",
      section1Title: "Participation criteria and selection process",
      section2Title: "Best supplier Primary Market / Secondary Market",
      section2Text1:
        "The best issuers of the last twelve months are nominated and awarded in the categories primary market issuer and secondary market issuer.",
      section2Text2:
        "The nominations criteria are composed of the following elements:",
      section2List1: "Market positioning",
      section2List2: "Innovation",
      section2List3: "Product offers",
      section2List4: "Digitalisation strategy",
      section2List5: "Unique selling points",
      section2List6: "Customer service",
      section2Text3:
        "The above evaluation criteria are subdivided into sup-criteria, which are clasified in a scorecard by the analysts of Scope Fund Analysis.",
      section3Title: "Best Issuer Trading",
      section3Text1:
        "Scope nominates and awards issuers with the best pricing and the best range of services for leverage products and discount certificates with the Trading Award. Scope's nomination is based on a quantitative evaluation of the issuers' pricing in September 2024 and on other factors such as product range, market share and customer service.",
      section4Title: "Best Issuer ESG",
      section4Text1:
        "With the ESG Award Scope nominates and awards issuers that have performed best from an ESG perspective in the past year. The basis for Scope's nomination is, on the one hand, how sustainable the issuer is as a product provider. On the other hand, the decision is based on other factors such as the expansion of the product range to include sustainable products as well as the provision of information under sustainable aspects.",
      section5Title: "Participation Criteria",
      section5Text1:
        "Participation in the Award is free of charge for the universe of those registered in the “Bundesverband für strukturierte Wertpapiere (BSW)” on the address of Scope Fund Analysis GmbH.",
      section5Text2: "For enquiries please contact:",
      email: "zertifikate-awards@scopeanalysis.com",
    },
    portrait: {
      subtitle:
        "Scope Fund Analysis ist Teil der Scope Gruppe und bewertet Investmentfonds, Asset Manager und Zertifikate-Anbieter. Die aktuell mehr als 10.000 analysierten Anlageprodukte verwalten ein Vermögen von rund 5,3 Billionen Euro. Der überwiegende Teil der Bewertungen ist auf dem ScopeExplorer frei zugänglich.",
      subtitle2:
        " Das Unternehmen hat mehr als 20 Jahre Erfahrung mit der Beurteilung von Investments und Asset Managern. Die mehr als 25 Analystinnen und Analysten verteilen sich auf die Standorte Frankfurt am Main und Berlin.",
      section1Title: "Die Analyse-Aktivitäten gruppieren sich in vier Bereiche",
      section2Title: "Das Fonds Rating von Scope – zum Hintergrund",
      section2Text1:
        "Das Fonds Rating von Scope Fund Analysis ist ein objektives Gütesiegel, das Investoren bei der Einschätzung der Qualität eines Fonds unterstützt. Die meisten Fonds werden quantitativ beurteilt auf Grundlage ihrer bisher gezeigten Leistungen. Bei ausgewählten Fonds erfolgt zusätzlich eine qualitative Bewertung.",
      section2Text2:
        "Das Scope Fonds Rating hat einen prognostischen Charakter und trifft eine in die Zukunft gerichtete Aussage über die Qualität eines Produkts. Grundlage des Ratings ist ein mehrdimensionales Bewertungsmodell, das sowohl Performance- als auch Risiko-Aspekte reflektiert. Darüber hinaus können weitere Aspekte wie ESG oder die Qualität des Fondsmanagements in die Bewertung einfließen.",
      methodologies: "Methodologies",
    },
    subscriptionRating: {
      text1:
        "Ausführliche Informationen inklusive Ratingnoten und Ratingreport stehen angemeldeten Nutzern zur Verfügung. Bitte loggen Sie sich mit Ihren Nutzerdaten ein oder registrieren sich jetzt kostenfrei.",
    },
  },
});
