import { SearchAction, SearchState } from "./types";

const SET_AUTOCOMPLETE_VISIBILITY = "SET_AUTOCOMPLETE_VISIBILITY";
const SET_AUTOCOMPLETE_VALUE = "SET_AUTOCOMPLETE_VALUE";
const SET_SEARCH_LOADING = "SET_SEARCH_LOADING";
const SET_ADVANCED_SEARCH = "SET_ADVANCED_SEARCH";
const SET_FILTER_SEARCH = "SET_FILTER_SEARCH";
const SET_RESETTING = "SET_RESETTING";
const SET_FILTER_SEARCH_TAB = "SET_FILTER_SEARCH_TAB";
const SET_FILTER_SEARCH_COUNT_LOADING = "SET_FILTER_SEARCH_COUNT_LOADING";
const SET_FILTERS_LOADING = "SET_FILTERS_LOADING";
const SET_NEXT_SEARCH = "SET_NEXT_SEARCH";

export const setAutocompleteValue = (value: string): SearchAction => ({
  type: SET_AUTOCOMPLETE_VALUE,
  payload: { value },
});

export const setAutocompleteVisibility = (value: boolean): SearchAction => ({
  type: SET_AUTOCOMPLETE_VISIBILITY,
  payload: { value },
});

export const setSearchLoading = (value: boolean): SearchAction => ({
  type: SET_SEARCH_LOADING,
  payload: { value },
});

export const setAdvancedSearch = (value: boolean): SearchAction => ({
  type: SET_ADVANCED_SEARCH,
  payload: { value },
});

export const setFilterSearch = (value: boolean): SearchAction => ({
  type: SET_FILTER_SEARCH,
  payload: { value },
});

export const setResetting = (value: boolean): SearchAction => ({
  type: SET_RESETTING,
  payload: { value },
});

export const setFilterSearchTab = (value: number): SearchAction => ({
  type: SET_FILTER_SEARCH_TAB,
  payload: { value },
});

export const setFilterSearchCountLoading = (value: boolean): SearchAction => ({
  type: SET_FILTER_SEARCH_COUNT_LOADING,
  payload: { value },
});

export const setFiltersLoading = (value: boolean): SearchAction => ({
  type: SET_FILTERS_LOADING,
  payload: { value },
});

export const setNextSearch = (value: string): SearchAction => ({
  type: SET_NEXT_SEARCH,
  payload: { value: value?.replace("http://api/api/v1", "") || null },
});

export default (
  state: SearchState = {
    autocompleteValue: "",
    isAutocompleteVisible: false,
    isLoading: false,
    isAdvancedSearch: false,
    isFilterSearch: false,
    isResetting: false,
    filterSearchTab: 0,
    isFilterSearchCountLoading: false,
    isFiltersLoading: true,
    nextSearch: null,
  },
  { type, payload }: SearchAction
) => {
  switch (type) {
    case SET_AUTOCOMPLETE_VALUE:
      return {
        ...state,
        autocompleteValue: payload.value,
      };
    case SET_AUTOCOMPLETE_VISIBILITY:
      return {
        ...state,
        isAutocompleteVisible: payload.value,
      };
    case SET_SEARCH_LOADING:
      return {
        ...state,
        isLoading: payload.value,
      };
    case SET_ADVANCED_SEARCH:
      return {
        ...state,
        isAdvancedSearch: payload.value,
      };
    case SET_FILTER_SEARCH:
      return {
        ...state,
        isFilterSearch: payload.value,
      };
    case SET_RESETTING:
      return {
        ...state,
        isResetting: payload.value,
      };
    case "LOGOUT":
      return {
        ...state,
        isAdvancedSearch: false,
      };
    case SET_FILTER_SEARCH_TAB:
      return {
        ...state,
        filterSearchTab: payload.value,
      };
    case SET_FILTER_SEARCH_COUNT_LOADING:
      return {
        ...state,
        isFilterSearchCountLoading: payload.value,
      };
    case SET_FILTERS_LOADING:
      return {
        ...state,
        isFiltersLoading: payload.value,
      };
    case SET_NEXT_SEARCH:
      return {
        ...state,
        nextSearch: payload.value,
      };
    default:
      return state;
  }
};
