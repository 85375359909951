import { Action, ErrorAction } from "../domain/types";
import { AppAction, AppState } from "./types";
import { Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";

import { API_URL } from "./../domain/config";
import { ajax } from "rxjs/ajax";
import { getFormattedDate } from "../../utils/formats";
import { lang } from "../../lang/lang";
import { ofType } from "redux-observable";

const SET_APP_LOADING = "SET_APP_LOADING";
const SET_LICENSE_LOADING = "SET_LICENSE_LOADING";
const SET_IS_INITIALIZED = "SET_IS_INITIALIZED";
const SET_CURRENT_LANG = "SET_CURRENT_LANG";
const SET_COOKIE_INFO_VISIBILITY = "SET_COOKIE_INFO_VISIBILITY";
const FETCH_RECORD_DATE_REQUESTED = "FETCH_RECORD_DATE_REQUESTED";
const FETCH_RECORD_DATE_SUCCESSFUL = "FETCH_RECORD_DATE_SUCCESSFUL";
const FETCH_RECORD_DATE_REJECTED = "FETCH_RECORD_DATE_REJECTED";

export const setAppLoading = (value: boolean): AppAction => ({
  type: SET_APP_LOADING,
  payload: { value },
});

export const setLicenseLoading = (value: boolean): AppAction => ({
  type: SET_LICENSE_LOADING,
  payload: { value },
});

export const setIsInitialized = (value: boolean): AppAction => ({
  type: SET_IS_INITIALIZED,
  payload: { value },
});

export const setCurrentLang = (value: string): AppAction => ({
  type: SET_CURRENT_LANG,
  payload: { value },
});

export const setCookieInfoVisibility = (value: boolean): AppAction => ({
  type: SET_COOKIE_INFO_VISIBILITY,
  payload: { value },
});

export const fetchRecordDate = (): Action => ({
  type: FETCH_RECORD_DATE_REQUESTED,
});

const fetchRecordDateSuccessful = (date: string): AppAction => ({
  type: FETCH_RECORD_DATE_SUCCESSFUL,
  payload: { date },
});

const fetchRecordDateRejected = (error: TypeError): ErrorAction => ({
  type: FETCH_RECORD_DATE_REJECTED,
  error,
});

export default (
  state: AppState = {
    isAppLoading: true,
    isLicenseLoading: true,
    isInitialized: false,
    lang: lang.getLanguage(),
    isCookieInfoVisible: true,
    recordDate: "31.10.2019",
    recordDateUTC: 1569888000000,
  },
  { type, payload }: AppAction
) => {
  switch (type) {
    case SET_APP_LOADING:
      return {
        ...state,
        isAppLoading: payload.value,
      };
    case SET_LICENSE_LOADING:
      return {
        ...state,
        isLicenseLoading: payload.value,
      };
    case SET_IS_INITIALIZED:
      return {
        ...state,
        isInitialized: payload.value,
      };
    case SET_CURRENT_LANG:
      return {
        ...state,
        lang: payload.value,
      };
    case SET_COOKIE_INFO_VISIBILITY:
      return {
        ...state,
        isCookieInfoVisible: payload.value,
      };
    case FETCH_RECORD_DATE_SUCCESSFUL:
      const dateParts = payload.date.split(".");
      return {
        ...state,
        recordDate: payload.date,
        recordDateUTC: Date.UTC(
          parseInt(dateParts[2]),
          parseInt(dateParts[1]) - 1,
          parseInt(dateParts[0])
        ),
      };
    default:
      return state;
  }
};

export const fetchRecordDateEpic = (
  action$: Observable<AppAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_RECORD_DATE_REQUESTED),
    mergeMap(() =>
      ajax.get(`${API_URL}/record_date/`).pipe(
        mergeMap(({ response }) =>
          of(fetchRecordDateSuccessful(getFormattedDate(response.result)))
        ),
        catchError(error => of(fetchRecordDateRejected(error)))
      )
    )
  );
