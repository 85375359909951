import { ConferenceDetailsAction, ConferenceDetailsState } from "./types";

const SET_CONFERENCE_DETAILS_LOADING = "SET_CONFERENCE_DETAILS_LOADING";
const SET_CONFERENCE_REGISTER_LOADING = "SET_CONFERENCE_REGISTER_LOADING";

export const setConferenceDetailsLoading = (
  value: boolean
): ConferenceDetailsAction => ({
  type: SET_CONFERENCE_DETAILS_LOADING,
  payload: { value }
});

export const setConferenceRegisterLoading = (
  value: boolean
): ConferenceDetailsAction => ({
  type: SET_CONFERENCE_REGISTER_LOADING,
  payload: { value }
});

export default (
  state: ConferenceDetailsState = {
    isLoading: false,
    isRegisterLoading: false
  },
  { type, payload }: ConferenceDetailsAction
) => {
  switch (type) {
    case SET_CONFERENCE_DETAILS_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    case SET_CONFERENCE_REGISTER_LOADING:
      return {
        ...state,
        isRegisterLoading: payload.value
      };
    default:
      return state;
  }
};
