import { BASE_URL } from "./config";
import { lang } from "../lang/lang";
import urls from "./urls";
import { store } from "../redux";

export const getLangUrl = () => (lang.getLanguage() === "en" ? "/en" : "");

export const getUrl = () => getLangUrl();

export const getBaseUrl = () => `${BASE_URL}${getLangUrl()}`;

export const getCurrentPathname = () => window.location.pathname;

export const getCurrentSearchParams = () => window.location.search;

export const getCurrentUrl = () => `${getCurrentPathname()}`;

export const getCurrentLocation = () =>
  `${getCurrentPathname()}${getCurrentSearchParams()}`;

export const resize = () =>
  window.dispatchEvent(new window.CustomEvent("resize"));

export const isProd = () => window.location.hostname === "www.scopeexplorer.com" || window.location.hostname === 'app.scope-one.com';

// Check if location matches route regex
export const isHomepage = () => urls.HOME.reg.test(window.location.pathname);

export const isContactPage = () =>
  urls.CONTACT.reg.test(window.location.pathname);

export const isSearchResults = () =>
  urls.SEARCH_RESULTS.reg.test(window.location.pathname);

export const isAdvancedSearchResults = () =>
  urls.ADV_SEARCH_RESULTS.reg.test(window.location.pathname);

export const isFilterSearch = () =>
  urls.FILTER_SEARCH_RESULTS.reg.test(window.location.pathname);

export const isSurveyPage = () =>
  urls.SURVEY_ESG_FUND.reg.test(window.location.pathname) ||
  urls.SURVEY_ESG_COMPANY.reg.test(window.location.pathname) ||
  urls.SURVEY_EQUITY_FUND.reg.test(window.location.pathname) ||
  urls.SURVEY_SOVEREIGN_BOND_FUND.reg.test(window.location.pathname) ||
  urls.SURVEY_CORPORATE_BOND_FUND.reg.test(window.location.pathname) ||
  urls.SURVEY_AGGREGATE_BOND_FUND.reg.test(window.location.pathname) ||
  urls.SURVEY_MULTI_ASSET_FUND.reg.test(window.location.pathname) ||
  urls.DEKA_SURVEY.reg.test(window.location.pathname);

export const isEsgSurveyPage = () => urls.SURVEY_ESG_FUND.reg.test(window.location.pathname);

export const isSurveyPageAllowed = () => {
  const { isDvmSurveyAllowed,
    isEsgCompanySurveyAllowed,
    isEsgFundSurveyAllowed,
    isEsgArticleUploadAllowed,
    isCompanyEsgAdmin } = store.getState().domain?.auth;
  return isDvmSurveyAllowed
    || isEsgCompanySurveyAllowed
    || isEsgFundSurveyAllowed
    || isEsgArticleUploadAllowed
    || isCompanyEsgAdmin;
};

export const isHomeComponent = () =>
  urls.HOME.reg.test(window.location.pathname) ||
  urls.CONFIRM.reg.test(window.location.pathname) ||
  urls.RESET.reg.test(window.location.pathname);

export const scrollToRef = ref => {
  const startingY = window.pageYOffset;
  const diff = ref.current.getBoundingClientRect().top - 80;
  const duration = 250;
  let start;

  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    const time = timestamp - start;
    const percent = Math.min(time / duration, 1);

    window.scrollTo(0, startingY + diff * percent);

    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
};

export const isAnyModalVisible = props =>
  Object.values(props).some(modalVisibility => modalVisibility === true);

export const gtmPushEvent = props => {
  const win = window as any;
  win.dataLayer.push(props);
};

export const scrollTo = (element, to, duration) => {
  if (duration <= 0) return;
  const difference = to - element.scrollTop;
  const perTick = (difference / duration) * 10;

  setTimeout(function () {
    element.scrollTop = element.scrollTop + perTick;
    if (element.scrollTop === to) return;
    scrollTo(element, to, duration - 10);
  }, 10);
};

export const getLocation = (link: string) =>
  link.replace(/\/en(\/|$)/, "/").replace(/\/de(\/|$)/, "/");

export const getRedirectLocation = (route: string) =>
  `${lang.getLanguage() === "en" ? "/en" : ""}${getLocation(route)}`;
