import { RemoveFundKeyFactsModalAction, RemoveFundKeyFactsModalState, } from "./types";

const SET_REMOVE_FUND_KEY_FACTS_MODAL_VISIBILITY = "SET_REMOVE_FUND_KEY_FACTS_MODAL_VISIBILITY";

export const setRemoveFundKeyFactsModalVisibility = (
  value: boolean,
  values?: any,
  item?: any,
  sectionNumber?: Number,
): RemoveFundKeyFactsModalAction => ({
  type: SET_REMOVE_FUND_KEY_FACTS_MODAL_VISIBILITY,
  payload: { value, values, item, sectionNumber }
});

export default (
  state: RemoveFundKeyFactsModalState = {
    isVisible: false,
    values: null,
    item: null,
    sectionNumber: null,
  },
  { type, payload }: RemoveFundKeyFactsModalAction
) => {
  switch (type) {
    case SET_REMOVE_FUND_KEY_FACTS_MODAL_VISIBILITY:
      return {
        ...state,
        isVisible: payload.value,
        item: payload.item,
        sectionNumber: payload.sectionNumber,
        values: payload.values,
      };
    default:
      return state;
  }
};
