import { AifBenchmarksAction, AifBenchmarksState } from "./types";

const SET_AIF_BENCHMAKRS_VISIBILITY = "SET_AIF_BENCHMAKRS_VISIBILITY";

export const setAifBenchmarksVisibility = (
  value: boolean
): AifBenchmarksAction => ({
  type: SET_AIF_BENCHMAKRS_VISIBILITY,
  payload: { value }
});

export default (
  state: AifBenchmarksState = {
    isVisible: true
  },
  { type, payload }: AifBenchmarksAction
) => {
  switch (type) {
    case SET_AIF_BENCHMAKRS_VISIBILITY:
      return {
        ...state,
        isVisible: payload.value
      };
    default:
      return state;
  }
};
