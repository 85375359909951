import { StateValue } from "../types";

export const API_URL = "/api/v1";

export const getReqOptions = () => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  withCredentials: true,
});

export const chartsUrls = {
  performanceChart: "detail_performance_chart",
  peergroupComparisonChart: "detail_peergroup_comparison_chart",
  crashDrawdownChart: "crash_drawdown_index_performance_chart",
  riskReturnChart: "detail_risk_return_chart",
  fundRatingChart: "detail_fund_rating_chart",
  classDistributionChart: "detail_class_distribution_chart",
  distributionChart: "detail_distribution_chart",
  evolutionOfRiskChart: "detail_evolution_of_risk_chart",
  comparisonChart: "comparison_chart",
};

export const getStatisticsHeader = (state: StateValue) => ({
  "Statistics-Fund-Details-Source": state.value.domain.route.fundEntranceSrc,
});
