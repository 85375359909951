import { API_URL, getReqOptions } from "./config";
import { Action, ErrorAction, Meta, SurveyAction, SurveyState } from "./types";
import { from, Observable, of } from "rxjs";
import { catchError, filter, mergeMap, switchMap, takeUntil } from "rxjs/operators";
import {
  getIsinId,
  getSurveyType,
  setSurveyAnswers,
  setSurveyValues,
  setSurveyRequiredAnswersValues,
} from "./../../utils/survey";

import { StateValue } from "../types";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { setAssetManagerSummaryExportLoading, setEsgCompanySummaryExportLoading, setEsgFundSummaryExportLoading, setIsinsTableLoading, setSurveyExportLoading, setSurveyLoading } from "../ui/survey";
import { addErrorToast, addSuccessToast } from "../ui/toasts";
import { lang } from "../../lang/lang";
import { setUploadArticle89ModalUploading } from "../ui/uploadArticle89Modal";
import { getDashedDate } from "../../utils/formats";

const FETCH_SURVEY_REQUESTED = "FETCH_SURVEY_REQUESTED";
const FETCH_SURVEY_SUCCESSFUL = "FETCH_SURVEY_SUCCESSFUL";
const FETCH_SURVEY_REJECTED = "FETCH_SURVEY_REJECTED";

const SEND_SURVEY_REQUESTED = "SEND_SURVEY_REQUESTED";
const SEND_SURVEY_SUCCESSFUL = "SEND_SURVEY_SUCCESSFUL";
const SEND_SURVEY_REJECTED = "SEND_SURVEY_REJECTED";

const SEND_SURVEY_VERSION_REQUESTED = "SEND_SURVEY_VERSION_REQUESTED";
const SEND_SURVEY_VERSION_SUCCESSFUL = "SEND_SURVEY_VERSION_SUCCESSFUL";
const SEND_SURVEY_VERSION_REJECTED = "SEND_SURVEY_VERSION_REJECTED";

const FETCH_QUESTIONS_COUNT_REQUESTED = "FETCH_QUESTIONS_COUNT_REQUESTED";
const FETCH_QUESTIONS_COUNT_SUCCESSFUL = "FETCH_QUESTIONS_COUNT_SUCCESSFUL";
const FETCH_QUESTIONS_COUNT_REJECTED = "FETCH_QUESTIONS_COUNT_REJECTED";

const SURVEY_EXPORT_REQUESTED = "SURVEY_EXPORT_REQUESTED";
const SURVEY_EXPORT_SUCCESSFUL = "SURVEY_EXPORT_SUCCESSFUL";
const SURVEY_EXPORT_REJECTED = "SURVEY_EXPORT_REJECTED";

const ASSET_MANAGER_SUMMARY_EXPORT_REQUESTED = "ASSET_MANAGER_SUMMARY_EXPORT_REQUESTED"
const ASSET_MANAGER_SUMMARY_EXPORT_SUCCESSFUL = "ASSET_MANAGER_SUMMARY_EXPORT_SUCCESSFUL"
const ASSET_MANAGER_SUMMARY_EXPORT_REJECTED = "ASSET_MANAGER_SUMMARY_EXPORT_REJECTED"

const ESG_FUND_SUMMARY_EXPORT_REQUESTED = "ESG_FUND_SUMMARY_EXPORT_REQUESTED"
const ESG_FUND_SUMMARY_EXPORT_SUCCESSFUL = "ESG_FUND_SUMMARY_EXPORT_SUCCESSFUL"
const ESG_FUND_SUMMARY_EXPORT_REJECTED = "ESG_FUND_SUMMARY_EXPORT_REJECTED"

const ESG_COMPANY_SUMMARY_EXPORT_REQUESTED = "ESG_COMPANY_SUMMARY_EXPORT_REQUESTED"
const ESG_COMPANY_SUMMARY_EXPORT_SUCCESSFUL = "ESG_COMPANY_SUMMARY_EXPORT_SUCCESSFUL"
const ESG_COMPANY_SUMMARY_EXPORT_REJECTED = "ESG_COMPANY_SUMMARY_EXPORT_REJECTED"

const UPLOAD_ARTICLE89_REQUESTED = "UPLOAD_ARTICLE89_REQUESTED";
const UPLOAD_ARTICLE89_SUCCESSFUL = "UPLOAD_ARTICLE89_SUCCESSFUL";
const UPLOAD_ARTICLE89_REJECTED = "UPLOAD_ARTICLE89_REJECTED";

const FETCH_SURVEYS_VERSIONS_REQUESTED = "FETCH_SURVEYS_VERSIONS_REQUESTED";
const FETCH_SURVEYS_VERSIONS_SUCCESSFUL = "FETCH_SURVEYS_VERSIONS_SUCCESSFUL";
const FETCH_SURVEYS_VERSIONS_REJECTED = "FETCH_SURVEYS_VERSIONS_REJECTED";

const CLEAN_SURVEY = "CLEAN_SURVEY";
const SET_SURVEY_ROOT_ANSWERS = "SET_SURVEY_ROOT_ANSWERS";
const SET_SURVEY_REQUIRED_ANSWERS = "SET_SURVEY_REQUIRED_ANSWERS"

export const sendSurvey = (values: any, meta?: Meta): SurveyAction => ({
  type: SEND_SURVEY_REQUESTED,
  payload: { values, meta },
});
const sendSurveySuccessful = (values: any): SurveyAction => ({
  type: SEND_SURVEY_SUCCESSFUL,
  payload: values
});
const sendSurveyRejected = (error?: TypeError): ErrorAction => ({
  type: SEND_SURVEY_REJECTED,
  error,
});

export const sendSurveyVersion = (values: any, meta?: Meta): SurveyAction => ({
  type: SEND_SURVEY_VERSION_REQUESTED,
  payload: { values, meta },
});
const sendSurveyVersionSuccessful = (): Action => ({
  type: SEND_SURVEY_VERSION_SUCCESSFUL,
});
const sendSurveyVersionRejected = (error?: TypeError): ErrorAction => ({
  type: SEND_SURVEY_VERSION_REJECTED,
  error,
});

export const fetchSurvey = (page: number, meta?: Meta): SurveyAction => ({
  type: FETCH_SURVEY_REQUESTED,
  payload: { page, meta },
});
const fetchSurveySuccessful = (survey: any): SurveyAction => ({
  type: FETCH_SURVEY_SUCCESSFUL,
  payload: { survey },
});
const fetchSurveyRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_SURVEY_REJECTED,
  error,
});

export const fetchQuestionsCount = (form: string): SurveyAction => ({
  type: FETCH_QUESTIONS_COUNT_REQUESTED,
  payload: { form },
});
const fetchQuestionsCountSuccessful = (value: number): SurveyAction => ({
  type: FETCH_QUESTIONS_COUNT_SUCCESSFUL,
  payload: { value },
});
const fetchQuestionsCountRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_QUESTIONS_COUNT_REJECTED,
  error,
});


export const surveyExport = (): SurveyAction => ({
  type: SURVEY_EXPORT_REQUESTED,
  payload: {},
});
const surveyExportSuccessful = (): Action => ({
  type: SURVEY_EXPORT_SUCCESSFUL,
});
const surveyExportRejected = (error?: TypeError): ErrorAction => ({
  type: SURVEY_EXPORT_REJECTED,
  error,
});

export const assetManagerSummaryExport = (): SurveyAction => ({
  type: ASSET_MANAGER_SUMMARY_EXPORT_REQUESTED,
  payload: {},
});
const assetManagerSummaryExportSuccessful = (): Action => ({
  type: ASSET_MANAGER_SUMMARY_EXPORT_SUCCESSFUL,
});
const assetManagerSummaryExportRejected = (error?: TypeError): ErrorAction => ({
  type: ASSET_MANAGER_SUMMARY_EXPORT_REJECTED,
  error,
});


export const esgFundSummaryExport = (): SurveyAction => ({
  type: ESG_FUND_SUMMARY_EXPORT_REQUESTED,
  payload: {},
});
const esgFundSummaryExportSuccessful = (): Action => ({
  type: ESG_FUND_SUMMARY_EXPORT_SUCCESSFUL,
});
const esgFundSummaryExportRejected = (error?: TypeError): ErrorAction => ({
  type: ESG_FUND_SUMMARY_EXPORT_REJECTED,
  error,
});


export const esgCompanySummaryExport = (): SurveyAction => ({
  type: ESG_COMPANY_SUMMARY_EXPORT_REQUESTED,
  payload: {},
});
const esgCompanySummaryExportSuccessful = (): Action => ({
  type: ESG_COMPANY_SUMMARY_EXPORT_SUCCESSFUL,
});
const esgCompanySummaryExportRejected = (error?: TypeError): ErrorAction => ({
  type: ESG_COMPANY_SUMMARY_EXPORT_REJECTED,
  error,
});

export const uploadArticle89 = (
  // TODO: add proper type for article 8/9 upload (type, file)
  values: any, meta: Meta
): SurveyAction => ({
  type: UPLOAD_ARTICLE89_REQUESTED,
  payload: { values, meta },
});
const uploadArticle89Successful = (): Action => ({
  type: UPLOAD_ARTICLE89_SUCCESSFUL,
});
const uploadArticle89Rejected = (error?: TypeError): ErrorAction => ({
  type: UPLOAD_ARTICLE89_REJECTED,
  error,
});

export const fetchSurveysVersions = (): SurveyAction => ({
  type: FETCH_SURVEYS_VERSIONS_REQUESTED,
  payload: {},
});
const fetchSurveysVersionsSuccessful = (surveysVersions: any): SurveyAction => ({
  type: FETCH_SURVEYS_VERSIONS_SUCCESSFUL,
  payload: { surveysVersions },
});
const fetchSurveysVersionsRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_SURVEYS_VERSIONS_REJECTED,
  error,
});

export const cleanSurvey = (): Action => ({
  type: CLEAN_SURVEY,
});

export const setSurveyRootAnswers = (values): SurveyAction => ({
  type: SET_SURVEY_ROOT_ANSWERS,
  payload: { values },
});

export const setSurveyRequiredAnswers = (values): SurveyAction => ({
  type: SET_SURVEY_REQUIRED_ANSWERS,
  payload: { values },
});

export default (
  state: SurveyState = {
    survey: {},
    surveyRootAnswers: [],
    surveyRequiredAnswers: [],
    surveyPageCount: 0,
    questionsCount: 0,
    surveyType: null,
    surveysVersions: null,
    wasNewVersionCreated: null,
    version: null,
    surveyOverviewPdfs: [],
  },
  { type, payload }: SurveyAction
) => {
  switch (type) {
    case FETCH_SURVEY_SUCCESSFUL:
      return {
        ...state,
        survey: {
          ...state.survey,
          [payload.survey.page]: payload.survey,
        },
        surveyPageCount: payload.survey.page_count,
        surveyType: payload.survey.survey_type,
        surveyOverviewPdfs: payload.survey.overview_pdfs,
      };
    case FETCH_QUESTIONS_COUNT_SUCCESSFUL:
      return {
        ...state,
        questionsCount: payload.value,
      };
    case CLEAN_SURVEY:
      return {
        survey: {},
        surveyRootAnswers: [],
        surveyPageCount: 0,
        questionsCount: 0,
        surveyType: null,
      };
    case SET_SURVEY_ROOT_ANSWERS:
      return {
        ...state,
        surveyRootAnswers: {
          ...state.surveyRootAnswers,
          ...payload.values,
        },
      };
    case SET_SURVEY_REQUIRED_ANSWERS:
      return {
        ...state,
        surveyRequiredAnswers: {
          ...state.surveyRequiredAnswers,
          ...payload.values,
        },
      };
    case FETCH_SURVEYS_VERSIONS_SUCCESSFUL:
      return {
        ...state,
        surveysVersions: payload.surveysVersions
      }
    case SEND_SURVEY_SUCCESSFUL:
      return {
        ...state,
        wasNewVersionCreated: payload.was_new_version_created,
        version: payload.version
      }
    default:
      return state;
  }
};

export const fetchSurveyEpic = (
  action$: Observable<SurveyAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_SURVEY_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .get(
          `${API_URL}/surveys/am_survey/?page=${payload.page
          }&form=${getSurveyType()
          }${(getSurveyType() === 'esg-fund'
            || getSurveyType() === 'esg-company'
            || getSurveyType() === 'deka-esg'
            || getIsinId(state) === null
            ? ""
            : `&isins_version=${getIsinId(state)}`)}`
        )
        .pipe(
          mergeMap(({ response }) => {
            const shouldLoadNextPage = payload.page + 1 <= response.page_count;

            setSurveyValues(response.questions);

            const actions = [
              fetchSurveySuccessful(response),
              setSurveyRootAnswers(
                setSurveyAnswers(
                  response.questions,
                  state.value.form?.survey?.values
                )
              ),
              setSurveyRequiredAnswers(
                setSurveyRequiredAnswersValues(
                  response.questions,
                  state.value.form?.survey?.values
                )
              ),
              fetchQuestionsCount(getSurveyType()),
              setSurveyLoading(false),
            ];

            if (shouldLoadNextPage) {
              actions.push(fetchSurvey(payload.page + 1));
            }
            return of(...actions);
          }),
          catchError(error => {
            return of(setSurveyLoading(false), fetchSurveyRejected(error));
          })
        )
    )
  );

export const fetchQuestionsCountEpic = (
  action$: Observable<SurveyAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_QUESTIONS_COUNT_REQUESTED),
    mergeMap(() =>
      ajax
        .get(
          `${API_URL}/surveys/am_survey/questions_count/?form=${getSurveyType()}`
        )
        .pipe(
          mergeMap(({ response }) =>
            of(fetchQuestionsCountSuccessful(response.questions_count))
          ),
          catchError(error => of(fetchQuestionsCountRejected(error)))
        )
    )
  );

export const sendSurveyEpic = (
  action$: Observable<SurveyAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(SEND_SURVEY_REQUESTED),
    mergeMap(({ payload }) => {
      const { resolve, reject }: Meta = payload.meta;
      let newValues = {};
      const regex = /^answer-[0-9]+-[0-9]+/;
      Object.keys(payload.values).forEach(key => {
        if (regex.test(key)) {
          const newKey = key.replace("answer-", "").slice(0, -2);
          newValues[newKey]
            ? newValues[newKey].push(payload.values[key])
            : (newValues[newKey] = [payload.values[key]]);
        } else {
          const newKey = key.replace("answer-", "");
          newValues[newKey] = payload.values[key];
        }
      });
      newValues["fund_names"] = newValues["113"];
      newValues["isins"] = newValues["114"];
      newValues["isins_version"] = getIsinId(state);

      return ajax
        .post(
          `${API_URL}/surveys/am_survey/?survey_type=${getSurveyType()
          }${(getSurveyType() === 'esg-fund'
            || getSurveyType() === 'esg-company'
            || getSurveyType() === 'deka-esg'
            ? ""
            : `&isins_version=${getIsinId(state)}`)}`,
          { ...newValues },
          getReqOptions()
        )
        .pipe(
          mergeMap(({ response }) => {
            if (resolve) resolve();
            return of(sendSurveySuccessful(response));
          }),
          catchError(error => {
            if (reject) reject(error);
            return of(sendSurveyRejected(error));
          })
        );
    })
  );

export const sendSurveyVersionEpic = (
  action$: Observable<SurveyAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(SEND_SURVEY_VERSION_REQUESTED),
    mergeMap(({ payload }) => {
      const { resolve, reject }: Meta = payload.meta;
      let newValues = {};
      const regex = /^answer-[0-9]+-[0-9]+/;
      Object.keys(payload.values).forEach(key => {
        if (regex.test(key)) {
          const newKey = key.replace("answer-", "").slice(0, -2);
          newValues[newKey]
            ? newValues[newKey].push(payload.values[key])
            : (newValues[newKey] = [payload.values[key]]);
        } else {
          const newKey = key.replace("answer-", "");
          newValues[newKey] = payload.values[key];
        }
      });
      newValues["fund_names"] = newValues["113"];
      newValues["isins"] = newValues["114"];
      newValues["isins_version"] = getIsinId(state);
      return ajax
        .post(
          `${API_URL}/surveys/am_survey/?survey_type=${getSurveyType()
          }&version_answers=true${(getSurveyType() === 'esg-fund'
            || getSurveyType() === 'esg-company'
            || getSurveyType() === 'deka-esg'
            ? ""
            : `&isins_version=${getIsinId(state)}`)}`,
          { ...newValues },
          getReqOptions()
        )
        .pipe(
          mergeMap(() => {
            if (resolve) resolve();
            return of(
              addSuccessToast("Survey saved."),
              sendSurveyVersionSuccessful());
          }),
          catchError(error => {
            if (reject) reject(error);
            return of(sendSurveyVersionRejected(error));
          })
        );
    })
  );

export const surveyExportEpic = (
  action$: Observable<SurveyAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(SURVEY_EXPORT_REQUESTED),
    mergeMap(() => {
      const fetchObservable = from(
        // TODO: ?form=deka-esg hardcoded for now
        fetch(`${API_URL}/surveys/am_survey/dvm/complete_summary/?form=deka-esg
        `, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
      );
      return fetchObservable.pipe(
        switchMap(response =>
          response.status === 200 ? response.arrayBuffer() : "error"
        ),
        mergeMap(response => {
          if (response !== "error") {
            const blob = response;
            const downloadLink = window.document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(
              new Blob([blob], {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            downloadLink.download =
              `${lang.esgSurvey.filename}${getDashedDate()}`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            return of(setSurveyExportLoading(false), surveyExportSuccessful());
          } else {
            return of(
              setSurveyExportLoading(false),
              addErrorToast("Error: unable to export questionnaires.")
            );
          }
        }),
        takeUntil(
          action$.pipe(
            filter(({ type }) => type === SURVEY_EXPORT_REQUESTED)
          )
        ),
        catchError(error =>
          of(setSurveyExportLoading(false), surveyExportRejected(error))
        )
      );
    }),
    catchError(error =>
      of(setSurveyExportLoading(false), surveyExportRejected(error))
    )
  );

export const assetManagerSummaryExportEpic = (
  action$: Observable<SurveyAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(ASSET_MANAGER_SUMMARY_EXPORT_REQUESTED),
    mergeMap(() => {
      const fetchObservable = from(
        // TODO: ?form=deka-esg hardcoded for now
        fetch(`${API_URL}/surveys/am_survey/dvm/asset_manager_summary/?form=deka-esg
        `, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
      );
      return fetchObservable.pipe(
        switchMap(response =>
          response.status === 200 ? response.arrayBuffer() : "error"
        ),
        mergeMap(response => {
          if (response !== "error") {
            const blob = response;
            const downloadLink = window.document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(
              new Blob([blob], {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            downloadLink.download =
              `AM ${getDashedDate()}`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            return of(setAssetManagerSummaryExportLoading(false), assetManagerSummaryExportSuccessful());
          } else {
            return of(
              setAssetManagerSummaryExportLoading(false),
              addErrorToast("Error: unable to export questionnaires.")
            );
          }
        }),
        takeUntil(
          action$.pipe(
            filter(({ type }) => type === ASSET_MANAGER_SUMMARY_EXPORT_REQUESTED)
          )
        ),
        catchError(error =>
          of(setAssetManagerSummaryExportLoading(false), assetManagerSummaryExportRejected(error))
        )
      );
    }),
    catchError(error =>
      of(setAssetManagerSummaryExportLoading(false), assetManagerSummaryExportRejected(error))
    )
  );

export const esgFundSummaryExportEpic = (
  action$: Observable<SurveyAction>
): Observable<Action> =>
  action$.pipe(
    ofType(ESG_FUND_SUMMARY_EXPORT_REQUESTED),
    mergeMap(() => {
      const fetchObservable = from(
        fetch(`${API_URL}/surveys/am_survey/esg/complete_summary/?form=esg-fund`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
      );
      return fetchObservable.pipe(
        switchMap(response =>
          response.status === 200 ? response.arrayBuffer() : "error"
        ),
        mergeMap(response => {
          if (response !== "error") {
            const blob = response;
            const downloadLink = window.document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(
              new Blob([blob], {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            downloadLink.download =
              `ESG FUND ${getDashedDate()}`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            return of(setEsgFundSummaryExportLoading(false), esgFundSummaryExportSuccessful());
          } else {
            return of(
              setEsgFundSummaryExportLoading(false),
              addErrorToast("Error: unable to export questionnaires.")
            );
          }
        }),
        takeUntil(
          action$.pipe(
            filter(({ type }) => type === ESG_FUND_SUMMARY_EXPORT_REQUESTED)
          )
        ),
        catchError(error =>
          of(setEsgFundSummaryExportLoading(false), esgFundSummaryExportRejected(error))
        )
      );
    }),
    catchError(error =>
      of(setEsgFundSummaryExportLoading(false), esgFundSummaryExportRejected(error))
    )
  );

export const esgCompanySummaryExportEpic = (
  action$: Observable<SurveyAction>,
): Observable<Action> =>
  action$.pipe(
    ofType(ESG_COMPANY_SUMMARY_EXPORT_REQUESTED),
    mergeMap(() => {
      const fetchObservable = from(
        fetch(`${API_URL}/surveys/am_survey/esg/complete_summary/?form=esg-company`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
      );
      return fetchObservable.pipe(
        switchMap(response =>
          response.status === 200 ? response.arrayBuffer() : "error"
        ),
        mergeMap(response => {
          if (response !== "error") {
            const blob = response;
            const downloadLink = window.document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(
              new Blob([blob], {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            downloadLink.download =
              `ESG COMPANY ${getDashedDate()}`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            return of(setEsgCompanySummaryExportLoading(false), esgCompanySummaryExportSuccessful());
          } else {
            return of(
              setEsgCompanySummaryExportLoading(false),
              addErrorToast("Error: unable to export questionnaires.")
            );
          }
        }),
        takeUntil(
          action$.pipe(
            filter(({ type }) => type === ESG_COMPANY_SUMMARY_EXPORT_REQUESTED)
          )
        ),
        catchError(error =>
          of(setEsgCompanySummaryExportLoading(false), esgCompanySummaryExportRejected(error))
        )
      );
    }),
    catchError(error =>
      of(setEsgCompanySummaryExportLoading(false), esgCompanySummaryExportRejected(error))
    )
  );

export const uploadArticle89Epic = (
  action$: Observable<SurveyAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(UPLOAD_ARTICLE89_REQUESTED),
    mergeMap(({ payload }) => {
      const { resolve, reject }: Meta = payload.meta;
      const { values } = payload;
      return ajax
        .post(
          `${API_URL}/company_info/esg/articles/`,
          {
            file: values?.file
          },
          getReqOptions()
        )
        .pipe(
          mergeMap(({ response }) => {
            if (resolve) resolve(response);
            return of(
              setUploadArticle89ModalUploading(false),
              uploadArticle89Successful()
            );
          }),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === UPLOAD_ARTICLE89_REQUESTED)
            )
          ),
          catchError(error => {
            if (reject) reject(error);
            return of(
              setUploadArticle89ModalUploading(false),
              uploadArticle89Rejected(error)
            );
          })
        );

    })
  );

export const fetchSurveysVersionsEpic = (
  action$: Observable<SurveyAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_SURVEYS_VERSIONS_REQUESTED),
    mergeMap(() => {
      return ajax
        .get(
          `${API_URL}/surveys/am_survey/isin_versions/`,
          getReqOptions(),
        )
        .pipe(
          mergeMap(({ response }) => {
            return of(
              fetchSurveysVersionsSuccessful(response),
              setIsinsTableLoading(false),
            );
          }),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === FETCH_SURVEYS_VERSIONS_REQUESTED)
            )
          ),
          catchError(error => {
            return of(
              fetchSurveysVersionsRejected(error),
              setIsinsTableLoading(false),
            );
          })
        );
    })
  );
