import { ConferenceModalAction, ConferenceModalState } from "./types";

const SET_CONFERENCE_MODAL_LOADING = "SET_CONFERENCE_MODAL_LOADING";
const SET_CONFERENCE_MODAL_VISIBILITY = "SET_CONFERENCE_MODAL_VISIBILITY";
const SET_CONFERENCE_ID = "SET_CONFERENCE_ID";

export const setConferenceModalLoading = (
  value: boolean
): ConferenceModalAction => ({
  type: SET_CONFERENCE_MODAL_LOADING,
  payload: { value }
});

export const setConferenceModalVisibility = (
  value: boolean
): ConferenceModalAction => ({
  type: SET_CONFERENCE_MODAL_VISIBILITY,
  payload: { value }
});

export const setConferenceId = (id: number): ConferenceModalAction => ({
  type: SET_CONFERENCE_ID,
  payload: { id }
});

export default (
  state: ConferenceModalState = {
    isLoading: false,
    isVisible: false,
    conferenceId: null
  },
  { type, payload }: ConferenceModalAction
) => {
  switch (type) {
    case SET_CONFERENCE_MODAL_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    case SET_CONFERENCE_MODAL_VISIBILITY:
      return {
        ...state,
        isVisible: payload.value
      };
    case SET_CONFERENCE_ID:
      return {
        ...state,
        conferenceId: payload.id
      };
    default:
      return state;
  }
};
