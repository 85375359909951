import { SurveyAction, SurveyState } from "./types";

const SET_SURVEY_PAGE = "SET_SURVEY_PAGE";
const SET_SURVEY_LOADING = "SET_SURVEY_LOADING";
const SET_SURVEY_SAVING = "SET_SURVEY_SAVING";
const SET_SURVEY_EXPORT_LOADING = "SET_SURVEY_EXPORT_LOADING";
const SET_ASSET_MANAGER_SUMMARY_EXPORT_LOADING = "SET_ASSET_MANAGER_SUMMARY_EXPORT_LOADING";
const SET_ESG_FUND_SUMMARY_EXPORT_LOADING = "SET_ESG_FUND_SUMMARY_EXPORT_LOADING";
const SET_ESG_COMPANY_SUMMARY_EXPORT_LOADING = "SET_ESG_COMPANY_SUMMARY_EXPORT_LOADING";
const SET_ISINS_TABLE_LOADING = "SET_ISINS_TABLE_LOADING";

export const setSurveyPage = (value: number): SurveyAction => ({
  type: SET_SURVEY_PAGE,
  payload: { value },
});

export const setSurveyLoading = (value: boolean): SurveyAction => ({
  type: SET_SURVEY_LOADING,
  payload: { value },
});

export const setSurveySaving = (value: boolean): SurveyAction => ({
  type: SET_SURVEY_SAVING,
  payload: { value },
});

export const setSurveyExportLoading = (value: boolean): SurveyAction => ({
  type: SET_SURVEY_EXPORT_LOADING,
  payload: { value }
})

export const setAssetManagerSummaryExportLoading = (value: boolean): SurveyAction => ({
  type: SET_ASSET_MANAGER_SUMMARY_EXPORT_LOADING,
  payload: { value }
})

export const setEsgFundSummaryExportLoading = (value: boolean): SurveyAction => ({
  type: SET_ESG_FUND_SUMMARY_EXPORT_LOADING,
  payload: { value }
})

export const setEsgCompanySummaryExportLoading = (value: boolean): SurveyAction => ({
  type: SET_ESG_COMPANY_SUMMARY_EXPORT_LOADING,
  payload: { value }
})

export const setIsinsTableLoading = (value: boolean): SurveyAction => ({
  type: SET_ISINS_TABLE_LOADING,
  payload: { value },
})

export default (
  state: SurveyState = {
    page: 1,
    isLoading: true,
    isExportLoading: false,
    isSurveySaving: false,
    isAssetManagerSummaryExportLoading: false,
    isEsgFundSummaryExportLoading: false,
    isEsgCompanySummaryExportLoading: false,
    isIsinsTableLoading: true,
  },
  { type, payload }: SurveyAction
) => {
  switch (type) {
    case SET_SURVEY_PAGE:
      return {
        ...state,
        page: payload.value,
      };
    case SET_SURVEY_LOADING:
      return {
        ...state,
        isLoading: payload.value,
      };
    case SET_SURVEY_SAVING:
      return {
        ...state,
        isSurveySaving: payload.value,
      };
    case SET_SURVEY_EXPORT_LOADING:
      return {
        ...state,
        isExportLoading: payload.value,
      };
    case SET_ASSET_MANAGER_SUMMARY_EXPORT_LOADING:
      return {
        ...state,
        isAssetManagerSummaryExportLoading: payload.value,
      }
    case SET_ESG_FUND_SUMMARY_EXPORT_LOADING:
      return {
        ...state,
        isEsgFundSummaryExportLoading: payload.value,
      }
    case SET_ESG_COMPANY_SUMMARY_EXPORT_LOADING:
      return {
        ...state,
        isEsgCompanySummaryExportLoading: payload.value,
      }
    case SET_ISINS_TABLE_LOADING:
      return {
        ...state,
        isIsinsTableLoading: payload.value,
      }
    default:
      return state;
  }
};
