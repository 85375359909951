import { ConfirmModalAction, ConfirmModalState } from "./types";

const SET_CONFIRM_MODAL_VISIBILITY = "SET_CONFIRM_MODAL_VISIBILITY";
const SET_CONFIRM_MODAL_TEXT = "SET_CONFIRM_MODAL_TEXT";
const SET_CONFIRM_MODAL_CONFIRM_ACTION = "SET_CONFIRM_MODAL_CONFIRM_ACTION";
const SET_CONFIRM_MODAL_CANCEL_ACTION = "SET_CONFIRM_MODAL_CANCEL_ACTION";

export const setConfirmModalVisibility = (
  value: boolean
): ConfirmModalAction => ({
  type: SET_CONFIRM_MODAL_VISIBILITY,
  payload: { value }
});

export const setConfirmModalText = (text: string): ConfirmModalAction => ({
  type: SET_CONFIRM_MODAL_TEXT,
  payload: { text }
});

export const setConfirmModalConfirmAction = (
  confirm: Function
): ConfirmModalAction => ({
  type: SET_CONFIRM_MODAL_CONFIRM_ACTION,
  payload: { confirm }
});

export const setConfirmModalCancelAction = (
  cancel: Function
): ConfirmModalAction => ({
  type: SET_CONFIRM_MODAL_CANCEL_ACTION,
  payload: { cancel }
});

export default (
  state: ConfirmModalState = {
    isVisible: false,
    text: "Are you sure?",
    confirm: () => {},
    cancel: () => {}
  },
  { type, payload }: ConfirmModalAction
) => {
  switch (type) {
    case SET_CONFIRM_MODAL_VISIBILITY:
      return {
        ...state,
        isVisible: payload.value
      };
    case SET_CONFIRM_MODAL_TEXT:
      return {
        ...state,
        text: payload.text
      };
    case SET_CONFIRM_MODAL_CONFIRM_ACTION:
      return {
        ...state,
        confirm: payload.confirm
      };
    case SET_CONFIRM_MODAL_CANCEL_ACTION:
      return {
        ...state,
        cancel: payload.cancel
      };
    default:
      return state;
  }
};
