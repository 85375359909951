import {
  Action,
  ErrorAction,
  Fund,
  FundsAction,
  FundsDetailsState,
} from "../types";
import { Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";

import { API_URL, getStatisticsHeader } from "../config";
import { StateValue } from "../../types";
import { ajax } from "rxjs/ajax";
import { fetchActiveCharts } from "../charts";
import { ofType } from "redux-observable";
import { push } from "connected-react-router";
import { setDetailsLoading } from "../../ui/fundDetails";
import { setFundsIds } from "../../ui/comparisonModal";
import { setPrimaryCompareFund } from "./common";
import { lang } from "../../../lang/lang";
import {setFundEntranceSrc} from "../route";

const FETCH_FUND_REQUESTED = "FETCH_FUND_REQUESTED";
const FETCH_FUND_SUCCESSFUL = "FETCH_FUND_SUCCESSFUL";
const FETCH_FUND_REJECTED = "FETCH_FUND_REJECTED";

const CLEAR_FUND_DETAILS_STATE = "CLEAR_FUND_DETAILS_STATE";

export const fetchFund = (id: number): FundsAction => ({
  type: FETCH_FUND_REQUESTED,
  payload: { id },
});

export const fetchFundSuccessful = (fund: Fund): FundsAction => ({
  type: FETCH_FUND_SUCCESSFUL,
  payload: { fund },
});

export const fetchFundRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_FUND_REJECTED,
  error,
});

export const clearFundDetailsState = (): Action => ({
  type: CLEAR_FUND_DETAILS_STATE,
});

export default (
  state: FundsDetailsState = {
    funds: {},
  },
  { type, payload }: FundsAction
) => {
  switch (type) {
    case FETCH_FUND_SUCCESSFUL:
      if (payload.fund) {
        return {
          ...state,
          funds: {
            ...state.funds,
            [payload.fund.id]: payload.fund,
          },
        };
      } else {
        return state;
      }
    case CLEAR_FUND_DETAILS_STATE:
      return {
        ...state,
        funds: {},
      };
    default:
      return state;
  }
};

export const fetchFundEpic = (
  action$: Observable<FundsAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_FUND_REQUESTED),
    mergeMap(({ payload }) => {
      return ajax
        .get(
          `${API_URL}/funds/${payload.id}/?lang=${lang.getLanguage()}`,
          getStatisticsHeader(state)
        )
        .pipe(
          mergeMap(({ response }) =>
            of(
              setDetailsLoading(false),
              setFundsIds(response.tranche_id, 0),
              setPrimaryCompareFund(response),
              fetchFundSuccessful(response),
              fetchActiveCharts(
                response.id,
                response.tranche_id,
                response.fund_id,
                response.peergroup
              ),
              setFundEntranceSrc("")
            )
          ),
          catchError(error => {
            if (error.status === 404) {
              return of(push(`/404`));
            }
            return of(setDetailsLoading(false), fetchFundRejected(error));
          })
        );
    })
  );