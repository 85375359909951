import { API_URL, getReqOptions } from "./config";
import {
  Action,
  AssetManager,
  AssetManagerFund,
  AssetManagerList,
  AssetManagersAction,
  AssetManagersState,
  ErrorAction,
  MacroOverviewsList,
  Meta,
  News,
} from "./types";
import { Observable, of } from "rxjs";
import { addErrorToast, addSuccessToast } from "./../ui/toasts";
import { catchError, filter, mergeMap, takeUntil } from "rxjs/operators";
import {
  getChangedValues,
  updateEsg,
  updateMacroOverview,
  updatePortfolioManagers,
} from "../../utils/assetManagers";
import {
  setAssetManagerInfoLoading,
  setAssetManagerNewsLoading,
  setMacroOverviewsLoading,
  setTopAssetManagerFundsLoading,
} from "../ui/assetManagerDetails";
import { setFundsTableLoading, setFundsTableTotal } from "../ui/fundsTable";

import { StateValue } from "../types";
import { ajax } from "rxjs/ajax";
import { getAssetManagerFundsQueryParams } from "../../utils/routing";
import { lang } from "../../lang/lang";
import { ofType } from "redux-observable";
import { setAmNewsModalUploading } from "./../ui/assetManagerNewsModal";
import { setAssetManagersLoading } from "../ui/assetManagerList";

const FETCH_ASSET_MANAGERS_REQUESTED = "FETCH_ASSET_MANAGERS_REQUESTED";
const FETCH_ASSET_MANAGERS_SUCCESSFUL = "FETCH_ASSET_MANAGERS_SUCCESSFUL";
const FETCH_ASSET_MANAGERS_REJECTED = "FETCH_ASSET_MANAGERS_REJECTED";
const FETCH_ASSET_MANAGER_REQUESTED = "FETCH_ASSET_MANAGER_REQUESTED";
const FETCH_ASSET_MANAGER_SUCCESSFUL = "FETCH_ASSET_MANAGER_SUCCESSFUL";
const FETCH_ASSET_MANAGER_REJECTED = "FETCH_ASSET_MANAGER_REJECTED";
const FETCH_ASSET_MANAGER_FUNDS_REQUESTED =
  "FETCH_ASSET_MANAGER_FUNDS_REQUESTED";
const FETCH_ASSET_MANAGER_FUNDS_SUCCESSFUL =
  "FETCH_ASSET_MANAGER_FUNDS_SUCCESSFUL";
const FETCH_ASSET_MANAGER_FUNDS_REJECTED = "FETCH_ASSET_MANAGER_FUNDS_REJECTED";
const FETCH_ASSET_MANAGER_NEWS_REQUESTED = "FETCH_ASSET_MANAGER_NEWS_REQUESTED";
const FETCH_ASSET_MANAGER_NEWS_SUCCESSFUL =
  "FETCH_ASSET_MANAGER_NEWS_SUCCESSFUL";
const FETCH_ASSET_MANAGER_NEWS_REJECTED = "FETCH_ASSET_MANAGER_NEWS_REJECTED";
const FETCH_SINGLE_ASSET_MANAGER_NEWS_REQUESTED =
  "FETCH_SINGLE_ASSET_MANAGER_NEWS_REQUESTED";
const FETCH_SINGLE_ASSET_MANAGER_NEWS_SUCCESSFUL =
  "FETCH_SINGLE_ASSET_MANAGER_NEWS_SUCCESSFUL";
const FETCH_SINGLE_ASSET_MANAGER_NEWS_REJECTED =
  "FETCH_SINGLE_ASSET_MANAGER_NEWS_REJECTED";
const FETCH_TOP_ASSET_MANAGER_FUNDS_REQUESTED =
  "FETCH_TOP_ASSET_MANAGER_FUNDS_REQUESTED";
const FETCH_TOP_ASSET_MANAGER_FUNDS_SUCCESSFUL =
  "FETCH_TOP_ASSET_MANAGER_FUNDS_SUCCESSFUL";
const FETCH_TOP_ASSET_MANAGER_FUNDS_REJECTED =
  "FETCH_TOP_ASSET_MANAGER_FUNDS_REJECTED";
const SAVE_ASSET_MANAGER_REQUESTED = "SAVE_ASSET_MANAGER_REQUESTED";
const SAVE_ASSET_MANAGER_SUCCESSFUL = "SAVE_ASSET_MANAGER_SUCCESSFUL";
const SAVE_ASSET_MANAGER_REJECTED = "SAVE_ASSET_MANAGER_REJECTED";

const ADD_ASSET_MANAGER_NEWS_REQUESTED = "ADD_ASSET_MANAGER_NEWS_REQUESTED";
const ADD_ASSET_MANAGER_NEWS_SUCCESSFUL = "ADD_ASSET_MANAGER_NEWS_SUCCESSFUL";
const ADD_ASSET_MANAGER_NEWS_REJECTED = "ADD_ASSET_MANAGER_NEWS_REJECTED";
const EDIT_ASSET_MANAGER_NEWS_REQUESTED = "EDIT_ASSET_MANAGER_NEWS_REQUESTED";
const EDIT_ASSET_MANAGER_NEWS_SUCCESSFUL = "EDIT_ASSET_MANAGER_NEWS_SUCCESSFUL";
const EDIT_ASSET_MANAGER_NEWS_REJECTED = "EDIT_ASSET_MANAGER_NEWS_REJECTED";
const REMOVE_ASSET_MANAGER_NEWS_REQUESTED =
  "REMOVE_ASSET_MANAGER_NEWS_REQUESTED";
const REMOVE_ASSET_MANAGER_NEWS_SUCCESSFUL =
  "REMOVE_ASSET_MANAGER_NEWS_SUCCESSFUL";
const REMOVE_ASSET_MANAGER_NEWS_REJECTED = "REMOVE_ASSET_MANAGER_NEWS_REJECTED";

const FETCH_MACRO_OVERVIEWS_REQUESTED = "FETCH_MACRO_OVERVIEWS_REQUESTED";
const FETCH_MACRO_OVERVIEWS_SUCCESSFUL = "FETCH_MACRO_OVERVIEWS_SUCCESSFUL";
const FETCH_MACRO_OVERVIEWS_REJECTED = "FETCH_MACRO_OVERVIEWS_REJECTED";

export const fetchAssetManagers = (): Action => ({
  type: FETCH_ASSET_MANAGERS_REQUESTED,
});

export const fetchAssetManagersSuccessful = (
  assetManagers: AssetManagerList[]
): AssetManagersAction => ({
  type: FETCH_ASSET_MANAGERS_SUCCESSFUL,
  payload: { assetManagers },
});

export const fetchAssetManagersRejected = (error: TypeError): ErrorAction => ({
  type: FETCH_ASSET_MANAGERS_REJECTED,
  error,
});

export const fetchAssetManager = (id: number): AssetManagersAction => ({
  type: FETCH_ASSET_MANAGER_REQUESTED,
  payload: { id },
});

export const fetchAssetManagerSuccessful = (
  assetManager: AssetManager
): AssetManagersAction => ({
  type: FETCH_ASSET_MANAGER_SUCCESSFUL,
  payload: { assetManager },
});

export const fetchAssetManagerRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_ASSET_MANAGER_REJECTED,
  error,
});

export const fetchAssetManagerFunds = (id: number): AssetManagersAction => ({
  type: FETCH_ASSET_MANAGER_FUNDS_REQUESTED,
  payload: { id },
});

export const fetchAssetManagerFundsSuccessful = (
  funds: AssetManagerFund[],
  id: number
): AssetManagersAction => ({
  type: FETCH_ASSET_MANAGER_FUNDS_SUCCESSFUL,
  payload: { funds, id },
});

export const fetchAssetManagerFundsRejected = (
  error?: TypeError
): ErrorAction => ({
  type: FETCH_ASSET_MANAGER_FUNDS_REJECTED,
  error,
});

export const fetchAssetManagerNews = (id: number): AssetManagersAction => ({
  type: FETCH_ASSET_MANAGER_NEWS_REQUESTED,
  payload: { id },
});

export const fetchAssetManagerNewsSuccessful = (
  news: News[],
  id: number
): AssetManagersAction => ({
  type: FETCH_ASSET_MANAGER_NEWS_SUCCESSFUL,
  payload: { news, id },
});

export const fetchAssetManagerNewsRejected = (
  error?: TypeError
): ErrorAction => ({
  type: FETCH_ASSET_MANAGER_NEWS_REJECTED,
  error,
});

export const fetchSingleAssetManagerNews = (
  id: number
): AssetManagersAction => ({
  type: FETCH_SINGLE_ASSET_MANAGER_NEWS_REQUESTED,
  payload: { id },
});

export const fetchSingleAssetManagerNewsSuccessful = (
  singleNews: News
): AssetManagersAction => ({
  type: FETCH_SINGLE_ASSET_MANAGER_NEWS_SUCCESSFUL,
  payload: { singleNews },
});

export const fetchSingleAssetManagerNewsRejected = (
  error: TypeError
): ErrorAction => ({
  type: FETCH_SINGLE_ASSET_MANAGER_NEWS_REJECTED,
  error,
});

export const fetchTopAssetManagerFunds = (id: number): AssetManagersAction => ({
  type: FETCH_TOP_ASSET_MANAGER_FUNDS_REQUESTED,
  payload: { id },
});

export const fetchTopAssetManagerFundsSuccessful = (
  funds: AssetManagerFund[],
  id: number
): AssetManagersAction => ({
  type: FETCH_TOP_ASSET_MANAGER_FUNDS_SUCCESSFUL,
  payload: { funds, id },
});

export const fetchTopAssetManagerFundsRejected = (
  error?: TypeError
): ErrorAction => ({
  type: FETCH_TOP_ASSET_MANAGER_FUNDS_REJECTED,
  error,
});

export const saveAssetManager = (
  values: AssetManager,
  meta?: Meta
): AssetManagersAction => ({
  type: SAVE_ASSET_MANAGER_REQUESTED,
  payload: { values, meta },
});

export const saveAssetManagerSuccessful = (): Action => ({
  type: SAVE_ASSET_MANAGER_SUCCESSFUL,
});

export const saveAssetManagerRejected = (error?: TypeError): ErrorAction => ({
  type: SAVE_ASSET_MANAGER_REJECTED,
  error,
});

export const addAssetManagerNews = (
  values: News,
  meta: Meta
): AssetManagersAction => ({
  type: ADD_ASSET_MANAGER_NEWS_REQUESTED,
  payload: { values, meta },
});

export const addAssetManagerNewsSuccessful = (): Action => ({
  type: ADD_ASSET_MANAGER_NEWS_SUCCESSFUL,
});

export const addAssetManagerNewsRejected = (
  error?: TypeError
): ErrorAction => ({
  type: ADD_ASSET_MANAGER_NEWS_REJECTED,
  error,
});

export const editAssetManagerNews = (
  values: News,
  meta: Meta
): AssetManagersAction => ({
  type: EDIT_ASSET_MANAGER_NEWS_REQUESTED,
  payload: { values, meta },
});

export const editAssetManagerNewsSuccessful = (): Action => ({
  type: EDIT_ASSET_MANAGER_NEWS_SUCCESSFUL,
});

export const editAssetManagerNewsRejected = (
  error?: TypeError
): ErrorAction => ({
  type: EDIT_ASSET_MANAGER_NEWS_REJECTED,
  error,
});

export const removeAssetManagerNews = (id: number): AssetManagersAction => ({
  type: REMOVE_ASSET_MANAGER_NEWS_REQUESTED,
  payload: { id },
});

export const removeAssetManagerNewsSuccessful = (): Action => ({
  type: REMOVE_ASSET_MANAGER_NEWS_SUCCESSFUL,
});

export const removeAssetManagerNewsRejected = (
  error?: TypeError
): ErrorAction => ({
  type: REMOVE_ASSET_MANAGER_NEWS_REJECTED,
  error,
});

export const fetchMacroOverviews = (): Action => ({
  type: FETCH_MACRO_OVERVIEWS_REQUESTED,
});

export const fetchMacroOverviewsSuccessful = (
  macroOverviews: MacroOverviewsList[]
): AssetManagersAction => ({
  type: FETCH_MACRO_OVERVIEWS_SUCCESSFUL,
  payload: { macroOverviews },
});

export const fetchMacroOverviewsRejected = (error: TypeError): ErrorAction => ({
  type: FETCH_MACRO_OVERVIEWS_REJECTED,
  error,
});

export default (
  state: AssetManagersState = {
    assetManagers: {},
    assetManagersList: [],
    news: {},
    chartData: {},
    macroOverviewsList: [],
  },
  { type, payload }: AssetManagersAction
) => {
  switch (type) {
    case FETCH_ASSET_MANAGERS_SUCCESSFUL:
      return {
        ...state,
        assetManagersList: payload.assetManagers,
      };
    case FETCH_MACRO_OVERVIEWS_SUCCESSFUL:
      return {
        ...state,
        macroOverviewsList: payload.macroOverviews,
      };
    case FETCH_ASSET_MANAGER_SUCCESSFUL:
      if (payload.assetManager) {
        return {
          ...state,
          assetManagers: {
            ...state.assetManagers,
            [payload.assetManager.id]: {
              ...state.assetManagers[payload.assetManager.id],
              ...payload.assetManager,
            },
          },
        };
      } else {
        return state;
      }
    case FETCH_ASSET_MANAGER_FUNDS_SUCCESSFUL:
      if (payload.funds && payload.id) {
        return {
          ...state,
          assetManagers: {
            ...state.assetManagers,
            [payload.id]: {
              ...state.assetManagers[payload.id],
              funds: payload.funds,
            },
          },
        };
      } else {
        return state;
      }
    case FETCH_ASSET_MANAGER_NEWS_SUCCESSFUL:
      if (payload.news && payload.id) {
        return {
          ...state,
          assetManagers: {
            ...state.assetManagers,
            [payload.id]: {
              ...state.assetManagers[payload.id],
              news: payload.news,
            },
          },
        };
      } else {
        return state;
      }
    case FETCH_SINGLE_ASSET_MANAGER_NEWS_SUCCESSFUL:
      if (payload.singleNews) {
        return {
          ...state,
          news: {
            ...state.news,
            [payload.singleNews.id]: payload.singleNews,
          },
        };
      } else {
        return state;
      }
    case FETCH_TOP_ASSET_MANAGER_FUNDS_SUCCESSFUL:
      if (payload.funds && payload.id) {
        return {
          ...state,
          assetManagers: {
            ...state.assetManagers,
            [payload.id]: {
              ...state.assetManagers[payload.id],
              topAssetManagerFunds: payload.funds,
            },
          },
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export const fetchAssetManagersEpic = (
  action$: Observable<AssetManagersAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_ASSET_MANAGERS_REQUESTED),
    mergeMap(() =>
      ajax.get(`${API_URL}/company_info/?lang=${lang.getLanguage()}`).pipe(
        mergeMap(({ response }) =>
          of(
            setAssetManagersLoading(false),
            fetchAssetManagersSuccessful(response)
          )
        ),
        takeUntil(
          action$.pipe(
            filter(({ type }) => type === FETCH_ASSET_MANAGERS_REQUESTED)
          )
        ),
        catchError(error =>
          of(setAssetManagersLoading(false), fetchAssetManagersRejected(error))
        )
      )
    )
  );

export const fetchMacroOverviewsEpic = (
  action$: Observable<AssetManagersAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_MACRO_OVERVIEWS_REQUESTED),
    mergeMap(() =>
      ajax.get(`${API_URL}/macro_overviews/?lang=${lang.getLanguage()}`).pipe(
        mergeMap(({ response }) =>
          of(
            setMacroOverviewsLoading(false),
            fetchMacroOverviewsSuccessful(response)
          )
        ),
        takeUntil(
          action$.pipe(
            filter(({ type }) => type === FETCH_MACRO_OVERVIEWS_REQUESTED)
          )
        ),
        catchError(error =>
          of(setMacroOverviewsLoading(false), fetchMacroOverviewsRejected(error))
        )
      )
    )
  );

export const fetchAssetManagerEpic = (
  action$: Observable<AssetManagersAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_ASSET_MANAGER_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .get(
          `${API_URL}/company_info/${payload.id}/?lang=${lang.getLanguage()}`,
          {
            pragma: "no-cache",
          }
        )
        .pipe(
          mergeMap(({ response }) =>
            of(
              fetchAssetManagerSuccessful(response),
              setAssetManagerInfoLoading(false)
            )
          ),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === FETCH_ASSET_MANAGER_REQUESTED)
            )
          ),
          catchError(error =>
            of(
              setAssetManagerInfoLoading(false),
              fetchAssetManagerRejected(error)
            )
          )
        )
    )
  );

export const fetchAssetManagerFundsEpic = (
  action$: Observable<AssetManagersAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_ASSET_MANAGER_FUNDS_REQUESTED),
    mergeMap(({ payload }) => {
      let queryParams = getAssetManagerFundsQueryParams(state.value.form);
      if (queryParams.indexOf("ordering") === -1) {
        queryParams += "&ordering=-ranking_points";
      }

      return ajax
        .get(`${API_URL}/company_funds/${payload.id}/${queryParams}`)
        .pipe(
          mergeMap(({ response }) =>
            of(
              setFundsTableLoading(false),
              setFundsTableTotal(response.count),
              fetchAssetManagerFundsSuccessful(response.results, payload.id)
            )
          ),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === FETCH_ASSET_MANAGER_FUNDS_REQUESTED)
            )
          ),
          catchError(error =>
            of(
              setFundsTableLoading(false),
              fetchAssetManagerFundsRejected(error)
            )
          )
        );
    })
  );

export const fetchAssetManagerNewsEpic = (
  action$: Observable<AssetManagersAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_ASSET_MANAGER_NEWS_REQUESTED),
    mergeMap(({ payload }) => {
      return ajax
        .get(
          `${API_URL}/company_news/?company_info=${
            payload.id
          }&lang=${lang.getLanguage()}`
        )
        .pipe(
          mergeMap(({ response }) =>
            of(
              setAssetManagerNewsLoading(false),
              fetchAssetManagerNewsSuccessful(response.results, payload.id)
            )
          ),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === FETCH_ASSET_MANAGER_NEWS_REQUESTED)
            )
          ),
          catchError(error =>
            of(
              setAssetManagerNewsLoading(false),
              fetchAssetManagerNewsRejected(error)
            )
          )
        );
    })
  );

export const fetchSingleAssetManagerNewsEpic = (
  action$: Observable<AssetManagersAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_SINGLE_ASSET_MANAGER_NEWS_REQUESTED),
    mergeMap(({ payload }) =>
      ajax.get(`${API_URL}/company_news/${payload.id}/`).pipe(
        mergeMap(({ response }) =>
          of(
            setAssetManagerNewsLoading(false),
            fetchSingleAssetManagerNewsSuccessful(response)
          )
        ),
        takeUntil(
          action$.pipe(
            filter(
              ({ type }) => type === FETCH_SINGLE_ASSET_MANAGER_NEWS_REQUESTED
            )
          )
        ),
        catchError(error =>
          of(
            setAssetManagerNewsLoading(false),
            fetchSingleAssetManagerNewsRejected(error)
          )
        )
      )
    )
  );

export const fetchTopAssetManagerFundsEpic = (
  action$: Observable<AssetManagersAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_TOP_ASSET_MANAGER_FUNDS_REQUESTED),
    mergeMap(({ payload }) => {
      if (payload.id) {
        return ajax.get(`${API_URL}/top_company_funds/${payload.id}/`).pipe(
          mergeMap(({ response }) =>
            of(
              setTopAssetManagerFundsLoading(false),
              fetchTopAssetManagerFundsSuccessful(response.results, payload.id)
            )
          ),
          takeUntil(
            action$.pipe(
              filter(
                ({ type }) => type === FETCH_TOP_ASSET_MANAGER_FUNDS_REQUESTED
              )
            )
          ),
          catchError(error =>
            of(
              setTopAssetManagerFundsLoading(false),
              fetchTopAssetManagerFundsRejected(error)
            )
          )
        );
      } else {
        return of(
          setTopAssetManagerFundsLoading(false),
          fetchTopAssetManagerFundsRejected()
        );
      }
    })
  );

export const saveAssetManagerEpic = (
  action$: Observable<AssetManagersAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(SAVE_ASSET_MANAGER_REQUESTED),
    mergeMap(({ payload }) => {
      const { resolve, reject }: Meta | any = payload.meta || {};
      if (payload.values) {
        const form = state.value.form.assetManagerProfile;
        const macroOverviewForm = state.value.form.macroOverview;
        const selectedFundsForm = state.value.form.selectedFunds;
        const changedValues = getChangedValues(
          payload.values,
          form,
          macroOverviewForm,
          selectedFundsForm
        );

        updateEsg(changedValues);
        updatePortfolioManagers(changedValues);
        updateMacroOverview(changedValues);

        return ajax
          .patch(
            `${API_URL}/company_info/${
              state.value.domain.auth.assetManagerId
            }/?lang=${lang.getLanguage()}`,
            { ...changedValues },
            { ...getReqOptions(), pragma: "no-cache" }
          )
          .pipe(
            mergeMap(({ response }) => {
              if (resolve) resolve(response);
              return of(
                saveAssetManagerSuccessful(),
                fetchAssetManager(state.value.domain.auth.assetManagerId)
              );
            }),
            takeUntil(
              action$.pipe(
                filter(({ type }) => type === SAVE_ASSET_MANAGER_REQUESTED)
              )
            ),
            catchError(error => {
              if (reject) reject(error);
              return of(saveAssetManagerRejected(error));
            })
          );
      } else {
        if (reject) reject();
        return of(saveAssetManagerRejected());
      }
    })
  );

export const addAssetManagerNewsEpic = (
  action$: Observable<AssetManagersAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(ADD_ASSET_MANAGER_NEWS_REQUESTED),
    mergeMap(({ payload }) => {
      const { resolve, reject }: Meta = payload.meta;
      const { values } = payload;
      if (state.value.domain.auth.assetManagerId) {
        const data = {
          ...values,
          company_info: state.value.domain.auth.assetManagerId,
          ...(values.date ? { date: values.date } : {}),
          ...(values.lang ? { lang: values.lang.value } : {}),
        };

        return ajax
          .post(
            `${API_URL}/company_news/?lang=${lang.getLanguage()}`,
            data,
            getReqOptions()
          )
          .pipe(
            mergeMap(({ response }) => {
              if (resolve) resolve(response);
              return of(
                setAmNewsModalUploading(false),
                addAssetManagerNewsSuccessful()
              );
            }),
            takeUntil(
              action$.pipe(
                filter(({ type }) => type === ADD_ASSET_MANAGER_NEWS_REQUESTED)
              )
            ),
            catchError(error => {
              if (reject) reject(error);
              return of(
                setAmNewsModalUploading(false),
                addAssetManagerNewsRejected(error)
              );
            })
          );
      } else {
        if (reject) reject();
        return of(
          setAmNewsModalUploading(false),
          addAssetManagerNewsRejected()
        );
      }
    })
  );

export const editAssetManagerNewsEpic = (
  action$: Observable<AssetManagersAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(EDIT_ASSET_MANAGER_NEWS_REQUESTED),
    mergeMap(({ payload }) => {
      const { resolve, reject }: Meta = payload.meta;
      const { values } = payload;
      if (state.value.domain.auth.assetManagerId) {
        Object.keys(values).forEach(key => {
          if (
            values[key] === null ||
            (key === "image" && !values[key].base64_string) ||
            (key === "pdf_file" && !values[key].base64_string)
          ) {
            delete values[key];
          }
        });

        const data = {
          ...values,
          company_info: state.value.domain.auth.assetManagerId,
          ...(values.lang ? { lang: values.lang.value } : {}),
        };

        return ajax
          .patch(`${API_URL}/company_news/${values.id}/`, data, getReqOptions())
          .pipe(
            mergeMap(() => {
              if (resolve) resolve();
              return of(
                setAmNewsModalUploading(false),
                editAssetManagerNewsSuccessful()
              );
            }),
            takeUntil(
              action$.pipe(
                filter(({ type }) => type === EDIT_ASSET_MANAGER_NEWS_REQUESTED)
              )
            ),
            catchError(error => {
              if (reject) reject(error);
              return of(
                setAmNewsModalUploading(false),
                editAssetManagerNewsRejected(error)
              );
            })
          );
      } else {
        if (reject) reject();
        return of(
          setAmNewsModalUploading(false),
          editAssetManagerNewsRejected()
        );
      }
    })
  );

export const removeAssetManagerNewsEpic = (
  action$: Observable<AssetManagersAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(REMOVE_ASSET_MANAGER_NEWS_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .delete(`${API_URL}/company_news/${payload.id}/`, getReqOptions())
        .pipe(
          mergeMap(() =>
            of(
              addSuccessToast(lang.assetManagerDashboard.newsRemoved),
              fetchAssetManagerNews(state.value.domain.auth.assetManagerId),
              removeAssetManagerNewsSuccessful()
            )
          ),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === REMOVE_ASSET_MANAGER_NEWS_REQUESTED)
            )
          ),
          catchError(error =>
            of(
              addErrorToast(lang.assetManagerDashboard.errors.default),
              removeAssetManagerNewsRejected(error)
            )
          )
        )
    )
  );
