import {
  Action,
  ErrorAction,
  Meta,
  News,
  NewsAction,
  NewsState,
} from "./types";
import { Observable, of } from "rxjs";
import { catchError, filter, mergeMap, takeUntil } from "rxjs/operators";
import { setNewsListTotal, setNewsLoading } from "../ui/news";

import { API_URL } from "./config";
import { StateValue } from "../types";
import { ajax } from "rxjs/ajax";
import { getLangUrl } from "../../utils/app";
import { getNewsQueryParams } from "../../utils/routing";
import { lang } from "../../lang/lang";
import { ofType } from "redux-observable";
import { push } from "connected-react-router";
import { slugify } from "../../utils/funds";
import urls from "../../utils/urls";

const FETCH_NEWS_LIST_REQUESTED = "FETCH_NEWS_LIST_REQUESTED";
const FETCH_NEWS_LIST_SUCCESSFUL = "FETCH_NEWS_LIST_SUCCESSFUL";
const FETCH_NEWS_LIST_REJECTED = "FETCH_NEWS_LIST_REJECTED";
const FETCH_SINGLE_NEWS_REQUESTED = "FETCH_SINGLE_NEWS_REQUESTED";
const FETCH_SINGLE_NEWS_SUCCESSFUL = "FETCH_SINGLE_NEWS_SUCCESSFUL";
const FETCH_SINGLE_NEWS_REJECTED = "FETCH_SINGLE_NEWS_REJECTED";

export const fetchNewsList = (meta?: Meta): NewsAction => ({
  type: FETCH_NEWS_LIST_REQUESTED,
  payload: { meta },
});

export const fetchNewsListSuccessful = (news: News[]): NewsAction => ({
  type: FETCH_NEWS_LIST_SUCCESSFUL,
  payload: { news },
});

export const fetchNewsListRejected = (error: TypeError): ErrorAction => ({
  type: FETCH_NEWS_LIST_REJECTED,
  error,
});

export const fetchSingleNews = (id: number | string): NewsAction => ({
  type: FETCH_SINGLE_NEWS_REQUESTED,
  payload: { id },
});

export const fetchSingleNewsSuccessful = (singleNews: News): NewsAction => ({
  type: FETCH_SINGLE_NEWS_SUCCESSFUL,
  payload: { singleNews },
});

export const fetchSingleNewsRejected = (error: TypeError): ErrorAction => ({
  type: FETCH_SINGLE_NEWS_REJECTED,
  error,
});

export default (
  state: NewsState = {
    newsList: [],
    news: null,
  },
  { type, payload }: NewsAction
) => {
  switch (type) {
    case FETCH_NEWS_LIST_SUCCESSFUL:
      return {
        ...state,
        newsList: payload.news,
      };
    case FETCH_SINGLE_NEWS_SUCCESSFUL:
      if (payload.singleNews) {
        return {
          ...state,
          news: payload.singleNews,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export const fetchNewsListEpic = (
  action$: Observable<NewsAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_NEWS_LIST_REQUESTED),
    mergeMap(({ payload }) => {
      const queryParams = getNewsQueryParams(state.value.form);
      const resolve = (payload.meta && payload.meta.resolve) || null;
      const reject = (payload.meta && payload.meta.reject) || null;
      const language = `${
        queryParams === "" ? "?" : "&"
      }lang=${lang.getLanguage()}`;

      return ajax.get(`${API_URL}/news/${queryParams}${language}`).pipe(
        mergeMap(({ response }) => {
          if (resolve) resolve(response);
          return of(
            setNewsLoading(false),
            setNewsListTotal(response.count),
            fetchNewsListSuccessful(response.results)
          );
        }),
        takeUntil(
          action$.pipe(filter(({ type }) => type === FETCH_NEWS_LIST_REQUESTED))
        ),
        catchError(error => {
          if (reject) reject(error);
          return of(setNewsLoading(false), fetchNewsListRejected(error));
        })
      );
    })
  );

export const fetchSingleNewsEpic = (
  action$: Observable<NewsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_SINGLE_NEWS_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .get(`${API_URL}/news/${payload.id}/?lang=${lang.getLanguage()}`)
        .pipe(
          mergeMap(({ response }) => {
            if (urls.NEWS_DETAILS_SHORT.reg.test(window.location.pathname)) {
              return of(
                push(
                  `${getLangUrl()}/news/${slugify(response.title)}/${
                    response.id
                  }`
                )
              );
            }
            return of(
              setNewsLoading(false),
              fetchSingleNewsSuccessful(response)
            );
          }),
          catchError(error => {
            if (error.status === 404) {
              return of(push(`/404`));
            }
            return of(setNewsLoading(false), fetchSingleNewsRejected(error));
          })
        )
    )
  );
