import { DetailsViewBreadcrumbs } from "../components/common/appBreadcrumbs";
import React from "react";
import { lang } from "../lang/lang";

export default {
  HOME: {
    route: () => lang.routes.home,
    reg: /^\/?(en(\/)?)?($|\/$)/,
    breadcrumb: () => "",
  },
  CONFIRM: {
    route: () => lang.routes.confirm,
    reg: /^\/?(en\/)?confirm($|\/)/,
    breadcrumb: () => "",
  },
  RESET: {
    route: () => lang.routes.reset,
    reg: /^\/?(en\/)?reset($|\/)/,
    breadcrumb: () => "",
  },
  UNSUBSCRIBE: {
    route: () => lang.routes.unsubscribe,
    reg: /^\/?(en\/)?unsubscribe($|\/)/,
    breadcrumb: () => lang.unsubscribe.title,
  },
  FUNDS_IN_FOCUS: {
    route: () => lang.routes.fundsInFocus,
    reg: /^\/?(en\/)?funds-in-focus($|\/)/,
    breadcrumb: () => lang.menu.fundsInFocus,
  },
  RATING_IN_FOCUS: {
    route: () => lang.routes.ratingInFocus,
    reg: /^\/?(en\/)?rating-in-focus$/,
    breadcrumb: () => lang.menu.ratingInFocus,
  },
  WATCHLIST: {
    route: () => lang.routes.watchlist,
    reg: /^\/?(en\/)?watchlist($|\/)/,
    breadcrumb: () => lang.menu.watchlist,
  },
  NEWS: {
    route: () => lang.routes.news,
    reg: /^\/?(en\/)?news($|\/$)/,
    breadcrumb: () => lang.menu.news,
  },
  NEWS_DETAILS: {
    route: () => lang.routes.newsDetails,
    reg: /^\/?(en\/)?news(\/[\d-\w_]+)?\/[\d-\w_]+/,
    breadcrumb: () => <DetailsViewBreadcrumbs />,
  },
  NEWS_DETAILS_SHORT: {
    route: () => lang.routes.newsDetails,
    reg: /^\/ ?(en\/)?news\/\d+/,
    breadcrumb: () => <DetailsViewBreadcrumbs />,
  },
  REPORTS: {
    route: () => lang.routes.reports,
    reg: /^\/?(en\/)?reports($|\/$)/,
    breadcrumb: () => lang.menu.reports,
  },
  REPORTS_DETAILS: {
    route: () => lang.routes.reportsDetails,
    reg: /^\/?(en\/)?reports(\/[\d-\w]+)?\/\d+/,
    breadcrumb: () => <DetailsViewBreadcrumbs />,
  },
  REPORTS_DETAILS_SHORT: {
    route: () => lang.routes.reportsDetails,
    reg: /^\/?(en\/)?reports\/\d+/,
    breadcrumb: () => <DetailsViewBreadcrumbs />,
  },
  OIF: {
    route: () => lang.routes.oif,
    reg: /^\/?(en\/)?oif($|\/$)/,
    breadcrumb: () => lang.menu.oif,
  },
  OIF_DETAILS: {
    route: () => lang.routes.oifDetails,
    reg: /^\/?(en\/)?oif(\/[\d-\w]+)?\/\d+/,
    breadcrumb: () => <DetailsViewBreadcrumbs />,
  },
  OIF_DETAILS_SHORT: {
    route: () => lang.routes.oifDetails,
    reg: /^\/ ?(en\/)?oif\/\d+/,
    breadcrumb: () => <DetailsViewBreadcrumbs />,
  },
  SEARCH_RESULTS: {
    route: () => lang.routes.searchResults,
    reg: /^\/?(en\/)?search-results($|\/)/,
    breadcrumb: () => lang.menu.searchResults,
  },
  FILTER_SEARCH_RESULTS: {
    route: () => lang.routes.filterSearchResults,
    reg: /^\/?(en\/)?filter-search-results($|\/)/,
    breadcrumb: () => lang.menu.searchResults,
  },
  ADV_SEARCH_RESULTS: {
    route: () => lang.routes.advSearchResults,
    reg: /^\/?(en\/)?advanced-search-results($|\/)/,
    breadcrumb: () => lang.menu.searchResults,
  },
  DETAILS: {
    route: () => lang.routes.details,
    reg: /^\/?(en\/)?details(\/[\d-\w]+)\/[\d-\w]+/,
    breadcrumb: () => <DetailsViewBreadcrumbs />,
  },
  PREMIUM: {
    route: () => lang.routes.premium,
    reg: /^\/?(en\/)?premium($|\/)/,
    breadcrumb: () => lang.menu.premium,
  },
  METHODOLOGY: {
    route: () => lang.routes.methodology,
    reg: /^\/?(en\/)?methodology($|\/)/,
    breadcrumb: () => lang.menu.methodology,
  },
  METHODOLOGIES: {
    route: () => lang.routes.methodologies,
    reg: /^\/?(en\/)?methodologies($|\/)/,
    breadcrumb: () => lang.menu.methodologies,
  },
  LEGAL_NOTICE: {
    route: () => lang.routes.legalNotice,
    reg: /^\/?(en\/)?legal-notice($|\/)/,
    breadcrumb: () => lang.legalNotice.title,
  },
  PRIVACY_POLICY: {
    route: () => lang.routes.privacyPolicy,
    reg: /^\/?(en\/)?privacy-policy($|\/)/,
    breadcrumb: () => lang.privacyPolicy.title,
  },
  CONTACT: {
    route: () => lang.routes.contact,
    reg: /^\/?(en\/)?contact($|\/)/,
    breadcrumb: () => lang.contact.contact,
  },
  SAVED_SEARCHES: {
    route: () => lang.routes.savedSearches,
    reg: /^\/?(en\/)?saved-searches($|\/)/,
    breadcrumb: () => lang.menu.savedSearches,
  },
  RECHTLICHE_HINWEISE: {
    route: () => lang.routes.rechtlicheHinweise,
    reg: /^\/?(en\/)?rechtliche-hinweise($|\/)/,
    breadcrumb: () => lang.rechtlicheHinweise.title,
  },
  TERMS_OF_USE: {
    route: () => lang.routes.termsOfUse,
    reg: /^\/?(en\/)?terms-of-use($|\/)/,
    breadcrumb: () => lang.termsOfUse.title,
  },
  TERMS_AND_CONDITIONS: {
    route: () => lang.routes.termsAndConditions,
    reg: /^\/?(en\/)?terms-and-conditions($|\/)/,
    breadcrumb: () => lang.termsAndConditions.title,
  },
  NOT_FOUND: {
    reg: /^\/?(en\/)?404\.html($|\/)/,
    breadcrumb: () => "",
  },
  CONFERENCES_LIST_LOADING: {
    route: () => lang.routes.conferencesListLoading,
    reg: /^\/?(en\/)?conferences-loading($|\/$)/,
    breadcrumb: () => lang.assetManagers.conferences2,
  },
  CONFERENCES_LIST: {
    route: () => lang.routes.conferencesList,
    reg: /^\/?(en\/)?conferences($|\/$)/,
    breadcrumb: () => lang.assetManagers.conferences2,
  },
  CONFERENCES_LIST_2: {
    route: () => lang.routes.conferencesList2,
    reg: /^\/?(en\/)?conferences_list($|\/$)/,
    breadcrumb: () => lang.assetManagers.conferences2,
  },
  CONFERENCE_DETAILS: {
    route: () => lang.routes.conferenceDetails,
    reg: /^\/?(en\/)?conferences\/[\d-\w]+($|\/)/,
    breadcrumb: () => <DetailsViewBreadcrumbs />,
  },
  ASSET_MANAGER_DASHBOARD: {
    route: () => lang.routes.assetManagerDashboard,
    reg: /^\/?(en\/)?asset-manager-dashboard\/[\d-\w]+($|\/)/,
    breadcrumb: () => lang.menu.assetManagers,
  },
  FUND_RATINGS: {
    route: () => lang.routes.fundRatings,
    reg: /^\/?(en\/)?asset-manager-dashboard\/[\d-\w]+\/fund-ratings($|\/)/,
    breadcrumb: () => lang.fundRatings.title,
  },
  TEAM_RATING: {
    route: () => lang.routes.teamRating,
    reg: /^\/?(en\/)?asset-manager-dashboard\/[\d-\w]+\/team-rating($|\/)/,
    breadcrumb: () => lang.teamRating.title,
  },
  PROFILE_EDIT: {
    route: () => lang.routes.profileEdit,
    reg: /^\/?(en\/)?asset-manager-dashboard\/[\d-\w]+\/profile($|\/)/,
    breadcrumb: () => lang.assetManagers.title,
  },
  ASSET_MANAGER_STATS: {
    route: () => lang.routes.assetManagerStats,
    reg: /^\/?(en\/)?asset-manager-dashboard\/[\d-\w]+\/stats($|\/)/,
    breadcrumb: () => lang.assetManagers.statistics,
  },
  ASSET_MANAGER_CONFERENCES: {
    route: () => lang.routes.assetManagerConferences,
    reg: /^\/?(en\/)?asset-manager-dashboard\/[\d-\w]+\/conferences($|\/)/,
    breadcrumb: () => lang.assetManagers.conferences,
  },
  SELECT_SURVEY: {
    route: () => lang.routes.selectSurvey,
    reg: /^\/?(en\/)?select-survey($|\/)/,
    breadcrumb: () => lang.menu.wholesale,
  },
  SURVEY_ESG_COMPANY: {
    route: () => lang.routes.surveyEsgCompany,
    reg: /^\/?(en\/)?survey\/company($|\/$)/,
    breadcrumb: () => lang.menu.wholesale,
  },
  SURVEY_ESG_FUND: {
    route: () => lang.routes.surveyEsgFund,
    reg: /^\/?(en\/)?survey\/fund($|\/$)/,
    breadcrumb: () => lang.menu.wholesale,
  },
  SURVEY_EQUITY_FUND: {
    route: () => lang.routes.surveyEquityFund,
    reg: /^\/?(en\/)?survey\/equity-fund($|\/$)/,
    breadcrumb: () => lang.menu.wholesale,
  },
  SURVEY_SOVEREIGN_BOND_FUND: {
    route: () => lang.routes.surveySovereignBondFund,
    reg: /^\/?(en\/)?survey\/sovereign-bond-fund($|\/$)/,
    breadcrumb: () => lang.menu.wholesale,
  },
  SURVEY_CORPORATE_BOND_FUND: {
    route: () => lang.routes.surveyCorporateBondFund,
    reg: /^\/?(en\/)?survey\/corporate-bond-fund($|\/$)/,
    breadcrumb: () => lang.menu.wholesale,
  },
  SURVEY_AGGREGATE_BOND_FUND: {
    route: () => lang.routes.surveyAggregateBondFund,
    reg: /^\/?(en\/)?survey\/aggregate-bond-fund($|\/$)/,
    breadcrumb: () => lang.menu.wholesale,
  },
  SURVEY_MULTI_ASSET_FUND: {
    route: () => lang.routes.surveyMultiAssetFund,
    reg: /^\/?(en\/)?survey\/multi-asset-fund($|\/$)/,
    breadcrumb: () => lang.menu.wholesale,
  },
  DEKA_SURVEY: {
    route: () => lang.routes.dekaSurvey,
    reg: /^\/?(en\/)?deka-survey($|\/$)/,
    breadcrumb: () => lang.menu.wholesale,
  },
  ASSET_MANAGER_DETAILS: {
    route: () => lang.routes.assetManagerDetails,
    reg: /^\/?(en\/)?asset-managers\/[\d-\w]+($|\/)/,
    breadcrumb: () => lang.assetManagers.title,
  },
  ASSET_MANAGERS: {
    route: () => lang.routes.assetManagers,
    reg: /^\/?(en\/)?asset-managers($|\/)/,
    breadcrumb: () => lang.assetManagers.listTitle,
  },
  ASSET_MANAGER_NEWS: {
    route: () => lang.routes.assetManagerNews,
    reg: /^\/?(en\/)?asset-manager-news(\/[\d-\w]+)\/[\d-\w]+/,
    breadcrumb: () => <DetailsViewBreadcrumbs />,
  },
  EDIT_FUND: {
    route: () => lang.routes.editFund,
    reg: /^\/?(en\/)?edit-fund\/[\d-\w]+/,
    breadcrumb: () => lang.assetManagers.title,
  },
  USER_PROFILE: {
    route: () => lang.routes.userProfile,
    reg: /^\/?(en\/)?profile($|\/)/,
    breadcrumb: () => "Profile",
  },
  BENCHMARKS: {
    route: () => lang.routes.benchmarks,
    reg: /^\/?(en\/)?benchmarks\/[\d-\w]+/,
    breadcrumb: () => "Benchmarks",
  },
  AIF_PAGE: {
    route: () => lang.routes.aifPage,
    reg: /^\/?(en\/)?aif-page($|\/)/,
    breadcrumb: () => "AIF page",
  },
  WHOLESALE_LANDING_PAGE: {
    route: () => lang.routes.wholesale,
    reg: /^\/?(en\/)?professional-investors($|\/)/,
    breadcrumb: () => lang.menu.wholesale,
  },
  SCOPE_AWARDS: {
    route: () => lang.routes.scopeAwards,
    reg: /^\/?(en\/)?scope-awards\/[0-9]{4}($|\/$)/,
    breadcrumb: () => lang.menu.scopeAwards,
  },
  SCOPE_INVESTMENT_AWARDS: {
    route: () => lang.routes.scopeInvestmentAwards,
    reg: /^\/?(en\/)?scope-awards\/[0-9]{4}\/investment($|\/$)/,
    breadcrumb: () => lang.scopeAwards.investmentAwards,
  },
  SCOPE_ALTERNATIVE_INVESTMENT_AWARDS: {
    route: () => lang.routes.scopeAlternativeInvestmentAwards,
    reg: /^\/?(en\/)?scope-awards\/[0-9]{4}\/alternative-investment($|\/$)/,
    breadcrumb: () => lang.scopeAwards.alternativeInvestmentAwards,
  },
  SCOPE_CERTIFICATE_AWARDS: {
    route: () => lang.routes.scopeCertificateAwards,
    reg: /^\/?(en\/)?scope-awards\/[0-9]{4}\/certificate($|\/$)/,
    breadcrumb: () => lang.scopeAwards.certificateAwards,
  },
  SCOPE_AWARDS_CATEGORIES: {
    route: () => lang.routes.scopeAwardsCategories,
    reg: /^\/?(en\/)?scope-awards\/[0-9]{4}\/[\d-\w]+\/categories($|\/$)/,
    breadcrumb: () => lang.menu.scopeAwardsCategories,
  },
  SCOPE_AWARDS_NOMINEES: {
    route: () => lang.routes.scopeAwardsNominees,
    reg: /^\/?(en\/)?scope-awards\/[0-9]{4}\/nominees($|\/$)/,
    breadcrumb: () => lang.menu.scopeAwardsNominees,
  },
  SCOPE_AWARDS_WINNERS: {
    route: () => lang.routes.scopeAwardsWinners,
    reg: /^\/?(en\/)?scope-awards\/[0-9]{4}\/winners($|\/$)/,
    breadcrumb: () => lang.menu.scopeAwardsWinners,
  },
  NOMINATION_REQUIREMENTS: {
    route: () => lang.routes.nominationAltInvestmentRequirements,
    reg: /^\/?(en\/)?nomination-alternative-investment-requirements($|\/$)/,
    breadcrumb: () => lang.menu.nominationAltInvestmentRequirements,
  },
  NOMINATION_INVESTMENT_REQUIREMENTS: {
    route: () => lang.routes.nominationInvestmentRequirements,
    reg: /^\/?(en\/)?nomination-investment-requirements($|\/$)/,
    breadcrumb: () => lang.menu.nominationInvestmentRequirements,
  },
  NOMINATION_CERTIFICATE_REQUIREMENTS: {
    route: () => lang.routes.nominationCertificateRequirements,
    reg: /^\/?(en\/)?nomination-certificate-requirements($|\/$)/,
    breadcrumb: () => lang.menu.nominationCertificateRequirements,
  },
  PORTRAIT: {
    route: () => lang.routes.portrait,
    reg: /^\/?(en\/)?scope-fund-analysis($|\/$)/,
    breadcrumb: () => lang.menu.portrait,
  },
};
