import { ConferencesListAction, ConferencesListState } from "./types";

const SET_CONFERENCES_LIST_LOADING = "SET_CONFERENCES_LIST_LOADING";

export const setConferencesListLoading = (
  value: boolean
): ConferencesListAction => ({
  type: SET_CONFERENCES_LIST_LOADING,
  payload: { value }
});

export default (
  state: ConferencesListState = {
    isLoading: false
  },
  { type, payload }: ConferencesListAction
) => {
  switch (type) {
    case SET_CONFERENCES_LIST_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    default:
      return state;
  }
};
