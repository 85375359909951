export const colors = {
  blue: "#004d8f",
  lightBlue: "#a6c3d6",
  gray: "#444",
  gray2: "#666",
  lightGray: "#f7f8f8",
  lightGray2: "#c1c1c1",
  white: "#fff",
  yellow: "#ffc100",
  red: "#9c1f10",
  red2: "#f24747",
  green2: "#50be87",
  redTransparent: "rgba(208,33,39, 0.3)",
  greenTransparent: "rgba(43,166,74,0.3)",
  yellowTransparent: "rgba(255,193,0, 0.3)",
};
