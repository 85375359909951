import {
  AssetManagerDashboardAction,
  AssetManagerDashboardState
} from "./types";

const SET_IS_ASSET_MANAGER_DASHBOARD_SAVING =
  "SET_IS_ASSET_MANAGER_DASHBOARD_SAVING";
const SET_ASSET_MANAGER_DASHBOARD_CHART_TYPE =
  "SET_ASSET_MANAGER_DASHBOARD_CHART_TYPE";

export const setIsAssetManagerDashboardSaving = (
  value: boolean
): AssetManagerDashboardAction => ({
  type: SET_IS_ASSET_MANAGER_DASHBOARD_SAVING,
  payload: { value }
});

export const setAssetManagerDashboardChartType = (
  value: number
): AssetManagerDashboardAction => ({
  type: SET_ASSET_MANAGER_DASHBOARD_CHART_TYPE,
  payload: { value }
});

export default (
  state: AssetManagerDashboardState = {
    isAssetManagerDashboardChartLoading: false,
    isAssetManagerDashboardSaving: false,
    chartType: 1
  },
  { type, payload }: AssetManagerDashboardAction
) => {
  switch (type) {
    case SET_IS_ASSET_MANAGER_DASHBOARD_SAVING:
      return {
        ...state,
        isAssetManagerDashboardSaving: payload.value
      };
    case SET_ASSET_MANAGER_DASHBOARD_CHART_TYPE:
      return {
        ...state,
        chartType: payload.value
      };
    default:
      return state;
  }
};
