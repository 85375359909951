import { AMNewsModalAction, AMNewsModalState } from "./types";

const SET_AM_NEWS_MODAL_VISIBILITY = "SET_AM_NEWS_MODAL_VISIBILITY";
const SET_AM_NEWS_MODAL_UPLOADING = "SET_AM_NEWS_MODAL_UPLOADING";
const SET_EDIT_NEWS_ID = "SET_EDIT_NEWS_ID";

export const setAmNewsModalVisibility = (
  value: boolean
): AMNewsModalAction => ({
  type: SET_AM_NEWS_MODAL_VISIBILITY,
  payload: { value }
});

export const setAmNewsModalUploading = (value: boolean): AMNewsModalAction => ({
  type: SET_AM_NEWS_MODAL_UPLOADING,
  payload: { value }
});

export const setEditNewsId = (id: number): AMNewsModalAction => ({
  type: SET_EDIT_NEWS_ID,
  payload: { id }
});

export default (
  state: AMNewsModalState = {
    isVisible: false,
    isUploading: false,
    editNewsId: null
  },
  { type, payload }: AMNewsModalAction
) => {
  switch (type) {
    case SET_AM_NEWS_MODAL_VISIBILITY:
      return {
        ...state,
        isVisible: payload.value
      };
    case SET_AM_NEWS_MODAL_UPLOADING:
      return {
        ...state,
        isUploading: payload.value
      };
    case SET_EDIT_NEWS_ID:
      return {
        ...state,
        editNewsId: payload.id
      };
    default:
      return state;
  }
};
