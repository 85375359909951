import { LoginAction, LoginState } from "./types";

const SET_LOGIN_VISIBILITY = "SET_LOGIN_VISIBILITY";
const SET_LOGIN_LOADING = "SET_LOGIN_LOADING";

export const setLoginVisibility = (value: boolean): LoginAction => {
  if (!value)
    localStorage.removeItem('loginModalVisibility')

  return ({
    type: SET_LOGIN_VISIBILITY,
    payload: { value }
  });
}

export const setLoginLoading = (value: boolean): LoginAction => ({
  type: SET_LOGIN_LOADING,
  payload: { value }
});

export default (
  state: LoginState = {
    isLoginVisible: false,
    isLoginLoading: false
  },
  { type, payload }: LoginAction
) => {
  switch (type) {
    case SET_LOGIN_VISIBILITY:
      return {
        ...state,
        isLoginVisible: payload.value,
        isMobileNavVisible: false
      };
    case SET_LOGIN_LOADING:
      return {
        ...state,
        isLoginLoading: payload.value
      };
    default:
      return state;
  }
};
