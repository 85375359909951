import { lang } from "../../lang/lang";
import { colors } from "../../utils/styles";

const ADD_TOAST = "ADD_TOAST";
const REMOVE_TOAST = "REMOVE_TOAST";
const CLEAR_TOASTS = "CLEAR_TOASTS";

let id = 0;

const defaultOptions = {
  color: colors.white
};

const createToast = options => ({
  ...defaultOptions,
  ...options,
  id: id++
});

const createInfoToast = text => ({
  color: colors.blue,
  id: id++,
  text
});

const createErrorToast = text => ({
  color: colors.red2,
  id: id++,
  text
});

const createSuccessToast = text => ({
  color: colors.green2,
  id: id++,
  text
});

export const addToast = (options = {}) => ({
  type: ADD_TOAST,
  payload: {
    toast: createToast(options)
  }
});

export const addInfoToast = text => ({
  type: ADD_TOAST,
  payload: {
    toast: createInfoToast(text)
  }
});

export const addErrorToast = error => ({
  type: ADD_TOAST,
  payload: {
    toast: createErrorToast(error)
  }
});

export const addSuccessToast = text => ({
  type: ADD_TOAST,
  payload: {
    toast: createSuccessToast(text)
  }
});

export const removeToast = id => ({
  type: REMOVE_TOAST,
  payload: {
    id
  }
});

export const clearToasts = () => ({
  type: CLEAR_TOASTS
});

export default (state = [], { type, payload }) => {
  switch (type) {
    case ADD_TOAST:
      return [payload.toast, ...state];
    case REMOVE_TOAST:
      return state.filter(toast => toast.id !== payload.id);
    case CLEAR_TOASTS:
      return [];
    case "CONFIRM_USER_REJECTED":
      return [createErrorToast(lang.confirm.error), ...state];
    case "CONFIRM_USER_SUCCESSFUL":
      return [createSuccessToast(lang.confirm.success), ...state];
    default:
      return state;
  }
};
