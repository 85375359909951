import { FundPhotoModalAction, FundPhotoModalState } from "./types";

const SET_FUND_PHOTO_VISIBILITY = "SET_FUND_PHOTO_VISIBILITY";
const SET_FUND_PHOTO_UPLOADING = "SET_FUND_PHOTO_UPLOADING";
const SET_FUND_PHOTO_EDIT_INDEX = "SET_FUND_PHOTO_EDIT_INDEX";

export const setFundPhotoModalVisibility = (
  value: boolean
): FundPhotoModalAction => ({
  type: SET_FUND_PHOTO_VISIBILITY,
  payload: { value }
});

export const setFundPhotoModalUploading = (
  value: boolean
): FundPhotoModalAction => ({
  type: SET_FUND_PHOTO_UPLOADING,
  payload: { value }
});

export const setFundPhotoModalFundId = (
  index: number | null
): FundPhotoModalAction => ({
  type: SET_FUND_PHOTO_EDIT_INDEX,
  payload: { index }
});

export default (
  state: FundPhotoModalState = {
    isVisible: false,
    isUploading: false,
    editIndex: null
  },
  { type, payload }: FundPhotoModalAction
) => {
  switch (type) {
    case SET_FUND_PHOTO_VISIBILITY:
      return {
        ...state,
        isVisible: payload.value
      };
    case SET_FUND_PHOTO_UPLOADING:
      return {
        ...state,
        isUploading: payload.value
      };
    case SET_FUND_PHOTO_EDIT_INDEX:
      return {
        ...state,
        editIndex: payload.index
      };
    default:
      return state;
  }
};
