import { HeaderAction, HeaderState } from "./types";

const SET_MOBILE_NAV_VISIBILITY = "SET_MOBILE_NAV_VISIBILITY";
const SET_ACCOUNT_MENU_VISIBILITY = "SET_ACCOUNT_MENU_VISIBILITY";
const SET_LANG_MENU_VISIBILITY = "SET_LANG_MENU_VISIBILITY";
const SET_PUBLICATIONS_MENU_VISIBILITY = "SET_PUBLICATIONS_MENU_VISIBILITY";
const SET_SERVICES_MENU_VISIBILITY = "SET_SERVICES_MENU_VISIBILITY";
const SET_SCOPE_AWARDS_MENU_VISIBILITY = "SET_SCOPE_AWARDS_MENU_VISIBILITY";

export const setMobileNavVisibility = (value: boolean): HeaderAction => ({
  type: SET_MOBILE_NAV_VISIBILITY,
  payload: { value }
});

export const setAccountMenuVisibility = (value: boolean): HeaderAction => ({
  type: SET_ACCOUNT_MENU_VISIBILITY,
  payload: { value }
});

export const setLangMenuVisibility = (value: boolean): HeaderAction => ({
  type: SET_LANG_MENU_VISIBILITY,
  payload: { value }
});

export const setPublicationsMenuVisibility = (
  value: boolean
): HeaderAction => ({
  type: SET_PUBLICATIONS_MENU_VISIBILITY,
  payload: { value }
});

export const setServicesMenuVisibility = (value: boolean): HeaderAction => ({
  type: SET_SERVICES_MENU_VISIBILITY,
  payload: { value }
});

export const setScopeAwardsMenuVisibility = (value: boolean): HeaderAction => ({
  type: SET_SCOPE_AWARDS_MENU_VISIBILITY,
  payload: { value },
});

export default (
  state: HeaderState = {
    isMobileNavVisible: false,
    isAccountMenuVisible: false,
    isLangMenuVisible: false,
    isPublicationsMenuVisible: false,
    isServicesMenuVisible: false,
    isScopeAwardsMenuVisible: false,
  },
  { type, payload }: HeaderAction
) => {
  switch (type) {
    case SET_MOBILE_NAV_VISIBILITY:
      return {
        isMobileNavVisible: payload.value,
        isAccountMenuVisible: false,
        isLangMenuVisible: false,
        isPublicationsMenuVisible: false,
        isServicesMenuVisible: false,
        isScopeAwardsMenuVisible: false
      };
    case SET_ACCOUNT_MENU_VISIBILITY:
      return {
        isMobileNavVisible: false,
        isAccountMenuVisible: payload.value,
        isLangMenuVisible: false,
        isPublicationsMenuVisible: false,
        isServicesMenuVisible: false,
        isScopeAwardsMenuVisible: false,
      };
    case SET_LANG_MENU_VISIBILITY:
      return {
        isMobileNavVisible: false,
        isAccountMenuVisible: false,
        isLangMenuVisible: payload.value,
        isPublicationsMenuVisible: false,
        isServicesMenuVisible: false,
        isScopeAwardsMenuVisible: false,
      };
    case SET_PUBLICATIONS_MENU_VISIBILITY:
      return {
        isMobileNavVisible: false,
        isAccountMenuVisible: false,
        isLangMenuVisible: false,
        isPublicationsMenuVisible: payload.value,
        isServicesMenuVisible: false,
        isScopeAwardsMenuVisible: false,
      };
    case SET_SERVICES_MENU_VISIBILITY:
      return {
        isMobileNavVisible: false,
        isAccountMenuVisible: false,
        isLangMenuVisible: false,
        isPublicationsMenuVisible: false,
        isServicesMenuVisible: payload.value,
        isScopeAwardsMenuVisible: false,
      };
    case SET_SCOPE_AWARDS_MENU_VISIBILITY:
      return {
        isMobileNavVisible: false,
        isAccountMenuVisible: false,
        isLangMenuVisible: false,
        isPublicationsMenuVisible: false,
        isServicesMenuVisible: false,
        isScopeAwardsMenuVisible: payload.value,
      };
    default:
      return state;
  }
};
