import { HomeAction, HomeState } from "./types";

const SET_HOME_NEWS_LOADING = "SET_HOME_NEWS_LOADING";
const SET_HOME_FACTS_LOADING = "SET_HOME_FACTS_LOADING";

export const setHomeNewsLoading = (value: boolean): HomeAction => ({
  type: SET_HOME_NEWS_LOADING,
  payload: { value }
});

export const setHomeFactsLoading = (value: boolean): HomeAction => ({
  type: SET_HOME_FACTS_LOADING,
  payload: { value }
});

export default (
  state: HomeState = {
    isHomeNewsLoading: false,
    isHomeFactsLoading: false
  },
  { type, payload }: HomeAction
) => {
  switch (type) {
    case SET_HOME_NEWS_LOADING:
      return {
        ...state,
        isHomeNewsLoading: payload.value
      };
    case SET_HOME_FACTS_LOADING:
      return {
        ...state,
        isHomeFactsLoading: payload.value
      };
    default:
      return state;
  }
};
