import { API_URL, getReqOptions } from "./config";
import {
  Action,
  ErrorAction,
  Invoice,
  License,
  LicensesAction,
  LicensesState,
  Meta,
  Price,
} from "./types";
import { Observable, of } from "rxjs";
import { addErrorToast, addSuccessToast } from "../ui/toasts";
import { catchError, filter, mergeMap, takeUntil } from "rxjs/operators";

import { StateValue } from "./../types";
import { ajax } from "rxjs/ajax";
import { fetchLicense } from "./auth";
import { lang } from "./../../lang/lang";
import { ofType } from "redux-observable";
import { setInvoiceModalVisibility } from "../ui/invoiceModal";
import { setPurchaseModalLoading } from "../ui/purchaseModal";
import { setPurchaseModalVisibility } from "./../ui/purchaseModal";

const FETCH_LICENSES_REQUESTED = "FETCH_LICENSES_REQUESTED";
const FETCH_LICENSES_SUCCESSFUL = "FETCH_LICENSES_SUCCESSFUL";
const FETCH_LICENSES_REJECTED = "FETCH_LICENSES_REJECTED";
const REDIRECT_TO_PAYMENT_REQUESTED = "REDIRECT_TO_PAYMENT_REQUESTED";
const REDIRECT_TO_PAYMENT_SUCCESSFUL = "REDIRECT_TO_PAYMENT_SUCCESSFUL";
const REDIRECT_TO_PAYMENT_REJECTED = "REDIRECT_TO_PAYMENT_REJECTED";
const CANCEL_SUBSCRIPTION_REQUESTED = "CANCEL_SUBSCRIPTION_REQUESTED";
const CANCEL_SUBSCRIPTION_SUCCESSFUL = "CANCEL_SUBSCRIPTION_SUCCESSFUL";
const CANCEL_SUBSCRIPTION_REJECTED = "CANCEL_SUBSCRIPTION_REJECTED";
const SET_SELECTED_PRICE = "SET_SELECTED_PRICE";
const SET_SELECTED_LICENSE = "SET_SELECTED_LICENSE";

export const fetchLicenses = (): Action => ({
  type: FETCH_LICENSES_REQUESTED,
});

export const fetchLicensesSuccessful = (
  licenses: License[]
): LicensesAction => ({
  type: FETCH_LICENSES_SUCCESSFUL,
  payload: { licenses },
});

export const fetchLicensesRejected = (error: TypeError): ErrorAction => ({
  type: FETCH_LICENSES_REJECTED,
  error,
});

export const redirectToPayment = (
  item: PaymentItem,
  meta: Meta
): LicensesAction => ({
  type: REDIRECT_TO_PAYMENT_REQUESTED,
  payload: { item, meta },
});

export const redirectToPaymentSuccessful = (
  invoice: Invoice
): LicensesAction => ({
  type: REDIRECT_TO_PAYMENT_SUCCESSFUL,
  payload: { invoice },
});

export const redirectToPaymentRejected = (error: TypeError): ErrorAction => ({
  type: REDIRECT_TO_PAYMENT_REJECTED,
  error,
});

export const cancelSubscription = (): Action => ({
  type: CANCEL_SUBSCRIPTION_REQUESTED,
});

export const cancelSubscriptionSuccessful = (
  license: License
): LicensesAction => ({
  type: CANCEL_SUBSCRIPTION_SUCCESSFUL,
  payload: { license },
});

export const cancelSubscriptionRejected = (error: TypeError): ErrorAction => ({
  type: CANCEL_SUBSCRIPTION_REJECTED,
  error,
});

export const setSelectedPrice = (price: Price): LicensesAction => ({
  type: SET_SELECTED_PRICE,
  payload: { price },
});

export const setSelectedLicense = (license: License): LicensesAction => ({
  type: SET_SELECTED_LICENSE,
  payload: { license },
});

export default (
  state: LicensesState = {
    licenses: [],
    invoice: null,
    selectedPrice: null,
    selectedLicense: null,
  },
  { type, payload }: LicensesAction
) => {
  switch (type) {
    case FETCH_LICENSES_SUCCESSFUL:
      return {
        ...state,
        licenses: payload.licenses,
      };
    case SET_SELECTED_PRICE:
      return {
        ...state,
        selectedPrice: payload.price,
      };
    case SET_SELECTED_LICENSE:
      return {
        ...state,
        selectedLicense: payload.license,
      };
    case REDIRECT_TO_PAYMENT_SUCCESSFUL:
      return {
        ...state,
        invoice: payload.invoice,
      };
    default:
      return state;
  }
};

export const fetchLicensesEpic = (
  action$: Observable<LicensesAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_LICENSES_REQUESTED),
    mergeMap(() =>
      ajax.get(`${API_URL}/payments/products/?type=good`, getReqOptions()).pipe(
        mergeMap(({ response }) =>
          of(fetchLicensesSuccessful(response.results))
        ),
        takeUntil(
          action$.pipe(filter(({ type }) => type === FETCH_LICENSES_REQUESTED))
        ),
        catchError(error => of(fetchLicensesRejected(error)))
      )
    )
  );

export const redirectToPaymentEpic = (
  action$: Observable<LicensesAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(REDIRECT_TO_PAYMENT_REQUESTED),
    mergeMap(({ payload }) => {
      const data = {
        item: payload.item,
        user: { ...state.value.form.purchase?.values },
        success_url: `${window.location.origin}/payments/success`,
        cancel_url: window.location.href,
      };

      const { resolve, reject }: Meta | any = payload.meta || {};

      return ajax
        .post(`${API_URL}/payments/invoice/`, data, getReqOptions())
        .pipe(
          mergeMap(({ response }) => {
            if (resolve) resolve(response);
            return of(
              setPurchaseModalLoading(false),
              setPurchaseModalVisibility(false),
              setInvoiceModalVisibility(true),
              fetchLicense(),
              redirectToPaymentSuccessful(response)
            );
          }),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === REDIRECT_TO_PAYMENT_REQUESTED)
            )
          ),
          catchError(error => {
            if (reject) reject(error);
            return of(
              setPurchaseModalLoading(false),
              redirectToPaymentRejected(error)
            );
          })
        );
    })
  );

export const cancelSubscriptionEpic = (
  action$: Observable<LicensesAction>
): Observable<Action> =>
  action$.pipe(
    ofType(CANCEL_SUBSCRIPTION_REQUESTED),
    mergeMap(() =>
      ajax
        .post(`${API_URL}/profile/subscription/cancel/`, getReqOptions())
        .pipe(
          mergeMap(({ response }) =>
            of(
              addSuccessToast("Your premium subscription was cancelled."),
              fetchLicense(),
              cancelSubscriptionSuccessful(response)
            )
          ),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === CANCEL_SUBSCRIPTION_REQUESTED)
            )
          ),
          catchError(error =>
            of(
              addErrorToast(lang.premium.subscribeListError),
              cancelSubscriptionRejected(error)
            )
          )
        )
    )
  );
