export const aifParams = [
  {
    id: 1,
    field: "property_portfolio",
    isInstrument: true,
    formatter: "getFloatValue"
  },
  {
    id: 2,
    field: "sustainability",
    isInstrument: true,
    formatter: "getFloatValue"
  },
  {
    id: 3,
    field: "financial_structure",
    isInstrument: true,
    formatter: "getFloatValue"
  },
  {
    id: 4,
    field: "asset_management",
    isInstrument: true,
    formatter: "getFloatValue"
  },
  {
    id: 5,
    field: "fund_management",
    isInstrument: true,
    formatter: "getFloatValue"
  },
  {
    id: 6,
    field: "rating_total",
    isInstrument: true,
    formatter: "getFloatValue"
  }
];

export const yourAifParams = [
  {
    id: 7,
    field: "ter",
    isInstrument: false,
    formatter: "getPercentOneDigitValue"
  },
  {
    id: 8,
    field: "net_volume",
    isInstrument: true,
    formatter: "getIntValue"
  },
  {
    id: 9,
    field: "real_volume",
    isInstrument: true,
    formatter: "getIntValue"
  },
  {
    id: 10,
    field: "objects_number",
    isInstrument: true,
    formatter: "getIntValue"
  },
  {
    id: 11,
    field: "liquidity_ratio",
    isInstrument: true,
    formatter: "getPercentOneDigitValue"
  },
  {
    id: 12,
    field: "credit_ratio",
    isInstrument: true,
    formatter: "getPercentOneDigitValue"
  },
  {
    id: 13,
    field: "geographical_german",
    isInstrument: true,
    formatter: "getPercentOneDigitValue"
  },
  {
    id: 14,
    field: "geographical_european",
    isInstrument: true,
    formatter: "getPercentOneDigitValue"
  },
  {
    id: 15,
    field: "geographical_non_european",
    isInstrument: true,
    formatter: "getPercentOneDigitValue"
  },
  {
    id: 16,
    field: "sectoral_office",
    isInstrument: true,
    formatter: "getPercentOneDigitValue"
  },
  {
    id: 17,
    field: "sectoral_retail",
    isInstrument: true,
    formatter: "getPercentOneDigitValue"
  },
  {
    id: 18,
    field: "sectoral_living",
    isInstrument: true,
    formatter: "getPercentOneDigitValue"
  },
  {
    id: 19,
    field: "sectoral_hotel",
    isInstrument: true,
    formatter: "getPercentOneDigitValue"
  },
  {
    id: 20,
    field: "sectoral_logistics",
    isInstrument: true,
    formatter: "getPercentOneDigitValue"
  },
  {
    id: 21,
    name: "Performance 1y",
    field: "performance_1y",
    isInstrument: false,
    formatter: "getPerfValue"
  },
  {
    id: 22,
    field: "performance_5y",
    isInstrument: false,
    formatter: "getPerfValue"
  },
  {
    id: 23,
    field: "volatility_5y",
    isInstrument: false,
    formatter: "getRiskValue"
  },
  {
    id: 24,
    field: "occupancy",
    isInstrument: true,
    formatter: "getFloatValue"
  }
];
