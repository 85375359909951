import { ReportDetailsAction, ReportDetailsState } from "./types";

const SET_REPORT_DETAILS_LOADING = "SET_REPORT_DETAILS_LOADING";

export const setReportDetailsLoading = (
  value: boolean
): ReportDetailsAction => ({
  type: SET_REPORT_DETAILS_LOADING,
  payload: { value }
});

export default (
  state: ReportDetailsState = {
    isLoading: true
  },
  { type, payload }: ReportDetailsAction
) => {
  switch (type) {
    case SET_REPORT_DETAILS_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    default:
      return state;
  }
};
