import { InvoiceModalAction, InvoiceModalState } from "./types";

const SET_INVOICE_MODAL_VISIBILITY = "SET_INVOICE_MODAL_VISIBILITY";

export const setInvoiceModalVisibility = (
  value: boolean
): InvoiceModalAction => ({
  type: SET_INVOICE_MODAL_VISIBILITY,
  payload: { value }
});

export default (
  state: InvoiceModalState = {
    isVisible: false
  },
  { type, payload }: InvoiceModalAction
) => {
  switch (type) {
    case SET_INVOICE_MODAL_VISIBILITY:
      return {
        ...state,
        isVisible: payload.value
      };
    default:
      return state;
  }
};
