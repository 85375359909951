import {
  Action,
  CompareFund,
  ErrorAction,
  Fund,
  FundsAction,
  FundsAifState,
  Meta,
  News,
  PaidDocument,
} from "../types";
import { Observable, of } from "rxjs";
import { catchError, filter, mergeMap, takeUntil } from "rxjs/operators";
import { setFundsLoading, setSelectedFundLast } from "../../ui/fundsList";

import { API_URL } from "../config";
import { ajax } from "rxjs/ajax";
import { getReqOptions } from "./../config";
import { lang } from "../../../lang/lang";
import { ofType } from "redux-observable";
import { setFundAifNewsLoading } from "../../ui/fundDetails";
import { setFundPhotoModalUploading } from "./../../ui/fundPhotoModal";
import { setSelectedFund } from "./search";

const FETCH_FUND_AIF_NEWS_REQUESTED = "FETCH_FUND_AIF_NEWS_REQUESTED";
const FETCH_FUND_AIF_NEWS_SUCCESSFUL = "FETCH_FUND_AIF_NEWS_SUCCESSFUL";
const FETCH_FUND_AIF_NEWS_REJECTED = "FETCH_FUND_AIF_NEWS_REJECTED";
const FETCH_AIF_FUNDS_REQUESTED = "FETCH_AIF_FUNDS_REQUESTED";
const FETCH_AIF_FUNDS_SUCCESSFUL = "FETCH_AIF_FUNDS_SUCCESSFUL";
const FETCH_AIF_FUNDS_REJECTED = "FETCH_AIF_FUNDS_REJECTED";
const SAVE_FUND_REQUESTED = "SAVE_FUND_REQUESTED";
const SAVE_FUND_SUCCESSFUL = "SAVE_FUND_SUCCESSFUL";
const SAVE_FUND_REJECTED = "SAVE_FUND_REJECTED";
const FETCH_PAID_DOCUMENT_REQUESTED = "FETCH_PAID_DOCUMENT_REQUESTED";
const FETCH_PAID_DOCUMENT_SUCCESSFUL = "FETCH_PAID_DOCUMENT_SUCCESSFUL";
const FETCH_PAID_DOCUMENT_REJECTED = "FETCH_PAID_DOCUMENT_REJECTED";
const SET_SELECTED_AIF_FUNDS = "SET_SELECTED_AIF_FUNDS";

export const fetchFundAifNews = (id: number): FundsAction => ({
  type: FETCH_FUND_AIF_NEWS_REQUESTED,
  payload: { id },
});

const fetchFundAifNewsSuccessful = (news: News[], id: number): FundsAction => ({
  type: FETCH_FUND_AIF_NEWS_SUCCESSFUL,
  payload: { news, id },
});

const fetchFundAifNewsRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_FUND_AIF_NEWS_REJECTED,
  error,
});

export const fetchAifFunds = (id?: number): FundsAction => ({
  type: FETCH_AIF_FUNDS_REQUESTED,
  payload: { id },
});

const fetchAifFundsSuccessful = (funds: CompareFund[]): FundsAction => ({
  type: FETCH_AIF_FUNDS_SUCCESSFUL,
  payload: { funds },
});

const fetchAifFundsRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_AIF_FUNDS_REJECTED,
  error,
});

export const saveFund = (fund: Fund, meta: Meta): FundsAction => ({
  type: SAVE_FUND_REQUESTED,
  payload: { fund, meta },
});

export const saveFundSuccessful = (fund: Fund): FundsAction => ({
  type: SAVE_FUND_SUCCESSFUL,
  payload: { fund },
});

export const saveFundRejected = (error?: TypeError): ErrorAction => ({
  type: SAVE_FUND_REJECTED,
  error,
});

export const fetchPaidDocument = (): Action => ({
  type: FETCH_PAID_DOCUMENT_REQUESTED,
});

const fetchPaidDocumentSuccessful = (document: PaidDocument): FundsAction => ({
  type: FETCH_PAID_DOCUMENT_SUCCESSFUL,
  payload: { document },
});

const fetchPaidDocumentRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_PAID_DOCUMENT_REJECTED,
  error,
});

export const setSelectedAifFunds = (
  fund: Fund,
  isChecked: boolean
): FundsAction => ({
  type: SET_SELECTED_AIF_FUNDS,
  payload: { fund, isChecked },
});

export default (
  state: FundsAifState = {
    selectedAifFunds: {},
    fundAifNews: {},
    aifFunds: [],
    paidDocument: null,
  },
  { type, payload }: FundsAction
) => {
  switch (type) {
    case FETCH_FUND_AIF_NEWS_SUCCESSFUL:
      return {
        ...state,
        fundAifNews: {
          ...state.fundAifNews,
          [payload.id]: payload.news,
        },
      };
    case FETCH_AIF_FUNDS_SUCCESSFUL:
      return {
        ...state,
        aifFunds: payload.funds,
      };
    case FETCH_PAID_DOCUMENT_SUCCESSFUL:
      return {
        ...state,
        paidDocument: payload.document,
      };
    case SET_SELECTED_AIF_FUNDS:
      if (payload.isChecked) {
        return {
          ...state,
          selectedAifFunds: {
            ...state.selectedAifFunds,
            [payload.fund.id]: payload.fund,
          },
        };
      } else {
        const selectedAifFunds = state.selectedAifFunds;
        if (selectedAifFunds[payload.fund.id]) {
          delete selectedAifFunds[payload.fund.id];
        }

        return {
          ...state,
          selectedAifFunds: {
            ...selectedAifFunds,
          },
        };
      }
    default:
      return state;
  }
};

export const fetchFundAifNewsEpic = (
  action$: Observable<FundsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_FUND_AIF_NEWS_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .get(
          `${API_URL}/aif/news/?fund_id=${payload.id
          }&lang=${lang.getLanguage()}`
        )
        .pipe(
          mergeMap(({ response }) =>
            of(
              setFundAifNewsLoading(false),
              fetchFundAifNewsSuccessful(response.results, payload.id)
            )
          ),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === FETCH_FUND_AIF_NEWS_REQUESTED)
            )
          ),
          catchError(error =>
            of(setFundAifNewsLoading(false), fetchFundAifNewsRejected(error))
          )
        )
    )
  );

export const fetchAifFundsEpic = (
  action$: Observable<FundsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_AIF_FUNDS_REQUESTED),
    mergeMap(({ payload }) =>
      ajax.get(`${API_URL}/aif/funds/`).pipe(
        mergeMap(({ response }) => {
          const selectedFund = response.filter(
            fund => fund.id === payload.id
          )[0];
          return of(
            setFundsLoading(false),
            setSelectedFund(selectedFund),
            setSelectedFundLast(selectedFund?.instrument?.rank > response.length),
            fetchAifFundsSuccessful(response)
          );
        }),
        takeUntil(
          action$.pipe(filter(({ type }) => type === FETCH_AIF_FUNDS_REQUESTED))
        ),
        catchError(error =>
          of(setFundsLoading(false), fetchAifFundsRejected(error))
        )
      )
    )
  );

export const saveFundEpic = (
  action$: Observable<FundsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(SAVE_FUND_REQUESTED),
    mergeMap(({ payload }) => {
      const { resolve, reject }: Meta = payload.meta;

      return ajax
        .post(
          `${API_URL}/funds/${payload.fund.id}/photo/`,
          payload.fund,
          getReqOptions()
        )
        .pipe(
          mergeMap(({ response }) => {
            if (resolve) resolve(response);
            return of(
              setFundPhotoModalUploading(false),
              saveFundSuccessful(response)
            );
          }),
          catchError(error => {
            if (reject) reject(error);
            return of(
              setFundPhotoModalUploading(false),
              saveFundRejected(error)
            );
          })
        );
    })
  );

export const fetchPaidDocumentEpic = (
  action$: Observable<FundsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_PAID_DOCUMENT_REQUESTED),
    mergeMap(() =>
      ajax.get(`${API_URL}/aif/scope_aif_document/`, getReqOptions()).pipe(
        mergeMap(({ response }) => of(fetchPaidDocumentSuccessful(response))),
        takeUntil(
          action$.pipe(
            filter(({ type }) => type === FETCH_PAID_DOCUMENT_REQUESTED)
          )
        ),
        catchError(error => of(fetchPaidDocumentRejected(error)))
      )
    )
  );
