import {
  SurveySubmissionModalAction,
  SurveySubmissionModalState,
} from "./types";
const SET_SURVEY_SUBMISSION_MODAL_VISIBILITY =
  "SET_SURVEY_SUBMISSION_MODAL_VISIBILITY";

export const setSurveySubmissionModalVisibility = (
  value: boolean
): SurveySubmissionModalAction => ({
  type: SET_SURVEY_SUBMISSION_MODAL_VISIBILITY,
  payload: { value },
});

export default (
  state: SurveySubmissionModalState = {
    isVisible: false,
  },
  { type, payload }: SurveySubmissionModalAction
) => {
  switch (type) {
    case SET_SURVEY_SUBMISSION_MODAL_VISIBILITY:
      return {
        ...state,
        isVisible: payload.value,
      };
    default:
      return state;
  }
};
