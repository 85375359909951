import { PurchaseModalAction, PurchaseModalState } from "./types";

const SET_PURCHASE_MODAL_VISIBILITY = "SET_PURCHASE_MODAL_VISIBILITY";
const SET_PURCHASE_MODAL_LOADING = "SET_PURCHASE_MODAL_LOADING";

export const setPurchaseModalVisibility = (
  value: boolean
): PurchaseModalAction => ({
  type: SET_PURCHASE_MODAL_VISIBILITY,
  payload: { value }
});

export const setPurchaseModalLoading = (
  value: boolean
): PurchaseModalAction => ({
  type: SET_PURCHASE_MODAL_LOADING,
  payload: { value }
});

export default (
  state: PurchaseModalState = {
    isVisible: false,
    isLoading: false
  },
  { type, payload }: PurchaseModalAction
) => {
  switch (type) {
    case SET_PURCHASE_MODAL_VISIBILITY:
      return {
        ...state,
        isVisible: payload.value
      };
    case SET_PURCHASE_MODAL_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    default:
      return state;
  }
};
