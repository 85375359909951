import { API_URL, chartsUrls, getReqOptions } from "./config";
import {
  Action,
  ChartData,
  ChartsAction,
  ChartsState,
  ErrorAction,
} from "./types";
import { Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { getChartData, getChartXYValue } from "../../utils/funds";
import {
  setComparisonAutocompleteValue,
  setComparisonLoading,
} from "../ui/comparisonModal";

import { StateValue } from "../types";
import { ajax } from "rxjs/ajax";
import { getDateTickInterval } from "../../utils/charts";
import { ofType } from "redux-observable";
import { setChartLoading } from "../ui/fundCharts";
import { setChartTickInterval } from "./../ui/fundCharts";
import { setDetailsLoading } from "../ui/fundDetails";

const FETCH_CHART_DATA_REQUESTED = "FETCH_CHART_DATA_REQUESTED";
const FETCH_CHART_DATA_SUCCESSFUL = "FETCH_CHART_DATA_SUCCESSFUL";
const FETCH_CHART_DATA_REJECTED = "FETCH_CHART_DATA_REJECTED";
const FETCH_COMPARISON_CHART_DATA_REQUESTED =
  "FETCH_COMPARISON_CHART_DATA_REQUESTED";
const FETCH_COMPARISON_CHART_DATA_SUCCESSFUL =
  "FETCH_COMPARISON_CHART_DATA_SUCCESSFUL";
const FETCH_COMPARISON_CHART_DATA_REJECTED =
  "FETCH_COMPARISON_CHART_DATA_REJECTED";
const RESET_DETAILS_CHARTS = "RESET_DETAILS_CHARTS";
const RESET_COMPARISON_CHART = "RESET_COMPARISON_CHART";
const FETCH_ACTIVE_CHARTS_REQUESTED = "FETCH_ACTIVE_CHARTS_REQUESTED";
const SET_CHARTS_LOADING = "SET_CHARTS_LOADING";

export const fetchChartData = (
  id: number,
  chart: string,
  peergroup: number
): ChartsAction => ({
  type: FETCH_CHART_DATA_REQUESTED,
  payload: { id, chart, peergroup },
});

const fetchChartDataSuccessful = (
  chart: string,
  data: ChartData
): ChartsAction => ({
  type: FETCH_CHART_DATA_SUCCESSFUL,
  payload: { chart, data },
});

const fetchChartDataRejected = (
  error?: TypeError,
  chart?: string
): ErrorAction | ChartsAction => ({
  type: FETCH_CHART_DATA_REJECTED,
  payload: { chart },
  error,
});

export const fetchComparisonChartData = (
  fundId: number,
  secondFundId: number,
  thirdFundId: number
): ChartsAction => ({
  type: FETCH_COMPARISON_CHART_DATA_REQUESTED,
  payload: { fundId, secondFundId, thirdFundId },
});

const fetchComparisonChartDataSuccessful = (
  chart: string,
  data: ChartData
): ChartsAction => ({
  type: FETCH_COMPARISON_CHART_DATA_SUCCESSFUL,
  payload: { chart, data },
});

const fetchComparisonChartDataRejected = (
  error?: TypeError,
  chart?: string
): ErrorAction | ChartsAction => ({
  type: FETCH_COMPARISON_CHART_DATA_REJECTED,
  payload: { chart },
  error,
});

export const resetDetailsCharts = (): Action => ({
  type: RESET_DETAILS_CHARTS,
});

export const resetComparisonChart = (): Action => ({
  type: RESET_COMPARISON_CHART,
});

export const setChartsLoading = (): Action => ({
  type: SET_CHARTS_LOADING,
});

export const fetchActiveCharts = (
  id: number,
  trancheId: number,
  fundId: number,
  peergroupId: number
): ChartsAction => ({
  type: FETCH_ACTIVE_CHARTS_REQUESTED,
  payload: { id, trancheId, fundId, peergroupId },
});

export default (
  state: ChartsState = {
    performanceChart: null,
    peergroupComparisonChart: null,
    crashDrawdownChart: null,
    riskReturnChart: null,
    fundRatingChart: null,
    classDistributionChart: null,
    distributionChart: null,
    evolutionOfRiskChart: null,
    comparisonChart: null,
  },
  { type, payload }: ChartsAction
) => {
  switch (type) {
    case FETCH_CHART_DATA_REJECTED:
      return {
        ...state,
        [payload.chart]: { data: [] },
      };
    case FETCH_CHART_DATA_SUCCESSFUL:
      const { chart, data } = payload;
      let result = null;
      if (chart === "performanceChart") {
        result = {
          fund: getChartData(data, "fund"),
          index: getChartData(data, "index"),
          peergroup: getChartData(data, "peergroup"),
        };
      } else if (
        chart === "peergroupComparisonChart" ||
        chart === "evolutionOfRiskChart"
      ) {
        result = {
          fund: getChartData(data, "fund"),
          worstFund: getChartData(data, "worstFund"),
          bestFund: getChartData(data, "bestFund"),
        };
      } else if (chart === "fundRatingChart") {
        result = {
          rating: getChartXYValue("rating", data),
        };
      } else if (chart === "distributionChart") {
        result = {
          fund: data.fund,
          distribution: data.distribution,
        };
      } else if (
        chart === "riskReturnChart" ||
        chart === "classDistributionChart"
      ) {
        result = data;
      } else if (chart === "crashDrawdownChart") {
        result = {
          name: data?.name,
          data: getChartXYValue("", data?.data),
        };
      }

      return {
        ...state,
        [payload.chart]: result,
      };
    case FETCH_COMPARISON_CHART_DATA_SUCCESSFUL:
      return {
        ...state,
        [payload.chart]: {
          fund: getChartData(payload.data, "fund"),
          second_fund: getChartData(payload.data, "second_fund"),
          third_fund: getChartData(payload.data, "third_fund"),
        },
      };
    case RESET_COMPARISON_CHART:
      return {
        ...state,
        comparisonChart: null,
      };
    case RESET_DETAILS_CHARTS:
      return {
        ...state,
        performanceChart: null,
        peergroupComparisonChart: null,
        riskReturnChart: null,
        fundRatingChart: null,
        classDistributionChart: null,
        distributionChart: null,
        evolutionOfRiskChart: null,
        comparisonChart: null,
      };
    default:
      return state;
  }
};

export const fetchChartDataEpic = (
  action$: Observable<ChartsAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_CHART_DATA_REQUESTED),
    mergeMap(({ payload }) => {
      const { id, chart, peergroup } = payload;
      const chartState = state.value.ui.fundCharts[chart];
      const period = chartState ? chartState.period : 3;
      const volatilityPeriod = chartState ? chartState.volatilityPeriod : 3;

      if (state.value.ui.fundCharts[chart].isVisible) {
        let queryParams = period ? `?period=${period}` : "";

        queryParams += volatilityPeriod
          ? `&vol_period=${volatilityPeriod}`
          : "";

        const hasPeergroup =
          chart === "evolutionOfRiskChart" ||
          chart === "performanceChart" ||
          chart === "classDistributionChart" ||
          chart === "distributionChart";

        const hasParams = chart !== "crashDrawdownChart";

        const params = hasParams
          ? `${id}/${hasPeergroup ? `${peergroup}/` : ""}${queryParams}`
          : "";

        return ajax
          .get(`${API_URL}/${chartsUrls[chart]}/${params}`, getReqOptions())
          .pipe(
            mergeMap(({ response }) =>
              of(
                setChartLoading(false, chart),
                setChartTickInterval(getDateTickInterval(), chart),
                fetchChartDataSuccessful(chart, response)
              )
            ),
            catchError(error =>
              of(
                setChartLoading(false, chart),
                fetchChartDataRejected(error, chart)
              )
            )
          );
      } else {
        return of(
          setChartLoading(false, chart),
          fetchChartDataRejected(null, chart)
        );
      }
    })
  );

export const fetchComparisonChartDataEpic = (
  action$: Observable<ChartsAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_COMPARISON_CHART_DATA_REQUESTED),
    mergeMap(({ payload }) => {
      const { fundId, secondFundId, thirdFundId } = payload;

      if (fundId !== -1) {
        const chartState = state.value.ui.fundCharts["comparisonChart"];
        const period = chartState ? chartState.period : 3;

        let queryParams = period ? `?period=${period}` : "";
        if (secondFundId && secondFundId !== -1)
          queryParams += `&second_tranche_id=${secondFundId}`;
        if (thirdFundId && thirdFundId !== -1)
          queryParams += `&third_tranche_id=${thirdFundId}`;

        return ajax
          .get(
            `${API_URL}/${chartsUrls["comparisonChart"]}/${fundId}/${queryParams}`,
            getReqOptions()
          )
          .pipe(
            mergeMap(({ response }) =>
              of(
                setChartLoading(false, "comparisonChart"),
                setComparisonLoading(false),
                setComparisonAutocompleteValue(response.second_fund.name, 0),
                setComparisonAutocompleteValue(response.third_fund.name, 1),
                fetchComparisonChartDataSuccessful("comparisonChart", response)
              )
            ),
            catchError(error =>
              of(
                setChartLoading(false, "comparisonChart"),
                setComparisonLoading(false),
                fetchComparisonChartDataRejected(error, "comparisonChart")
              )
            )
          );
      } else {
        return of(
          setChartLoading(false, "comparisonChart"),
          setComparisonLoading(false),
          resetComparisonChart(),
          fetchComparisonChartDataRejected(null, "comparisonChart")
        );
      }
    })
  );

export const setChartsLoadingEpic = (
  action$: Observable<ChartsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(SET_CHARTS_LOADING),
    mergeMap(() =>
      of(
        setChartLoading(true, "performanceChart"),
        setChartLoading(true, "peergroupComparisonChart"),
        setChartLoading(true, "riskReturnChart"),
        setChartLoading(true, "fundRatingChart"),
        setChartLoading(true, "classDistributionChart"),
        setChartLoading(true, "distributionChart"),
        setChartLoading(true, "evolutionOfRiskChart")
      )
    )
  );

export const fetchActiveChartsEpic = (
  action$: Observable<ChartsAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_ACTIVE_CHARTS_REQUESTED),
    mergeMap(({ payload }) => {
      const { id, trancheId, fundId, peergroupId } = payload;
      const {
        performanceChart,
        peergroupComparisonChart,
        riskReturnChart,
        fundRatingChart,
        classDistributionChart,
        distributionChart,
        evolutionOfRiskChart,
      } = state.value.ui.fundCharts;
      if (state.value.domain.auth.isWholesaleUser) {
        const actions = [setChartsLoading()];
        if (performanceChart.isVisible) {
          actions.push(
            fetchChartData(trancheId, "performanceChart", peergroupId)
          );
        }
        if (peergroupComparisonChart.isVisible) {
          actions.push(
            fetchChartData(fundId, "peergroupComparisonChart", peergroupId)
          );
        }
        if (riskReturnChart.isVisible) {
          actions.push(fetchChartData(id, "riskReturnChart", peergroupId));
        }
        if (fundRatingChart.isVisible) {
          actions.push(fetchChartData(id, "fundRatingChart", peergroupId));
        }
        if (classDistributionChart.isVisible) {
          actions.push(
            fetchChartData(trancheId, "classDistributionChart", peergroupId)
          );
        }
        if (distributionChart.isVisible) {
          actions.push(
            fetchChartData(trancheId, "distributionChart", peergroupId)
          );
        }
        if (evolutionOfRiskChart.isVisible) {
          actions.push(
            fetchChartData(trancheId, "evolutionOfRiskChart", peergroupId)
          );
        }
        return of(...actions);
      } else {
        return of(setDetailsLoading(false));
      }
    })
  );
