import { fetchFilters } from "../redux/domain/filters";
import { fetchLicenses } from "../redux/domain/licenses";
import { fetchRecordDate } from "../redux/ui/app";
import { refreshToken } from "../redux/domain/auth";
import { store } from "./../redux/index";

export const sxAuth = () => {
  store.dispatch(refreshToken());
  setTimeout(() => {
    store.dispatch(fetchRecordDate());
    store.dispatch(fetchFilters());
    store.dispatch(fetchLicenses());
  }, 1);

  setInterval(() => {
    if (store.getState().domain.auth.isAuthenticated) {
      store.dispatch(refreshToken());
    }
  }, 540000);
};
