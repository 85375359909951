import { lang } from "../lang/lang";

export const BASE_URL = "https://www.scopeexplorer.com";
export const SCOPE_IMAGES_URL =
  "https://www.scopegroup.com/ScopeGroupApi/api/announcement-img?imageName=";
export const HOME_VIDEO_URL =
  "https://www.youtube-nocookie.com/embed/toSzVEO5AM8?rel=0&amp;showinfo=0&modestbranding=1&color=white&autoplay=0&controls=1";
export const WHOLESALE_VIDEO_URL =
  "https://www.youtube-nocookie.com/embed/oTx53yrC1cE?rel=0&amp;showinfo=0&modestbranding=1&color=white&autoplay=0&controls=1";

export const MAGNOLIA_IMAGES_URL =
  window.location.hostname === "www.scopeexplorer.com"
    ? "https://scopegroup.com"
    : "https://staging.scoperatings.com";

export const searchParamsConfig = {
  formName: "advancedSearch",
  fields: [
    { name: "searchPhrase", type: "single" },
    { name: "peergroups", type: "multi" },
    { name: "companies", type: "multi" },
    { name: "universes", type: "select" },
    { name: "rating", type: "multi" },
    { name: "fundSize", type: "single" },
    { name: "fundSizeSign", type: "single" },
    { name: "investmentFocus", type: "select" },
    { name: "mainTranche", type: "single" },
    { name: "page", type: "single" },
    { name: "perPage", type: "single" },
    { name: "ordering", type: "single" },
  ],
};

export const newsParamsConfig = {
  formName: "news",
  fields: [
    { name: "searchPhrase", type: "single" },
    { name: "lang", type: "single" },
    { name: "page", type: "single" },
    { name: "perPage", type: "single" },
  ],
};

export const reportsParamsConfig = {
  formName: "reports",
  fields: [
    { name: "lang", type: "single" },
    { name: "page", type: "single" },
    { name: "perPage", type: "single" },
  ],
};

export const fundsTableParamsConfig = {
  formName: "fundsTable",
  fields: [
    { name: "searchPhrase", type: "single" },
    { name: "page", type: "single" },
    { name: "perPage", type: "single" },
    { name: "ordering", type: "single" },
  ],
};

export const filterSearchParamsConfig = {
  formName: "filterSearch",
  fields: [
    { name: "regions", type: "multi" },
    { name: "investmentFocus", type: "multi" },
    { name: "srri", type: "multi" },
  ],
};

export const queryFilters = {
  searchPhrase: "q",
  peergroups: "p",
  companies: "c",
  lang: "lang",
  universes: "u",
  rating: "r",
  fundSize: "s",
  fundSizeSign: "fs",
  investmentFocus: "i",
  srri: "srri",
  regions: "region",
  mainTranche: "m",
  page: "page",
  perPage: "per_page",
  ordering: "ordering",
};

export const advancedSearchFields = [
  "peergroups",
  "investmentFocus",
  "companies",
  "fundSize",
  "fundSizeSign",
  "universes",
  "rating",
  "mainTranche",
];

export const reportsTypes = [
  "monthly_report",
  "annual_report",
  "key_information",
  "fund_prospect",
  "semi_annual_report",
  "key_investor"
];

export const getInvestmentFocusLabels = () => [
  lang.fund.all,
  lang.fund.equity,
  lang.fund.fixedIncome,
  lang.fund.other,
];
