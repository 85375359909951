import { ReportsAction, ReportsState } from "./types";

const SET_REPORTS_LOADING = "SET_REPORTS_LOADING";
const SET_REPORTS_LIST_TOTAL = "SET_REPORTS_LIST_TOTAL";
const SET_REPORTS_CARDS_VIEW = "SET_REPORTS_CARDS_VIEW";

export const setReportsLoading = (value: boolean): ReportsAction => ({
  type: SET_REPORTS_LOADING,
  payload: { value }
});

export const setReportsListTotal = (value: number): ReportsAction => ({
  type: SET_REPORTS_LIST_TOTAL,
  payload: { value }
});

export const setReportsCardsView = (value: boolean): ReportsAction => ({
  type: SET_REPORTS_CARDS_VIEW,
  payload: { value }
});

export default (
  state: ReportsState = {
    total: 0,
    isReportsLoading: true,
    isReportsCardsView: true
  },
  { type, payload }: ReportsAction
) => {
  switch (type) {
    case SET_REPORTS_LOADING:
      return {
        ...state,
        isReportsLoading: payload.value
      };
    case SET_REPORTS_LIST_TOTAL:
      return {
        ...state,
        total: payload.value
      };
    case SET_REPORTS_CARDS_VIEW:
      return {
        ...state,
        isReportsCardsView: payload.value
      };
    default:
      return state;
  }
};
