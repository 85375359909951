import {
  Action,
  AnyFund,
  ErrorAction,
  FundsAction,
  FundsCommonState,
} from "../types";
import { Observable, from, of } from "rxjs";
import {
  catchError,
  filter,
  mergeMap,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { API_URL } from "../config";
import { StateValue } from "../../types";
import { addErrorToast } from "./../../ui/toasts";
import { ajax } from "rxjs/ajax";
import { fetch } from "cross-fetch";
import { getReqOptions } from "./../config";
import { lang } from "../../../lang/lang";
import { ofType } from "redux-observable";
import { setExportLoading } from "./../../ui/fundsList";

const SET_PRIMARY_COMPARE_FUND = "SET_PRIMARY_COMPARE_FUND";
const SET_PERFORMANCE_MODAL_FUND = "SET_PERFORMANCE_MODAL_FUND";
const SET_HISTORY_MODAL_FUND = "SET_HISTORY_MODAL_FUND";

const INCREMENT_STATS_REQUESTED = "INCREMENT_STATS_REQUESTED";
const INCREMENT_STATS_SUCCESSFUL = "INCREMENT_STATS_SUCCESSFUL";
const INCREMENT_STATS_REJECTED = "INCREMENT_STATS_REJECTED";

const GENERATE_EXPORT_FILE_REQUESTED = "GENERATE_EXPORT_FILE_REQUESTED";
const GENERATE_EXPORT_FILE_SUCCESSFUL = "GENERATE_EXPORT_FILE_SUCCESSFUL";
const GENERATE_EXPORT_FILE_REJECTED = "GENERATE_EXPORT_FILE_REJECTED";

export const setPrimaryCompareFund = (fund: AnyFund | null): FundsAction => ({
  type: SET_PRIMARY_COMPARE_FUND,
  payload: { fund },
});

export const setPerformanceModalFund = (fund: AnyFund | null): FundsAction => ({
  type: SET_PERFORMANCE_MODAL_FUND,
  payload: { fund },
});

export const setHistoryModalFund = (fund: AnyFund | null): FundsAction => ({
  type: SET_HISTORY_MODAL_FUND,
  payload: { fund },
});

export const incrementStats = (id: number): FundsAction => ({
  type: INCREMENT_STATS_REQUESTED,
  payload: { id },
});

const incrementStatsSuccessful = (): Action => ({
  type: INCREMENT_STATS_SUCCESSFUL,
});

const incrementStatsRejected = (error?: TypeError): ErrorAction => ({
  type: INCREMENT_STATS_REJECTED,
  error,
});

export const generateExportFile = (fields?: string[]): FundsAction => ({
  type: GENERATE_EXPORT_FILE_REQUESTED,
  payload: { fields },
});

const generateExportFileSuccessful = (): Action => ({
  type: GENERATE_EXPORT_FILE_SUCCESSFUL,
});

const generateExportFileRejected = (error?: TypeError): ErrorAction => ({
  type: GENERATE_EXPORT_FILE_REJECTED,
  error,
});

export default (
  state: FundsCommonState = {
    primaryCompareFund: null,
    performanceModalFund: null,
    historyModalFund: null,
  },
  { type, payload }: FundsAction
) => {
  switch (type) {
    case SET_PRIMARY_COMPARE_FUND:
      return {
        ...state,
        primaryCompareFund: payload.fund,
      };
    case SET_PERFORMANCE_MODAL_FUND:
      return {
        ...state,
        performanceModalFund: payload.fund,
      };
    case SET_HISTORY_MODAL_FUND:
      return {
        ...state,
        historyModalFund: payload.fund,
      };
    default:
      return state;
  }
};

export const generateExportFileEpic = (
  action$: Observable<FundsAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(GENERATE_EXPORT_FILE_REQUESTED),
    mergeMap(({ payload }) => {
      const search = window.location.search;
      const total = state.value.ui.fundsTable.total;
      const queryParams = search.replace(
        /(\?|&)per_page=[0-9]+/g,
        `$1per_page=${total}`
      );

      const fetchObservable = from(
        fetch(`${API_URL}/generate_report/${queryParams}`, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ fields: payload.fields }),
        })
      );
      return fetchObservable.pipe(
        switchMap(response =>
          response.status === 200 ? response.arrayBuffer() : "error"
        ),
        mergeMap(response => {
          if (response !== "error") {
            const blob = response;
            const downloadLink = window.document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(
              new Blob([blob], {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            downloadLink.download = lang.advancedFundsList.filename;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            return of(setExportLoading(false), generateExportFileSuccessful());
          } else {
            return of(
              setExportLoading(false),
              addErrorToast("Error: unable to export funds.")
            );
          }
        }),
        takeUntil(
          action$.pipe(
            filter(({ type }) => type === GENERATE_EXPORT_FILE_REQUESTED)
          )
        ),
        catchError(error =>
          of(setExportLoading(false), generateExportFileRejected(error))
        )
      );
    }),
    catchError(error =>
      of(setExportLoading(false), generateExportFileRejected(error))
    )
  );

export const incrementStatsEpic = (action$: Observable<any>): any =>
  action$.pipe(
    ofType(INCREMENT_STATS_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .put(
          `${API_URL}/stats/rating_points_requests`,
          { fund_id: payload.id },
          getReqOptions()
        )
        .pipe(
          mergeMap(() => of(incrementStatsSuccessful())),
          catchError(error => of(incrementStatsRejected(error)))
        )
    )
  );
