import { FundDetailsAction, FundDetailsState } from "./types";

const SET_DETAILS_LOADING = "SET_DETAILS_LOADING";
const SET_FUND_AIF_NEWS_LOADING = "SET_FUND_AIF_NEWS_LOADING";
const SET_COMPARISON_VISIBILITY = "SET_COMPARISON_VISIBILITY";
const SET_GENERATING_PDF = "SET_GENERATING_PDF";

export const setDetailsLoading = (value: boolean): FundDetailsAction => ({
  type: SET_DETAILS_LOADING,
  payload: { value },
});

export const setFundAifNewsLoading = (value: boolean): FundDetailsAction => ({
  type: SET_FUND_AIF_NEWS_LOADING,
  payload: { value },
});

export const setComparisonVisibility = (value: boolean): FundDetailsAction => ({
  type: SET_COMPARISON_VISIBILITY,
  payload: { value },
});

export const setGeneratingPdf = (value: boolean): FundDetailsAction => ({
  type: SET_GENERATING_PDF,
  payload: { value },
});

export default (
  state: FundDetailsState = {
    isLoading: true,
    isFundAifNewsLoading: false,
    isComparisonVisible: false,
    isGeneratingPdf: false,
  },
  { type, payload }: FundDetailsAction
) => {
  switch (type) {
    case SET_DETAILS_LOADING:
      return {
        ...state,
        isLoading: payload.value,
      };
    case SET_FUND_AIF_NEWS_LOADING:
      return {
        ...state,
        isFundAifNewsLoading: payload.value,
      };
    case SET_COMPARISON_VISIBILITY:
      return {
        ...state,
        isComparisonVisible: payload.value,
      };
    case SET_GENERATING_PDF:
      return {
        ...state,
        isGeneratingPdf: payload.value,
      };
    default:
      return state;
  }
};