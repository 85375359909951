import { WatchlistAction, WatchlistState } from "./types";

const SET_WATCHLIST_LOADING = "SET_WATCHLIST_LOADING";

export const setWatchlistLoading = (value: boolean): WatchlistAction => ({
  type: SET_WATCHLIST_LOADING,
  payload: { value }
});

export default (
  state: WatchlistState = {
    isLoading: false
  },
  { type, payload }: WatchlistAction
) => {
  switch (type) {
    case SET_WATCHLIST_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    default:
      return state;
  }
};
