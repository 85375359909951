import { API_URL, getReqOptions } from "./config";
import {
  Action,
  Conference,
  ConferencesAction,
  ConferencesState,
  ErrorAction,
  Meta,
} from "./types";
import { Observable, of } from "rxjs";
import { addErrorToast, addSuccessToast } from "./../ui/toasts";
import { catchError, filter, mergeMap, takeUntil } from "rxjs/operators";
import {
  setConferenceDetailsLoading,
  setConferenceRegisterLoading,
} from "./../ui/conferenceDetails";

import { StateValue } from "../types";
import { ajax } from "rxjs/ajax";
import { fetchUserWebinars } from "./auth";
import { getFormattedDate } from "../../utils/formats";
import { lang } from "../../lang/lang";
import { ofType } from "redux-observable";
import { push } from "connected-react-router";
import { setConferencesListLoading } from "../ui/conferencesList";

const FETCH_CONFERENCES_LIST_REQUESTED = "FETCH_CONFERENCES_LIST_REQUESTED";
const FETCH_CONFERENCES_LIST_SUCCESSFUL = "FETCH_CONFERENCES_LIST_SUCCESSFUL";
const FETCH_CONFERENCES_LIST_REJECTED = "FETCH_CONFERENCES_LIST_REJECTED";
const FETCH_SINGLE_CONFERENCE_REQUESTED = "FETCH_SINGLE_CONFERENCE_REQUESTED";
const FETCH_SINGLE_CONFERENCE_SUCCESSFUL = "FETCH_SINGLE_CONFERENCE_SUCCESSFUL";
const FETCH_SINGLE_CONFERENCE_REJECTED = "FETCH_SINGLE_CONFERENCE_REJECTED";
const ADD_CONFERENCE_REQUESTED = "ADD_CONFERENCE_REQUESTED";
const ADD_CONFERENCE_SUCCESSFUL = "ADD_CONFERENCE_SUCCESSFUL";
const ADD_CONFERENCE_REJECTED = "ADD_CONFERENCE_REJECTED";
const EDIT_CONFERENCE_REQUESTED = "EDIT_CONFERENCE_REQUESTED";
const EDIT_CONFERENCE_SUCCESSFUL = "EDIT_CONFERENCE_SUCCESSFUL";
const EDIT_CONFERENCE_REJECTED = "EDIT_CONFERENCE_REJECTED";
const REGISTER_FOR_CONFERENCE_REQUESTED = "REGISTER_FOR_CONFERENCE_REQUESTED";
const REGISTER_FOR_CONFERENCE_SUCCESSFUL = "REGISTER_FOR_CONFERENCE_SUCCESSFUL";
const REGISTER_FOR_CONFERENCE_REJECTED = "REGISTER_FOR_CONFERENCE_REJECTED";
const UNREGISTER_FROM_CONFERENCE_REQUESTED =
  "UNREGISTER_FROM_CONFERENCE_REQUESTED";
const UNREGISTER_FROM_CONFERENCE_SUCCESSFUL =
  "UNREGISTER_FROM_CONFERENCE_SUCCESSFUL";
const UNREGISTER_FROM_CONFERENCE_REJECTED =
  "UNREGISTER_FROM_CONFERENCE_REJECTED";
const SEND_CONFERENCE_PARTICIPATION_CONFIRMATION_REQUESTED =
  "SEND_CONFERENCE_PARTICIPATION_CONFIRMATION_REQUESTED";
const SEND_CONFERENCE_PARTICIPATION_CONFIRMATION_SUCCESSFUL =
  "SEND_CONFERENCE_PARTICIPATION_CONFIRMATION_SUCCESSFUL";
const SEND_CONFERENCE_PARTICIPATION_CONFIRMATION_REJECTED =
  "SEND_CONFERENCE_PARTICIPATION_CONFIRMATION_REJECTED";
const SEND_CONFERENCE_REQUEST_REQUESTED = "SEND_CONFERENCE_REQUEST_REQUESTED";
const SEND_CONFERENCE_REQUEST_SUCCESSFUL = "SEND_CONFERENCE_REQUEST_SUCCESSFUL";
const SEND_CONFERENCE_REQUEST_REJECTED = "SEND_CONFERENCE_REQUEST_REJECTED";

export const fetchConferencesList = (meta?: Meta): ConferencesAction => ({
  type: FETCH_CONFERENCES_LIST_REQUESTED,
  payload: { meta },
});

export const fetchConferencesListSuccessful = (
  conferences: Conference[]
): ConferencesAction => ({
  type: FETCH_CONFERENCES_LIST_SUCCESSFUL,
  payload: { conferences },
});

export const fetchConferencesListRejected = (
  error: TypeError
): ErrorAction => ({
  type: FETCH_CONFERENCES_LIST_REJECTED,
  error,
});

export const fetchSingleConference = (id: number): ConferencesAction => ({
  type: FETCH_SINGLE_CONFERENCE_REQUESTED,
  payload: { id },
});

export const fetchSingleConferenceSuccessful = (
  singleConference: Conference
): ConferencesAction => ({
  type: FETCH_SINGLE_CONFERENCE_SUCCESSFUL,
  payload: { singleConference },
});

export const fetchSingleConferenceRejected = (
  error: TypeError
): ErrorAction => ({
  type: FETCH_SINGLE_CONFERENCE_REJECTED,
  error,
});

export const addConference = (
  conference: Conference,
  meta?: Meta
): ConferencesAction => ({
  type: ADD_CONFERENCE_REQUESTED,
  payload: { conference, meta },
});

export const addConferenceSuccessful = (
  conference: Conference
): ConferencesAction => ({
  type: ADD_CONFERENCE_SUCCESSFUL,
  payload: { conference },
});

export const addConferenceRejected = (error: TypeError): ErrorAction => ({
  type: ADD_CONFERENCE_REJECTED,
  error,
});

export const editConference = (
  values: any,
  meta?: Meta
): ConferencesAction => ({
  type: EDIT_CONFERENCE_REQUESTED,
  payload: { values, meta },
});

export const editConferenceSuccessful = (
  conference: Conference
): ConferencesAction => ({
  type: EDIT_CONFERENCE_SUCCESSFUL,
  payload: { conference },
});

export const editConferenceRejected = (error: TypeError): ErrorAction => ({
  type: EDIT_CONFERENCE_REJECTED,
  error,
});

export const registerForConference = (
  conferenceId: number,
  conferenceTitle: string
): ConferencesAction => ({
  type: REGISTER_FOR_CONFERENCE_REQUESTED,
  payload: { conferenceId, conferenceTitle },
});

export const registerForConferenceSuccessful = (): Action => ({
  type: REGISTER_FOR_CONFERENCE_SUCCESSFUL,
});

export const registerForConferenceRejected = (
  error: TypeError
): ErrorAction => ({
  type: REGISTER_FOR_CONFERENCE_REJECTED,
  error,
});

export const unregisterFromConference = (
  conferenceId: number
): ConferencesAction => ({
  type: UNREGISTER_FROM_CONFERENCE_REQUESTED,
  payload: { conferenceId },
});

export const unregisterFromConferenceSuccessful = (): Action => ({
  type: UNREGISTER_FROM_CONFERENCE_SUCCESSFUL,
});

export const unregisterFromConferenceRejected = (
  error: TypeError
): ErrorAction => ({
  type: UNREGISTER_FROM_CONFERENCE_REJECTED,
  error,
});

export const sendConferenceParticipationConfirmation = (
  conferenceTitle: string
): ConferencesAction => ({
  type: SEND_CONFERENCE_PARTICIPATION_CONFIRMATION_REQUESTED,
  payload: { conferenceTitle },
});

export const sendConferenceParticipationConfirmationSuccessful = (): Action => ({
  type: SEND_CONFERENCE_PARTICIPATION_CONFIRMATION_SUCCESSFUL,
});

export const sendConferenceParticipationConfirmationRejected = (
  error: TypeError
): ErrorAction => ({
  type: SEND_CONFERENCE_PARTICIPATION_CONFIRMATION_REJECTED,
  error,
});

export const sendConferenceRequest = (
  conference: Conference
): ConferencesAction => ({
  type: SEND_CONFERENCE_REQUEST_REQUESTED,
  payload: { conference },
});

export const sendConferenceRequestSuccessful = (): Action => ({
  type: SEND_CONFERENCE_REQUEST_SUCCESSFUL,
});

export const sendConferenceRequestRejected = (
  error: TypeError
): ErrorAction => ({
  type: SEND_CONFERENCE_REQUEST_REJECTED,
  error,
});

export default (
  state: ConferencesState = {
    conferencesList: [],
    conferences: {},
  },
  { type, payload }: ConferencesAction
) => {
  switch (type) {
    case FETCH_CONFERENCES_LIST_SUCCESSFUL:
      return {
        ...state,
        conferencesList: payload.conferences,
      };
    case FETCH_SINGLE_CONFERENCE_SUCCESSFUL:
      if (payload.singleConference) {
        return {
          ...state,
          conferences: {
            ...state.conferences,
            [payload.singleConference.id]: payload.singleConference,
          },
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export const fetchConferencesListEpic = (
  action$: Observable<ConferencesAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_CONFERENCES_LIST_REQUESTED),
    mergeMap(() => {
      const status =
        window.location.pathname.indexOf("asset-manager") !== -1
          ? ""
          : "&status=ACCEPTED";

      return ajax
        .get(
          `${API_URL}/portfolio_manager/webinars/?lang=${lang.getLanguage()}${status}`
        )
        .pipe(
          mergeMap(({ response }) =>
            of(
              setConferencesListLoading(false),
              fetchConferencesListSuccessful(response)
            )
          ),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === FETCH_CONFERENCES_LIST_REQUESTED)
            )
          ),
          catchError(error =>
            of(
              setConferencesListLoading(false),
              fetchConferencesListRejected(error)
            )
          )
        );
    })
  );

export const fetchSingleConferenceEpic = (
  action$: Observable<ConferencesAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_SINGLE_CONFERENCE_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .get(
          `${API_URL}/portfolio_manager/webinars/${
            payload.id
          }/?lang=${lang.getLanguage()}`
        )
        .pipe(
          mergeMap(({ response }) =>
            of(
              setConferenceDetailsLoading(false),
              fetchSingleConferenceSuccessful(response)
            )
          ),
          catchError(error => {
            if (error.status === 404) {
              return of(push(`/404`));
            }
            return of(
              setConferenceDetailsLoading(false),
              fetchSingleConferenceRejected(error)
            );
          })
        )
    )
  );

export const addConferenceEpic = (
  action$: Observable<ConferencesAction>
): Observable<Action> =>
  action$.pipe(
    ofType(ADD_CONFERENCE_REQUESTED),
    mergeMap(({ payload }) => {
      const { resolve, reject }: Meta | any = payload.meta || {};
      return ajax
        .post(
          `${API_URL}/portfolio_manager/webinars/`,
          payload.conference,
          getReqOptions()
        )
        .pipe(
          mergeMap(({ response }) => {
            if (resolve) resolve(response);
            return of(
              sendConferenceRequest(response),
              fetchConferencesList(),
              addConferenceSuccessful(response)
            );
          }),
          catchError(error => {
            if (reject) reject(error);
            return of(addConferenceRejected(error));
          })
        );
    })
  );

export const editConferenceEpic = (
  action$: Observable<ConferencesAction>
): Observable<Action> =>
  action$.pipe(
    ofType(EDIT_CONFERENCE_REQUESTED),
    mergeMap(({ payload }) => {
      const { resolve, reject }: Meta | any = payload.meta || {};
      return ajax
        .patch(
          `${API_URL}/portfolio_manager/webinars/${payload.values.id}/`,
          payload.values,
          getReqOptions()
        )
        .pipe(
          mergeMap(({ response }) => {
            if (resolve) resolve(response);
            return of(
              fetchConferencesList(),
              addSuccessToast(lang.conferences.editSuccess),
              editConferenceSuccessful(response)
            );
          }),
          catchError(error => {
            if (reject) reject(error);
            return of(editConferenceRejected(error));
          })
        );
    })
  );

export const registerForConferenceEpic = (
  action$: Observable<ConferencesAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(REGISTER_FOR_CONFERENCE_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .post(
          `${API_URL}/register_for_conference/`,
          {
            conferenceId: payload.conferenceId,
            conferenceTitle: payload.conferenceTitle,
            userEmail: state.value.domain.auth.user.email,
            userName: state.value.domain.auth.user.name,
            userCompany: state.value.domain.auth.company || "-",
            userPhone: state.value.domain.auth.phone || "-",
            date: getFormattedDate(new Date().toString()),
          },
          getReqOptions()
        )
        .pipe(
          mergeMap(() =>
            of(
              addSuccessToast(
                lang.assetManagerDashboard.registerForConferenceSuccess
              ),
              fetchUserWebinars(),
              setConferenceRegisterLoading(false),
              registerForConferenceSuccessful()
            )
          ),
          catchError(error =>
            of(
              addErrorToast(lang.assetManagerDashboard.requestError),
              setConferenceRegisterLoading(false),
              registerForConferenceRejected(error)
            )
          )
        )
    )
  );

export const unregisterFromConferenceEpic = (
  action$: Observable<ConferencesAction>
): Observable<Action> =>
  action$.pipe(
    ofType(UNREGISTER_FROM_CONFERENCE_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .delete(
          `${API_URL}/profile/webinars/${payload.conferenceId}/unsubscribe/`
        )
        .pipe(
          mergeMap(() =>
            of(
              addSuccessToast(
                lang.assetManagerDashboard.unsubscribedFromConference
              ),
              fetchUserWebinars(),
              setConferenceRegisterLoading(false),
              unregisterFromConferenceSuccessful()
            )
          ),
          catchError(error =>
            of(
              addErrorToast(lang.assetManagerDashboard.requestError),
              setConferenceRegisterLoading(false),
              unregisterFromConferenceRejected(error)
            )
          )
        )
    )
  );

export const sendConferenceParticipationConfirmationEpic = (
  action$: Observable<ConferencesAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(SEND_CONFERENCE_PARTICIPATION_CONFIRMATION_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .post(
          `${API_URL}/conference_participation_confirmation/`,
          {
            conferenceTitle: payload.conferenceTitle,
            userEmail: state.value.domain.auth.user.email,
            userName: state.value.domain.auth.user.name,
          },
          getReqOptions()
        )
        .pipe(
          mergeMap(() =>
            of(sendConferenceParticipationConfirmationSuccessful())
          ),
          catchError(error =>
            of(
              addErrorToast(lang.assetManagerDashboard.requestError),
              sendConferenceParticipationConfirmationRejected(error)
            )
          )
        )
    )
  );

export const sendConferenceRequestEpic = (
  action$: Observable<ConferencesAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(SEND_CONFERENCE_REQUEST_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .post(
          `${API_URL}/request_conference/`,
          {
            conferenceId: payload.conference.id,
            conferenceTitle: payload.conference.title,
            conferenceStartDate: `${payload.conference.date} ${payload.conference.start_time}`,
            conferenceEndDate: `${payload.conference.date} ${payload.conference.end_time}`,
            conferenceDescription: payload.conference.description,
            conferenceAdditionalInfo: payload.conference.additional_information,
            userEmail: state.value.domain.auth.user.email,
            userName: state.value.domain.auth.user.name,
            userCompany: state.value.domain.auth.company || "-",
            userPhone: state.value.domain.auth.phone || "-",
          },
          getReqOptions()
        )
        .pipe(
          mergeMap(() =>
            of(
              addSuccessToast(
                lang.assetManagerDashboard.requestConferenceSuccess
              ),
              sendConferenceParticipationConfirmationSuccessful()
            )
          ),
          catchError(error =>
            of(
              addErrorToast(lang.assetManagerDashboard.requestError),
              sendConferenceParticipationConfirmationRejected(error)
            )
          )
        )
    )
  );
