import { RegisterAction, RegisterState } from "./types";

const SET_REGISTER_VISIBILITY = "SET_REGISTER_VISIBILITY";
const SET_REGISTER_LOADING = "SET_REGISTER_LOADING";
const SET_REGISTER_SUCCESS = "SET_REGISTER_SUCCESS";
const SET_REGISTER_ACCOUNT_TYPE = "SET_REGISTER_ACCOUNT_TYPE";
const SET_REGISTER_STEP = "SET_REGISTER_STEP";
const SET_REGISTER_LOCATION = "SET_REGISTER_LOCATION";

export const setRegisterVisibility = (value: boolean): RegisterAction => {
  if (!value)
    localStorage.removeItem('registrationModalVisibility')

  return ({
    type: SET_REGISTER_VISIBILITY,
    payload: { value },
  });
}

export const setRegisterLoading = (value: boolean): RegisterAction => ({
  type: SET_REGISTER_LOADING,
  payload: { value },
});

export const setRegisterSuccess = (value: boolean): RegisterAction => ({
  type: SET_REGISTER_SUCCESS,
  payload: { value },
});

export const setRegisterAccountType = (value: string): RegisterAction => ({
  type: SET_REGISTER_ACCOUNT_TYPE,
  payload: { value },
});

export const setRegisterStep = (value: number): RegisterAction => ({
  type: SET_REGISTER_STEP,
  payload: { value },
});

export const setRegisterLocation = (value: string): RegisterAction => ({
  type: SET_REGISTER_LOCATION,
  payload: { value },
});

export default (
  state: RegisterState = {
    isRegisterVisible: false,
    isRegisterLoading: false,
    registerAccountType: "",
    registerLocation: "",
    registerStep: 0,
    isRegisterSuccess: false,
  },
  { type, payload }: RegisterAction
) => {
  switch (type) {
    case SET_REGISTER_VISIBILITY:
      return {
        ...state,
        isRegisterVisible: payload.value,
        registerStep: 0,
        registerAccountType: "",
        isMobileNavVisible: false,
      };
    case SET_REGISTER_LOADING:
      return {
        ...state,
        isRegisterLoading: payload.value,
      };
    case SET_REGISTER_SUCCESS:
      return {
        ...state,
        isRegisterSuccess: payload.value,
      };
    case SET_REGISTER_ACCOUNT_TYPE:
      return {
        ...state,
        registerAccountType: payload.value,
      };
    case SET_REGISTER_STEP:
      return {
        ...state,
        registerStep: payload.value,
      };
    case SET_REGISTER_LOCATION:
      return {
        ...state,
        registerLocation: payload.value,
      };
    default:
      return state;
  }
};
