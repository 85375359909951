import { API_URL, getReqOptions } from "./config";
import {
  Action,
  ErrorAction,
  Filters,
  FiltersAction,
  FiltersState,
} from "./types";
import { Observable, of } from "rxjs";
import { addErrorToast, addSuccessToast } from "../ui/toasts";
import { catchError, mergeMap } from "rxjs/operators";

import { StateValue } from "../types";
import { ajax } from "rxjs/ajax";
import { fetchFilterSearchFunds } from "./funds/filterSearch";
import { lang } from "../../lang/lang";
import { ofType } from "redux-observable";
import { setFiltersLoading } from "../ui/search";
import urls from "../../utils/urls";

const FETCH_FILTERS_REQUESTED = "FETCH_FILTERS_REQUESTED";
const FETCH_FILTERS_SUCCESSFUL = "FETCH_FILTERS_SUCCESSFUL";
const FETCH_FILTERS_REJECTED = "FETCH_FILTERS_REJECTED";
const FETCH_CUSTOM_FIELDS_REQUESTED = "FETCH_CUSTOM_FIELDS_REQUESTED";
const FETCH_CUSTOM_FIELDS_SUCCESSFUL = "FETCH_CUSTOM_FIELDS_SUCCESSFUL";
const FETCH_CUSTOM_FIELDS_REJECTED = "FETCH_CUSTOM_FIELDS_REJECTED";
const SAVE_CUSTOM_FIELDS_REQUESTED = "SAVE_CUSTOM_FIELDS_REQUESTED";
const SAVE_CUSTOM_FIELDS_SUCCESSFUL = "SAVE_CUSTOM_FIELDS_SUCCESSFUL";
const SAVE_CUSTOM_FIELDS_REJECTED = "SAVE_CUSTOM_FIELDS_REJECTED";
const CHANGE_FILTERS_LANG = "CHANGE_FILTERS_LANG";

export const fetchFilters = (): Action => ({
  type: FETCH_FILTERS_REQUESTED,
});

const fetchFiltersSuccessful = (filters: Filters): FiltersAction => ({
  type: FETCH_FILTERS_SUCCESSFUL,
  payload: { filters },
});

const fetchFiltersRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_FILTERS_REJECTED,
  error,
});

export const fetchCustomFields = (): Action => ({
  type: FETCH_CUSTOM_FIELDS_REQUESTED,
});

const fetchCustomFieldsSuccessful = (fields: string[]): FiltersAction => ({
  type: FETCH_CUSTOM_FIELDS_SUCCESSFUL,
  payload: { fields },
});

const fetchCustomFieldsRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_CUSTOM_FIELDS_REJECTED,
  error,
});

export const saveCustomFields = (): Action => ({
  type: SAVE_CUSTOM_FIELDS_REQUESTED,
});

const saveCustomFieldsSuccessful = (filters: Filters): FiltersAction => ({
  type: SAVE_CUSTOM_FIELDS_SUCCESSFUL,
  payload: { filters },
});

const saveCustomFieldsRejected = (error?: TypeError): ErrorAction => ({
  type: SAVE_CUSTOM_FIELDS_REJECTED,
  error,
});

export const changeFiltersLang = (): Action => ({
  type: CHANGE_FILTERS_LANG,
});

export default (
  state: FiltersState = {
    peergroups: [],
    companies: [],
    universes: [],
    investmentFocus: [],
    rating: [
      { label: "A", value: "A" },
      { label: "B", value: "B" },
      { label: "C", value: "C" },
      { label: "D", value: "D" },
      { label: "E", value: "E" },
    ],
    regions: [],
    srri: [
      { label: lang.filterSearch.low, value: 1 },
      { label: lang.filterSearch.medium, value: 2 },
      { label: lang.filterSearch.high, value: 3 },
    ],
    customFields: [],
  },
  { type, payload }: FiltersAction
) => {
  switch (type) {
    case FETCH_FILTERS_SUCCESSFUL:
      if (payload.filters) {
        return {
          ...state,
          peergroups: payload.filters.peergroups,
          companies: payload.filters.companies,
          universes: payload.filters.universes,
          investmentFocus: payload.filters.investment_focus,
          regions: payload.filters.regions,
        };
      }
      return state;
    case FETCH_CUSTOM_FIELDS_SUCCESSFUL:
      return {
        ...state,
        customFields: payload.fields,
      };
    case CHANGE_FILTERS_LANG:
      return {
        ...state,
        srri: [
          { label: lang.filterSearch.low, value: 1 },
          { label: lang.filterSearch.medium, value: 2 },
          { label: lang.filterSearch.high, value: 3 },
        ],
      };
    default:
      return state;
  }
};

export const fetchFiltersEpic = (
  action$: Observable<FiltersAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_FILTERS_REQUESTED),
    mergeMap(() =>
      ajax.get(`${API_URL}/filters/?lang=${lang.getLanguage()}`).pipe(
        mergeMap(({ response }) => {
          if (urls.FILTER_SEARCH_RESULTS.reg.test(window.location.pathname)) {
            return of(
              setFiltersLoading(false),
              fetchFilterSearchFunds(),
              fetchFiltersSuccessful(response)
            );
          } else {
            return of(
              setFiltersLoading(false),
              fetchFiltersSuccessful(response)
            );
          }
        }),
        catchError(error =>
          of(setFiltersLoading(false), fetchFiltersRejected(error))
        )
      )
    )
  );

export const saveCustomFieldsEpic = (
  action$: Observable<FiltersAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(SAVE_CUSTOM_FIELDS_REQUESTED),
    mergeMap(() => {
      const form = state.value.form.advancedFundsList;
      if (form && form.values) {
        const fields = [];
        Object.keys(form.values).forEach(field => {
          if (form.values[field] === true) {
            fields.push({ value: field });
          }
        });
        return ajax
          .post(
            `${API_URL}/custom_search_fields/`,
            { custom_fields: fields },
            getReqOptions()
          )
          .pipe(
            mergeMap(({ response }) =>
              of(
                addSuccessToast(lang.advancedSearch.addSuccess),
                fetchCustomFields(),
                saveCustomFieldsSuccessful(response)
              )
            ),
            catchError(error =>
              of(
                addErrorToast(lang.advancedSearch.addError),
                saveCustomFieldsRejected(error)
              )
            )
          );
      } else {
        return of(
          addErrorToast(lang.advancedSearch.addError),
          saveCustomFieldsRejected()
        );
      }
    })
  );

export const fetchCustomFieldsEpic = (
  action$: Observable<FiltersAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_CUSTOM_FIELDS_REQUESTED),
    mergeMap(() =>
      ajax.get(`${API_URL}/custom_search_fields/`, getReqOptions()).pipe(
        mergeMap(({ response }) =>
          of(fetchCustomFieldsSuccessful(response.custom_fields))
        ),
        catchError(error => of(fetchCustomFieldsRejected(error)))
      )
    )
  );
