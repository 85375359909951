import { FundRatingChartData } from "./../redux/domain/types";
import HighchartsReact from "highcharts-react-official";
import { RefObject } from "react";
import { colors } from "./styles";

export const getChartsLang = () => ({
  en: {
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    weekdays: [
      "Sunday",
      "Monday",
      "Tuesdey",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    shortMonths: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  de: {
    months: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ],
    weekdays: [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag",
    ],
    shortMonths: [
      "Jan",
      "Feb",
      "Mär",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez",
    ],
  },
});

export const getRank = (value: number) =>
  value >= 78
    ? "A"
    : value >= 60
    ? "B"
    : value >= 41
    ? "C"
    : value >= 23
    ? "D"
    : "E";

let lettersArray = [];
let circle = null;
let text = null;

export const drawRatingHistoryChart = (
  chartRef: RefObject<HighchartsReact>,
  { rating }: FundRatingChartData
) => {
  const { chart } = chartRef.current;
  const xAxis = chart.xAxis[0];
  const yAxis = chart.yAxis[0];
  const points = [23, 41, 60, 78];
  const letters = [
    { letter: "A", position: 90 },
    { letter: "B", position: 72 },
    { letter: "C", position: 53 },
    { letter: "D", position: 35 },
    { letter: "E", position: 15 },
  ];

  const plotLines = [];

  plotLines.forEach(v => chart.yAxis[0].removePlotLine(v));

  points.map((point, index) =>
    chart.yAxis[0].addPlotLine({
      value: point,
      color: "#e6e6e6",
      width: 1,
      zIndex: -1,
      id: `plot-line-${index}`,
    })
  );

  letters.map(({ letter, position }, index) => {
    if (lettersArray[index]) {
      lettersArray[index].destroy();
    }
    return (lettersArray[index] = chart.renderer
      .label(letter, 15, yAxis.toPixels(position, false))
      .css({ color: colors.gray2 })
      .add());
  });

  if (text && circle) {
    circle.destroy();
    text.destroy();
  }

  circle = chart.renderer
    .circle(
      xAxis.toPixels(rating[rating.length - 1].x, false),
      yAxis.toPixels(rating[rating.length - 1].y, false) - 26,
      16
    )
    .attr({ fill: colors.blue })
    .css({ "z-index": 999 })
    .add();

  text = chart.renderer
    .text(
      getRank(rating[rating.length - 1].y),
      xAxis.toPixels(rating[rating.length - 1].x, false),
      yAxis.toPixels(rating[rating.length - 1].y, false) - 22
    )
    .attr({ "text-anchor": "middle" })
    .css({ color: colors.white, "z-index": 9999, "font-weight": "bold" })
    .add();
};

export const monthInterval = 30 * 24 * 3600 * 1000;

export const getDateTickInterval = () => monthInterval * 6.1;

export const getTickPositioner = ({ min, max }, tickInterval) => {
  const positions = [];
  let tick = Math.floor(min);

  if (max !== null && min !== null) {
    for (tick; tick < max; tick += tickInterval) {
      positions.push(tick);
    }
  }
  positions.push(max);

  return positions;
};

export const getMinDate = (period: number, recordDateUTC: number) => {
  const date = new Date(recordDateUTC);
  return date.setFullYear(date.getFullYear() - period);
};

export const getLastDayOfMonth = (year, month) =>
  new Date(year, month + 1, 0).getDate();

export const getInitialFromDate = () => {
  const d = new Date();
  return [1, d.getMonth(), d.getFullYear()];
};

export const getInitialToDate = () => {
  const d = new Date();
  const y = d.getFullYear();
  const m = d.getMonth() + 1;
  return [getLastDayOfMonth(y, m), m, y];
};

export const getFromDateUTC = date => date && Date.UTC(date[2], date[1], 1);

export const getToDateUTC = date =>
  date && Date.UTC(date[2], date[1], getLastDayOfMonth(date[2], date[1]));
