import { AssetManagerListAction, AssetManagerListState } from "./types";

const SET_ASSET_MANAGERS_LOADING = "SET_ASSET_MANAGERS_LOADING";

export const setAssetManagersLoading = (
  value: boolean
): AssetManagerListAction => ({
  type: SET_ASSET_MANAGERS_LOADING,
  payload: { value }
});

export default (
  state: AssetManagerListState = {
    isAssetManagersLoading: true
  },
  { type, payload }: AssetManagerListAction
) => {
  switch (type) {
    case SET_ASSET_MANAGERS_LOADING:
      return {
        ...state,
        isAssetManagersLoading: payload.value
      };

    default:
      return state;
  }
};
