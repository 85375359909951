import aifBenchmarks from "./aifBenchmarks";
import aifBenchmarksModal from "./aifBenchmarksModal";
import app from "../ui/app";
import assetManagerCharts from "./assetManagerCharts";
import assetManagerDashboard from "./assetManagerDashboard";
import assetManagerDetails from "./assetManagerDetails";
import assetManagerList from "./assetManagerList";
import assetManagerNewsModal from "./assetManagerNewsModal";
import { combineReducers } from "redux";
import comparisonModal from "../ui/comparisonModal";
import conferenceDetails from "../ui/conferenceDetails";
import conferenceInfoModal from "./conferenceInfoModal";
import esgSurveyInfoModal from "./esgSurveyInfoModal";
import uploadArticle89Modal from "./uploadArticle89Modal";
import surveySubmissionModal from "./surveySubmissionModal"
import removeFundKeyFactsModal from "./removeFundKeyFactsModal";
import conferenceModal from "../ui/conferenceModal";
import conferencesList from "../ui/conferencesList";
import confirmModal from "../ui/confirmModal";
import contactForm from "../ui/contactForm";
import fundCharts from "../ui/fundCharts";
import fundDetails from "../ui/fundDetails";
import fundDocumentsModal from "./fundDocumentsModal";
import fundPhotoModal from "./fundPhotoModal";
import fundsInFocus from "../ui/fundsInFocus";
import fundsList from "../ui/fundsList";
import fundsTable from "../ui/fundsTable";
import header from "../ui/header";
import home from "../ui/home";
import invoiceModal from "./invoiceModal";
import login from "../ui/login";
import news from "../ui/news";
import performanceModal from "../ui/performanceModal";
import portfolioManagerModal from "./portfolioManagerModal";
import premium from "../ui/premium";
import purchaseModal from "./purchaseModal";
import register from "../ui/register";
import reportDetails from "../ui/reportDetails";
import reports from "../ui/reports";
import requestResetPassword from "../ui/requestResetPassword";
import resetPassword from "../ui/resetPassword";
import savedSearches from "../ui/savedSearches";
import search from "../ui/search";
import survey from "./survey";
import toasts from "../ui/toasts";
import watchlist from "../ui/watchlist";

export const uiReducers = combineReducers<any>({
  assetManagerNewsModal,
  app,
  assetManagerCharts,
  assetManagerDashboard,
  assetManagerList,
  assetManagerDetails,
  search,
  header,
  home,
  fundsTable,
  fundsList,
  fundDetails,
  fundsInFocus,
  fundCharts,
  news,
  reports,
  reportDetails,
  performanceModal,
  register,
  login,
  requestResetPassword,
  resetPassword,
  premium,
  watchlist,
  contactForm,
  toasts,
  savedSearches,
  comparisonModal,
  portfolioManagerModal,
  fundDocumentsModal,
  fundPhotoModal,
  confirmModal,
  conferenceModal,
  conferencesList,
  conferenceDetails,
  aifBenchmarksModal,
  aifBenchmarks,
  conferenceInfoModal,
  esgSurveyInfoModal,
  uploadArticle89Modal,
  purchaseModal,
  invoiceModal,
  survey,
  removeFundKeyFactsModal,
  surveySubmissionModal,
});
