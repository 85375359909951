import { API_URL, getReqOptions } from "./config";
import {
  Action,
  ErrorAction,
  Exclusion,
  Meta,
  PremiumAction,
  PremiumState,
  SearchFund,
} from "./types";
import { Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { fetchEmpFunds, fetchEsgFunds } from "./funds/fundsPremium";
import {
  setEmpLoading,
  setEsgLoading,
  setExclusionsLoading,
  setPremiumLoading,
} from "../ui/premium";

import { Option } from "../../types";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";

const FETCH_EMP_PEERGROUPS_REQUESTED = "FETCH_EMP_PEERGROUPS_REQUESTED";
const FETCH_EMP_PEERGROUPS_SUCCESSFUL = "FETCH_EMP_PEERGROUPS_SUCCESSFUL";
const FETCH_EMP_PEERGROUPS_REJECTED = "FETCH_EMP_PEERGROUPS_REJECTED";
const FETCH_ESG_PEERGROUPS_REQUESTED = "FETCH_ESG_PEERGROUPS_REQUESTED";
const FETCH_ESG_PEERGROUPS_SUCCESSFUL = "FETCH_ESG_PEERGROUPS_SUCCESSFUL";
const FETCH_ESG_PEERGROUPS_REJECTED = "FETCH_ESG_PEERGROUPS_REJECTED";
const FETCH_EXCLUSIONS_REQUESTED = "FETCH_EXCLUSIONS_REQUESTED";
const FETCH_EXCLUSIONS_SUCCESSFUL = "FETCH_EXCLUSIONS_SUCCESSFUL";
const FETCH_EXCLUSIONS_REJECTED = "FETCH_EXCLUSIONS_REJECTED";
const FETCH_PREMIUM_SEARCH_FUNDS_REQUESTED =
  "FETCH_PREMIUM_SEARCH_FUNDS_REQUESTED";
const FETCH_PREMIUM_SEARCH_FUNDS_SUCCESSFUL =
  "FETCH_PREMIUM_SEARCH_FUNDS_SUCCESSFUL";
const FETCH_PREMIUM_SEARCH_FUNDS_REJECTED =
  "FETCH_PREMIUM_SEARCH_FUNDS_REJECTED";

export const fetchEmpPeergroups = (): Action => ({
  type: FETCH_EMP_PEERGROUPS_REQUESTED,
});

const fetchEmpPeergroupsSuccessful = (peergroups: Option[]): PremiumAction => ({
  type: FETCH_EMP_PEERGROUPS_SUCCESSFUL,
  payload: { peergroups },
});

const fetchEmpPeergroupsRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_EMP_PEERGROUPS_REJECTED,
  error,
});

export const fetchEsgPeergroups = (): Action => ({
  type: FETCH_ESG_PEERGROUPS_REQUESTED,
});

const fetchEsgPeergroupsSuccessful = (peergroups: Option[]): PremiumAction => ({
  type: FETCH_ESG_PEERGROUPS_SUCCESSFUL,
  payload: { peergroups },
});

const fetchEsgPeergroupsRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_ESG_PEERGROUPS_REJECTED,
  error,
});

export const fetchExclusions = (id: number): PremiumAction => ({
  type: FETCH_EXCLUSIONS_REQUESTED,
  payload: { id },
});

const fetchExclusionsSuccessful = (
  exclusions: { [id: string]: Exclusion[] },
  id: number
): PremiumAction => ({
  type: FETCH_EXCLUSIONS_SUCCESSFUL,
  payload: { exclusions, id },
});

const fetchExclusionsRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_EXCLUSIONS_REJECTED,
  error,
});

export const fetchPremiumSearchFunds = (
  value: string,
  meta?: Meta
): PremiumAction => ({
  type: FETCH_PREMIUM_SEARCH_FUNDS_REQUESTED,
  payload: { value, meta },
});

const fetchPremiumSearchFundsSuccessful = (
  funds: SearchFund[]
): PremiumAction => ({
  type: FETCH_PREMIUM_SEARCH_FUNDS_SUCCESSFUL,
  payload: { funds },
});

const fetchPremiumSearchFundsRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_PREMIUM_SEARCH_FUNDS_REJECTED,
  error,
});

export default (
  state: PremiumState = {
    empPeergroups: [],
    esgPeergroups: [],
    premiumSearchFunds: null,
    exclusions: {},
  },
  { type, payload }: PremiumAction
) => {
  switch (type) {
    case FETCH_EMP_PEERGROUPS_SUCCESSFUL:
      return {
        ...state,
        empPeergroups: payload.peergroups,
      };
    case FETCH_ESG_PEERGROUPS_SUCCESSFUL:
      return {
        ...state,
        esgPeergroups: payload.peergroups,
      };
    case FETCH_EXCLUSIONS_SUCCESSFUL:
      if (payload.exclusions && payload.id) {
        return {
          ...state,
          exclusions: {
            ...state.exclusions,
            [payload.id]: payload.exclusions,
          },
        };
      } else {
        return state;
      }
    case FETCH_PREMIUM_SEARCH_FUNDS_SUCCESSFUL:
      return {
        ...state,
        premiumSearchFunds: payload.funds,
      };
    default:
      return state;
  }
};

export const fetchEmpPeergroupsEpic = (
  action$: Observable<PremiumAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_EMP_PEERGROUPS_REQUESTED),
    mergeMap(() =>
      ajax.get(`${API_URL}/emp_peergroup_list/`, getReqOptions()).pipe(
        mergeMap(({ response }) =>
          of(
            fetchEmpPeergroupsSuccessful(response),
            fetchEmpFunds(response[0].value)
          )
        ),
        catchError(error =>
          of(setEmpLoading(false), fetchEmpPeergroupsRejected(error))
        )
      )
    )
  );

export const fetchEsgPeergroupsEpic = (
  action$: Observable<PremiumAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_ESG_PEERGROUPS_REQUESTED),
    mergeMap(() =>
      ajax.get(`${API_URL}/esg_peergroup_list/`, getReqOptions()).pipe(
        mergeMap(({ response }) =>
          of(
            fetchEsgPeergroupsSuccessful(response),
            fetchEsgFunds(response[0].value)
          )
        ),
        catchError(error =>
          of(setEsgLoading(false), fetchEsgPeergroupsRejected(error))
        )
      )
    )
  );

export const fetchExclusionsEpic = (
  action$: Observable<PremiumAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_EXCLUSIONS_REQUESTED),
    mergeMap(({ payload }) =>
      ajax.get(`${API_URL}/exclusion/${payload.id}/`, getReqOptions()).pipe(
        mergeMap(({ response }) =>
          of(
            setExclusionsLoading(false),
            fetchExclusionsSuccessful(response.exclusions, payload.id)
          )
        ),
        catchError(error =>
          of(setExclusionsLoading(false), fetchExclusionsRejected(error))
        )
      )
    )
  );

export const fetchPremiumSearchFundsEpic = (
  action$: Observable<PremiumAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_PREMIUM_SEARCH_FUNDS_REQUESTED),
    mergeMap(({ payload }) => {
      const { resolve, reject }: Meta = payload.meta;
      return ajax
        .get(
          `${API_URL}/premium_search/?search=${payload.value}`,
          getReqOptions()
        )
        .pipe(
          mergeMap(({ response }) => {
            if (resolve) resolve(response);
            return of(
              setPremiumLoading(false),
              fetchPremiumSearchFundsSuccessful(response.results)
            );
          }),
          catchError(error => {
            if (reject) reject(error);
            return of(
              setPremiumLoading(false),
              fetchPremiumSearchFundsRejected(error)
            );
          })
        );
    })
  );
