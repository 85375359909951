import { getCurrentLocation } from "../../utils/app";
import { RouteAction, RouteState } from "./types";

const SET_PREV_PATHNAME = "SET_PREV_PATHNAME";
const SET_PREV_SEARCH = "SET_PREV_SEARCH";
const SET_SEARCH_RESULTS_PARAMS = "SET_SEARCH_RESULTS_PARAMS";
const SET_FUND_BACK_ROUTE = "SET_FUND_BACK_ROUTE";
const SET_NEWS_BACK_ROUTE = "SET_NEWS_BACK_ROUTE";
const SET_REPORTS_BACK_ROUTE = "SET_REPORTS_BACK_ROUTE";
const SET_LAST_ROUTE = "SET_LAST_ROUTE";
const SET_FUND_ENTRANCE_SRC = "SET_FUND_ENTRANCE_SRC";

export const setPrevPathname = (pathname: string): RouteAction => ({
  type: SET_PREV_PATHNAME,
  payload: { pathname }
});

export const setPrevSearch = (search: string): RouteAction => ({
  type: SET_PREV_SEARCH,
  payload: { search }
});

export const setSearchResultsParams = (search: string): RouteAction => ({
  type: SET_SEARCH_RESULTS_PARAMS,
  payload: { search }
});

export const setFundBackRoute = (): RouteAction => ({
  type: SET_FUND_BACK_ROUTE,
  payload: { pathname: getCurrentLocation() }
});

export const setNewsBackRoute = (): RouteAction => ({
  type: SET_NEWS_BACK_ROUTE,
  payload: { pathname: getCurrentLocation() }
});

export const setReportsBackRoute = (): RouteAction => ({
  type: SET_REPORTS_BACK_ROUTE,
  payload: { pathname: getCurrentLocation() }
});

export const setLastRoute = (path = getCurrentLocation()): RouteAction => ({
  type: SET_LAST_ROUTE,
  payload: { pathname: path }
});

export const setFundEntranceSrc = (pathname: string): RouteAction => ({
  type: SET_FUND_ENTRANCE_SRC,
  payload: { pathname }
});

export default (
  state: RouteState = {
    prevPathname: "",
    prevSearch: "",
    searchResultsParams: "",
    fundBackRoute: "",
    newsBackRoute: "",
    reportsBackRoute: "",
    lastRoute: "",
    fundEntranceSrc: ""
  },
  { type, payload }: RouteAction
) => {
  switch (type) {
    case SET_PREV_PATHNAME:
      return {
        ...state,
        prevPathname: payload.pathname
      };
    case SET_PREV_SEARCH:
      return {
        ...state,
        prevSearch: payload.search
      };
    case SET_SEARCH_RESULTS_PARAMS:
      return {
        ...state,
        searchResultsParams: payload.search
      };
    case SET_FUND_BACK_ROUTE:
      return {
        ...state,
        fundBackRoute: payload.pathname
      };
    case SET_NEWS_BACK_ROUTE:
      return {
        ...state,
        newsBackRoute: payload.pathname
      };
    case SET_REPORTS_BACK_ROUTE:
      return {
        ...state,
        reportsBackRoute: payload.pathname
      };
    case SET_LAST_ROUTE:
      return {
        ...state,
        lastRoute: payload.pathname
      };
    case SET_FUND_ENTRANCE_SRC:
      return {
        ...state,
        fundEntranceSrc: payload.pathname
      };
    default:
      return state;
  }
};
