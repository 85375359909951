import "aos/dist/aos.css";
import "./styles/_picky.scss";
import "./styles/_forms.scss";
import "./styles/_buttons.scss";
import "./styles/_icons.scss";
import "./styles/_tabs.scss";
import "./styles/_table.scss";
import "./styles/_tooltips.scss";
import "./styles/_print.scss";
import "./styles/_slick.scss";
import "./styles/_grid.scss";
import "./index.scss";

import createStore, { history } from "./redux";

import AOS from "aos";
import { ConnectedRouter } from "connected-react-router";
import Highcharts from "highcharts";
import { Provider } from "react-redux";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { getChartsLang } from "./utils/charts";
import { lang } from "./lang/lang";
import objectFitImages from "object-fit-images";
import { setCurrentLang } from "./redux/ui/app";
import AppLink from "./components/common/appLink";
import LoadingIndicator from "./components/common/loadingIndicator";

const AppLazy = lazy(() => import("./app"));
const store = createStore();

// Enable animations
AOS.init({
  duration: 700,
  offset: 60,
});

// Fix object-fit for IE
objectFitImages();

// Set current language
if (window.location.pathname.indexOf("/en") !== -1) {
  lang.setLanguage("en");
  store.dispatch(setCurrentLang("en"));
} else {
  lang.setLanguage("de");
  store.dispatch(setCurrentLang("de"));
}

// Set language in charts
Highcharts.setOptions({ lang: { ...getChartsLang()[lang.getLanguage()] } });

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div className="app-container app-container--header">
        <div className="app-header__inner">
          <div
            className="app-header__logo"
          >
            <AppLink to="/" data-cy="header-logo">
              <div className="header__logo" />
            </AppLink>
          </div>
        </div>
      </div>
      <Suspense fallback={() => <LoadingIndicator />}>
        <AppLazy />
      </Suspense>
    </ConnectedRouter>
  </Provider>,
  rootElement
);
