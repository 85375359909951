import {
  AnyChartData,
  AnyFund,
  ChartSingleData,
  ChartXYData,
  Fund,
  SearchFund,
  Watchlist,
} from "../redux/domain/types";
import { getCalculatedPerf, getCalculatedRisk } from "./formats";
import { getLangUrl, isFilterSearch } from "./app";

import { Dispatch } from "redux";
import { FormState } from "../redux/types";
import { FundReports } from "./../redux/domain/types";
import { getSearchQueryParams } from "./routing";
import { lang } from "../lang/lang";
import { push } from "connected-react-router";
import { resetSelectedTableFunds } from "../redux/domain/funds/search";

export const getCurrentFund = (
  searchFunds: SearchFund[],
  autocompleteValue: string
) =>
  searchFunds.find(
    fund =>
      fund.name === autocompleteValue ||
      fund.isin.toLowerCase() === autocompleteValue.toLowerCase()
  ) || null;

export const filtersSubmit = (forms: FormState, dispatch: Dispatch) => {
  if (forms.advancedSearch && forms.advancedSearch.values) {
    const queryParams = getSearchQueryParams(forms);
    dispatch(resetSelectedTableFunds());
    dispatch(push(`${getLangUrl()}/advanced-search-results${queryParams}`));
  }
};

export const autocompleteSubmit = (
  searchFunds: SearchFund[],
  autocompleteValue: string,
  selectedFund: SearchFund,
  dispatch: Dispatch
) => {
  const currentFund =
    getCurrentFund(searchFunds, autocompleteValue) || selectedFund;
  if (currentFund) {
    dispatch(
      push(`${getLangUrl()}/search-results?selectedFund=${currentFund.id}`)
    );
  }
};

export const getCrashValue = (fund: Fund, value: string) =>
  fund.reference_drawdown
    ? [
        fund.reference_drawdown[`index_${value}`],
        fund.reference_drawdown[`avarage_${value}`],
        fund[value],
      ]
    : [null, null, null];

export const getPerformanceClass = (fund: AnyFund, name: string) => {
  if (fund[name] && fund[`avg_${name}`]) {
    const value = parseFloat(getCalculatedPerf(fund[name]).toFixed(2));
    const avg = parseFloat(getCalculatedPerf(fund[`avg_${name}`]).toFixed(2));

    return value > avg ? "text--green" : value < avg ? "text--red" : "";
  } else {
    return "";
  }
};

export const getRiskClass = (fund: AnyFund, name: string) => {
  if (fund[name] && fund[`avg_${name}`]) {
    const value = parseFloat(getCalculatedRisk(fund[name]).toFixed(2));
    const avg = parseFloat(getCalculatedRisk(fund[`avg_${name}`]).toFixed(2));

    return value < avg ? "text--green" : value > avg ? "text--red" : "";
  } else {
    return "";
  }
};

export const getPerfValueClass = (value: number) =>
  value ? (getCalculatedPerf(value) > 0 ? "text--green" : "text--red") : "";

export const getFundBarClass = (value: number) =>
  `value--${value < 40 ? "negative" : value < 70 ? "medium" : "positive"}`;

export const getFundBarStyle = (width: number, value: number) =>
  width > 768 ? { height: `${value * 3 || 0}px` } : { width: `${value || 0}%` };

export const isOnWatchlist = (list: Watchlist[], id: number) =>
  list.map(({ fund }) => fund && fund.id).includes(id);

export const getChartData = (data: ChartSingleData, name: string) => ({
  name: data[name].name,
  data: getChartXYValue(name, data),
});

export const getChartXYValue = (
  name: string,
  data: ChartSingleData | ChartXYData | ChartXYData[]
) => {
  const list = (name === "" ? data : data[name] && data[name].data) || [];
  return list.map(({ x, y }) => {
    const d = new Date(x);
    return { x: Date.UTC(d.getFullYear(), d.getMonth(), d.getUTCDate()), y };
  });
};

export const getAssetManagerChartXYValue = (
  name: string,
  data: ChartSingleData | ChartXYData | ChartXYData[]
) => {
  const list = (name === "" ? data : data[name] && data[name].data) || [];
  return list.map(({ x, y }) => {
    const d = new Date(x);
    return {
      x: Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()),
      y,
    };
  });
};

const getReport = (reports: FundReports[], language: string) =>
  reports.filter(report => report.language === language)[0];

export const getAnyReports = (reports: FundReports[]) => {
  const result = getReport(reports, lang.getLanguage());

  if (!result) {
    const secondLang = lang.getLanguage() === "de" ? "en" : "de";
    return getReport(reports, secondLang);
  }

  return result;
};

export const getLocalReports = (reports: FundReports[]) =>
  getReport(reports, lang.getLanguage());

export const hasData = (data: AnyChartData) =>
  data &&
  Object.values(data).some(
    (item: any) => item.length > 0 || (item.data && item.data.length > 0)
  );

export const hasNoData = (data: AnyChartData, state) =>
  data &&
  Object.values(data).every(data => data.length === 0) &&
  !state.isLoading;

export const getPeriod = (period: number) =>
  period === 1 ? 0 : period === 3 ? 1 : 2;

export const getPerfRiskClass = (value: string) => {
  if (value !== "-") {
    const calcVal = (Math.abs(parseFloat(value)) / 30) * 150 + 5;
    return calcVal > 100 ? "110px" : `${calcVal}px`;
  } else {
    return "0";
  }
};

export const getPerfRiskValueOffset = (value: string) => {
  if (value !== "-") {
    const calcVal = (Math.abs(parseFloat(value)) / 30) * 150 + 5;
    return calcVal > 100 ? "110px" : `${calcVal}px`;
  } else {
    return "0";
  }
};

export const getCostClass = (value: string) => {
  if (value === "-") {
    return "0";
  }

  const calcVal = 150 * (Math.abs(parseFloat(value)) / 100);

  if (calcVal < 15) return "15px";
  if (calcVal > 100) return "110px";

  return `${calcVal}px`;
};

export const getCostValueOffset = (value: string) => {
  if (value === "-") {
    return "0";
  }

  const calcVal = 150 * (Math.abs(parseFloat(value)) / 100);

  if (calcVal < 15) return "15px";
  if (calcVal > 100) return "110px";

  return `${calcVal}px`;
};

export const getShortName = (name: string) =>
  name.length > 36 ? `${name.substring(0, 35)}...` : name;

export const isAif = fund => fund.is_aif && fund.instrument?.rank;

export const getPosition = (fund, index) => {
  if (isFilterSearch()) return index + 1;
  if (isAif(fund)) return fund.instrument?.rank;
  if (fund.position_vs_peergroup) return fund.position_vs_peergroup;

  return "-";
};

export const slugify = (value: string) => {
  const a = "àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœøṕŕßśșțùúüûǘẃẍÿź·/_,:;";
  const b = "aaaaaaaaceeeeghiiiimnnnooooooprssstuuuuuwxyz------";
  const p = new RegExp(a.split("").join("|"), "g");
  return value
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with ‘and’
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

export const canHaveRating = (fund: Fund) => {
  // Ignores qual vs quan logic
  // https://jira.scopegroup.com/browse/SCI-6524

  // return fund?.qualitative_rated || fund?.is_quantitative_license;

  return true;
};
