import { API_URL, getReqOptions } from "./config";
import {
  Action,
  AnyChartData,
  AssetManagersChartsAction,
  AssetManagersChartsState,
  ErrorAction,
} from "./types";
import { Observable, of } from "rxjs";
import { catchError, filter, mergeMap, takeUntil } from "rxjs/operators";

import { StateValue } from "../types";
import { ajax } from "rxjs/ajax";
import { getReversedDateFormat } from "../../utils/formats";
import { ofType } from "redux-observable";
import { setAssetManagerChartLoading } from "../ui/assetManagerCharts";

const FETCH_ASSET_MANAGER_STATS_REQUESTED =
  "FETCH_ASSET_MANAGER_STATS_REQUESTED";
const FETCH_ASSET_MANAGER_STATS_SUCCESSFUL =
  "FETCH_ASSET_MANAGER_STATS_SUCCESSFUL";
const FETCH_ASSET_MANAGER_STATS_REJECTED = "FETCH_ASSET_MANAGER_STATS_REJECTED";

export const fetchAssetManagerStats = (
  name: string
): AssetManagersChartsAction => ({
  type: FETCH_ASSET_MANAGER_STATS_REQUESTED,
  payload: { name },
});

export const fetchAssetManagerStatsSuccessful = (
  name: string,
  data: AnyChartData
): AssetManagersChartsAction => ({
  type: FETCH_ASSET_MANAGER_STATS_SUCCESSFUL,
  payload: { name, data },
});

export const fetchAssetManagerStatsRejected = (
  error?: TypeError
): ErrorAction => ({
  type: FETCH_ASSET_MANAGER_STATS_REJECTED,
  error,
});

export default (
  state: AssetManagersChartsState = {
    allMyProductsChartDashboard: [],
    allMyProductsChart: [],
    myProductsInPeergroupChart: [],
    myTop10FundsChart: [],
    top10FundsInSeChart: [],
    top10PeergroupsInSeChart: [],
    topPeergroupsFund: [],
  },
  { type, payload }
) => {
  switch (type) {
    case FETCH_ASSET_MANAGER_STATS_SUCCESSFUL:
      if (payload.name) {
        return {
          ...state,
          [payload.name]: payload.data,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const fetchAssetManagerStatsEpic = (
  action$: Observable<AssetManagersChartsAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_ASSET_MANAGER_STATS_REQUESTED),
    mergeMap(({ payload }) => {
      const name = payload.name;
      const form = state.value.form[name];
      if (state.value.domain.auth.assetManagerId) {
        const values = form?.values;

        let dateRange = "";

        if (form?.values?.dateFrom && form?.values?.dateTo) {
          const dateFrom = getReversedDateFormat(
            new Date(values.dateFrom[2], values.dateFrom[1], values.dateFrom[0])
          );
          const dateTo = getReversedDateFormat(
            new Date(values.dateTo[2], values.dateTo[1], values.dateTo[0])
          );
          dateRange = `date_after=${dateFrom}&date_before=${dateTo}`;
        }

        const user = form?.values.user
          ? `&user=${form?.values.user.value}`
          : "";
        const type =
          name === "allMyProductsChartDashboard" ? "allMyProductsChart" : name;

        return ajax
          .get(
            `${API_URL}/company_info/${state.value.domain.auth.assetManagerId}/stats/?${dateRange}&type=${type}${user}`,
            getReqOptions()
          )
          .pipe(
            mergeMap(({ response }) =>
              of(
                setAssetManagerChartLoading(false, name),
                fetchAssetManagerStatsSuccessful(name, response)
              )
            ),
            takeUntil(
              action$.pipe(
                filter(
                  ({ type, payload }) =>
                    type === FETCH_ASSET_MANAGER_STATS_REQUESTED &&
                    payload.name === name
                )
              )
            ),
            catchError(error =>
              of(
                setAssetManagerChartLoading(false, payload.name),
                fetchAssetManagerStatsRejected(error)
              )
            )
          );
      } else {
        return of(
          setAssetManagerChartLoading(false, payload.name),
          fetchAssetManagerStatsRejected()
        );
      }
    })
  );
