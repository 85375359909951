import { API_URL, getReqOptions } from "../config";
import {
  Action,
  CompareFund,
  ErrorAction,
  EsgFund,
  FundsAction,
  FundsPremiumState,
} from "../types";
import { Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { setEmpLoading, setEsgLoading } from "../../ui/premium";

import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";

const FETCH_EMP_FUNDS_REQUESTED = "FETCH_EMP_FUNDS_REQUESTED";
const FETCH_EMP_FUNDS_SUCCESSFUL = "FETCH_EMP_FUNDS_SUCCESSFUL";
const FETCH_EMP_FUNDS_REJECTED = "FETCH_EMP_FUNDS_REJECTED";

const FETCH_ESG_FUNDS_REQUESTED = "FETCH_ESG_FUNDS_REQUESTED";
const FETCH_ESG_FUNDS_SUCCESSFUL = "FETCH_ESG_FUNDS_SUCCESSFUL";
const FETCH_ESG_FUNDS_REJECTED = "FETCH_ESG_FUNDS_REJECTED";

export const fetchEmpFunds = (id: number): FundsAction => ({
  type: FETCH_EMP_FUNDS_REQUESTED,
  payload: { id },
});

const fetchEmpFundsSuccessful = (funds: CompareFund[]): FundsAction => ({
  type: FETCH_EMP_FUNDS_SUCCESSFUL,
  payload: { funds },
});

const fetchEmpFundsRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_EMP_FUNDS_REJECTED,
  error,
});

export const fetchEsgFunds = (id: number): FundsAction => ({
  type: FETCH_ESG_FUNDS_REQUESTED,
  payload: { id },
});

const fetchEsgFundsSuccessful = (funds: EsgFund[]): FundsAction => ({
  type: FETCH_ESG_FUNDS_SUCCESSFUL,
  payload: { funds },
});

const fetchEsgFundsRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_ESG_FUNDS_REJECTED,
  error,
});

export default (
  state: FundsPremiumState = {
    empFunds: [],
    esgFunds: [],
  },
  { type, payload }: FundsAction
) => {
  switch (type) {
    case FETCH_EMP_FUNDS_SUCCESSFUL:
      return {
        ...state,
        empFunds: payload.funds,
      };
    case FETCH_ESG_FUNDS_SUCCESSFUL:
      return {
        ...state,
        esgFunds: payload.funds,
      };
    default:
      return state;
  }
};

export const fetchEmpFundsEpic = (
  action$: Observable<FundsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_EMP_FUNDS_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .get(`${API_URL}/emp_list/?peergroup=${payload.id}`, getReqOptions())
        .pipe(
          mergeMap(({ response }) =>
            of(setEmpLoading(false), fetchEmpFundsSuccessful(response.results))
          ),
          catchError(error =>
            of(setEmpLoading(false), fetchEmpFundsRejected(error))
          )
        )
    )
  );

export const fetchEsgFundsEpic = (
  action$: Observable<FundsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_ESG_FUNDS_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .get(`${API_URL}/esg_list/?peergroup=${payload.id}`, getReqOptions())
        .pipe(
          mergeMap(({ response }) =>
            of(setEsgLoading(false), fetchEsgFundsSuccessful(response.results))
          ),
          catchError(error =>
            of(setEsgLoading(false), fetchEsgFundsRejected(error))
          )
        )
    )
  );
