import { API_URL, getReqOptions } from "../config";
import {
  Action,
  ErrorAction,
  FundDocument,
  FundDocumentsState,
  FundsAction,
  Meta,
} from "../types";
import { Observable, of } from "rxjs";
import { addErrorToast, addSuccessToast } from "../../ui/toasts";
import { catchError, filter, mergeMap, takeUntil } from "rxjs/operators";
import {
  setFundDocumentsLoading,
  setFundDocumentsUploading,
} from "../../ui/fundDocumentsModal";

import { ajax } from "rxjs/ajax";
import { getReversedDateFormat } from "../../../utils/formats";
import { lang } from "../../../lang/lang";
import { ofType } from "redux-observable";

const ADD_FUND_DOCUMENT_REQUESTED = "ADD_FUND_DOCUMENT_REQUESTED";
const ADD_FUND_DOCUMENT_SUCCESSFUL = "ADD_FUND_DOCUMENT_SUCCESSFUL";
const ADD_FUND_DOCUMENT_REJECTED = "ADD_FUND_DOCUMENT_REJECTED";

const EDIT_FUND_DOCUMENT_REQUESTED = "EDIT_FUND_DOCUMENT_REQUESTED";
const EDIT_FUND_DOCUMENT_SUCCESSFUL = "EDIT_FUND_DOCUMENT_SUCCESSFUL";
const EDIT_FUND_DOCUMENT_REJECTED = "EDIT_FUND_DOCUMENT_REJECTED";

const REMOVE_FUND_DOCUMENT_REQUESTED = "REMOVE_FUND_DOCUMENT_REQUESTED";
const REMOVE_FUND_DOCUMENT_SUCCESSFUL = "REMOVE_FUND_DOCUMENT_SUCCESSFUL";
const REMOVE_FUND_DOCUMENT_REJECTED = "REMOVE_FUND_DOCUMENT_REJECTED";

const FETCH_FUND_DOCUMENTS_REQUESTED = "FETCH_FUND_DOCUMENTS_REQUESTED";
const FETCH_FUND_DOCUMENTS_SUCCESSFUL = "FETCH_FUND_DOCUMENTS_SUCCESSFUL";
const FETCH_FUND_DOCUMENTS_REJECTED = "FETCH_FUND_DOCUMENTS_REJECTED";

export const addFundDocument = (
  values: FundDocument,
  id: number,
  meta: Meta
): FundsAction => ({
  type: ADD_FUND_DOCUMENT_REQUESTED,
  payload: { values, id, meta },
});

const addFundDocumentSuccessful = (): Action => ({
  type: ADD_FUND_DOCUMENT_SUCCESSFUL,
});

const addFundDocumentRejected = (error?: TypeError): ErrorAction => ({
  type: ADD_FUND_DOCUMENT_REJECTED,
  error,
});

export const editFundDocuments = (
  values: FundDocument,
  id: number,
  meta: Meta
): FundsAction => ({
  type: EDIT_FUND_DOCUMENT_REQUESTED,
  payload: { values, id, meta },
});

const editFundDocumentSuccessful = (): Action => ({
  type: EDIT_FUND_DOCUMENT_SUCCESSFUL,
});

const editFundDocumentRejected = (error?: TypeError): ErrorAction => ({
  type: EDIT_FUND_DOCUMENT_REJECTED,
  error,
});

export const removeFundDocuments = (
  values: FundDocument,
  id: number
): FundsAction => ({
  type: REMOVE_FUND_DOCUMENT_REQUESTED,
  payload: { values, id },
});

const removeFundDocumentSuccessful = (): Action => ({
  type: REMOVE_FUND_DOCUMENT_SUCCESSFUL,
});

const removeFundDocumentRejected = (error?: TypeError): ErrorAction => ({
  type: REMOVE_FUND_DOCUMENT_REJECTED,
  error,
});

export const fetchFundDocuments = (id: number): FundsAction => ({
  type: FETCH_FUND_DOCUMENTS_REQUESTED,
  payload: { id },
});

const fetchFundDocumentsSuccessful = (
  documents: FundDocument[]
): FundsAction => ({
  type: FETCH_FUND_DOCUMENTS_SUCCESSFUL,
  payload: { documents },
});

const fetchFundDocumentsRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_FUND_DOCUMENTS_REJECTED,
  error,
});

export default (
  state: FundDocumentsState = {
    fundDocuments: [],
  },
  { type, payload }: FundsAction
) => {
  switch (type) {
    case FETCH_FUND_DOCUMENTS_SUCCESSFUL:
      return {
        ...state,
        fundDocuments: payload.documents,
      };
    default:
      return state;
  }
};

export const addFundDocumentEpic = (
  action$: Observable<FundsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(ADD_FUND_DOCUMENT_REQUESTED),
    mergeMap(({ payload }) => {
      const { resolve, reject }: Meta = payload.meta;

      payload.values = {
        ...payload.values,
        date:
          payload.values.date instanceof Date
            ? getReversedDateFormat(payload.values.date)
            : payload.values.date,
        category: payload.values.category?.value,
      };

      return ajax
        .post(
          `${API_URL}/fund_documents/${payload.id}/`,
          { ...payload.values },
          getReqOptions()
        )
        .pipe(
          mergeMap(response => {
            if (resolve) resolve(response);
            return of(
              setFundDocumentsUploading(false),
              addFundDocumentSuccessful()
            );
          }),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === ADD_FUND_DOCUMENT_REQUESTED)
            )
          ),
          catchError(error => {
            if (reject) reject(error);
            return of(
              setFundDocumentsUploading(false),
              addFundDocumentRejected(error)
            );
          })
        );
    })
  );

export const editFundDocumentEpic = (
  action$: Observable<FundsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(EDIT_FUND_DOCUMENT_REQUESTED),
    mergeMap(({ payload }) => {
      const { resolve, reject }: Meta = payload.meta;

      if (!payload.values.file.base64_string) {
        delete payload.values.file;
      }

      payload.values = {
        ...payload.values,
        date:
          payload.values.date instanceof Date
            ? getReversedDateFormat(payload.values.date)
            : payload.values.date,
        category: payload.values.category?.value,
      };

      return ajax
        .patch(
          `${API_URL}/fund_documents/${payload.id}/`,
          {
            ...payload.values,
          },
          getReqOptions()
        )
        .pipe(
          mergeMap(() => {
            if (resolve) resolve();
            return of(
              setFundDocumentsUploading(false),
              editFundDocumentSuccessful()
            );
          }),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === EDIT_FUND_DOCUMENT_REQUESTED)
            )
          ),
          catchError(error => {
            if (reject) reject(error);
            return of(
              setFundDocumentsUploading(false),
              editFundDocumentRejected(error)
            );
          })
        );
    })
  );

export const removeFundDocumentEpic = (
  action$: Observable<FundsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(REMOVE_FUND_DOCUMENT_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .delete(
          `${API_URL}/funds/${payload.id}/documents/${payload.values.id}/`,
          getReqOptions()
        )
        .pipe(
          mergeMap(() =>
            of(
              fetchFundDocuments(payload.id),
              addSuccessToast(lang.assetManagerDashboard.documentRemoved),
              removeFundDocumentSuccessful()
            )
          ),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === EDIT_FUND_DOCUMENT_REQUESTED)
            )
          ),
          catchError(error =>
            of(
              addErrorToast(lang.assetManagerDashboard.errors.default),
              removeFundDocumentRejected(error)
            )
          )
        )
    )
  );

export const fetchFundDocumentsEpic = (
  action$: Observable<FundsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_FUND_DOCUMENTS_REQUESTED),
    mergeMap(({ payload }) =>
      ajax.get(`${API_URL}/fund_documents/${payload.id}/?lang=${lang.getLanguage()}`).pipe(
        mergeMap(({ response }) =>
          of(
            setFundDocumentsLoading(false),
            fetchFundDocumentsSuccessful(response)
          )
        ),
        takeUntil(
          action$.pipe(
            filter(({ type }) => type === FETCH_FUND_DOCUMENTS_REQUESTED)
          )
        ),
        catchError(error =>
          of(setFundDocumentsLoading(false), fetchFundDocumentsRejected(error))
        )
      )
    )
  );
