import { PerformanceModalAction, PerformanceModalState } from "./types";

const SET_PERFORMANCE_ID = "SET_PERFORMANCE_ID";

export const setPerformanceId = (id: number): PerformanceModalAction => ({
  type: SET_PERFORMANCE_ID,
  payload: { id }
});

export default (
  state: PerformanceModalState = {
    id: null
  },
  { type, payload }: PerformanceModalAction
) => {
  switch (type) {
    case SET_PERFORMANCE_ID:
      return {
        ...state,
        id: payload.id
      };
    default:
      return state;
  }
};
