import { SavedSearchesAction, SavedSearchesState } from "./types";

const SET_SAVED_SEARCHES_LOADING = "SET_SAVED_SEARCHES_LOADING";
const SET_SAVED_SEARCHES_MODAL_VISIBILITY = "SET_SAVED_SEARCHES_MODAL_VISIBILITY";

export const setSavedSearchesLoading = (
  value: boolean
): SavedSearchesAction => ({
  type: SET_SAVED_SEARCHES_LOADING,
  payload: { value }
});

export const setSavedSearchesModalVisibility = (
  value: boolean
): SavedSearchesAction => ({
  type: SET_SAVED_SEARCHES_MODAL_VISIBILITY,
  payload: { value }
});

export default (
  state: SavedSearchesState = {
    isLoading: false,
    isSavedSearchesModalVisible: false
  },
  { type, payload }: SavedSearchesAction
) => {
  switch (type) {
    case SET_SAVED_SEARCHES_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    case SET_SAVED_SEARCHES_MODAL_VISIBILITY:
      return {
        ...state,
        isSavedSearchesModalVisible: payload.value
      };
    default:
      return state;
  }
};
