import { NewsAction, NewsState } from "./types";

const SET_NEWS_LOADING = "SET_NEWS_LOADING";
const SET_NEWS_LIST_TOTAL = "SET_NEWS_LIST_TOTAL";
const SET_CARDS_VIEW = "SET_CARDS_VIEW";

export const setNewsLoading = (value: boolean): NewsAction => ({
  type: SET_NEWS_LOADING,
  payload: { value }
});

export const setNewsListTotal = (value: number): NewsAction => ({
  type: SET_NEWS_LIST_TOTAL,
  payload: { value }
});

export const setCardsView = (value: boolean): NewsAction => ({
  type: SET_CARDS_VIEW,
  payload: { value }
});

export default (
  state: NewsState = {
    total: 0,
    isNewsLoading: true,
    isCardsView: true
  },
  { type, payload }: NewsAction
) => {
  switch (type) {
    case SET_NEWS_LOADING:
      return {
        ...state,
        isNewsLoading: payload.value
      };
    case SET_NEWS_LIST_TOTAL:
      return {
        ...state,
        total: payload.value
      };
    case SET_CARDS_VIEW:
      return {
        ...state,
        isCardsView: payload.value
      };
    default:
      return state;
  }
};
