import { RequestResetPasswordAction, RequestResetPasswordState } from "./types";

const SET_REQUEST_RESET_PASSWORD_VISIBILITY =
  "SET_REQUEST_RESET_PASSWORD_VISIBILITY";

export const setRequestResetPasswordVisibility = (
  value: boolean
): RequestResetPasswordAction => ({
  type: SET_REQUEST_RESET_PASSWORD_VISIBILITY,
  payload: { value }
});

export default (
  state: RequestResetPasswordState = {
    isRequestResetPasswordVisible: false
  },
  { type, payload }: RequestResetPasswordAction
) => {
  switch (type) {
    case SET_REQUEST_RESET_PASSWORD_VISIBILITY:
      return {
        ...state,
        isRequestResetPasswordVisible: payload.value,
        isMobileNavVisible: false
      };
    default:
      return state;
  }
};
