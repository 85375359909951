import "./loadingIndicator.scss";

import React from "react";

export const LoadingIndicator = props => (
  <div
    data-cy="loader"
    className={`loading__wrapper ${
      props.isSmall ? "loading__wrapper--small" : ""
    }`}
  >
    <div className="loading__indicator" />
  </div>
);

export default LoadingIndicator;
