import React from "react";
import { matchPath, withRouter } from "react-router";

import urls from "./utils/urls";

export const getBreadcrumbs = ({ pathname }) => {
  const matches = [];
  const routes = urls;

  pathname
    .replace(/\/$/, "")
    .split("/")
    .reduce((previous, current) => {
      const pathSection = `${previous}/${current}`;

      let breadcrumbMatch;

      Object.keys(routes).some(key => {
        const path = routes[key].reg;
        const breadcrumb = routes[key].breadcrumb;
        const match = matchPath(pathSection, { exact: true, path });

        if (match) {
          breadcrumbMatch = {
            breadcrumb,
            path,
            match
          };
          return true;
        }

        return false;
      });

      if (breadcrumbMatch) {
        if (breadcrumbMatch.match.url !== "/en") {
          matches.push(breadcrumbMatch);
        }
      }

      return pathSection;
    });

  return matches;
};

export const withBreadcrumbs = () => Component =>
  withRouter(props => (
    <Component
      {...props}
      breadcrumbs={getBreadcrumbs({
        pathname: props.location.pathname
      })}
    />
  ));
