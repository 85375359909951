import { FundDocumentsModalAction, FundDocumentsModalState } from "./types";

const SET_FUND_DOCUMENTS_LOADING = "SET_FUND_DOCUMENTS_LOADING";
const SET_FUND_DOCUMENTS_VISIBILITY = "SET_FUND_DOCUMENTS_VISIBILITY";
const SET_FUND_DOCUMENTS_UPLOADING = "SET_FUND_DOCUMENTS_UPLOADING";
const SET_FUND_DOCUMENTS_FUND_ID = "SET_FUND_DOCUMENTS_FUND_ID";
const SET_FUND_DOCUMENTS_EDIT_INDEX = "SET_FUND_DOCUMENTS_EDIT_INDEX";

export const setFundDocumentsLoading = (
  value: boolean
): FundDocumentsModalAction => ({
  type: SET_FUND_DOCUMENTS_LOADING,
  payload: { value }
});

export const setFundDocumentsVisibility = (
  value: boolean
): FundDocumentsModalAction => ({
  type: SET_FUND_DOCUMENTS_VISIBILITY,
  payload: { value }
});

export const setFundDocumentsUploading = (
  value: boolean
): FundDocumentsModalAction => ({
  type: SET_FUND_DOCUMENTS_UPLOADING,
  payload: { value }
});

export const setFundDocumentsFundId = (
  id: number
): FundDocumentsModalAction => ({
  type: SET_FUND_DOCUMENTS_FUND_ID,
  payload: { id }
});

export const setFundDocumentsEditIndex = (
  index: number
): FundDocumentsModalAction => ({
  type: SET_FUND_DOCUMENTS_EDIT_INDEX,
  payload: { index }
});

export default (
  state: FundDocumentsModalState = {
    isLoading: false,
    isVisible: false,
    isUploading: false,
    fundId: null,
    editIndex: null
  },
  { type, payload }: FundDocumentsModalAction
) => {
  switch (type) {
    case SET_FUND_DOCUMENTS_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    case SET_FUND_DOCUMENTS_VISIBILITY:
      return {
        ...state,
        isVisible: payload.value
      };
    case SET_FUND_DOCUMENTS_UPLOADING:
      return {
        ...state,
        isUploading: payload.value
      };
    case SET_FUND_DOCUMENTS_FUND_ID:
      return {
        ...state,
        fundId: payload.id
      };
    case SET_FUND_DOCUMENTS_EDIT_INDEX:
      return {
        ...state,
        editIndex: payload.index
      };
    default:
      return state;
  }
};
