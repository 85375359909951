import { ResetPasswordAction, ResetPasswordState } from "./types";

const SET_RESET_PASSWORD_VISIBILITY = "SET_RESET_PASSWORD_VISIBILITY";
const SET_RESET_PASSWORD_TOKEN = "SET_RESET_PASSWORD_TOKEN";

export const setResetPasswordVisibility = (
  value: boolean
): ResetPasswordAction => ({
  type: SET_RESET_PASSWORD_VISIBILITY,
  payload: { value }
});

export const setResetPasswordToken = (token: string): ResetPasswordAction => ({
  type: SET_RESET_PASSWORD_TOKEN,
  payload: { token }
});

export default (
  state: ResetPasswordState = {
    isResetPasswordVisible: false,
    token: null
  },
  { type, payload }: ResetPasswordAction
) => {
  switch (type) {
    case SET_RESET_PASSWORD_VISIBILITY:
      return {
        ...state,
        isResetPasswordVisible: payload.value,
        token: payload.value === false ? null : state.token
      };
    case SET_RESET_PASSWORD_TOKEN:
      return {
        ...state,
        token: payload.token
      };
    default:
      return state;
  }
};
