import moment from "moment";

import { getMacroOverviewFields } from "../components/assetManagers/assetManagerDashboard/profile/config";

export const getExtension = name => {
  const reg = /(\.[a-z0-9]+)$/;
  return name.match(reg)[0].substr(1);
};

export const getChangedValues = (
  values,
  form,
  macroOverviewForm,
  selectedFundsForm
) => {
  const changedValues = {};
  Object.keys(values).forEach(key => {
    if (form) {
      if (form.values[key] !== form.initial[key] && key !== "selected_funds") {
        changedValues[key] = values[key];
        if (key === "macro_overview_date") {
          changedValues[key] = moment(values[key]).format("YYYY-MM-DD");
        }
      }
    }
  });
  if (macroOverviewForm && form) {
    if (
      macroOverviewForm.values &&
      macroOverviewForm.values.macro_overviews !== form.initial.macro_overviews
    ) {
      changedValues["macro_overviews"] = values.macro_overviews;
    }
  }

  if (selectedFundsForm) {
    if (
      selectedFundsForm.values &&
      selectedFundsForm.values.selected_funds !==
        selectedFundsForm.initial.selected_funds
    ) {
      changedValues["selected_funds"] = values.selected_funds;
    }
  }
  return changedValues;
};

export const updateEsg = changedValues => {
  if (changedValues["esg"]) {
    const esg = [];
    changedValues["esg"].forEach(value => esg.push({ value }));
    changedValues["esg"] = esg;
  }
};

export const updatePortfolioManagers = changedValues => {
  if (changedValues["portfolio_managers"]) {
    const portfolio_managers = [];
    changedValues["portfolio_managers"].forEach(manager => {
      Object.keys(manager).forEach(key => {
        if (
          manager[key] === null ||
          (key === "photo" && !manager[key].base64_string)
        ) {
          delete manager[key];
        }
      });
      portfolio_managers.push(manager);
    });
    changedValues["portfolio_managers"] = portfolio_managers;
  }
};

export const updateMacroOverview = changedValues => {
  if (changedValues["macro_overviews"]) {
    changedValues["macro_overviews"].forEach((item, i) => {
      const l = getMacroOverviewFields()[i];
      item.label_de = l.label_de;
      item.label_en = l.label_en;
      item.regions.forEach((region, j) => {
        region.label_en = l.regions_en[j];
        region.label_de = l.regions_de[j];
      });
    });
  }
};
