import { lang } from "../../../../lang/lang";

export const getMacroOverviewFields = () => [
  {
    label_en: "Equity",
    label_de: "Aktien",
    regions_de: [
      "Europa Large Cap",
      "Europa SME",
      "USA",
      "Schwellenländer",
      "Japan"
    ],
    regions_en: [
      "Europe Large Cap",
      "Europe SME",
      "USA",
      "Emerging Markets",
      "Japan"
    ]
  },
  {
    label_en: "Government Bonds",
    label_de: "Staatsanleihen",
    regions_de: ["Europa", "USA", "Schwellenländer"],
    regions_en: ["Europe", "USA", "Emerging Markets"]
  },
  {
    label_en: "Corporate Bonds",
    label_de: "Unternehmens anleihen",
    regions_de: [
      "IG Europa",
      "IG USA",
      "HY Europa",
      "HY USA",
      "Schwellenländer"
    ],
    regions_en: [
      "IG Europe",
      "IG USA",
      "HY Europe",
      "HY USA",
      "Emerging Markets"
    ]
  },
  {
    label_en: "Currencies",
    label_de: "Währungen",
    regions_de: ["USD/EUR", "YEN/EUR"],
    regions_en: ["USD/EUR", "YEN/EUR"]
  }
];

export const getMacroOverviewOptions = () => [
  {
    label: lang.assetManagerDashboard.macroOverviewOptions.veryNegative,
    value: 1
  },
  {
    label: lang.assetManagerDashboard.macroOverviewOptions.negative,
    value: 2
  },
  {
    label: lang.assetManagerDashboard.macroOverviewOptions.neutral,
    value: 3
  },
  {
    label: lang.assetManagerDashboard.macroOverviewOptions.positive,
    value: 4
  },
  {
    label: lang.assetManagerDashboard.macroOverviewOptions.veryPositive,
    value: 5
  }
];
