import { AifParam } from "../domain/types";
import { AifBenchmarksModalAction, AifBenchmarksModalState } from "./types";

const SET_AIF_BENCHMAKRS_MODAL_VISIBILITY =
  "SET_AIF_BENCHMAKRS_MODAL_VISIBILITY";
const SET_AIF_MODAL_STEP = "SET_AIF_MODAL_STEP";
const SET_SELECTED_AIF_PARAMS = "SET_SELECTED_AIF_PARAMS";
const SET_AIF_SELECTED_FUND_ID = "SET_AIF_SELECTED_FUND_ID";

export const setAifBenchmarksModalVisibility = (
  value: boolean
): AifBenchmarksModalAction => ({
  type: SET_AIF_BENCHMAKRS_MODAL_VISIBILITY,
  payload: { value }
});

export const setAifModalStep = (value: number): AifBenchmarksModalAction => ({
  type: SET_AIF_MODAL_STEP,
  payload: { value }
});

export const setSelectedAifParams = (
  param: AifParam,
  isChecked: boolean
): AifBenchmarksModalAction => ({
  type: SET_SELECTED_AIF_PARAMS,
  payload: { param, isChecked }
});

export const setAifSelectedFundId = (
  id: number | null
): AifBenchmarksModalAction => ({
  type: SET_AIF_SELECTED_FUND_ID,
  payload: { id }
});

export default (
  state: AifBenchmarksModalState = {
    isVisible: false,
    id: null,
    step: 0,
    selectedAifParams: {}
  },
  { type, payload }: AifBenchmarksModalAction
) => {
  switch (type) {
    case SET_AIF_BENCHMAKRS_MODAL_VISIBILITY:
      return {
        ...state,
        isVisible: payload.value
      };
    case SET_AIF_MODAL_STEP:
      return {
        ...state,
        step: payload.value
      };
    case SET_SELECTED_AIF_PARAMS:
      if (payload.isChecked) {
        return {
          ...state,
          selectedAifParams: {
            ...state.selectedAifParams,
            [payload.param.id]: payload.param
          }
        };
      } else {
        const selectedAifParams = state.selectedAifParams;
        if (selectedAifParams[payload.param.id]) {
          delete selectedAifParams[payload.param.id];
        }

        return {
          ...state,
          selectedAifParams: {
            ...selectedAifParams
          }
        };
      }
    case SET_AIF_SELECTED_FUND_ID:
      return {
        ...state,
        id: payload.id
      };
    default:
      return state;
  }
};
