import { FundChartsAction, FundChartsState } from "./types";

import { getDateTickInterval } from "../../utils/charts";

const SET_CHART_PERIOD = "SET_CHART_PERIOD";
const SET_CHART_VOLATILITY_PERIOD = "SET_CHART_VOLATILITY_PERIOD";
const SET_CHART_VISIBILITY = "SET_CHART_VISIBILITY";
const SET_CHART_LOADING = "SET_CHART_LOADING";
const SET_CHART_TICK_INTERVAL = "SET_CHART_TICK_INTERVAL";

export const calculateDate = (
  input: Date,
  days: number,
  months: number,
  years: number
): number => {
  var date = new Date(input);
  date.setDate(date.getDate() + days);
  date.setMonth(date.getMonth() + months);
  date.setFullYear(date.getFullYear() + years);
  return Date.UTC(date.getFullYear(), date.getMonth());
};

export const setPeriod = (value: number, chart: string): FundChartsAction => ({
  type: SET_CHART_PERIOD,
  payload: { value, chart },
});

export const setChartLoading = (
  isLoading: boolean,
  chart: string
): FundChartsAction => ({
  type: SET_CHART_LOADING,
  payload: { isLoading, chart },
});

export const setChartTickInterval = (
  tickInterval: number,
  chart: string
): FundChartsAction => ({
  type: SET_CHART_TICK_INTERVAL,
  payload: { tickInterval, chart },
});

export const setVolatilityPeriod = (
  value: number,
  chart: string
): FundChartsAction => ({
  type: SET_CHART_VOLATILITY_PERIOD,
  payload: { value, chart },
});

export const setChartVisibility = (
  isVisible: boolean,
  chart: string
): FundChartsAction => ({
  type: SET_CHART_VISIBILITY,
  payload: { isVisible, chart },
});

export default (
  state: FundChartsState = {
    retailPerformanceRiskChart: {
      isVisible: true,
      isLoading: false,
      period: 3,
      tickInterval: getDateTickInterval(),
    },
    performanceChart: {
      isVisible: true,
      isLoading: false,
      period: 3,
      tickInterval: getDateTickInterval(),
    },
    crashDrawdownChart: {
      isVisible: true,
      isLoading: false,
      period: 3,
      tickInterval: getDateTickInterval(),
    },
    peergroupComparisonChart: {
      isVisible: true,
      isLoading: false,
      period: 3,
      tickInterval: getDateTickInterval(),
    },
    riskReturnChart: {
      isVisible: true,
      isLoading: false,
      period: 3,
      volatilityPeriod: 3,
    },
    fundRatingChart: {
      isVisible: true,
      isLoading: false,
      period: 3,
      tickInterval: getDateTickInterval(),
    },
    classDistributionChart: {
      isVisible: true,
      isLoading: false,
    },
    distributionChart: {
      isVisible: true,
      isLoading: false,
    },
    evolutionOfRiskChart: {
      isVisible: true,
      isLoading: false,
      period: 3,
      tickInterval: getDateTickInterval(),
    },
    comparisonChart: {
      isVisible: true,
      isLoading: false,
      period: 3,
      tickInterval: getDateTickInterval(),
    },
  },
  { type, payload }: FundChartsAction
) => {
  switch (type) {
    case SET_CHART_PERIOD:
      return {
        ...state,
        [payload.chart]: {
          ...state[payload.chart],
          period: payload.value,
        },
      };
    case SET_CHART_VOLATILITY_PERIOD:
      return {
        ...state,
        [payload.chart]: {
          ...state[payload.chart],
          volatilityPeriod: payload.value,
        },
      };
    case SET_CHART_VISIBILITY:
      return {
        ...state,
        [payload.chart]: {
          ...state[payload.chart],
          isVisible: payload.isVisible,
        },
      };
    case SET_CHART_LOADING:
      return {
        ...state,
        [payload.chart]: {
          ...state[payload.chart],
          isLoading: payload.isLoading,
        },
      };
    case SET_CHART_TICK_INTERVAL:
      return {
        ...state,
        [payload.chart]: {
          ...state[payload.chart],
          tickInterval: payload.tickInterval,
        },
      };
    default:
      return state;
  }
};
