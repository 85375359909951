import { ofType } from "redux-observable";
import { Observable, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import {
  catchError,
  filter,
  mergeMap,
  takeUntil
} from "rxjs/operators";

import { getFilterSearchQueryParams } from "./../../../utils/routing";
import { setFilterSearchCountLoading } from "./../../ui/search";
import { StateValue } from "../../types";
import {
  setFUndsListModalVisibility,
  setFundsLoading
} from "../../ui/fundsList";
import { API_URL } from "../config";
import {
  Action,
  CompareFund,
  ErrorAction,
  FundsAction,
  FundsFilterSearchState
} from "../types";

const FETCH_FILTER_SEARCH_FUNDS_REJECTED = "FETCH_FILTER_SEARCH_FUNDS_REJECTED";
const FETCH_FILTER_SEARCH_FUNDS_REQUESTED =
  "FETCH_FILTER_SEARCH_FUNDS_REQUESTED";
const FETCH_FILTER_SEARCH_FUNDS_SUCCESSFUL =
  "FETCH_FILTER_SEARCH_FUNDS_SUCCESSFUL";
const FETCH_FILTER_SEARCH_COUNT_REQUESTED =
  "FETCH_FILTER_SEARCH_COUNT_REQUESTED";
const FETCH_FILTER_SEARCH_COUNT_REJECTED = "FETCH_FILTER_SEARCH_COUNT_REJECTED";
const FETCH_FILTER_SEARCH_COUNT_SUCCESSFUL =
  "FETCH_FILTER_SEARCH_COUNT_SUCCESSFUL";
const FETCH_FILTER_SEARCH_PERF_RISK_REQUESTED =
  "FETCH_FILTER_SEARCH_PERF_RISK_REQUESTED";
const FETCH_FILTER_SEARCH_PERF_RISK_REJECTED =
  "FETCH_FILTER_SEARCH_PERF_RISK_REJECTED";
const FETCH_FILTER_SEARCH_PERF_RISK_SUCCESSFUL =
  "FETCH_FILTER_SEARCH_PERF_RISK_SUCCESSFUL";
const SET_FILTER_SEARCH_FUNDS_COUNT = "SET_FILTER_SEARCH_FUNDS_COUNT";
const SET_FILTER_SEARCH_FUNDS = "SET_FILTER_SEARCH_FUNDS";

export const fetchFilterSearchFunds = (): Action => ({
  type: FETCH_FILTER_SEARCH_FUNDS_REQUESTED
});

export const fetchFilterSearchFundsSuccessful = (
  funds: CompareFund[]
): FundsAction => ({
  type: FETCH_FILTER_SEARCH_FUNDS_SUCCESSFUL,
  payload: { funds }
});

export const fetchFilterSearchFundsRejected = (
  error?: TypeError
): ErrorAction => ({
  type: FETCH_FILTER_SEARCH_FUNDS_REJECTED,
  error
});

export const setFilterSearchFundsCount = (count: any): FundsAction => ({
  type: SET_FILTER_SEARCH_FUNDS_COUNT,
  payload: { count }
});

export const fetchFilterSearchCount = (): Action => ({
  type: FETCH_FILTER_SEARCH_COUNT_REQUESTED
});

const fetchFilterSearchCountSuccessful = (count: number): FundsAction => ({
  type: FETCH_FILTER_SEARCH_COUNT_SUCCESSFUL,
  payload: { count }
});

const fetchFilterSearchCountRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_FILTER_SEARCH_COUNT_REJECTED,
  error
});

export const fetchFilterSearchPerfRisk = (): Action => ({
  type: FETCH_FILTER_SEARCH_PERF_RISK_REQUESTED
});

const fetchFilterSearchPerfRiskSuccessful = ({
  results,
  count
}: any): FundsAction => ({
  type: FETCH_FILTER_SEARCH_PERF_RISK_SUCCESSFUL,
  payload: { count, results }
});

const fetchFilterSearchPerfRiskRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_FILTER_SEARCH_PERF_RISK_REJECTED,
  error
});

export default (
  state: FundsFilterSearchState = {
    filterSearchFunds: [],
    filterSearchCount: 0,
    perfRiskFunds: []
  },
  { type, payload }: FundsAction
) => {
  switch (type) {
    case FETCH_FILTER_SEARCH_FUNDS_SUCCESSFUL:
      return {
        ...state,
        filterSearchFunds: payload.funds
      };
    case SET_FILTER_SEARCH_FUNDS_COUNT:
      return {
        ...state,
        filterSearchCount: payload.count
      };
    case FETCH_FILTER_SEARCH_COUNT_SUCCESSFUL:
      return {
        ...state,
        filterSearchCount: payload.count
      };
    case FETCH_FILTER_SEARCH_PERF_RISK_SUCCESSFUL:
      return {
        ...state,
        filterSearchCount: payload.count,
        perfRiskFunds: payload.results
      };
    case SET_FILTER_SEARCH_FUNDS:
      return {
        ...state,
        filterSearchFunds: payload.funds
      };
    default:
      return state;
  }
};

export const fetchFilterSearchFundsEpic = (
  action$: Observable<Action>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_FILTER_SEARCH_FUNDS_REQUESTED),
    mergeMap(() => {
      const queryParams = getFilterSearchQueryParams(state.value.form);
      const params =
        queryParams === "" ? "?m=true&per_page=100" : "&m=true&per_page=100";

      return ajax.get(`${API_URL}/filter_search/${queryParams}${params}`).pipe(
        mergeMap(({ response }) =>
          of(
            setFundsLoading(false),
            setFUndsListModalVisibility(true),
            setFilterSearchFundsCount(response.count),
            fetchFilterSearchFundsSuccessful(response.results)
          )
        ),
        takeUntil(
          action$.pipe(
            filter(({ type }) => type === FETCH_FILTER_SEARCH_FUNDS_REQUESTED)
          )
        ),
        catchError(error =>
          of(setFundsLoading(false), fetchFilterSearchFundsRejected(error))
        )
      );
    })
  );

export const fetchFilterSearchCountEpic = (
  action$: Observable<FundsAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_FILTER_SEARCH_COUNT_REQUESTED),
    mergeMap(() => {
      const queryParams = getFilterSearchQueryParams(state.value.form);
      const params = queryParams === "" ? "?m=true" : "&m=true";

      return ajax
        .get(`${API_URL}/filter_search_count/${queryParams}${params}`)
        .pipe(
          mergeMap(({ response }) =>
            of(
              setFilterSearchCountLoading(false),
              fetchFilterSearchCountSuccessful(response.count)
            )
          ),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === FETCH_FILTER_SEARCH_COUNT_REQUESTED)
            )
          ),
          catchError(error =>
            of(
              setFilterSearchCountLoading(false),
              fetchFilterSearchCountRejected(error)
            )
          )
        );
    }),
    catchError(error =>
      of(
        setFilterSearchCountLoading(false),
        fetchFilterSearchCountRejected(error)
      )
    )
  );

export const fetchFilterSearchPerfRiskEpic = (
  action$: Observable<FundsAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_FILTER_SEARCH_PERF_RISK_REQUESTED),
    mergeMap(() => {
      const queryParams = getFilterSearchQueryParams(state.value.form);
      const params =
        queryParams === "" ? "?m=true&per_page=100" : "&m=true&per_page=100";

      return ajax
        .get(`${API_URL}/filter_search_perf_risk/${queryParams}${params}`)
        .pipe(
          mergeMap(({ response }) =>
            of(
              setFilterSearchCountLoading(false),
              fetchFilterSearchPerfRiskSuccessful(response)
            )
          ),
          takeUntil(
            action$.pipe(
              filter(
                ({ type }) => type === FETCH_FILTER_SEARCH_PERF_RISK_REQUESTED
              )
            )
          ),
          catchError(error =>
            of(
              setFilterSearchCountLoading(false),
              fetchFilterSearchPerfRiskRejected(error)
            )
          )
        );
    }),
    catchError(error =>
      of(
        setFilterSearchCountLoading(false),
        fetchFilterSearchPerfRiskRejected(error)
      )
    )
  );
