import { Link } from "react-router-dom";
import React from "react";
import { getRedirectLocation } from "../../utils/app";

export const AppLink = props => {
  let className = "";

  if (props.className) {
    className += props.className;
  }

  if (props.type === "button") {
    className += ` button`;
  }

  if (props.size) {
    className += ` button--${props.size}`;
  }

  if (props.variant) {
    className += ` button--${props.variant}`;
  }

  if (props.iconAlign) {
    className += ` button--icon-${props.iconAlign}`;
  }

  if (props.active) {
    className += " button--active";
  }

  if (props.disabled) {
    className += " applink--disabled";
  }

  if (props.external) {
    return (
      <a
        href={props.to}
        target={props.target || "_blank"}
        data-cy={props["data-cy"]}
        id={props.id}
        rel="noopener noreferrer nofollow"
        className={className}
      >
        {props.iconAlign === "left" && props.icon ? props.icon : <></>}
        {props.children}
        {props.iconAlign === "right" && props.icon ? props.icon : <></>}
      </a>
    );
  } else {
    return (
      <Link
        {...props}
        className={className}
        to={props.disabled ? '#' : `${getRedirectLocation(props.to)}`}
        {...(props.onClick ? { onClick: props.onClick } : {})}
      >
        {props.iconAlign === "left" && props.icon ? props.icon : <></>}
        {props.children}
        {props.iconAlign === "right" && props.icon ? props.icon : <></>}
      </Link>
    );
  }
};

export default AppLink;
