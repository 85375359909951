import { API_URL, getReqOptions } from "./config";
import {
  Action,
  ErrorAction,
  SavedSearchesAction,
  SavedSearchesState,
} from "./types";
import { Observable, of } from "rxjs";
import { addErrorToast, addSuccessToast } from "../ui/toasts";
import { catchError, mergeMap } from "rxjs/operators";
import {
  setSavedSearchesLoading,
  setSavedSearchesModalVisibility,
} from "../ui/savedSearches";

import { SavedSearch } from "../../types";
import { ajax } from "rxjs/ajax";
import { lang } from "../../lang/lang";
import { ofType } from "redux-observable";

const FETCH_SAVED_SEARCHES_REQUESTED = "FETCH_SAVED_SEARCHES_REQUESTED";
const FETCH_SAVED_SEARCHES_SUCCESSFUL = "FETCH_SAVED_SEARCHES_SUCCESSFUL";
const FETCH_SAVED_SEARCHES_REJECTED = "FETCH_SAVED_SEARCHES_REJECTED";
const SAVE_SEARCH_REQUESTED = "SAVE_SEARCH_REQUESTED";
const SAVE_SEARCH_SUCCESSFUL = "SAVE_SEARCH_SUCCESSFUL";
const SAVE_SEARCH_REJECTED = "SAVE_SEARCH_REJECTED";
const REMOVE_SEARCH_REQUESTED = "REMOVE_SEARCH_REQUESTED";
const REMOVE_SEARCH_SUCCESSFUL = "REMOVE_SEARCH_SUCCESSFUL";
const REMOVE_SEARCH_REJECTED = "REMOVE_SEARCH_REJECTED";

export const fetchSavedSearches = (): Action => ({
  type: FETCH_SAVED_SEARCHES_REQUESTED,
});

const fetchSavedSearchesSuccessful = (
  searches: SavedSearch[]
): SavedSearchesAction => ({
  type: FETCH_SAVED_SEARCHES_SUCCESSFUL,
  payload: { searches },
});

const fetchSavedSearchesRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_SAVED_SEARCHES_REJECTED,
  error,
});

export const saveSearch = (
  search: string,
  name: string
): SavedSearchesAction => ({
  type: SAVE_SEARCH_REQUESTED,
  payload: { search, name },
});

const saveSearchSuccessful = (): Action => ({
  type: SAVE_SEARCH_SUCCESSFUL,
});

const saveSearchRejected = (error?: TypeError): ErrorAction => ({
  type: SAVE_SEARCH_REJECTED,
  error,
});
export const removeSearch = (id: number): SavedSearchesAction => ({
  type: REMOVE_SEARCH_REQUESTED,
  payload: { id },
});

const removeSearchSuccessful = (): Action => ({
  type: REMOVE_SEARCH_SUCCESSFUL,
});

const removeSearchRejected = (error?: TypeError): ErrorAction => ({
  type: REMOVE_SEARCH_REJECTED,
  error,
});

export default (
  state: SavedSearchesState = {
    searches: [],
  },
  { type, payload }: SavedSearchesAction
) => {
  switch (type) {
    case FETCH_SAVED_SEARCHES_SUCCESSFUL:
      return {
        ...state,
        searches: payload.searches,
      };
    default:
      return state;
  }
};

export const fetchSavedSearchesEpic = (
  action$: Observable<SavedSearchesAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_SAVED_SEARCHES_REQUESTED),
    mergeMap(() =>
      ajax
        .get(`${API_URL}/saved_searches/`, {
          pragma: "no-cache",
          ...getReqOptions(),
        })
        .pipe(
          mergeMap(({ response }) =>
            of(
              setSavedSearchesLoading(false),
              fetchSavedSearchesSuccessful(response.results)
            )
          ),
          catchError(error =>
            of(
              setSavedSearchesLoading(false),
              fetchSavedSearchesRejected(error)
            )
          )
        )
    )
  );

export const saveSearchEpic = (
  action$: Observable<SavedSearchesAction>
): Observable<Action> =>
  action$.pipe(
    ofType(SAVE_SEARCH_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .post(
          `${API_URL}/saved_searches/`,
          { search_query: payload.search, search_name: payload.name },
          getReqOptions()
        )
        .pipe(
          mergeMap(() =>
            of(
              addSuccessToast(lang.savedSearches.addSuccess),
              setSavedSearchesModalVisibility(false),
              saveSearchSuccessful()
            )
          ),
          catchError(error =>
            of(
              addErrorToast(lang.savedSearches.addError),
              saveSearchRejected(error)
            )
          )
        )
    )
  );

export const removeSearchEpic = (
  action$: Observable<SavedSearchesAction>
): Observable<Action> =>
  action$.pipe(
    ofType(REMOVE_SEARCH_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .delete(`${API_URL}/saved_searches/${payload.id}/`, getReqOptions())
        .pipe(
          mergeMap(() =>
            of(
              removeSearchSuccessful(),
              addSuccessToast(lang.savedSearches.removeSuccess),
              fetchSavedSearches()
            )
          ),
          catchError(error =>
            of(
              addErrorToast(lang.savedSearches.removeError),
              removeSearchRejected(error)
            )
          )
        )
    )
  );
