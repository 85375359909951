import {
  PortfolioManagerModalAction,
  PortfolioManagerModalState
} from "./types";

const SET_PORTFOLIO_MANAGER_MODAL_VISIBILITY =
  "SET_PORTFOLIO_MANAGER_MODAL_VISIBILITY";
const SET_EDIT_PORTFOLIO_MANAGER_ID = "SET_EDIT_PORTFOLIO_MANAGER_ID";

export const setPortfolioManagerModalVisibility = (
  value: boolean
): PortfolioManagerModalAction => ({
  type: SET_PORTFOLIO_MANAGER_MODAL_VISIBILITY,
  payload: { value }
});

export const setEditPortfolioManagerId = (
  id: number | null
): PortfolioManagerModalAction => ({
  type: SET_EDIT_PORTFOLIO_MANAGER_ID,
  payload: { id }
});

export default (
  state: PortfolioManagerModalState = {
    isVisible: false,
    editPortfolioManagerId: null
  },
  { type, payload }: PortfolioManagerModalAction
) => {
  switch (type) {
    case SET_PORTFOLIO_MANAGER_MODAL_VISIBILITY:
      return {
        ...state,
        isVisible: payload.value
      };
    case SET_EDIT_PORTFOLIO_MANAGER_ID:
      return {
        ...state,
        editPortfolioManagerId: payload.id
      };
    default:
      return state;
  }
};
