import { API_URL, getReqOptions } from "../config";
import {
  Action,
  ErrorAction,
  RatingInFocusAction,
  RatingInFocusLink,
  RatingInFocusState,
} from "../types";
import { Observable, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";

import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";

const FETCH_RATING_IN_FOCUS_REQUESTED = "FETCH_RATING_IN_FOCUS_REQUESTED";
const FETCH_RATING_IN_FOCUS_SUCCESSFUL = "FETCH_RATING_IN_FOCUS_SUCCESSFUL";
const FETCH_RATING_IN_FOCUS_REJECTED = "FETCH_RATING_IN_FOCUS_REJECTED";

export const fetchRatingInFocus = (): Action => ({
  type: FETCH_RATING_IN_FOCUS_REQUESTED,
});

export const fetchRatingInFocusSuccessful = (
  links: RatingInFocusLink[]
): RatingInFocusAction => ({
  type: FETCH_RATING_IN_FOCUS_SUCCESSFUL,
  payload: { links },
});

export const fetchRatingInFocusRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_RATING_IN_FOCUS_REJECTED,
  error,
});

export default (
  state: RatingInFocusState = {
    links: [],
  },
  { type, payload }: RatingInFocusAction
) => {
  switch (type) {
    case FETCH_RATING_IN_FOCUS_SUCCESSFUL:
      return {
        ...state,
        links: payload.links,
      };
    default:
      return state;
  }
};

export const fetchRatingInFocusEpic = (
  action$: Observable<RatingInFocusAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_RATING_IN_FOCUS_REQUESTED),
    mergeMap(() =>
      ajax.get(`${API_URL}/team_rating_links/`, getReqOptions()).pipe(
        mergeMap(({ response }) => of(fetchRatingInFocusSuccessful(response))),
        catchError(error => of(fetchRatingInFocusRejected(error)))
      )
    )
  );
