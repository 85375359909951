import "moment/locale/de";

import { getInvestmentFocusLabels } from "./config";
import { lang } from "./../lang/lang";
import moment from "moment";

export const getLocalNumber = (value: number, digits: number) =>
  value.toLocaleString(lang.getLanguage(), {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });

export const getPercentValue = (value: number) =>
  (value !== null && `${getLocalNumber(value, 2)}%`) || "-";

export const getPercentOneDigitValue = (value: number) =>
  (value !== null && `${getLocalNumber(value, 1)}%`) || "-";

export const getIntValue = (value: number) =>
  (value !== null && getLocalNumber(value, 0)) || "-";

export const getFloatValue = (value: number) =>
  (value !== null && getLocalNumber(value, 2)) || "-";

export const getLongFloatValue = (value: number) =>
  (value !== null && getLocalNumber(value, 5)) || "-";

export const getPriceValue = (value: string) =>
  (value !== null && getLocalNumber(parseFloat(value), 2)) || "-";

export const getMinInvestmentValue = (value: number) =>
  (value !== null && (value === 1 ? "n.a." : getLocalNumber(value, 0))) ||
  "n.a.";

export const getCalculatedPerf = (value: number) => (value - 1) * 100;

export const getCalculatedRisk = (value: number) => value * 100;

export const getPerfValue = (value: number) =>
  (value !== null && getPercentValue(getCalculatedPerf(value))) || "-";

export const getPerfValueNa = (value: number) =>
  (value !== null && getPercentValue(getCalculatedPerf(value))) || "n.a.";

export const getRiskValue = (value: number) =>
  (value !== undefined &&
    value !== null &&
    getPercentValue(getCalculatedRisk(value))) ||
  "-";

export const getRiskValueNa = (value: number) =>
  (value !== undefined &&
    value !== null &&
    getPercentValue(getCalculatedRisk(value))) ||
  "n.a.";

export const getCostValueNa = (value: number) =>
  (value !== undefined && value !== null && getPercentValue(value)) || "n.a.";

export const getCrashDrawdownValue = (value: number) =>
  (value && getPercentValue(getCalculatedRisk(value))) || "-";

export const getDrawdownValue = (value: number) =>
  (value !== null && getPercentValue(getCalculatedRisk(value))) || "0";

export const getIsinValue = (value: string) =>
  value !== null && value.length === 12 ? value : "-";

export const getFormattedDate = (date: string | number) => {
  const d = new Date(date);
  return (
    ("0" + d.getDate()).slice(-2) +
    "." +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "." +
    d.getFullYear()
  );
};

export const getReversedDateFormat = (d: Date) =>
  d.getFullYear() +
  "-" +
  ("0" + (d.getMonth() + 1)).slice(-2) +
  "-" +
  ("0" + d.getDate()).slice(-2);

export const getFormattedDateShort = (date: string | number) => {
  const d = new Date(date);
  return ("0" + (d.getMonth() + 1)).slice(-2) + "." + d.getFullYear();
};

export const getDashedDate = (d: Date = new Date()) =>
  new Intl.DateTimeFormat("en-GB").format(d).replace(/([/]|[.])/g, "-");

export const addZeroBefore = n => (n < 10 ? "0" : "") + n;

export const getConferenceDateTime = (
  date: string | number,
  startTime: string
) => {
  const d = moment(date).locale(lang.getLanguage()).format("DD MMMM YYYY");
  const formattedTime = moment(startTime, "hh:mm:ss").format("HH:mm");
  const d2 = lang.getLanguage() === "de" ? "Uhr" : "(CEST)";
  return `${d} ${formattedTime} ${d2}`;
};

export const getApiDateFormat = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const getMacroOverviewDate = (date: string) =>
  moment(date).locale(lang.getLanguage()).format("DD. MMMM YYYY");

export const getYesNoValue = (value: boolean) =>
  value ? lang.app.yes : lang.app.no;

export const getVolumeValue = (value: number) =>
  (value !== null && `${getLocalNumber(value, 1)} Mio. EUR`) || "-";

export const getInvestmentFocus = (value: number) =>
  getInvestmentFocusLabels()[value];

export const getLicensePriceValue = (value: number) =>
  (value !== null && `${getLocalNumber(value / 100, 2)}`) || "-";

export const fieldsFormatters = {
  getPercentValue: getPercentValue,
  getPercentOneDigitValue: getPercentOneDigitValue,
  getFloatValue: getFloatValue,
  getIntValue: getIntValue,
  getLongFloatValue: getLongFloatValue,
  getPriceValue: getPriceValue,
  getPerfValue: getPerfValue,
  getRiskValue: getRiskValue,
  getDrawdownValue: getDrawdownValue,
  getIsinValue: getIsinValue,
  getFormattedDate: getFormattedDate,
  getYesNoValue: getYesNoValue,
  getInvestmentFocus: getInvestmentFocus,
  getVolumeValue: getVolumeValue,
};
