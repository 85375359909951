import { EsgSurveyInfoModalAction, EsgSurveyInfoModalState } from "./types";

const SET_ESG_SURVEY_INFO_MODAL_VISIBILITY =
  "SET_ESG_SURVEY_INFO_MODAL_VISIBILITY";

export const setEsgSurveyInfoModalVisibility = (
  value: boolean,
  path?: string
): EsgSurveyInfoModalAction => ({
  type: SET_ESG_SURVEY_INFO_MODAL_VISIBILITY,
  payload: { value, path },
});

export default (
  state: EsgSurveyInfoModalState = {
    isVisible: false,
    path: null,
  },
  { type, payload }: EsgSurveyInfoModalAction
) => {
  switch (type) {
    case SET_ESG_SURVEY_INFO_MODAL_VISIBILITY:
      return {
        ...state,
        isVisible: payload.value,
        path: payload.path,
      };
    default:
      return state;
  }
};
