import { PremiumAction, PremiumState } from "./types";

const SET_PREMIUM_FORM_VISIBILITY = "SET_PREMIUM_FORM_VISIBILITY";
const SET_EXCLUSIONS_VISIBILITY = "SET_EXCLUSIONS_VISIBILITY";
const SET_PREMIUM_LOADING = "SET_PREMIUM_LOADING";
const SET_EXCLUSIONS_LOADING = "SET_EXCLUSIONS_LOADING";
const SET_ESG_LOADING = "SET_ESG_LOADING";
const SET_EMP_LOADING = "SET_EMP_LOADING";
const SET_PREMIUM_PRINT_SECTION = "SET_PREMIUM_PRINT_SECTION";
const SET_EXCLUSIONS_ID = "SET_EXCLUSIONS_ID";
const SET_PREMIUM_FUND_SEARCH_VISIBILITY = "SET_PREMIUM_FUND_SEARCH_VISIBILITY";
const SET_SHOW_ETF_FUNDS = "SET_SHOW_ETF_FUNDS";

export const setPremiumFormVisibility = (value: boolean): PremiumAction => ({
  type: SET_PREMIUM_FORM_VISIBILITY,
  payload: { value },
});

export const setExclusionsVisibility = (value: boolean): PremiumAction => ({
  type: SET_EXCLUSIONS_VISIBILITY,
  payload: { value },
});

export const setPremiumLoading = (value: boolean): PremiumAction => ({
  type: SET_PREMIUM_LOADING,
  payload: { value },
});

export const setExclusionsLoading = (value: boolean): PremiumAction => ({
  type: SET_EXCLUSIONS_LOADING,
  payload: { value },
});

export const setEsgLoading = (value: boolean): PremiumAction => ({
  type: SET_ESG_LOADING,
  payload: { value },
});

export const setEmpLoading = (value: boolean): PremiumAction => ({
  type: SET_EMP_LOADING,
  payload: { value },
});

export const setPremiumPrintSection = (value: number): PremiumAction => ({
  type: SET_PREMIUM_PRINT_SECTION,
  payload: { value },
});

export const setExclusionsId = (value: number): PremiumAction => ({
  type: SET_EXCLUSIONS_ID,
  payload: { value },
});

export const setPremiumFundSearchVisibility = (
  value: boolean
): PremiumAction => ({
  type: SET_PREMIUM_FUND_SEARCH_VISIBILITY,
  payload: { value },
});

export const setShowEtfFunds = (value: boolean): PremiumAction => ({
  type: SET_SHOW_ETF_FUNDS,
  payload: { value },
});

export default (
  state: PremiumState = {
    isPremiumFormVisible: false,
    isPremiumFundSearchVisible: false,
    isExclusionsVisible: false,
    isLoading: false,
    isExclusionsLoading: false,
    isEsgLoading: true,
    isEmpLoading: true,
    premiumPrintSection: 0,
    exclusionsId: null,
    showEtfFunds: false,
  },
  { type, payload }: PremiumAction
) => {
  switch (type) {
    case SET_PREMIUM_FORM_VISIBILITY:
      return {
        ...state,
        isPremiumFormVisible: payload.value,
      };
    case SET_EXCLUSIONS_VISIBILITY:
      return {
        ...state,
        isExclusionsVisible: payload.value,
      };
    case SET_PREMIUM_LOADING:
      return {
        ...state,
        isLoading: payload.value,
      };
    case SET_EXCLUSIONS_LOADING:
      return {
        ...state,
        isExclusionsLoading: payload.value,
      };
    case SET_ESG_LOADING:
      return {
        ...state,
        isEsgLoading: payload.value,
      };
    case SET_EMP_LOADING:
      return {
        ...state,
        isEmpLoading: payload.value,
      };
    case SET_PREMIUM_PRINT_SECTION:
      return {
        ...state,
        premiumPrintSection: payload.value,
      };
    case SET_EXCLUSIONS_ID:
      return {
        ...state,
        exclusionsId: payload.value,
      };
    case SET_PREMIUM_FUND_SEARCH_VISIBILITY:
      return {
        ...state,
        isPremiumFundSearchVisible: payload.value,
      };
    case SET_SHOW_ETF_FUNDS:
      return {
        ...state,
        showEtfFunds: payload.value,
      };
    default:
      return state;
  }
};
