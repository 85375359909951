import { ConferenceInfoModalAction, ConferenceInfoModalState } from "./types";

const SET_CONFERENCE_INFO_MODAL_VISIBILITY =
  "SET_CONFERENCE_INFO_MODAL_VISIBILITY";

export const setConferenceInfoModalVisibility = (
  value: boolean
): ConferenceInfoModalAction => ({
  type: SET_CONFERENCE_INFO_MODAL_VISIBILITY,
  payload: { value }
});

export default (
  state: ConferenceInfoModalState = {
    isVisible: false
  },
  { type, payload }: ConferenceInfoModalAction
) => {
  switch (type) {
    case SET_CONFERENCE_INFO_MODAL_VISIBILITY:
      return {
        ...state,
        isVisible: payload.value
      };
    default:
      return state;
  }
};
