import { Redirect, Route } from "react-router-dom";
import { aifParams, yourAifParams } from "../components/funds/aifFunds/config";
import { change, destroy, reset } from "redux-form";
import { cleanSurvey, fetchSurvey } from "../redux/domain/survey";
import { confirmUser, cookies, getLoggedInUser } from "../redux/domain/auth";
import {
  fetchAdvancedSearchFunds,
  fetchSelectedFund,
} from "../redux/domain/funds/search";
import {
  fetchAifFunds,
  fetchFundAifNews,
  fetchPaidDocument,
  setSelectedAifFunds,
} from "../redux/domain/funds/aif";
import {
  fetchAssetManager,
  fetchAssetManagerFunds,
  fetchAssetManagerNews,
  fetchAssetManagers,
  fetchMacroOverviews,
  fetchSingleAssetManagerNews,
} from "../redux/domain/assetManagers";
import {
  fetchConferencesList,
  fetchSingleConference,
} from "../redux/domain/conferences";
import {
  fetchEmpPeergroups,
  fetchEsgPeergroups,
} from "../redux/domain/premium";
import {
  fetchFilterSearchFunds,
  fetchFilterSearchPerfRisk,
  setFilterSearchFundsCount,
} from "../redux/domain/funds/filterSearch";
import { fetchHomeFacts, fetchHomeNews } from "../redux/domain/home";
import { fetchNewsList, fetchSingleNews } from "../redux/domain/news";
import { fetchReportsList, fetchSingleReport } from "../redux/domain/reports";
import {
  filterSearchParamsConfig,
  fundsTableParamsConfig,
  newsParamsConfig,
  queryFilters,
  reportsParamsConfig,
  searchParamsConfig,
} from "./config";
import {
  getCurrentSearchParams,
  getLangUrl,
  getRedirectLocation,
  isSurveyPage,
  isSurveyPageAllowed,
} from "./app";
import {
  setAccountMenuVisibility,
  setLangMenuVisibility,
  setMobileNavVisibility,
  setPublicationsMenuVisibility,
} from "../redux/ui/header";
import {
  setAdvancedFundsList,
  setExportDropdownVisibility,
  setExportLoading,
  setFilterFundsList,
  setFilterSearchPerfPeriod,
  setFundsLoading,
  setPerfPeriod,
} from "../redux/ui/fundsList";
import {
  setAdvancedSearch,
  setFilterSearch,
  setFilterSearchCountLoading,
  setFilterSearchTab,
  setSearchLoading,
} from "../redux/ui/search";
import {
  setAifModalStep,
  setAifSelectedFundId,
  setSelectedAifParams,
} from "../redux/ui/aifBenchmarksModal";
import {
  setAssetManagerInfoLoading,
  setAssetManagerNewsLoading,
  setMacroOverviewsLoading,
  setTopAssetManagerFundsVisibility,
} from "../redux/ui/assetManagerDetails";
import {
  setComparisonAutocompleteValue,
  setFundsIds,
} from "../redux/ui/comparisonModal";
import {
  setComparisonVisibility,
  setDetailsLoading,
  setFundAifNewsLoading,
} from "../redux/ui/fundDetails";
import {
  setEmpLoading,
  setEsgLoading,
  setPremiumFormVisibility,
  setPremiumFundSearchVisibility,
} from "../redux/ui/premium";
import {
  setFundsTableLoading,
  setFundsTableOrdering,
  setFundsTableTotal,
} from "../redux/ui/fundsTable";
import { setHomeFactsLoading, setHomeNewsLoading } from "../redux/ui/home";
import { setLoginLoading, setLoginVisibility } from "../redux/ui/login";
import {
  setRegisterLoading,
  setRegisterSuccess,
  setRegisterVisibility,
} from "../redux/ui/register";
import {
  setResetPasswordToken,
  setResetPasswordVisibility,
} from "../redux/ui/resetPassword";
import { setSurveyLoading, setSurveyPage } from "../redux/ui/survey";

import React from "react";
import { State } from "../types";
import { Dispatch, Store } from "redux";
import { connect } from "react-redux";
import { fetchAssetManagerStats } from "../redux/domain/assetManagersCharts";
import { fetchFund } from "../redux/domain/funds/details";
import { fetchFundsInFocus } from "../redux/domain/funds/fundsInFocus";
import { fetchRatingInFocus } from "../redux/domain/funds/ratingInFocus";
import { fetchSavedSearches } from "../redux/domain/savedSearches";
import { fetchWatchlist } from "../redux/domain/funds/watchlist";
import { lang } from "../lang/lang";
import { push } from "connected-react-router";
import { resetDetailsCharts } from "../redux/domain/charts";
import { setAmNewsModalVisibility } from "../redux/ui/assetManagerNewsModal";
import { setAssetManagerChartLoading } from "../redux/ui/assetManagerCharts";
import { setAssetManagersLoading } from "../redux/ui/assetManagerList";
import { setConferenceDetailsLoading } from "../redux/ui/conferenceDetails";
import { setConferenceInfoModalVisibility } from "../redux/ui/conferenceInfoModal";
import { setConferencesListLoading } from "../redux/ui/conferencesList";
import { setFundDocumentsVisibility } from "../redux/ui/fundDocumentsModal";
import { setFundsInFocusLoading } from "../redux/ui/fundsInFocus";
import { setNewsLoading } from "../redux/ui/news";
import { setPortfolioManagerModalVisibility } from "../redux/ui/portfolioManagerModal";
import { setPrimaryCompareFund } from "../redux/domain/funds/common";
import { setPurchaseModalVisibility } from "../redux/ui/purchaseModal";
import { setReportDetailsLoading } from "../redux/ui/reportDetails";
import { setReportsLoading } from "../redux/ui/reports";
import { setRequestResetPasswordVisibility } from "../redux/ui/requestResetPassword";
import { setSavedSearchesLoading } from "../redux/ui/savedSearches";
import { unsubscribe } from "../redux/domain/contact";
import urls from "../utils/urls";
import { setLastRoute } from "../redux/domain/route";

export const getIdFromPathnae = pathname =>
  pathname.includes("asset-manager-dashboard")
    ? pathname.match(/\/\d+(\/|$)/) &&
      parseInt(pathname.match(/\/\d+(\/|$)/)[0].replace("/", ""), 10)
    : parseInt(pathname.substr(pathname.lastIndexOf("/") + 1), 10);

export const getUidFromPathname = pathname =>
  pathname.substr(pathname.lastIndexOf("/") + 1);

const mapStateToProps = ({ domain, router }: State) => ({
  pathname: router.location.pathname,
  isAuthenticated: domain.auth.isAuthenticated,
  isWholesaleUser: domain.auth.isWholesaleUser,
  isAssetManager: domain.auth.isAssetManager,
  assetManagerId: domain.auth.assetManagerId,
  isSurveyPageAllowed: isSurveyPageAllowed(),
  license: domain.auth.license,
  lastRoute: domain.route.lastRoute,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onRouteRedirect: () => dispatch(setLastRoute()),
});

const PrivateRouteComponent = ({
  onRouteRedirect,
  lastRoute,
  assetManagerId,
  isAuthenticated,
  isWholesaleUser,
  isAssetManager,
  isSurveyPageAllowed,
  license,
  role,
  location = window.location,
  component: Component,
  ...rest
}) => {
  const id = getIdFromPathnae(location.pathname);

  const hasAccess = {
    WITHOUT_LICENSE: isAuthenticated && !license ? true : false,
    WITH_LICENSE: isAuthenticated && license ? true : false,
    USER: isAuthenticated,
    WHOLESALE: isAuthenticated && isWholesaleUser,
    IS_SURVEY_ALLOWED: isAuthenticated && isSurveyPageAllowed,
    ASSET_MANAGER:
      isAuthenticated &&
      isAssetManager &&
      (id !== null && id !== undefined ? assetManagerId === id : false),
  };

  let redirectRoute = "/";
  if (urls.BENCHMARKS.reg.test(window.location.pathname)) {
    redirectRoute = "/aif-page";
  }

  const setLastRoute = () => {
    onRouteRedirect();
  };

  return (
    <Route
      {...rest}
      render={props =>
        hasAccess[role] ? (
          <Component {...props} />
        ) : (
          <>
            <Redirect to={{ pathname: getRedirectLocation(redirectRoute) }} />
            {setLastRoute()}
            {lastRoute && localStorage.setItem("loginModalVisibility", "true")}
          </>
        )
      }
    />
  );
};

export const PrivateRoute = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRouteComponent);

export const updateURLParameter = (url, param, paramVal) => {
  let newAdditionalURL = "";
  let tempArray = url.split("?");
  const baseURL = tempArray[0];
  const additionalURL = tempArray[1];
  let temp = "";
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (var i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split("=")[0] !== param) {
        newAdditionalURL += temp + tempArray[i];
        temp = "&";
      }
    }
  }

  const rows_txt = temp + "" + param + "=" + paramVal;
  return baseURL + "?" + newAdditionalURL + rows_txt;
};

const buildQueryParams = (params, form) => {
  let data = {};
  let queryParams = "";

  if (form && form.values) {
    params.forEach(({ name, type }) => {
      const value = form.values[name];
      type === "single" || type === "select"
        ? value &&
          (data[name] =
            name === "lang" ? lang.getLanguage() : value["value"] || value)
        : value &&
          (data[name] = Array.from(value, x => x["value"] || x).join(
            `&${queryFilters[name]}=`
          ));
    });
  }

  Object.keys(data).forEach(filter => {
    if (data[filter] !== undefined && data[filter] !== "") {
      queryParams += `${queryParams === "" ? "?" : "&"}`;
      queryParams += `${queryFilters[filter]}=${data[filter]}`;
    }
  });
  return queryParams;
};

export const getNewsQueryParams = (form): string =>
  buildQueryParams(newsParamsConfig.fields, form.news);

export const getReportsQueryParams = (form): string =>
  buildQueryParams(reportsParamsConfig.fields, form.reports);

export const getSearchQueryParams = (form): string =>
  buildQueryParams(searchParamsConfig.fields, form.advancedSearch);

export const getAssetManagerFundsQueryParams = (form): string =>
  buildQueryParams(fundsTableParamsConfig.fields, form.fundsTable);

export const getFilterSearchQueryParams = (form): string =>
  buildQueryParams(filterSearchParamsConfig.fields, form.filterSearch);

const getField = (field: string) => parseInt(field, 10) || field;

const getSingleQueryParams = (name, params, queryParamsConfig, store) => {
  if (params[name]) {
    store.dispatch(
      change(queryParamsConfig.formName, name, getField(params[name]))
    );
  }
};

const getSelectQueryParams = (
  name,
  params,
  filters,
  queryParamsConfig,
  store
) => {
  if (params[name] && filters[name]) {
    filters[name].forEach(filter => {
      if (filter.value === getField(params[name])) {
        store.dispatch(change(queryParamsConfig.formName, name, filter));
      }
    });
  }
};

const getMultiQueryParams = (
  name,
  params,
  filters,
  queryParamsConfig,
  store
) => {
  if (params[name] && filters[name]) {
    let values = [];
    filters[name].forEach(filter =>
      params[name].forEach(
        value =>
          (filter === value || filter.value === getField(value)) &&
          values.push(filter)
      )
    );
    store.dispatch(change(queryParamsConfig.formName, name, values));
  }
};

const getFiltersFromQueryParams = (
  searchParams: URLSearchParams,
  queryParamsConfig: any,
  store: Store
) => {
  const { filters } = store.getState().domain;
  const params = {};

  queryParamsConfig.fields.forEach(
    ({ name, type }) =>
      (params[name] =
        type === "single" || type === "select"
          ? searchParams.get(queryFilters[name])
          : searchParams.getAll(queryFilters[name]))
  );

  queryParamsConfig.fields.forEach(({ name, type }) => {
    if (type === "single") {
      getSingleQueryParams(name, params, queryParamsConfig, store);
    } else if (type === "select") {
      getSelectQueryParams(name, params, filters, queryParamsConfig, store);
    } else if (type === "multi") {
      getMultiQueryParams(name, params, filters, queryParamsConfig, store);
    }
  });
};

export const getSearchFiltersFromQueryParams = (searchParams, store) =>
  getFiltersFromQueryParams(searchParams, searchParamsConfig, store);

export const getNewsFiltersFromQueryParams = (searchParams, store) =>
  getFiltersFromQueryParams(searchParams, newsParamsConfig, store);

export const getReportsFiltersFromQueryParams = (searchParams, store) =>
  getFiltersFromQueryParams(searchParams, reportsParamsConfig, store);

export const getAssetManagerFundsFromQueryParams = (searchParams, store) =>
  getFiltersFromQueryParams(searchParams, fundsTableParamsConfig, store);

export const getFilterSearchFromQueryParams = (searchParams, store) =>
  getFiltersFromQueryParams(searchParams, filterSearchParamsConfig, store);

export const resetAppState = (store: Store) => {
  store.dispatch(setLoginVisibility(false));
  store.dispatch(setRegisterVisibility(false));

  window.scrollTo(0, 0);
  store.dispatch(setRegisterLoading(false));
  store.dispatch(setRegisterSuccess(false));
  store.dispatch(setLoginLoading(false));
  store.dispatch(setMobileNavVisibility(false));
  store.dispatch(setRequestResetPasswordVisibility(false));
  store.dispatch(setSearchLoading(false));
  store.dispatch(setDetailsLoading(false));
  store.dispatch(setFundsInFocusLoading(false));
  store.dispatch(setPremiumFormVisibility(false));
  store.dispatch(setFundsTableLoading(false));
  store.dispatch(setPerfPeriod(3));
  store.dispatch(setFilterSearchPerfPeriod(5));
  store.dispatch(setLangMenuVisibility(false));
  store.dispatch(setAccountMenuVisibility(false));
  store.dispatch(setPublicationsMenuVisibility(false));
  store.dispatch(setComparisonVisibility(false));
  store.dispatch(setFundsIds(-1, 0));
  store.dispatch(setFundsIds(-1, 1));
  store.dispatch(setFundsIds(-1, 2));
  store.dispatch(setPrimaryCompareFund(null));
  store.dispatch(setComparisonAutocompleteValue("", 0));
  store.dispatch(setComparisonAutocompleteValue("", 1));
  store.dispatch(setPremiumFundSearchVisibility(false));
  store.dispatch(setTopAssetManagerFundsVisibility(false));
  store.dispatch(setExportDropdownVisibility(false));
  store.dispatch(setExportLoading(false));
  store.dispatch(resetDetailsCharts());
  store.dispatch(reset("advancedSearch"));
  store.dispatch(reset("fundsTable"));
  store.dispatch(reset("survey"));
  store.dispatch(destroy("filterSearch"));
  store.dispatch(setFundsTableTotal(0));
  store.dispatch(setFundsTableOrdering("id"));
  store.dispatch(setFilterSearch(false));
  store.dispatch(setFilterSearchTab(0));
  store.dispatch(setFilterSearchFundsCount(0));
  store.dispatch(setAmNewsModalVisibility(false));
  store.dispatch(setFundDocumentsVisibility(false));
  store.dispatch(setPortfolioManagerModalVisibility(false));
  store.dispatch(setAifModalStep(0));
  store.dispatch(setConferenceInfoModalVisibility(false));
  store.dispatch(setPurchaseModalVisibility(false));
  store.dispatch(setSurveyLoading(true));
  store.dispatch(cleanSurvey());
  store.dispatch(setSurveyPage(1));

  if (urls.FILTER_SEARCH_RESULTS.reg.test(window.location.pathname)) {
    store.dispatch(setFilterSearchFundsCount(0));
  }
};

const getProps = ({ ...args }) => ({
  store: args.store,
  searchParams: args.searchParams,
  search: args.search,
  prevSearch: args.prevSearch,
  pathname: args.pathname,
  prevPathname: args.prevPathname,
  dispatch: args.store.dispatch,
  funds: args.store.getState().domain.funds,
  selectedFund: args.searchParams.get("selectedFund"),
  isAuthenticated: args.store.getState().domain.auth.isAuthenticated,
  isWholesaleUser: args.store.getState().domain.auth.isWholesaleUser,
  isFilterSearch: args.store.getState().ui.search.isFilterSearch,
  filters: args.store.getState().domain.filters,
  searchResultsParams: args.store.getState().domain.route.searchResultsParams,
  id: getIdFromPathnae(args.pathname),
  uid: getUidFromPathname(args.pathname),
  token: args.pathname.substr(args.pathname.lastIndexOf("/") + 1),
  isInitialized: args.store.getState().ui.app.isInitialized,
});

export const onRouteChange = (
  pathname: string,
  search: string,
  store: Store
): void => {
  const { prevSearch, prevPathname } = store.getState().domain.route;
  const searchParams = new URLSearchParams(search);

  resetAppState(store);

  const props = getProps({
    store,
    pathname,
    search,
    prevSearch,
    prevPathname,
    searchParams,
  });

  props.dispatch(getLoggedInUser());

  if (isSurveyPage()) {
    props.dispatch(setSurveyLoading(true));
    props.dispatch(fetchSurvey(1));
  }
  if (urls.HOME.reg.test(pathname)) {
    getCurrentSearchParams().includes("lo=1") &&
      props.dispatch(setLoginVisibility(true));
    getCurrentSearchParams().includes("ro=1") &&
      props.dispatch(setRegisterVisibility(true));
    props.dispatch(setHomeNewsLoading(true));
    props.dispatch(setHomeFactsLoading(true));
    props.dispatch(setNewsLoading(true));
    props.dispatch(fetchHomeNews());
    props.dispatch(fetchHomeFacts());
    props.dispatch(fetchNewsList());
  }
  if (urls.CONFIRM.reg.test(pathname)) {
    props.dispatch(confirmUser(props.token));
  }
  if (urls.UNSUBSCRIBE.reg.test(pathname)) {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(props.token)) {
      props.dispatch(unsubscribe(props.token));
    } else {
      props.dispatch(push("/"));
    }
  }
  if (urls.RESET.reg.test(pathname)) {
    if (props.token) {
      props.dispatch(setResetPasswordToken(props.token));
      props.dispatch(setResetPasswordVisibility(true));
    }
  }
  if (urls.FUNDS_IN_FOCUS.reg.test(pathname)) {
    if (props.funds.fundsInFocus.fundsInFocus.length === 0) {
      props.dispatch(setFundsInFocusLoading(true));
      props.dispatch(fetchFundsInFocus());
    }
  }
  if (urls.RATING_IN_FOCUS.reg.test(pathname)) {
    props.dispatch(fetchRatingInFocus());
  }
  if (urls.WATCHLIST.reg.test(pathname)) {
    props.dispatch(setFundsInFocusLoading(true));
    props.dispatch(fetchWatchlist(false));
  }
  if (urls.NEWS.reg.test(pathname)) {
    newsHandler(props);
  }
  if (urls.REPORTS.reg.test(pathname)) {
    reportsHandler(props);
  }
  if (urls.OIF.reg.test(pathname)) {
    oifHandler(props);
  }
  if (urls.NEWS_DETAILS.reg.test(pathname)) {
    props.dispatch(setNewsLoading(true));
    props.dispatch(fetchSingleNews(props.uid));
  }
  if (urls.REPORTS_DETAILS.reg.test(pathname)) {
    props.dispatch(setReportDetailsLoading(true));
    props.dispatch(fetchSingleReport(props.id));
  }
  if (urls.SEARCH_RESULTS.reg.test(pathname)) {
    searchResultsHandler(props);
  }
  if (urls.FILTER_SEARCH_RESULTS.reg.test(pathname)) {
    props.dispatch(setFilterFundsList(true));
    const int = setInterval(() => {
      if (store.getState().domain.filters.regions.length > 0) {
        filterSearchResultsHandler(props);
        clearInterval(int);
      }
    }, 100);
  }
  if (urls.ADV_SEARCH_RESULTS.reg.test(pathname)) {
    const int = setInterval(() => {
      if (store.getState().domain.filters.peergroups.length > 0) {
        advancedSearchResultsHandler(props);
        clearInterval(int);
      }
    }, 100);
  }
  if (urls.DETAILS.reg.test(pathname)) {
    props.dispatch(setDetailsLoading(true));
    props.dispatch(setFundAifNewsLoading(true));
    props.dispatch(fetchFund(props.id));
    props.dispatch(fetchFundAifNews(props.id));
    props.dispatch(fetchPaidDocument());
  }
  if (urls.PREMIUM.reg.test(pathname)) {
    props.dispatch(setEmpLoading(true));
    props.dispatch(setEsgLoading(true));
    props.dispatch(fetchEmpPeergroups());
    props.dispatch(fetchEsgPeergroups());
  }
  if (urls.SAVED_SEARCHES.reg.test(pathname)) {
    props.dispatch(setSavedSearchesLoading(true));
    props.dispatch(fetchSavedSearches());
  }
  if (urls.ASSET_MANAGERS.reg.test(pathname)) {
    props.dispatch(setAssetManagersLoading(true));
    props.dispatch(setMacroOverviewsLoading(true));
    props.dispatch(fetchAssetManagers());
    props.dispatch(fetchMacroOverviews());
  }
  if (urls.ASSET_MANAGER_DETAILS.reg.test(pathname)) {
    assetManagerDetailsHandler(props);
  }
  if (urls.ASSET_MANAGER_NEWS.reg.test(pathname)) {
    props.dispatch(setAssetManagerNewsLoading(true));
    props.dispatch(fetchSingleAssetManagerNews(props.id));
  }
  if (urls.ASSET_MANAGER_DASHBOARD.reg.test(pathname)) {
    props.dispatch(
      setAssetManagerChartLoading(true, "allMyProductsChartDashboard")
    );
    props.dispatch(fetchAssetManagerStats("allMyProductsChartDashboard"));
  }
  if (urls.PROFILE_EDIT.reg.test(pathname)) {
    props.dispatch(setAssetManagerInfoLoading(true));
    props.dispatch(setAssetManagerNewsLoading(true));
    props.dispatch(fetchAssetManager(props.id));
    props.dispatch(fetchAssetManagerNews(props.id));
  }
  if (urls.FUND_RATINGS.reg.test(pathname)) {
    props.dispatch(setAssetManagerInfoLoading(true));
    props.dispatch(fetchAssetManager(props.id));
    fundRatingsHandler(props);
  }
  if (urls.ASSET_MANAGER_STATS.reg.test(pathname)) {
    if (props.isInitialized) {
      props.dispatch(setAssetManagerChartLoading(true, "allMyProductsChart"));
      props.dispatch(setAssetManagerChartLoading(true, "myTop10FundsChart"));
      props.dispatch(
        setAssetManagerChartLoading(true, "myProductsInPeergroupChart")
      );
      props.dispatch(setAssetManagerChartLoading(true, "top10FundsInSeChart"));
      props.dispatch(
        setAssetManagerChartLoading(true, "top10PeergroupsInSeChart")
      );
      props.dispatch(setAssetManagerChartLoading(true, "topPeergroupsFund"));
      props.dispatch(fetchAssetManagerStats("allMyProductsChart"));
      props.dispatch(fetchAssetManagerStats("myTop10FundsChart"));
      props.dispatch(fetchAssetManagerStats("myProductsInPeergroupChart"));
      props.dispatch(fetchAssetManagerStats("top10FundsInSeChart"));
      props.dispatch(fetchAssetManagerStats("top10PeergroupsInSeChart"));
      props.dispatch(fetchAssetManagerStats("topPeergroupsFund"));
    }
  }
  if (urls.ASSET_MANAGER_CONFERENCES.reg.test(pathname)) {
    props.dispatch(setConferencesListLoading(true));
    props.dispatch(fetchConferencesList());
  }
  if (urls.CONFERENCES_LIST_LOADING.reg.test(pathname)) {
    props.dispatch(setConferencesListLoading(true));
  }
  if (urls.CONFERENCES_LIST.reg.test(pathname)) {
    props.dispatch(
      setConferenceInfoModalVisibility(
        cookies.get("dont_show_conference_modal") || true
      )
    );
    props.dispatch(setConferencesListLoading(true));
    props.dispatch(fetchConferencesList());
  }
  if (urls.CONFERENCES_LIST_2.reg.test(pathname)) {
    props.dispatch(setConferencesListLoading(true));
    props.dispatch(fetchConferencesList());
  }
  if (urls.CONFERENCE_DETAILS.reg.test(pathname)) {
    props.dispatch(setConferenceDetailsLoading(true));
    props.dispatch(fetchSingleConference(props.id));
  }
  if (urls.BENCHMARKS.reg.test(pathname)) {
    props.dispatch(setFundsLoading(true));
    props.dispatch(setAdvancedFundsList(false));
    props.dispatch(setAifSelectedFundId(props.id));
    props.dispatch(fetchAifFunds(props.id));
    const int = setInterval(() => {
      const funds = store.getState().domain.funds;
      if (funds.aif.aifFunds.length > 0) {
        funds.aif.aifFunds.map(fund =>
          props.dispatch(setSelectedAifFunds(fund, true))
        );
        aifParams.map(param =>
          props.dispatch(setSelectedAifParams(param, true))
        );
        yourAifParams.map(param =>
          props.dispatch(setSelectedAifParams(param, true))
        );
        clearInterval(int);
      }
    }, 100);
  }
};

export const onSearchChange = (
  pathname: string,
  search: string,
  store: Store
) => {
  const { prevSearch, prevPathname } = store.getState().domain.route;
  const searchParams = new URLSearchParams(search);

  const props = getProps({
    store,
    pathname,
    search,
    prevSearch,
    prevPathname,
    searchParams,
  });

  if (urls.NEWS.reg.test(pathname)) {
    newsHandler(props);
  } else if (urls.REPORTS.reg.test(pathname)) {
    reportsHandler(props);
  } else if (urls.OIF.reg.test(pathname)) {
    oifHandler(props);
  } else if (urls.SEARCH_RESULTS.reg.test(pathname)) {
    searchResultsHandler(props);
  } else if (urls.FILTER_SEARCH_RESULTS.reg.test(pathname)) {
    props.dispatch(setFilterFundsList(true));
    const int = setInterval(() => {
      if (store.getState().domain.filters.regions.length > 0) {
        filterSearchResultsHandler(props);
        clearInterval(int);
      }
    }, 100);
  } else if (urls.ADV_SEARCH_RESULTS.reg.test(pathname)) {
    const int = setInterval(() => {
      if (store.getState().domain.filters.peergroups.length > 0) {
        advancedSearchResultsHandler(props);
        clearInterval(int);
      }
    }, 100);
  } else if (urls.ASSET_MANAGER_DETAILS.reg.test(pathname)) {
    assetManagerDetailsHandler(props);
  } else if (urls.FUND_RATINGS.reg.test(pathname)) {
    fundRatingsHandler(props);
  }
};

const searchResultsHandler = props => {
  if (window.location.search.indexOf("?selectedFund=") !== -1) {
    props.dispatch(setFundsLoading(true));
    props.dispatch(setAdvancedFundsList(false));
    props.dispatch(fetchSelectedFund(parseInt(props.selectedFund, 10)));
  } else {
    props.dispatch(push(`${getLangUrl()}/search-results`));
  }
};

const filterSearchResultsHandler = props => {
  getFilterSearchFromQueryParams(props.searchParams, props.store);
  props.dispatch(setFilterSearchCountLoading(true));
  props.dispatch(fetchFilterSearchPerfRisk());
  props.dispatch(setFundsLoading(true));
  props.dispatch(fetchFilterSearchFunds());
};

const advancedSearchResultsHandler = props => {
  if (props.isAuthenticated) {
    props.dispatch(setAdvancedFundsList(true));
    props.dispatch(setAdvancedSearch(true));
    getSearchFiltersFromQueryParams(props.searchParams, props.store);
    props.dispatch(setFundsTableLoading(true));
    props.dispatch(fetchAdvancedSearchFunds());
  } else {
    props.dispatch(push(`${getLangUrl()}/search-results`));
  }
};

const newsHandler = props => {
  if (props.search === "") {
    props.dispatch(push(`${getLangUrl()}/news?page=1&per_page=15`));
  } else {
    const page = props.searchParams.get("page");
    const perPage = props.searchParams.get("per_page");
    getNewsFiltersFromQueryParams(props.searchParams, props.store);

    page && props.dispatch(change("news", "page", parseInt(page, 10)));
    perPage && props.dispatch(change("news", "perPage", parseInt(perPage, 10)));
    props.dispatch(setNewsLoading(true));
    props.dispatch(fetchNewsList());
  }
};

const reportsHandler = props => {
  if (props.search === "") {
    props.dispatch(push(`${getLangUrl()}/reports?page=1&per_page=15`));
  } else {
    const page = props.searchParams.get("page");
    const perPage = props.searchParams.get("per_page");
    getReportsFiltersFromQueryParams(props.searchParams, props.store);

    page && props.dispatch(change("reports", "page", parseInt(page, 10)));
    perPage &&
      props.dispatch(change("reports", "perPage", parseInt(perPage, 10)));
    props.dispatch(setReportsLoading(true));
    props.dispatch(fetchReportsList());
  }
};

const oifHandler = props => {
  props.dispatch(setFundsLoading(true));
  props.dispatch(fetchAifFunds());
};

const assetManagerFundsHandler = props => {
  const page = props.searchParams.get("page");
  const perPage = props.searchParams.get("per_page");
  getAssetManagerFundsFromQueryParams(props.searchParams, props.store);
  page && props.dispatch(change("fundsTable", "page", parseInt(page, 10)));
  perPage &&
    props.dispatch(change("fundsTable", "perPage", parseInt(perPage, 10)));
  props.dispatch(setFundsTableLoading(true));
  props.dispatch(fetchAssetManagerFunds(props.id));
};

const assetManagerDetailsHandler = props => {
  if (props.search === "") {
    props.dispatch(
      push(`${getLangUrl()}/asset-managers/${props.id}?page=1&per_page=15`)
    );
  } else {
    props.dispatch(setAssetManagerInfoLoading(true));
    props.dispatch(setAssetManagerNewsLoading(true));
    props.dispatch(fetchAssetManager(props.id));
    props.dispatch(fetchAssetManagerNews(props.id));

    assetManagerFundsHandler(props);
  }
};

const fundRatingsHandler = props => {
  props.search === ""
    ? props.dispatch(
        push(
          `${getLangUrl()}/asset-manager-dashboard/${
            props.id
          }/fund-ratings?page=1&per_page=15`
        )
      )
    : assetManagerFundsHandler(props);
};
