import { FundsTableAction, FundsTableState } from "./types";

const SET_FUNDS_TABLE_LOADING = "SET_FUNDS_TABLE_LOADING";
const SET_FUNDS_TABLE_TOTAL = "SET_FUNDS_TABLE_TOTAL";
const SET_FUNDS_TABLE_ORDERING = "SET_FUNDS_TABLE_ORDERING";

export const setFundsTableLoading = (value: boolean): FundsTableAction => ({
  type: SET_FUNDS_TABLE_LOADING,
  payload: { value }
});

export const setFundsTableTotal = (value: number): FundsTableAction => ({
  type: SET_FUNDS_TABLE_TOTAL,
  payload: { value }
});

export const setFundsTableOrdering = (value: string): FundsTableAction => ({
  type: SET_FUNDS_TABLE_ORDERING,
  payload: { value }
});

export default (
  state: FundsTableState = {
    isLoading: false,
    ordering: "id",
    total: 0
  },
  { type, payload }: FundsTableAction
) => {
  switch (type) {
    case SET_FUNDS_TABLE_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    case SET_FUNDS_TABLE_TOTAL:
      return {
        ...state,
        total: payload.value
      };
    case SET_FUNDS_TABLE_ORDERING:
      return {
        ...state,
        ordering: payload.value
      };
    default:
      return state;
  }
};
