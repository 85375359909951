import { UploadArticle89ModalAction, UploadArticle89ModalState } from "./types";

const SET_UPLOAD_ARTICLE_89_MODAL_VISIBILITY = "SET_UPLOAD_ARTICLE_89_MODAL_VISIBILITY";
const SET_UPLOAD_ARTICLE_89_MODAL_UPLOADING = "SET_UPLOAD_ARTICLE_89_MODAL_UPLOADING";

export const setUploadArticle89ModalVisibility = (
  value: boolean
): UploadArticle89ModalAction => ({
  type: SET_UPLOAD_ARTICLE_89_MODAL_VISIBILITY,
  payload: { value }
});

export const setUploadArticle89ModalUploading = (
  value: boolean
): UploadArticle89ModalAction => ({
  type: SET_UPLOAD_ARTICLE_89_MODAL_UPLOADING,
  payload: { value }
})

export default (
  state: UploadArticle89ModalState = {
    isVisible: false,
    isUploading: false,
  },
  { type, payload }: UploadArticle89ModalAction
) => {
  switch (type) {
    case SET_UPLOAD_ARTICLE_89_MODAL_VISIBILITY:
      return {
        ...state,
        isVisible: payload.value
      };
    case SET_UPLOAD_ARTICLE_89_MODAL_UPLOADING:
      return {
        ...state,
        isUploading: payload.value
      };
    default:
      return state;
  }
};
