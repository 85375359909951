import { API_URL, getReqOptions } from "./config";
import {
  Action,
  ContactAction,
  ContactState,
  ContactValues,
  ErrorAction,
  Meta,
} from "./types";
import { Observable, of } from "rxjs";
import { addErrorToast, addSuccessToast } from "../ui/toasts";
import { catchError, mergeMap } from "rxjs/operators";

import { StateValue } from "../types";
import { ajax } from "rxjs/ajax";
import { getFormattedDate } from "../../utils/formats";
import { lang } from "../../lang/lang";
import { ofType } from "redux-observable";

const SEND_MESSAGE_REQUESTED = "SEND_MESSAGE_REQUESTED";
const SEND_MESSAGE_SUCCESSFUL = "SEND_MESSAGE_SUCCESSFUL";
const SEND_MESSAGE_REJECTED = "SEND_MESSAGE_REJECTED";
const SEND_PREMIUM_MESSAGE_REQUESTED = "SEND_PREMIUM_MESSAGE_REQUESTED";
const SEND_PREMIUM_MESSAGE_SUCCESSFUL = "SEND_PREMIUM_MESSAGE_SUCCESSFUL";
const SEND_PREMIUM_MESSAGE_REJECTED = "SEND_PREMIUM_MESSAGE_REJECTED";
const SEND_FUND_REQUEST_REQUESTED = "SEND_FUND_REQUEST_REQUESTED";
const SEND_FUND_REQUEST_SUCCESSFUL = "SEND_FUND_REQUEST_SUCCESSFUL";
const SEND_FUND_REQUEST_REJECTED = "SEND_FUND_REQUEST_REJECTED";
const SEND_TEAM_REQUEST_REQUESTED = "SEND_TEAM_REQUEST_REQUESTED";
const SEND_TEAM_REQUEST_SUCCESSFUL = "SEND_TEAM_REQUEST_SUCCESSFUL";
const SEND_TEAM_REQUEST_REJECTED = "SEND_TEAM_REQUEST_REJECTED";
const SUBSCRIBE_LIST_REQUEST_REQUESTED = "SUBSCRIBE_LIST_REQUEST_REQUESTED";
const SUBSCRIBE_LIST_REQUEST_SUCCESSFUL = "SUBSCRIBE_LIST_REQUEST_SUCCESSFUL";
const SUBSCRIBE_LIST_REQUEST_REJECTED = "SUBSCRIBE_LIST_REQUEST_REJECTED";
const UNSUBSCRIBE_LIST_REQUEST_REQUESTED = "UNSUBSCRIBE_LIST_REQUEST_REQUESTED";
const UNSUBSCRIBE_LIST_REQUEST_SUCCESSFUL =
  "UNSUBSCRIBE_LIST_REQUEST_SUCCESSFUL";
const UNSUBSCRIBE_LIST_REQUEST_REJECTED = "UNSUBSCRIBE_LIST_REQUEST_REJECTED";
const UNSUBSCRIBE_REQUESTED = "UNSUBSCRIBE_REQUESTED";
const UNSUBSCRIBE_SUCCESSFUL = "UNSUBSCRIBE_SUCCESSFUL";
const UNSUBSCRIBE_REJECTED = "UNSUBSCRIBE_REJECTED";
const SEND_AM_REQUEST_REQUESTED = "SEND_AM_REQUEST_REQUESTED";
const SEND_AM_REQUEST_SUCCESSFUL = "SEND_AM_REQUEST_SUCCESSFUL";
const SEND_AM_REQUEST_REJECTED = "SEND_AM_REQUEST_REJECTED";

export const sendMessage = (
  values: ContactValues,
  meta: Meta
): ContactAction => ({
  type: SEND_MESSAGE_REQUESTED,
  payload: { values, meta },
});

const sendMessageSuccessful = (): Action => ({
  type: SEND_MESSAGE_SUCCESSFUL,
});

const sendMessageRejected = (error: TypeError): ErrorAction => ({
  type: SEND_MESSAGE_REJECTED,
  error,
});

export const sendPremiumMessage = (
  values: ContactValues,
  meta: Meta
): ContactAction => ({
  type: SEND_PREMIUM_MESSAGE_REQUESTED,
  payload: { values, meta },
});

const sendPremiumMessageSuccessful = (): Action => ({
  type: SEND_PREMIUM_MESSAGE_SUCCESSFUL,
});

const sendPremiumMessageRejected = (error: TypeError): ErrorAction => ({
  type: SEND_PREMIUM_MESSAGE_REJECTED,
  error,
});

export const sendFundRequest = (
  fundsIds: number[],
  type: string,
  meta?: Meta
): ContactAction => ({
  type: SEND_FUND_REQUEST_REQUESTED,
  payload: { fundsIds, type, meta },
});

const sendFundRequestSuccessful = (): Action => ({
  type: SEND_FUND_REQUEST_SUCCESSFUL,
});

const sendFundRequestRejected = (error: TypeError): ErrorAction => ({
  type: SEND_FUND_REQUEST_REJECTED,
  error,
});

export const sendTeamRequest = (name: string, meta?: Meta): ContactAction => ({
  type: SEND_TEAM_REQUEST_REQUESTED,
  payload: { name, meta },
});

const sendTeamRequestSuccessful = (): Action => ({
  type: SEND_TEAM_REQUEST_SUCCESSFUL,
});

const sendTeamRequestRejected = (error?: TypeError): ErrorAction => ({
  type: SEND_TEAM_REQUEST_REJECTED,
  error,
});

export const subscribeList = (): Action => ({
  type: SUBSCRIBE_LIST_REQUEST_REQUESTED,
});

const subscribeListSuccessful = (): Action => ({
  type: SUBSCRIBE_LIST_REQUEST_SUCCESSFUL,
});

const subscribeListRejected = (error?: TypeError): ErrorAction => ({
  type: SUBSCRIBE_LIST_REQUEST_REJECTED,
  error,
});

export const unsubscribeList = (): Action => ({
  type: UNSUBSCRIBE_LIST_REQUEST_REQUESTED,
});

const unsubscribeListSuccessful = (): Action => ({
  type: UNSUBSCRIBE_LIST_REQUEST_SUCCESSFUL,
});

const unsubscribeListRejected = (error?: TypeError): ErrorAction => ({
  type: UNSUBSCRIBE_LIST_REQUEST_REJECTED,
  error,
});

export const unsubscribe = (email: string): ContactAction => ({
  type: UNSUBSCRIBE_REQUESTED,
  payload: { email },
});

const unsubscribeSuccessful = (): Action => ({
  type: UNSUBSCRIBE_SUCCESSFUL,
});

const unsubscribeRejected = (error?: TypeError): ErrorAction => ({
  type: UNSUBSCRIBE_REJECTED,
  error,
});

export const sendAmRequest = (
  name: string,
  type: string,
  meta?: Meta
): ContactAction => ({
  type: SEND_AM_REQUEST_REQUESTED,
  payload: { name, type, meta },
});

const sendAmRequestSuccessful = (): Action => ({
  type: SEND_AM_REQUEST_SUCCESSFUL,
});

const sendAmRequestRejected = (error: TypeError): ErrorAction => ({
  type: SEND_AM_REQUEST_REJECTED,
  error,
});

export default (state: ContactState = {}, { type }: ContactAction) => {
  switch (type) {
    default:
      return state;
  }
};

export const sendMessageEpic = (
  action$: Observable<ContactAction>
): Observable<Action> =>
  action$.pipe(
    ofType(SEND_MESSAGE_REQUESTED),
    mergeMap(({ payload }) => {
      const { resolve, reject }: Meta = payload.meta;
      return ajax
        .post(
          `${API_URL}/send_message/`,
          {
            name: payload.values.name,
            email: payload.values.email,
            phone: payload.values.phone || "-",
            message: payload.values.message,
          },
          getReqOptions()
        )
        .pipe(
          mergeMap(({ response }) => {
            if (resolve) resolve(response);
            return of(sendMessageSuccessful());
          }),
          catchError(error => {
            if (reject) reject(error);
            return of(sendMessageRejected(error));
          })
        );
    })
  );

export const sendPremiumMessageEpic = (
  action$: Observable<ContactAction>
): Observable<Action> =>
  action$.pipe(
    ofType(SEND_PREMIUM_MESSAGE_REQUESTED),
    mergeMap(({ payload }) => {
      const { resolve, reject }: Meta = payload.meta;
      return ajax
        .post(
          `${API_URL}/send_premium_message/`,
          {
            name: payload.values.name,
            email: payload.values.email,
            phone: payload.values.phone || "-",
            message: payload.values.message,
          },
          getReqOptions()
        )
        .pipe(
          mergeMap(({ response }) => {
            if (resolve) resolve(response);
            return of(sendPremiumMessageSuccessful());
          }),
          catchError(error => {
            if (reject) reject(error);
            return of(sendPremiumMessageRejected(error));
          })
        );
    })
  );

export const sendFundRequestEpic = (
  action$: Observable<ContactAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(SEND_FUND_REQUEST_REQUESTED),
    mergeMap(({ payload }) => {
      const date = new Date();
      const url = state.value.domain.auth.isAssetManager
        ? "send_asset_manager_request"
        : "send_fund_request";

      return ajax
        .post(
          `${API_URL}/${url}/`,
          {
            funds: payload.fundsIds,
            email: state.value.domain.auth.user
              ? state.value.domain.auth.user.email
              : lang.fund.anonymous,
            request_type: payload.type,
            date: getFormattedDate(date.toString()),
          },
          getReqOptions()
        )
        .pipe(
          mergeMap(() =>
            of(
              addSuccessToast(lang.fund.fundRequestSent),
              sendFundRequestSuccessful()
            )
          ),
          catchError(error =>
            of(
              addErrorToast(lang.assetManagerDashboard.errors.default),
              sendFundRequestRejected(error)
            )
          )
        );
    })
  );

export const sendTeamRequestEpic = (
  action$: Observable<ContactAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(SEND_TEAM_REQUEST_REQUESTED),
    mergeMap(({ payload }) => {
      const date = new Date();
      const { resolve, reject }: Meta = payload.meta;
      if (payload.name) {
        return ajax
          .post(
            `${API_URL}/send_team_request/`,
            {
              name: payload.name,
              is_asset_manager: state.value.domain.auth.isAssetManager,
              email: state.value.domain.auth.user
                ? state.value.domain.auth.user.email
                : lang.fund.anonymous,
              date: getFormattedDate(date.toString()),
            },
            getReqOptions()
          )
          .pipe(
            mergeMap(({ response }) => {
              if (resolve) resolve(response);
              return of(sendTeamRequestSuccessful());
            }),
            catchError(error => {
              if (reject) reject(error);
              return of(sendTeamRequestRejected(error));
            })
          );
      } else {
        return of(sendTeamRequestRejected());
      }
    })
  );

export const subscribeListEpic = (
  action$: Observable<ContactAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(SUBSCRIBE_LIST_REQUEST_REQUESTED),
    mergeMap(() => {
      const date = new Date();
      const { user } = state.value.domain.auth;

      if (user) {
        return ajax
          .post(
            `${API_URL}/subscribe_list/`,
            {
              name: user.name,
              email: user.email,
              date: getFormattedDate(date.toString()),
            },
            getReqOptions()
          )
          .pipe(
            mergeMap(() =>
              of(
                addSuccessToast(lang.premium.subscribeListSuccess),
                subscribeListSuccessful()
              )
            ),
            catchError(error =>
              of(
                addErrorToast(lang.premium.subscribeListError),
                subscribeListRejected(error)
              )
            )
          );
      } else {
        return of(
          addErrorToast(lang.premium.subscribeListError),
          subscribeListRejected()
        );
      }
    })
  );

export const unsubscribeListEpic = (
  action$: Observable<ContactAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(UNSUBSCRIBE_LIST_REQUEST_REQUESTED),
    mergeMap(() => {
      const date = new Date();
      const { user } = state.value.domain.auth;

      if (user) {
        return ajax
          .post(
            `${API_URL}/unsubscribe_list/`,
            {
              name: user.name,
              email: user.email,
              date: getFormattedDate(date.toString()),
            },
            getReqOptions()
          )
          .pipe(
            mergeMap(() =>
              of(
                addSuccessToast(lang.premium.unsubscribeListSuccess),
                unsubscribeListSuccessful()
              )
            ),
            catchError(error =>
              of(
                addErrorToast(lang.premium.subscribeListError),
                unsubscribeListRejected(error)
              )
            )
          );
      } else {
        return of(
          addErrorToast(lang.premium.subscribeListError),
          unsubscribeListRejected()
        );
      }
    })
  );

export const unsubscribeEpic = (
  action$: Observable<ContactAction>
): Observable<Action> =>
  action$.pipe(
    ofType(UNSUBSCRIBE_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .put(
          `${API_URL}/unsubscribe`,
          {
            email: payload.email,
          },
          getReqOptions()
        )
        .pipe(
          mergeMap(() => of(unsubscribeSuccessful())),
          catchError(error => of(unsubscribeRejected(error)))
        )
    )
  );

export const sendAmRequestEpic = (
  action$: Observable<ContactAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(SEND_AM_REQUEST_REQUESTED),
    mergeMap(({ payload }) => {
      const date = new Date();

      return ajax
        .post(
          `${API_URL}/send_asset_manager_box_request/`,
          {
            asset_manager: payload.name,
            email: state.value.domain.auth.user
              ? state.value.domain.auth.user.email
              : lang.fund.anonymous,
            request_type: payload.type,
            date: getFormattedDate(date.toString()),
          },
          getReqOptions()
        )
        .pipe(
          mergeMap(() =>
            of(
              addSuccessToast(lang.assetManagers.requestSuccess),
              sendAmRequestSuccessful()
            )
          ),
          catchError(error =>
            of(
              addErrorToast(lang.assetManagerDashboard.errors.default),
              sendAmRequestRejected(error)
            )
          )
        );
    })
  );
