import {
  Action,
  ErrorAction,
  Meta,
  Report,
  ReportsAction,
  ReportsState,
} from "./types";
import { Observable, of } from "rxjs";
import { catchError, filter, mergeMap, takeUntil } from "rxjs/operators";
import { setReportsListTotal, setReportsLoading } from "./../ui/reports";

import { API_URL } from "./config";
import { StateValue } from "../types";
import { ajax } from "rxjs/ajax";
import { getReportsQueryParams } from "./../../utils/routing";
import { lang } from "../../lang/lang";
import { ofType } from "redux-observable";
import { push } from "connected-react-router";
import { setReportDetailsLoading } from "./../ui/reportDetails";
import { slugify } from "../../utils/funds";
import urls from "../../utils/urls";

const FETCH_REPORTS_LIST_REQUESTED = "FETCH_REPORTS_LIST_REQUESTED";
const FETCH_REPORTS_LIST_SUCCESSFUL = "FETCH_REPORTS_LIST_SUCCESSFUL";
const FETCH_REPORTS_LIST_REJECTED = "FETCH_REPORTS_LIST_REJECTED";
const FETCH_SINGLE_REPORT_REQUESTED = "FETCH_SINGLE_REPORT_REQUESTED";
const FETCH_SINGLE_REPORT_SUCCESSFUL = "FETCH_SINGLE_REPORT_SUCCESSFUL";
const FETCH_SINGLE_REPORT_REJECTED = "FETCH_SINGLE_REPORT_REJECTED";

export const fetchReportsList = (meta?: Meta): ReportsAction => ({
  type: FETCH_REPORTS_LIST_REQUESTED,
  payload: { meta },
});

export const fetchReportsListSuccessful = (
  reports: Report[]
): ReportsAction => ({
  type: FETCH_REPORTS_LIST_SUCCESSFUL,
  payload: { reports },
});

export const fetchReportsListRejected = (error: TypeError): ErrorAction => ({
  type: FETCH_REPORTS_LIST_REJECTED,
  error,
});

export const fetchSingleReport = (id: number): ReportsAction => ({
  type: FETCH_SINGLE_REPORT_REQUESTED,
  payload: { id },
});

export const fetchSingleReportSuccessful = (
  singleReport: Report
): ReportsAction => ({
  type: FETCH_SINGLE_REPORT_SUCCESSFUL,
  payload: { singleReport },
});

export const fetchSingleReportRejected = (error: TypeError): ErrorAction => ({
  type: FETCH_SINGLE_REPORT_REJECTED,
  error,
});

export default (
  state: ReportsState = {
    reportsList: [],
    reports: {},
    reportData: null,
  },
  { type, payload }: ReportsAction
) => {
  switch (type) {
    case FETCH_REPORTS_LIST_SUCCESSFUL:
      return {
        ...state,
        reportsList: payload.reports,
      };
    case FETCH_SINGLE_REPORT_SUCCESSFUL:
      if (payload.singleReport) {
        return {
          ...state,
          reports: {
            ...state.reports,
            [payload.singleReport.id]: payload.singleReport,
          },
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export const fetchReportsListEpic = (
  action$: Observable<ReportsAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_REPORTS_LIST_REQUESTED),
    mergeMap(({ payload }) => {
      const queryParams = getReportsQueryParams(state.value.form);
      const resolve = (payload.meta && payload.meta.resolve) || null;
      const reject = (payload.meta && payload.meta.reject) || null;
      const language = `${queryParams === "" ? "?" : "&"
        }lang=${lang.getLanguage()}`;

      return ajax.get(`${API_URL}/reports/${queryParams}${language}`).pipe(
        mergeMap(({ response }) => {
          if (resolve) resolve(response);
          return of(
            setReportsLoading(false),
            setReportsListTotal(response.count),
            fetchReportsListSuccessful(response.results)
          );
        }),
        takeUntil(
          action$.pipe(
            filter(({ type }) => type === FETCH_REPORTS_LIST_REQUESTED)
          )
        ),
        catchError((error) => {
          if (reject) reject(error);
          return of(setReportsLoading(false), fetchReportsListRejected(error));
        })
      );
    })
  );

export const fetchSingleReportEpic = (
  action$: Observable<ReportsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_SINGLE_REPORT_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .get(`${API_URL}/reports/${payload.id}/?lang=${lang.getLanguage()}`)
        .pipe(
          mergeMap(({ response }) => {
            if (urls.NEWS_DETAILS_SHORT.reg.test(window.location.pathname)) {
              return of(
                push(`/reports/${slugify(response.title)}/${response.id}`)
              );
            }
            return of(
              setReportDetailsLoading(false),
              fetchSingleReportSuccessful(response)
            );
          }),
          catchError((error) => {
            if (error.status === 404) {
              return of(push(`/404`));
            }
            return of(
              setReportDetailsLoading(false),
              fetchSingleReportRejected(error)
            );
          })
        )
    )
  );
