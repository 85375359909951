import { ofType } from "redux-observable";
import { Observable, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import {
  catchError,
  mergeMap,
} from "rxjs/operators";

import { setFundsInFocusLoading } from "../../ui/fundsInFocus";
import { API_URL } from "../config";
import {
  Action,
  ErrorAction,
  FundInFocus,
  FundsAction,
  FundsInFocusState,
} from "../types";

const FETCH_FUNDS_IN_FOCUS_REQUESTED = "FETCH_FUNDS_IN_FOCUS_REQUESTED";
const FETCH_FUNDS_IN_FOCUS_SUCCESSFUL = "FETCH_FUNDS_IN_FOCUS_SUCCESSFUL";
const FETCH_FUNDS_IN_FOCUS_REJECTED = "FETCH_FUNDS_IN_FOCUS_REJECTED";

export const fetchFundsInFocus = (): Action => ({
  type: FETCH_FUNDS_IN_FOCUS_REQUESTED
});

export const fetchFundsInFocusSuccessful = (
  funds: FundInFocus[]
): FundsAction => ({
  type: FETCH_FUNDS_IN_FOCUS_SUCCESSFUL,
  payload: { funds }
});

export const fetchFundsInFocusRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_FUNDS_IN_FOCUS_REJECTED,
  error
});

export default (
  state: FundsInFocusState = {
    fundsInFocus: []
  },
  { type, payload }: FundsAction
) => {
  switch (type) {
    case FETCH_FUNDS_IN_FOCUS_SUCCESSFUL:
      return {
        ...state,
        fundsInFocus: payload.funds
      };
    default:
      return state;
  }
};

export const fetchFundsInFocusEpic = (
  action$: Observable<FundsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_FUNDS_IN_FOCUS_REQUESTED),
    mergeMap(() =>
      ajax.get(`${API_URL}/fund_in_focus_categories/`).pipe(
        mergeMap(({ response }) =>
          of(
            setFundsInFocusLoading(false),
            fetchFundsInFocusSuccessful(response.results)
          )
        ),
        catchError(error =>
          of(setFundsInFocusLoading(false), fetchFundsInFocusRejected(error))
        )
      )
    )
  );
