import "./appBreadcrumbs.scss";

import AppLink from "./appLink";
import { NavLink } from "react-router-dom";
import React from "react";
import { State } from "../../types";
import { connect } from "react-redux";
import urls from "../../utils/urls";
import { withBreadcrumbs } from "../../withBreadcrumbs";
import { withRouter } from "react-router";

const DetailsViewBreadcrumbsComponent = ({
  match,
  news,
  assetManagerNews,
  reports,
  funds,
  oif,
}) => {
  const { pathname } = window.location;
  if (
    urls.NEWS_DETAILS.reg.test(pathname) ||
    urls.NEWS_DETAILS_SHORT.reg.test(pathname)
  ) {
    return news ? news?.title : "";
  } else if (urls.ASSET_MANAGER_NEWS.reg.test(pathname)) {
    return getBreadcrumbsTitle(assetManagerNews, match);
  } else if (
    urls.REPORTS_DETAILS.reg.test(pathname) ||
    urls.REPORTS_DETAILS_SHORT.reg.test(pathname)
  ) {
    return getBreadcrumbsTitle(reports, match);
  } else if (urls.DETAILS.reg.test(pathname)) {
    return getBreadcrumbsTitle(funds, match);
  } else if (
    urls.OIF_DETAILS.reg.test(pathname) ||
    urls.OIF_DETAILS_SHORT.reg.test(pathname)
  ) {
    return getBreadcrumbsTitle(oif, match);
  } else if (urls.DETAILS.reg.test(pathname)) {
    return getBreadcrumbsTitle(funds, match);
  } else {
    return;
  }
};

const getBreadcrumbsTitle = (items, match) => {
  const id = parseInt(match.url.substr(match.url.lastIndexOf("/") + 1), 10);
  const title = urls.DETAILS.reg.test(window.location.pathname)
    ? "name"
    : "title";
  return items[id] ? items[id][title] : "";
};

const mapStateToProps = ({ domain }: State) => ({
  news: domain.news.news,
  assetManagerNews: domain.assetManagers.news,
  reports: domain.reports.reports,
  funds: domain.funds.details.funds,
});

export const DetailsViewBreadcrumbs = withRouter<any, any>(
  connect(mapStateToProps, undefined)(DetailsViewBreadcrumbsComponent)
);

const isNewsBreadcrumbs = (breadcrumbs) =>
  breadcrumbs[1] && breadcrumbs[1].match.url.indexOf("/news") !== -1
    ? "app-container--small"
    : "";

const AppBreadcrumbs = ({ breadcrumbs }) => (
  <div className="app-breadcrumbs">
    <div className={`app-container ${isNewsBreadcrumbs(breadcrumbs)} `}>
      <ul className="breadcrumbs">
        <li className="breadcrumbs__item">
          <AppLink to="/">
            <i className="fa fa-home" />
          </AppLink>
          <span className="breadcrumbs__separator">&#47;</span>
        </li>
        {breadcrumbs.map(({ breadcrumb, path, match }) => (
          <li key={path} className="breadcrumbs__item">
            <NavLink to={match.url}>{breadcrumb()}</NavLink>
            <span className="breadcrumbs__separator">&#47;</span>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default withBreadcrumbs()(AppBreadcrumbs);
