import { ContactFormAction, ContactFormState } from "./types";

const SET_CONTACT_LOADING = "SET_CONTACT_LOADING";

export const setContactLoading = (value: boolean): ContactFormAction => ({
  type: SET_CONTACT_LOADING,
  payload: { value }
});

export default (
  state: ContactFormState = {
    isLoading: false
  },
  { type, payload }: ContactFormAction
) => {
  switch (type) {
    case SET_CONTACT_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    default:
      return state;
  }
};
