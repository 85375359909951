import { change } from "redux-form";
import { isSurveyPage } from "./app";
import { store } from "../redux";
import urls from "./urls";
import { StateValue } from "../redux/types";

export const scrollSurvey = () => {
  const surveyForm = document.getElementById("survey");
  if (surveyForm) {
    surveyForm.scrollIntoView({ behavior: "smooth" });
  }
};

export const setSurveyValues = questions => {
  questions?.forEach(({ id, type, value, sub_questions }) => {
    if (type !== "table" && type !== "row" && type !== "list") {
      if (type === "radio") {
        store.dispatch(change("survey", `answer-${id}`, value?.value || value));
      } else if (type === "text-list-element") {
        for (let i = 0; i < 4; i++) {
          store.dispatch(change("survey", `answer-${id}-${i}`, value[i]));
        }
      } else {
        store.dispatch(change("survey", `answer-${id}`, value));
      }
    }
    if (sub_questions.length > 0) {
      setSurveyValues(sub_questions);
    }
  });
};

const isSubquestionAnswered = (sub_questions, values) => {
  let isAnswered = false;
  let index = 0;

  while (isAnswered === false && index < sub_questions.length) {
    const item = sub_questions[index];
    const length = item.sub_questions.length;

    if (length > 0) {
      isAnswered = isSubquestionAnswered(item.sub_questions, values);
    } else if (length === 0 && values && values[`answer-${item.id}`]) {
      isAnswered = true;
    }
    index++;
  }

  return isAnswered;
};

export const setSurveyAnswers = (questions, form) => {
  const values = {};
  questions?.forEach(({ id, super_question_id, sub_questions }) => {
    if (super_question_id === null && form) {
      values[id] =
        sub_questions.length > 0
          ? isSubquestionAnswered(sub_questions, form)
          : form[`answer-${id}`];
    }
  });
  return values;
};

export const setSurveyRequiredAnswersValues = (questions, form) => {
  const values = {};
  questions?.filter(q => q.required).forEach(({ id, super_question_id, sub_questions }) => {
    if (super_question_id === null && form) {
      values[id] =
        sub_questions.length > 0
          ? isSubquestionAnswered(sub_questions, form)
          : true;
    }
  });
  return values;
};

export const getAllPages = (survey, maxPage) => {
  let allPages = true;
  for (let i = 1; i < maxPage; i++) {
    if (!survey[i]) allPages = false;
  }
  return allPages;
};

export const getAllQuestions = (survey, maxPage) => {
  const questions = [];
  for (let i = 1; i <= maxPage; i++) {
    if (survey[i]) questions.push(...survey[i].questions);
  }
  return questions;
};

export const getSurveyLink = values =>
  isSurveyPage() && values["answer-15"] ? values["answer-15"] : "";

export const getSurveyType = () => {
  const path = window.location.pathname;
  let type = "esg-fund";

  if (urls.SURVEY_ESG_COMPANY.reg.test(path)) {
    type = "esg-company"
  }
  if (urls.SURVEY_ESG_FUND.reg.test(path)) {
    type = "esg-fund"
  }
  if (urls.SURVEY_EQUITY_FUND.reg.test(path)) {
    type = "esg-equity-fund";
  }
  if (urls.SURVEY_SOVEREIGN_BOND_FUND.reg.test(path)) {
    type = "esg-sovereign-bond-fund";
  }
  if (urls.SURVEY_CORPORATE_BOND_FUND.reg.test(path)) {
    type = "esg-corporate-bond-fund";
  }
  if (urls.SURVEY_AGGREGATE_BOND_FUND.reg.test(path)) {
    type = "esg-aggregate-bond-fund";
  }
  if (urls.SURVEY_MULTI_ASSET_FUND.reg.test(path)) {
    type = "esg-multi-asset-fund";
  }
  if (urls.DEKA_SURVEY.reg.test(path)) {
    type = "deka-esg";
  }

  return type;
};

export const getIsinId = ({ value }: StateValue) => {
  const { version } = value?.domain?.survey;
  let isinId = "";
  // eslint-disable-next-line no-restricted-globals
  isinId = version?.toString() || new URL(location.href).searchParams.get('isins_versions');

  return isinId;
}

export const getAnsweredCount = values => {
  let counter = 0;
  Object.keys(values).forEach(key => values[key] && counter++);
  return counter;
};
