import { FundsInFocusAction, FundsInFocusState } from "./types";

const SET_FUNDS_IN_FOCUS_LOADING = "SET_FUNDS_IN_FOCUS_LOADING";
const SET_FUNDS_IN_FOCUS_CATEGORY = "SET_FUNDS_IN_FOCUS_CATEGORY";
const SET_FUNDS_IN_FOCUS_STEP = "SET_FUNDS_IN_FOCUS_STEP";

export const setFundsInFocusLoading = (value: boolean): FundsInFocusAction => ({
  type: SET_FUNDS_IN_FOCUS_LOADING,
  payload: { value },
});

export const setFundsInFocusActiveCategory = (
  value: number
): FundsInFocusAction => ({
  type: SET_FUNDS_IN_FOCUS_CATEGORY,
  payload: { value },
});

export const setFundsInFocusStep = (value: number): FundsInFocusAction => ({
  type: SET_FUNDS_IN_FOCUS_STEP,
  payload: { value },
});

export default (
  state: FundsInFocusState = {
    isLoading: false,
    category: 0,
    step: 0,
  },
  { type, payload }: FundsInFocusAction
) => {
  switch (type) {
    case SET_FUNDS_IN_FOCUS_LOADING:
      return {
        ...state,
        isLoading: payload.value,
      };
    case SET_FUNDS_IN_FOCUS_CATEGORY:
      return {
        ...state,
        category: payload.value,
        step: 0,
      };
    case SET_FUNDS_IN_FOCUS_STEP:
      return {
        ...state,
        step: payload.value,
      };
    default:
      return state;
  }
};
