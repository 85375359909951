import { ofType } from "redux-observable";
import { Observable, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, mergeMap } from "rxjs/operators";

import { lang } from "../../lang/lang";
import { setHomeFactsLoading, setHomeNewsLoading } from "../ui/home";
import { API_URL } from "./config";
import {
  Action,
  ErrorAction,
  HomeAction,
  HomeState,
  Meta,
  News
} from "./types";

const FETCH_HOME_NEWS_REQUESTED = "FETCH_HOME_NEWS_REQUESTED";
const FETCH_HOME_NEWS_SUCCESSFUL = "FETCH_HOME_NEWS_SUCCESSFUL";
const FETCH_HOME_NEWS_REJECTED = "FETCH_HOME_NEWS_REJECTED";
const FETCH_HOME_FACTS_REQUESTED = "FETCH_HOME_FACTS_REQUESTED";
const FETCH_HOME_FACTS_SUCCESSFUL = "FETCH_HOME_FACTS_SUCCESSFUL";
const FETCH_HOME_FACTS_REJECTED = "FETCH_HOME_FACTS_REJECTED";

export const fetchHomeNews = (meta?: Meta): HomeAction => ({
  type: FETCH_HOME_NEWS_REQUESTED,
  payload: { meta }
});

const fetchHomeNewsSuccessful = (news: News[]): HomeAction => ({
  type: FETCH_HOME_NEWS_SUCCESSFUL,
  payload: { news }
});

const fetchHomeNewsRejected = (error: TypeError): ErrorAction => ({
  type: FETCH_HOME_NEWS_REJECTED,
  error
});

export const fetchHomeFacts = (meta?: Meta): HomeAction => ({
  type: FETCH_HOME_FACTS_REQUESTED,
  payload: { meta }
});

const fetchHomeFactsSuccessful = (news: News[]): HomeAction => ({
  type: FETCH_HOME_FACTS_SUCCESSFUL,
  payload: { news }
});

const fetchHomeFactsRejected = (error: TypeError): ErrorAction => ({
  type: FETCH_HOME_FACTS_REJECTED,
  error
});

export default (
  state: HomeState = {
    homeNews: [],
    homeFacts: []
  },
  { type, payload }: HomeAction
) => {
  switch (type) {
    case FETCH_HOME_NEWS_SUCCESSFUL:
      return {
        ...state,
        homeNews: payload.news
      };
    case FETCH_HOME_FACTS_SUCCESSFUL:
      return {
        ...state,
        homeFacts: payload.news
      };
    default:
      return state;
  }
};

export const fetchHomeNewsEpic = (
  action$: Observable<HomeAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_HOME_NEWS_REQUESTED),
    mergeMap(() =>
      ajax.get(`${API_URL}/home_news/?lang=${lang.getLanguage()}`).pipe(
        mergeMap(({ response }) =>
          of(setHomeNewsLoading(false), fetchHomeNewsSuccessful(response))
        ),
        catchError(error =>
          of(setHomeNewsLoading(false), fetchHomeNewsRejected(error))
        )
      )
    )
  );

export const fetchHomeFactsEpic = (
  action$: Observable<HomeAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_HOME_FACTS_REQUESTED),
    mergeMap(() =>
      ajax.get(`${API_URL}/home_facts/?lang=${lang.getLanguage()}`).pipe(
        mergeMap(({ response }) =>
          of(setHomeFactsLoading(false), fetchHomeFactsSuccessful(response))
        ),
        catchError(error =>
          of(setHomeFactsLoading(false), fetchHomeFactsRejected(error))
        )
      )
    )
  );
