import { API_URL, getReqOptions } from "./config";
import {
  Action,
  AuthAction,
  AuthState,
  ErrorAction,
  License,
  LoginValues,
  Meta,
  RegisterValues,
  ResetPasswordValues,
  UserConference,
  WholesaleRegisterValues,
} from "./types";
import { Observable, of } from "rxjs";
import { addErrorToast, addSuccessToast } from "../ui/toasts";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import {
  getCurrentLocation,
  getCurrentSearchParams,
  getLangUrl,
  gtmPushEvent,
} from "../../utils/app";
import { setIsInitialized, setLicenseLoading } from "./../ui/app";
import { setRegisterLoading, setRegisterSuccess } from "../ui/register";

import Cookies from "universal-cookie";
import { Md5 } from "ts-md5/dist/md5";
import { StateValue } from "../types";
import { ajax } from "rxjs/ajax";
import { clearSearchFunds } from "./funds/search";
import { fetchCustomFields } from "./filters";
import { fetchFund } from "./funds/details";
import { fetchWatchlist } from "./funds/watchlist";
import jwt_decode from "jwt-decode";
import { lang } from "../../lang/lang";
import { ofType } from "redux-observable";
import { push } from "connected-react-router";
import { reset } from "redux-form";
import { setAdvancedFundsList } from "../ui/fundsList";
import { setAdvancedSearch } from "../ui/search";
import { setAppLoading } from "../ui/app";
import { setDetailsLoading } from "../ui/fundDetails";
import { setLoginVisibility } from "../ui/login";
import urls from "../../utils/urls";
import { setLastRoute } from "./route";

export const cookies = new Cookies();
export const cookiesOptions = {
  path: "/",
  secure: false,
  httpOnly: false,
  sameSite: true,
};

const LOGIN_REQUESTED = "LOGIN_REQUESTED";
const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
const LOGIN_REJECTED = "LOGIN_REJECTED";
const GET_LOGGED_IN_USER_REQUESTED = "GET_LOGGED_IN_USER_REQUESTED";
const GET_LOGGED_IN_USER_SUCCESSFUL = "GET_LOGGED_IN_USER_SUCCESSFUL";
const GET_LOGGED_IN_USER_REJECTED = "GET_LOGGED_IN_USER_REJECTED";
const REGISTER_REQUESTED = "REGISTER_REQUESTED";
const REGISTER_SUCCESSFUL = "REGISTER_SUCCESSFUL";
const REGISTER_REJECTED = "REGISTER_REJECTED";
const WHOLESALE_REGISTER_REQUESTED = "WHOLESALE_REGISTER_REQUESTED";
const WHOLESALE_REGISTER_SUCCESSFUL = "WHOLESALE_REGISTER_SUCCESSFUL";
const WHOLESALE_REGISTER_REJECTED = "WHOLESALE_REGISTER_REJECTED";
const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";
const LOGOUT_SUCCESSFUL = "LOGOUT_SUCCESSFUL";
const LOGOUT_REJECTED = "LOGOUT_REJECTED";
export const REFRESH_TOKEN_REQUESTED = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESSFUL = "REFRESH_TOKEN_SUCCESSFUL";
export const REFRESH_TOKEN_REJECTED = "REFRESH_TOKEN_REJECTED";
const CONFIRM_USER_REQUESTED = "CONFIRM_USER_REQUESTED";
const CONFIRM_USER_SUCCESSFUL = "CONFIRM_USER_SUCCESSFUL";
const CONFIRM_USER_REJECTED = "CONFIRM_USER_REJECTED";
const REQUEST_RESET_PASSWORD_REQUESTED = "REQUEST_RESET_PASSWORD_REQUESTED";
const REQUEST_RESET_PASSWORD_SUCCESSFUL = "REQUEST_RESET_PASSWORD_SUCCESSFUL";
const REQUEST_RESET_PASSWORD_REJECTED = "REQUEST_RESET_PASSWORD_REJECTED";
const RESET_PASSWORD_REQUESTED = "RESET_PASSWORD_REQUESTED";
const RESET_PASSWORD_SUCCESSFUL = "RESET_PASSWORD_SUCCESSFUL";
const RESET_PASSWORD_REJECTED = "RESET_PASSWORD_REJECTED";
const FETCH_USER_WEBINARS_REQUESTED = "FETCH_USER_WEBINARS_REQUESTED";
const FETCH_USER_WEBINARS_SUCCESSFUL = "FETCH_USER_WEBINARS_SUCCESSFUL";
const FETCH_USER_WEBINARS_REJECTED = "FETCH_USER_WEBINARS_REJECTED";
const FETCH_LICENSE_REQUESTED = "FETCH_LICENSE_REQUESTED";
const FETCH_LICENSE_SUCCESSFUL = "FETCH_LICENSE_SUCCESSFUL";
const FETCH_LICENSE_REJECTED = "FETCH_LICENSE_REJECTED";
const RESET_AUTH = "RESET_AUTH";
const SET_RECEIVE_EMAILS_REQUESTED = "SET_RECEIVE_EMAILS_REQUESTED";
const SET_RECEIVE_EMAILS_SUCCESSFUL = "SET_RECEIVE_EMAILS_SUCCESSFUL";
const SET_RECEIVE_EMAILS_REJECTED = "SET_RECEIVE_EMAILS_REJECTED";

export const login = (values: LoginValues, meta: Meta): AuthAction => ({
  type: LOGIN_REQUESTED,
  payload: { values, meta },
});

export const loginSuccessful = (
  token: string,
  isWholesaleUser: boolean,
  isAssetManager: boolean,
  assetManagerId: number | null,
  assetManagerName: string | null,
  company: string | null,
  phone: number | null,
  isExternalUser: boolean,
  receive_email_notification: boolean,
  isEsgFundSurveyAllowed: boolean = false,
  isEsgCompanySurveyAllowed: boolean = false,
  isDvmSurveyAllowed: boolean = false,
  isCompanyEsgAdmin: boolean = false,
  isEsgArticleUploadAllowed: boolean = false,
  isSurveySummaryAllowed: boolean = false
): AuthAction => ({
  type: LOGIN_SUCCESSFUL,
  payload: {
    user: jwt_decode(token),
    isWholesaleUser,
    isAssetManager,
    assetManagerId,
    assetManagerName,
    isExternalUser,
    receive_email_notification,
    company,
    phone,
    isEsgFundSurveyAllowed,
    isEsgCompanySurveyAllowed,
    isDvmSurveyAllowed,
    isCompanyEsgAdmin,
    isEsgArticleUploadAllowed,
    isSurveySummaryAllowed,
  },
});

const loginRejected = (error?: TypeError): ErrorAction => ({
  type: LOGIN_REJECTED,
  error,
});

export const getLoggedInUser = (): AuthAction => ({
  type: GET_LOGGED_IN_USER_REQUESTED,
  payload: {},
});

export const getLoggedInUserSuccessful = (
  isWholesaleUser: boolean,
  isAssetManager: boolean,
  assetManagerId: number | null,
  assetManagerName: string | null,
  company: string | null,
  phone: number | null,
  isExternalUser: boolean,
  receive_email_notification: boolean,
  isEsgFundSurveyAllowed: boolean = false,
  isEsgCompanySurveyAllowed: boolean = false,
  isDvmSurveyAllowed: boolean = false,
  isCompanyEsgAdmin: boolean = false,
  isEsgArticleUploadAllowed: boolean = false,
  isSurveySummaryAllowed: boolean = false
): AuthAction => ({
  type: GET_LOGGED_IN_USER_SUCCESSFUL,
  payload: {
    isWholesaleUser,
    isAssetManager,
    assetManagerId,
    assetManagerName,
    company,
    phone,
    isExternalUser,
    receive_email_notification,
    isEsgFundSurveyAllowed,
    isEsgCompanySurveyAllowed,
    isDvmSurveyAllowed,
    isCompanyEsgAdmin,
    isEsgArticleUploadAllowed,
    isSurveySummaryAllowed,
  },
});

const getLoggedInUserRejected = (error?: TypeError): ErrorAction => ({
  type: GET_LOGGED_IN_USER_REJECTED,
  error,
});

export const wholesaleRegister = (
  values: WholesaleRegisterValues
): AuthAction => ({
  type: WHOLESALE_REGISTER_REQUESTED,
  payload: { values },
});

const wholesaleRegisterSuccessful = (): Action => ({
  type: WHOLESALE_REGISTER_SUCCESSFUL,
});

const wholesaleRegisterRejected = (error?: TypeError): ErrorAction => ({
  type: WHOLESALE_REGISTER_REJECTED,
  error,
});

export const register = (values: RegisterValues): AuthAction => ({
  type: REGISTER_REQUESTED,
  payload: { values },
});

const registerSuccessful = (): Action => ({
  type: REGISTER_SUCCESSFUL,
});

const registerRejected = (error?: TypeError): ErrorAction => ({
  type: REGISTER_REJECTED,
  error,
});

export const logout = (): Action => ({
  type: LOGOUT_REQUESTED,
});

export const logoutSuccessful = (): Action => ({
  type: LOGOUT_SUCCESSFUL,
});

const logoutRejected = (error?: TypeError): ErrorAction => ({
  type: LOGOUT_REJECTED,
  error,
});

export const refreshToken = (): Action => ({
  type: REFRESH_TOKEN_REQUESTED,
});

export const refreshTokenSuccessful = (
  token: string,
  isWholesaleUser: boolean,
  isAssetManager: boolean,
  isEsgFundSurveyAllowed: boolean,
  isEsgCompanySurveyAllowed: boolean,
  isDvmSurveyAllowed: boolean,
  assetManagerId: number | null,
  assetManagerName: string | null,
  company: string | null,
  phone: number | null,
  isCompanyEsgAdmin: boolean,
  isEsgArticleUploadAllowed: boolean,
  isSurveySummaryAllowed: boolean
): AuthAction => ({
  type: REFRESH_TOKEN_SUCCESSFUL,
  payload: {
    user: jwt_decode(token),
    token,
    isWholesaleUser,
    isAssetManager,
    assetManagerId,
    assetManagerName,
    isEsgFundSurveyAllowed,
    isEsgCompanySurveyAllowed,
    isDvmSurveyAllowed,
    company,
    phone,
    isCompanyEsgAdmin,
    isEsgArticleUploadAllowed,
    isSurveySummaryAllowed,
  },
});

export const refreshTokenRejected = (error?: TypeError): ErrorAction => ({
  type: REFRESH_TOKEN_REJECTED,
  error,
});

export const confirmUser = (token: string): AuthAction => ({
  type: CONFIRM_USER_REQUESTED,
  payload: { token },
});

const confirmUserSuccessful = (): Action => ({
  type: CONFIRM_USER_SUCCESSFUL,
});

const confirmUserRejected = (error?: TypeError): ErrorAction => ({
  type: CONFIRM_USER_REJECTED,
  error,
});

export const requestResetPassword = (
  values: ResetPasswordValues,
  meta: Meta
): AuthAction => ({
  type: REQUEST_RESET_PASSWORD_REQUESTED,
  payload: { values, meta },
});

const requestResetPasswordSuccessful = (): Action => ({
  type: REQUEST_RESET_PASSWORD_SUCCESSFUL,
});

const requestResetPasswordRejected = (error?: TypeError): ErrorAction => ({
  type: REQUEST_RESET_PASSWORD_REJECTED,
  error,
});

export const resetPassword = (
  values: ResetPasswordValues,
  meta: Meta
): AuthAction => ({
  type: RESET_PASSWORD_REQUESTED,
  payload: { values, meta },
});

const resetPasswordSuccessful = (): Action => ({
  type: RESET_PASSWORD_SUCCESSFUL,
});

const resetPasswordRejected = (error?: TypeError): ErrorAction => ({
  type: RESET_PASSWORD_REJECTED,
  error,
});

export const fetchUserWebinars = (): Action => ({
  type: FETCH_USER_WEBINARS_REQUESTED,
});

const fetchUserWebinarsSuccessful = (
  webinars: UserConference[]
): AuthAction => ({
  type: FETCH_USER_WEBINARS_SUCCESSFUL,
  payload: { webinars },
});

const fetchUserWebinarsRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_USER_WEBINARS_REJECTED,
  error,
});

export const fetchLicense = (): Action => ({
  type: FETCH_LICENSE_REQUESTED,
});

const fetchLicenseSuccessful = (license: License): AuthAction => ({
  type: FETCH_LICENSE_SUCCESSFUL,
  payload: { license },
});

const fetchLicenseRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_LICENSE_REJECTED,
  error,
});

export const resetAuth = (): Action => ({
  type: RESET_AUTH,
});

export const setReceiveEmails = (value: boolean): AuthAction => ({
  type: SET_RECEIVE_EMAILS_REQUESTED,
  payload: { value },
});

const setReceiveEmailsSuccessful = (value: boolean): AuthAction => ({
  type: SET_RECEIVE_EMAILS_SUCCESSFUL,
  payload: { value },
});

const setReceiveEmailsRejected = (error?: TypeError): ErrorAction => ({
  type: SET_RECEIVE_EMAILS_REJECTED,
  error,
});

export default (
  state: AuthState = {
    isAuthenticated: false,
    isWholesaleUser: false,
    isAssetManager: false,
    assetManagerId: null,
    assetManagerName: null,
    isExternalUser: false,
    receiveEmailNotifications: false,
    isEsgFundSurveyAllowed: false,
    isEsgCompanySurveyAllowed: false,
    isDvmSurveyAllowed: false,
    company: null,
    phone: null,
    user: null,
    webinars: [],
    license: null,
    data: null,
    isCompanyEsgAdmin: false,
    isEsgArticleUploadAllowed: false,
    isSurveySummaryAllowed: false,
  },
  { type, payload }: AuthAction
) => {
  switch (type) {
    case REFRESH_TOKEN_SUCCESSFUL:
    case LOGIN_SUCCESSFUL:
      return {
        ...state,
        user: payload.user,
        isAuthenticated: true,
        isWholesaleUser: payload.isWholesaleUser,
        isAssetManager: payload.isAssetManager,
        assetManagerId: payload.assetManagerId,
        assetManagerName: payload.assetManagerName,
        isExternalUser: payload.isExternalUser,
        receiveEmailNotifications: payload.receive_email_notification,
        isEsgFundSurveyAllowed: payload.isEsgFundSurveyAllowed,
        isEsgCompanySurveyAllowed: payload.isEsgCompanySurveyAllowed,
        isDvmSurveyAllowed: payload.isDvmSurveyAllowed,
        company: payload.company,
        phone: payload.phone,
        isCompanyEsgAdmin: payload.isCompanyEsgAdmin,
        isEsgArticleUploadAllowed: payload.isEsgArticleUploadAllowed,
        isSurveySummaryAllowed: payload.isSurveySummaryAllowed,
      };
    case GET_LOGGED_IN_USER_SUCCESSFUL:
      return {
        ...state,
        isAuthenticated: true,
        isWholesaleUser: payload.isWholesaleUser,
        isAssetManager: payload.isAssetManager,
        assetManagerId: payload.assetManagerId,
        assetManagerName: payload.assetManagerName,
        isExternalUser: payload.isExternalUser,
        receiveEmailNotifications: payload.receive_email_notification,
        isEsgFundSurveyAllowed: payload.isEsgFundSurveyAllowed,
        isEsgCompanySurveyAllowed: payload.isEsgCompanySurveyAllowed,
        isDvmSurveyAllowed: payload.isDvmSurveyAllowed,
        company: payload.company,
        phone: payload.phone,
        isCompanyEsgAdmin: payload.isCompanyEsgAdmin,
        isEsgArticleUploadAllowed: payload.isEsgArticleUploadAllowed,
        isSurveySummaryAllowed: payload.isSurveySummaryAllowed,
      };
    case REFRESH_TOKEN_REJECTED:
    case LOGIN_REJECTED:
    case LOGOUT_SUCCESSFUL:
    case RESET_AUTH:
      return {
        ...state,
        isAuthenticated: false,
        isWholesaleUser: false,
        isAssetManager: false,
        isExternalUser: false,
        receiveEmailNotifications: false,
        isEsgFundSurveyAllowed: false,
        isEsgCompanySurveyAllowed: false,
        isDvmSurveyAllowed: false,
        assetManagerId: null,
        assetManagerName: null,
        company: null,
        phone: null,
        user: null,
        from: null,
        webinars: [],
        license: null,
        isEsgArticleUploadAllowed: false,
        isSurveySummaryAllowed: false,
      };
    case SET_RECEIVE_EMAILS_SUCCESSFUL:
      return {
        ...state,
        receiveEmailNotifications: payload.value,
      };
    case FETCH_USER_WEBINARS_SUCCESSFUL:
      return {
        ...state,
        webinars: payload.webinars,
      };
    case FETCH_LICENSE_SUCCESSFUL:
    case "CANCEL_SUBSCRIPTION_SUCCESSFUL":
      return {
        ...state,
        license: payload.license,
      };
    default:
      return state;
  }
};

export const logoutEpic = (
  action$: Observable<AuthAction>
): Observable<Action> =>
  action$.pipe(
    ofType(LOGOUT_REQUESTED),
    mergeMap(() =>
      ajax.post(`${API_URL}/logout/`, {}, { withCredentials: true }).pipe(
        mergeMap(() =>
          of(
            reset("advancedSearch"),
            reset("advancedFundsList"),
            setAdvancedSearch(false),
            setAdvancedFundsList(false),
            clearSearchFunds(),
            addSuccessToast(lang.menu.logoutSuccess),
            logoutSuccessful(),
            setLastRoute(null),
            push(`${getLangUrl()}${getCurrentSearchParams()}`)
          )
        ),
        catchError(error => of(logoutRejected(error)))
      )
    ),
    catchError(error => of(logoutRejected(error)))
  );

export const loginEpic = (
  action$: Observable<AuthAction>,
  state: StateValue
): Observable<Action | {}> =>
  action$.pipe(
    ofType(LOGIN_REQUESTED),
    mergeMap(({ payload }) => {
      const { resolve, reject }: Meta = payload.meta;
      return ajax
        .post(
          `${API_URL}/login/?id=${Md5.hashStr(payload.values.email)}`,
          {
            userName: payload.values.email,
            password: payload.values.password,
          },
          {
            "Content-Type": "application/json",
            withCredentials: true,
          }
        )
        .pipe(
          mergeMap(({ response }) => {
            if (resolve) resolve(response);
            const pathname = state.value.domain.route.prevPathname;
            const id = parseInt(
              pathname.substr(pathname.lastIndexOf("/") + 1),
              10
            );

            if (urls.DETAILS.reg.test(pathname)) {
              return of(
                setLoginVisibility(false),
                fetchLicense(),
                loginSuccessful(
                  response.token,
                  response.is_wholesale,
                  response.is_asset_manager,
                  response.company_info_id,
                  response.company_info_name,
                  response.company,
                  response.phone,
                  response.is_external_login,
                  response.receive_email_notifications,
                  response.is_esg_fund_survey_allowed,
                  response.is_esg_company_survey_allowed,
                  response.is_dvm_survey_allowed,
                  response.is_company_esg_admin,
                  response.is_esg_article_upload_allowed,
                  response.is_survey_summary_allowed
                ),
                setAdvancedSearch(response.is_wholesale),
                fetchUserWebinars(),
                fetchWatchlist(true),
                setDetailsLoading(true),
                fetchFund(id)
              );
            } else {
              return of(
                setLoginVisibility(false),
                fetchLicense(),
                loginSuccessful(
                  response.token,
                  response.is_wholesale,
                  response.is_asset_manager,
                  response.company_info_id,
                  response.company_info_name,
                  response.company,
                  response.phone,
                  response.is_external_login,
                  response.receive_email_notifications,
                  response.is_esg_fund_survey_allowed,
                  response.is_esg_company_survey_allowed,
                  response.is_dvm_survey_allowed,
                  response.is_company_esg_admin,
                  response.is_esg_article_upload_allowed,
                  response.is_survey_summary_allowed
                ),
                setAdvancedSearch(response.is_wholesale),
                fetchUserWebinars(),
                fetchWatchlist(true)
              );
            }
          }),
          catchError(error => {
            if (reject) reject(error);
            return of(loginRejected(error));
          })
        );
    })
  );

export const getLoggedInUserEpic = (
  action$: Observable<AuthAction>
): Observable<Action> =>
  action$.pipe(
    ofType(GET_LOGGED_IN_USER_REQUESTED),
    mergeMap(() =>
      ajax.get(`${API_URL}/user_info/`, getReqOptions()).pipe(
        mergeMap(({ response }) => {
          return of(
            getLoggedInUserSuccessful(
              response.is_wholesale,
              response.is_asset_manager,
              response.company_info_id,
              response.company_info_name,
              response.company,
              response.phone,
              response.is_test_user,
              response.receive_email_notifications,
              response.is_esg_fund_survey_allowed,
              response.is_esg_company_survey_allowed,
              response.is_dvm_survey_allowed,
              response.is_company_esg_admin,
              response.is_esg_article_upload_allowed,
              response.is_survey_summary_allowed
            )
          );
        }),
        catchError(error => {
          if (error.status === 404) {
            return of(push(`/404`));
          }
          return of(getLoggedInUserRejected(error));
        })
      )
    )
  );

export const registerEpic = (
  action$: Observable<AuthAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(REGISTER_REQUESTED),
    mergeMap(({ payload }) => {
      const { protocol, host } = window.location;
      const portalHost = `${protocol}//${host}/confirm/{code}`;

      return ajax
        .post(
          `${API_URL}/register/`,
          {
            firstName: payload.values.firstName,
            name: payload.values.lastName,
            email: payload.values.email,
            password: payload.values.password,
          },
          {
            "Content-Type": "application/json",
            "Portal-Host": portalHost,
          }
        )
        .pipe(
          mergeMap(() => {
            gtmPushEvent({
              event: "registerFormSubmit",
              userType: "retail",
              registerLocation: state.value.ui.register.registerLocation,
            });
            return of(
              setRegisterLoading(false),
              reset("register"),
              addSuccessToast(lang.register.success),
              setRegisterSuccess(true),
              registerSuccessful()
            );
          }),
          catchError(error =>
            of(
              setRegisterLoading(false),
              addErrorToast(
                lang.register.errors[error.status] ||
                  lang.register.errors.default ||
                  lang.register.fillInFields
              ),
              registerRejected(error)
            )
          )
        );
    })
  );

export const wholesaleRegisterEpic = (
  action$: Observable<AuthAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(WHOLESALE_REGISTER_REQUESTED),
    mergeMap(({ payload }) => {
      const { protocol, host } = window.location;
      const portalHost = `${protocol}//${host}/confirm/{code}`;

      return ajax
        .post(
          `${API_URL}/register/`,
          {
            firstName: payload.values.firstName,
            name: payload.values.lastName,
            email: payload.values.email,
            password: payload.values.password,
            company: payload.values.company,
            phone: payload.values.phone,
            sector: payload.values.sector.value,
            additinalSectorQuestion: payload.values.additinalSectorQuestion
              ? payload.values.additinalSectorQuestion.value
              : null,
            division: payload.values.division.value,
            isWholesale: true,
          },
          {
            "Content-Type": "application/json",
            "Portal-Host": portalHost,
          }
        )
        .pipe(
          mergeMap(() => {
            gtmPushEvent({
              event: "registerFormSubmit",
              userType: "wholesale",
              registerLocation: state.value.ui.register.registerLocation,
            });
            return of(
              reset("wholesaleRegister"),
              addSuccessToast(lang.register.success),
              setRegisterLoading(false),
              setRegisterSuccess(true),
              wholesaleRegisterSuccessful()
            );
          }),
          catchError(error =>
            of(
              addErrorToast(
                lang.register.errors[error.status] ||
                  lang.register.errors.default ||
                  lang.register.fillInFields
              ),
              setRegisterLoading(false),
              wholesaleRegisterRejected(error)
            )
          )
        );
    })
  );

export const rejectTokenEpic = (
  action$: Observable<ErrorAction>
): Observable<Action> | {} =>
  action$.pipe(
    ofType(REFRESH_TOKEN_REJECTED),
    mergeMap(() =>
      of(
        setAppLoading(false),
        setLicenseLoading(false),
        setAdvancedSearch(false),
        setIsInitialized(true),
        clearSearchFunds(),
        push(getCurrentLocation())
      )
    ),
    catchError(() =>
      of(setAppLoading(false), setIsInitialized(true), setAdvancedSearch(false))
    )
  );

export const refreshTokenEpic = (
  action$: Observable<AuthAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(REFRESH_TOKEN_REQUESTED),
    switchMap(() =>
      ajax
        .post(
          `${API_URL}/refresh-token/`,
          {},
          { "Content-Type": "application/json", withCredentials: true }
        )
        .pipe(
          mergeMap(({ response }) => {
            if (response.status === 204) {
              return of(setAppLoading(false), setIsInitialized(true));
            } else if (state.value.ui.app.isAppLoading) {
              return of(
                refreshTokenSuccessful(
                  response.token,
                  response.is_wholesale,
                  response.is_asset_manager,
                  response.is_esg_fund_survey_allowed === "True" ? true : false,
                  response.is_esg_company_survey_allowed === "True"
                    ? true
                    : false,
                  response.is_dvm_survey_allowed === "True" ? true : false,
                  response.company_info_id,
                  response.company_info_name,
                  response.company,
                  response.phone,
                  response.is_company_esg_admin === "True" ? true : false,
                  response.is_esg_article_upload_allowed,
                  response.is_survey_summary_allowed === "True" ? true : false
                ),
                fetchCustomFields(),
                fetchUserWebinars(),
                fetchLicense(),
                fetchWatchlist(true),
                setAppLoading(false),
                setIsInitialized(true)
              );
            } else {
              return of(
                setIsInitialized(true),
                refreshTokenSuccessful(
                  response.token,
                  response.is_wholesale,
                  response.is_asset_manager,
                  response.is_esg_fund_survey_allowed === "True" ? true : false,
                  response.is_esg_company_survey_allowed === "True"
                    ? true
                    : false,
                  response.is_dvm_survey_allowed === "True" ? true : false,
                  response.company_info_id,
                  response.company_info_name,
                  response.company,
                  response.phone,
                  response.is_company_esg_admin === "True" ? true : false,
                  response.is_esg_article_upload_allowed === "True"
                    ? true
                    : false,
                  response.is_survey_summary_allowed === "True" ? true : false
                )
              );
            }
          }),
          catchError(error =>
            of(clearSearchFunds(), refreshTokenRejected(error))
          )
        )
    ),
    catchError(error => of(refreshTokenRejected(error)))
  );

export const confirmUserEpic = (
  action$: Observable<AuthAction>
): Observable<Action> =>
  action$.pipe(
    ofType(CONFIRM_USER_REQUESTED),
    switchMap(({ payload }) => {
      const { token } = payload;
      if (token) {
        return ajax
          .post(
            `${API_URL}/confirm/${token}/`,
            {},
            { "Content-Type": "application/json" }
          )
          .pipe(
            mergeMap(() => of(confirmUserSuccessful(), push("/"))),
            catchError(error => of(confirmUserRejected(error), push("/")))
          );
      } else {
        return of(confirmUserRejected(), push("/"));
      }
    }),
    catchError(error => of(confirmUserRejected(error), push("/")))
  );

export const requestResetPasswordEpic = (
  action$: Observable<AuthAction>
): Observable<Action | {}> =>
  action$.pipe(
    ofType(REQUEST_RESET_PASSWORD_REQUESTED),
    switchMap(({ payload }) => {
      const { email } = payload.values;
      const { resolve, reject } = payload.meta;
      const { protocol, host } = window.location;
      const portalHost = `${protocol}//${host}/reset/{code}`;

      if (email) {
        return ajax
          .post(
            `${API_URL}/request-reset-password/`,
            { email },
            {
              "Content-Type": "application/json",
              "Portal-Host": portalHost,
            }
          )
          .pipe(
            map(({ response }) => {
              if (resolve) resolve(response);
              return requestResetPasswordSuccessful();
            }),
            catchError(error => {
              if (error) reject(error);
              return of(requestResetPasswordRejected(error));
            })
          );
      } else {
        reject();
        return of(requestResetPasswordRejected());
      }
    }),
    catchError(error => of(requestResetPasswordRejected(error)))
  );

export const resetPasswordEpic = (
  action$: Observable<AuthAction>,
  state: StateValue
): Observable<Action | {}> =>
  action$.pipe(
    ofType(RESET_PASSWORD_REQUESTED),
    switchMap(({ payload }) => {
      const { token } = state.value.ui.resetPassword;
      const { password } = payload.values;
      const { resolve, reject } = payload.meta;
      if (token && password) {
        return ajax
          .post(
            `${API_URL}/reset-password/`,
            { token, password },
            { "Content-Type": "application/json" }
          )
          .pipe(
            mergeMap(({ response }) => {
              if (resolve) resolve(response);
              return of(resetPasswordSuccessful());
            }),
            catchError(error => {
              if (error) reject(error);
              return of(resetPasswordRejected(error));
            })
          );
      } else {
        reject();
        return of(resetPasswordRejected());
      }
    }),
    catchError(error => of(resetPasswordRejected(error)))
  );

export const fetchUserWebinarsEpic = (
  action$: Observable<AuthAction>
): Observable<Action | {}> =>
  action$.pipe(
    ofType(FETCH_USER_WEBINARS_REQUESTED),
    switchMap(() =>
      ajax.get(`${API_URL}/profile/webinars/`, getReqOptions()).pipe(
        mergeMap(({ response }) => of(fetchUserWebinarsSuccessful(response))),
        catchError(error => of(fetchUserWebinarsRejected(error)))
      )
    ),
    catchError(error => of(fetchUserWebinarsRejected(error)))
  );

export const fetchLicenseEpic = (
  action$: Observable<AuthAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_LICENSE_REQUESTED),
    mergeMap(() =>
      ajax.get(`${API_URL}/profile/subscription/`, getReqOptions()).pipe(
        mergeMap(({ response }) =>
          of(
            push(getCurrentLocation()),
            fetchLicenseSuccessful(response),
            setLicenseLoading(false)
          )
        ),
        catchError(error =>
          of(
            setLicenseLoading(false),
            push(getCurrentLocation()),
            fetchLicenseRejected(error)
          )
        )
      )
    )
  );

export const setReceiveEmailsEpic = (
  action$: Observable<AuthAction>
): Observable<Action> =>
  action$.pipe(
    ofType(SET_RECEIVE_EMAILS_REQUESTED),
    mergeMap(({ payload }) =>
      ajax.post(`${API_URL}/profile/change_email_subscription/`).pipe(
        mergeMap(() =>
          of(
            addSuccessToast("Settings saved."),
            setReceiveEmailsSuccessful(payload.value)
          )
        ),
        catchError(error =>
          of(
            addErrorToast("Request failed. Please try again."),
            setReceiveEmailsRejected(error)
          )
        )
      )
    )
  );
