import { ComparisonModalAction, ComparisonModalState } from "./types";

const SET_FUNDS_IDS = "SET_FUNDS_ID";
const SET_COMPARISON_LOADING = "SET_COMPARISON_LOADING";
const SET_COMPARISON_AUTOCOMPLETE_VALUE = "SET_COMPARISON_AUTOCOMPLETE_VALUE";
const SET_COMPARISON_AUTOCOMPLETE_LOADING =
  "SET_COMPARISON_AUTOCOMPLETE_LOADING";
const SET_COMPARISON_AUTOCOMPLETE_VISIBILITY =
  "SET_COMPARISON_AUTOCOMPLETE_VISIBILITY";
const SET_COMPARISON_ERROR_VISIBILITY = "SET_COMPARISON_ERROR_VISIBILITY";

export const setFundsIds = (
  id: number | null,
  index: number
): ComparisonModalAction => ({
  type: SET_FUNDS_IDS,
  payload: { id, index }
});

export const setComparisonLoading = (
  value: boolean
): ComparisonModalAction => ({
  type: SET_COMPARISON_LOADING,
  payload: { value }
});

export const setComparisonAutocompleteValue = (
  value: string,
  index: number
): ComparisonModalAction => ({
  type: SET_COMPARISON_AUTOCOMPLETE_VALUE,
  payload: { value, index }
});

export const setComparisonAutocompleteLoading = (
  value: boolean,
  index: number
): ComparisonModalAction => ({
  type: SET_COMPARISON_AUTOCOMPLETE_LOADING,
  payload: { value, index }
});

export const setComparisonAutocompleteVisibility = (
  value: boolean,
  index: number
): ComparisonModalAction => ({
  type: SET_COMPARISON_AUTOCOMPLETE_VISIBILITY,
  payload: { value, index }
});

export const setComparisonErrorVisibility = (
  value: boolean
): ComparisonModalAction => ({
  type: SET_COMPARISON_ERROR_VISIBILITY,
  payload: { value }
});

export default (
  state: ComparisonModalState = {
    isLoading: false,
    isErrorVisible: false,
    fundsIds: [-1, -1, -1],
    comparisonAutocomplete: [
      {
        value: "",
        isLoading: false,
        isVisible: false
      },
      {
        value: "",
        isLoading: false,
        isVisible: false
      }
    ]
  },
  { type, payload }: ComparisonModalAction
) => {
  switch (type) {
    case SET_FUNDS_IDS:
      return {
        ...state,
        fundsIds: state.fundsIds.map((item, index) => {
          if (index !== payload.index) return item;
          return payload.id;
        })
      };
    case SET_COMPARISON_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    case SET_COMPARISON_AUTOCOMPLETE_VALUE:
      return {
        ...state,
        comparisonAutocomplete: state.comparisonAutocomplete.map(
          (item, index) => {
            if (index !== payload.index) {
              return item;
            }

            return {
              ...item,
              value: payload.value
            };
          }
        )
      };
    case SET_COMPARISON_AUTOCOMPLETE_LOADING:
      return {
        ...state,
        comparisonAutocomplete: state.comparisonAutocomplete.map(
          (item, index) => {
            if (index !== payload.index) {
              return item;
            }

            return {
              ...item,
              isLoading: payload.value
            };
          }
        )
      };
    case SET_COMPARISON_AUTOCOMPLETE_VISIBILITY:
      return {
        ...state,
        comparisonAutocomplete: state.comparisonAutocomplete.map(
          (item, index) => {
            if (index !== payload.index) {
              return item;
            }

            return {
              ...item,
              isVisible: payload.value
            };
          }
        )
      };
    case SET_COMPARISON_ERROR_VISIBILITY:
      return {
        ...state,
        isErrorVisible: payload.value
      };
    default:
      return state;
  }
};
