import { AssetManagerChartsAction, AssetManagerChartsState } from "./types";

const SET_ASSET_MANAGER_CHART_LOADING = "SET_ASSET_MANAGER_CHART_LOADING";

export const setAssetManagerChartLoading = (
  isLoading: boolean,
  chart: string
): AssetManagerChartsAction => ({
  type: SET_ASSET_MANAGER_CHART_LOADING,
  payload: { isLoading, chart },
});

export default (
  state: AssetManagerChartsState = {
    allMyProductsChartDashboard: { isLoading: false },
    allMyProductsChart: { isLoading: false },
    myProductsInPeergroupChart: { isLoading: false },
    myTop10FundsChart: { isLoading: false },
    top10FundsInSeChart: { isLoading: false },
    top10PeergroupsInSeChart: { isLoading: false },
    topPeergroupsFund: { isLoading: false },
  },
  { type, payload }: AssetManagerChartsAction
) => {
  switch (type) {
    case SET_ASSET_MANAGER_CHART_LOADING:
      return {
        ...state,
        [payload.chart]: {
          ...state[payload.chart],
          isLoading: payload.isLoading,
        },
      };
    default:
      return state;
  }
};
