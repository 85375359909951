import { FundsListAction, FundsListState } from "./types";

import { FundDocument } from "../domain/types";

const SET_FUNDS_LOADING = "SET_FUNDS_LOADING";
const SET_FUNDS_LIST_ACTIVE_TAB = "SET_FUNDS_LIST_ACTIVE_TAB";
const SET_ADVANCED_FUNDS_LIST = "SET_ADVANCED_FUNDS_LIST";
const SET_FILTER_FUNDS_LIST = "SET_FILTER_FUNDS_LIST";
const SET_PERF_PERIOD = "SET_PERF_PERIOD";
const SET_FILTER_SEARCH_PERF_PERIOD = "SET_FILTER_SEARCH_PERF_PERIOD";
const SET_SELECTED_FUND_PERF_PERIOD = "SET_SELECTED_FUND_PERF_PERIOD";
const SET_DOCUMENTS_VISIBILITY = "SET_DOCUMENTS_VISIBILITY";
const SET_DOCUMENTS = "SET_DOCUMENTS";
const SET_AIF_DOCUMENT = "SET_AIF_DOCUMENT";
const SET_RATING_HISTORY_VISIBILITY = "SET_RATING_HISTORY_VISIBILITY";
const SET_PERFORMANCE_VISIBILITY = "SET_PERFORMANCE_VISIBILITY";
const SET_SELECTED_FUND_LAST = "SET_SELECTED_FUND_LAST";
const SET_FUNDS_LIST_MODAL_VISIBILITY = "SET_FUNDS_LIST_MODAL_VISIBILITY";
const SET_EXPORT_DROPDOWN_VISIBILITY = "SET_EXPORT_DROPDOWN_VISIBILITY";
const SET_EXPORT_LOADING = "SET_EXPORT_LOADING";
const SET_EXPORT_LINK = "SET_EXPORT_LINK";

export const setFundsLoading = (value: boolean): FundsListAction => ({
  type: SET_FUNDS_LOADING,
  payload: { value }
});

export const setFundsListActiveTab = (value: number): FundsListAction => ({
  type: SET_FUNDS_LIST_ACTIVE_TAB,
  payload: { value }
});

export const setAdvancedFundsList = (value: boolean): FundsListAction => ({
  type: SET_ADVANCED_FUNDS_LIST,
  payload: { value }
});

export const setFilterFundsList = (value: boolean): FundsListAction => ({
  type: SET_FILTER_FUNDS_LIST,
  payload: { value }
});

export const setPerfPeriod = (value: number): FundsListAction => ({
  type: SET_PERF_PERIOD,
  payload: { value }
});

export const setFilterSearchPerfPeriod = (value: number): FundsListAction => ({
  type: SET_FILTER_SEARCH_PERF_PERIOD,
  payload: { value }
});

export const setSelectedFundPerfPeriod = (value: number): FundsListAction => ({
  type: SET_SELECTED_FUND_PERF_PERIOD,
  payload: { value }
});

export const setDocumentsVisibility = (value: boolean): FundsListAction => ({
  type: SET_DOCUMENTS_VISIBILITY,
  payload: { value }
});

export const setDocuments = (documents: FundDocument[]): FundsListAction => ({
  type: SET_DOCUMENTS,
  payload: { documents }
});

export const setAifDocument = (document: FundDocument): FundsListAction => ({
  type: SET_AIF_DOCUMENT,
  payload: { document }
});

export const setRatingHistoryVisibility = (
  value: boolean
): FundsListAction => ({
  type: SET_RATING_HISTORY_VISIBILITY,
  payload: { value }
});

export const setPerformanceVisibility = (value: boolean): FundsListAction => ({
  type: SET_PERFORMANCE_VISIBILITY,
  payload: { value }
});

export const setSelectedFundLast = (value: boolean): FundsListAction => ({
  type: SET_SELECTED_FUND_LAST,
  payload: { value }
});

export const setFUndsListModalVisibility = (
  value: boolean
): FundsListAction => ({
  type: SET_FUNDS_LIST_MODAL_VISIBILITY,
  payload: { value }
});

export const setExportDropdownVisibility = (
  value: boolean
): FundsListAction => ({
  type: SET_EXPORT_DROPDOWN_VISIBILITY,
  payload: { value }
});

export const setExportLoading = (value: boolean): FundsListAction => ({
  type: SET_EXPORT_LOADING,
  payload: { value }
});

export const setExportLink = (value: string | null): FundsListAction => ({
  type: SET_EXPORT_LINK,
  payload: { value }
});

export default (
  state: FundsListState = {
    isLoading: false,
    isAdvancedFundsList: false,
    isFilterFundsList: false,
    fundsListActiveTab: 0,
    perfPeriod: 3,
    filterSearchPerfPeriod: 5,
    selectedFundPerfPeriod: 3,
    isDocumentsVisible: false,
    documents: [],
    aifDocument: null,
    isRatingHistoryVisible: false,
    isPerformanceVisible: false,
    isSelectedFundLast: false,
    isFundsListModalVisible: true,
    isExportDropdownVisible: false,
    isExportLoading: false,
    exportLink: null
  },
  { type, payload }: FundsListAction
) => {
  switch (type) {
    case SET_FUNDS_LOADING:
      return {
        ...state,
        isLoading: payload.value
      };
    case SET_FUNDS_LIST_ACTIVE_TAB:
      return {
        ...state,
        fundsListActiveTab: payload.value
      };
    case SET_ADVANCED_FUNDS_LIST:
      return {
        ...state,
        isAdvancedFundsList: payload.value,
        isFilterFundsList:
          payload.value === true ? false : state.isFilterFundsList
      };
    case SET_FILTER_FUNDS_LIST:
      return {
        ...state,
        isFilterFundsList: payload.value,
        isAdvancedFundsList:
          payload.value === true ? false : state.isAdvancedFundsList
      };
    case SET_PERF_PERIOD:
      return {
        ...state,
        perfPeriod: payload.value
      };
    case SET_FILTER_SEARCH_PERF_PERIOD:
      return {
        ...state,
        filterSearchPerfPeriod: payload.value
      };
    case SET_SELECTED_FUND_PERF_PERIOD:
      return {
        ...state,
        selectedFundPerfPeriod: payload.value
      };
    case SET_DOCUMENTS_VISIBILITY:
      return {
        ...state,
        isDocumentsVisible: payload.value
      };
    case SET_DOCUMENTS:
      return {
        ...state,
        documents: payload.documents
      };
    case SET_AIF_DOCUMENT:
      return {
        ...state,
        aifDocument: payload.document
      };
    case SET_RATING_HISTORY_VISIBILITY:
      return {
        ...state,
        isRatingHistoryVisible: payload.value
      };
    case SET_PERFORMANCE_VISIBILITY:
      return {
        ...state,
        isPerformanceVisible: payload.value
      };
    case SET_SELECTED_FUND_LAST:
      return {
        ...state,
        isSelectedFundLast: payload.value
      };
    case SET_FUNDS_LIST_MODAL_VISIBILITY:
      return {
        ...state,
        isFundsListModalVisible: payload.value
      };
    case SET_EXPORT_DROPDOWN_VISIBILITY:
      return {
        ...state,
        isExportDropdownVisible: payload.value
      };
    case SET_EXPORT_LOADING:
      return {
        ...state,
        isExportLoading: payload.value
      };
    case SET_EXPORT_LINK:
      return {
        ...state,
        exportLink: payload.value
      };
    default:
      return state;
  }
};
