import { AssetManagerDetailsAction, AssetManagerDetailsState } from "./types";

const SET_TOP_ASSET_MANAGER_FUNDS_VISIBILITY = "SET_TOP_ASSET_MANAGER_FUNDS_VISIBILITY";
const SET_ASSET_MANAGER_INFO_LOADING = "SET_ASSET_MANAGER_INFO_LOADING";
const SET_ASSET_MANAGER_NEWS_LOADING = "SET_ASSET_MANAGER_NEWS_LOADING";
const SET_TOP_ASSET_MANAGER_FUNDS_LOADING = "SET_TOP_ASSET_MANAGER_FUNDS_LOADING";
const SET_TOP_ASSET_MANAGER_FUND_ID = "SET_TOP_ASSET_MANAGER_FUND_ID";
const SET_MACRO_OVERVIEWS_LOADING = "SET_MACRO_OVERVIEWS_LOADING";

export const setTopAssetManagerFundsVisibility = (
  value: boolean
): AssetManagerDetailsAction => ({
  type: SET_TOP_ASSET_MANAGER_FUNDS_VISIBILITY,
  payload: { value }
});

export const setAssetManagerInfoLoading = (
  value: boolean
): AssetManagerDetailsAction => ({
  type: SET_ASSET_MANAGER_INFO_LOADING,
  payload: { value }
});

export const setAssetManagerNewsLoading = (
  value: boolean
): AssetManagerDetailsAction => ({
  type: SET_ASSET_MANAGER_NEWS_LOADING,
  payload: { value }
});

export const setTopAssetManagerFundsLoading = (
  value: boolean
): AssetManagerDetailsAction => ({
  type: SET_TOP_ASSET_MANAGER_FUNDS_LOADING,
  payload: { value }
});

export const setTopAssetManagerFundId = (
  value: number
): AssetManagerDetailsAction => ({
  type: SET_TOP_ASSET_MANAGER_FUND_ID,
  payload: { value }
});

export const setMacroOverviewsLoading = (
  value: boolean
): AssetManagerDetailsAction => ({
  type: SET_MACRO_OVERVIEWS_LOADING,
  payload: { value }
});

export default (
  state: AssetManagerDetailsState = {
    isAssetManagerFundsLoading: true,
    isAssetManagerInfoLoading: true,
    isAssetManagerNewsLoading: true,
    isTopAssetManagerFundsVisible: false,
    isTopAssetManagerFundsLoading: true,
    topAssetManagerFundId: null,
    isMacroOverviewsLoading: true,
  },
  { type, payload }: AssetManagerDetailsAction
) => {
  switch (type) {
    case SET_TOP_ASSET_MANAGER_FUNDS_VISIBILITY:
      return {
        ...state,
        isTopAssetManagerFundsVisible: payload.value
      };
    case SET_ASSET_MANAGER_INFO_LOADING:
      return {
        ...state,
        isAssetManagerInfoLoading: payload.value
      };
    case SET_ASSET_MANAGER_NEWS_LOADING:
      return {
        ...state,
        isAssetManagerNewsLoading: payload.value
      };
    case SET_TOP_ASSET_MANAGER_FUNDS_LOADING:
      return {
        ...state,
        isTopAssetManagerFundsLoading: payload.value
      };
    case SET_TOP_ASSET_MANAGER_FUND_ID:
      return {
        ...state,
        topAssetManagerFundId: payload.value
      };
    case SET_MACRO_OVERVIEWS_LOADING:
      return {
        ...state,
        isMacroOverviewsLoading: payload.value
      };
    default:
      return state;
  }
};
