import { API_URL, getReqOptions } from "../config";
import {
  Action,
  CompareFund,
  ErrorAction,
  Fund,
  FundsAction,
  FundsSearchState,
  SearchFund,
} from "../types";
import { Observable, of } from "rxjs";
import {
  catchError,
  debounceTime,
  filter,
  mergeMap,
  takeUntil,
} from "rxjs/operators";
import { setAutocompleteValue, setSearchLoading } from "../../ui/search";
import {
  setFUndsListModalVisibility,
  setFundsLoading,
  setSelectedFundLast,
} from "../../ui/fundsList";
import { setFundsTableLoading, setFundsTableTotal } from "../../ui/fundsTable";

import { StateValue } from "../../types";
import { ajax } from "rxjs/ajax";
import { getSearchQueryParams } from "../../../utils/routing";
import { lang } from "../../../lang/lang";
import { ofType } from "redux-observable";
import { setAutocompleteVisibility } from "../../ui/search";
import { setComparisonAutocompleteLoading } from "../../ui/comparisonModal";
import { setNextSearch } from "./../../ui/search";
import { setSearchResultsParams } from "../route";
import { setSelectedFundPerfPeriod } from "./../../ui/fundsList";

const FETCH_COMPARE_FUNDS_REQUESTED = "FETCH_COMPARE_FUNDS_REQUESTED";
const FETCH_COMPARE_FUNDS_SUCCESSFUL = "FETCH_COMPARE_FUNDS_SUCCESSFUL";
const FETCH_COMPARE_FUNDS_REJECTED = "FETCH_COMPARE_FUNDS_REJECTED";

const FETCH_SEARCH_FUNDS_REQUESTED = "FETCH_SEARCH_FUNDS_REQUESTED";
const FETCH_SEARCH_FUNDS_SUCCESSFUL = "FETCH_SEARCH_FUNDS_SUCCESSFUL";
const FETCH_SEARCH_FUNDS_REJECTED = "FETCH_SEARCH_FUNDS_REJECTED";

const FETCH_NEXT_SEARCH_FUNDS_REQUESTED = "FETCH_NEXT_SEARCH_FUNDS_REQUESTED";
const FETCH_NEXT_SEARCH_FUNDS_SUCCESSFUL = "FETCH_NEXT_SEARCH_FUNDS_SUCCESSFUL";
const FETCH_NEXT_SEARCH_FUNDS_REJECTED = "FETCH_NEXT_SEARCH_FUNDS_REJECTED";

const FETCH_SEARCH_FUNDS_CANCELLED = "FETCH_SEARCH_FUNDS_CANCELLED";

const FETCH_ADVANCED_SEARCH_FUNDS_REQUESTED =
  "FETCH_ADVANCED_SEARCH_FUNDS_REQUESTED";
const FETCH_ADVANCED_SEARCH_FUNDS_SUCCESSFUL =
  "FETCH_ADVANCED_SEARCH_FUNDS_SUCCESSFUL";
const FETCH_ADVANCED_SEARCH_FUNDS_REJECTED =
  "FETCH_ADVANCED_SEARCH_FUNDS_REJECTED";

const CLEAR_SEARCH_FUNDS = "CLEAR_SEARCH_FUNDS";
const SET_SELECTED_FUND = "SET_SELECTED_FUND";
const SET_ADVANCED_SEARCH_FUNDS = "SET_ADVANCED_SEARCH_FUNDS";
const SET_SELECTED_TABLE_FUNDS = "SET_SELECTED_TABLE_FUNDS";
const RESET_SELECTED_TABLE_FUNDS = "RESET_SELECTED_TABLE_FUNDS";

const FETCH_SELECTED_FUND_REQUESTED = "FETCH_SELECTED_FUND_REQUESTED";
const FETCH_SELECTED_FUND_SUCCESSFUL = "FETCH_SELECTED_FUND_SUCCESSFUL";
const FETCH_SELECTED_FUND_REJECTED = "FETCH_SELECTED_FUND_REJECTED";

const FETCH_FUNDS_IN_PEERGROUP_REQUESTED = "FETCH_FUNDS_IN_PEERGROUP_REQUESTED";
const FETCH_FUNDS_IN_PEERGROUP_SUCCESSFUL =
  "FETCH_FUNDS_IN_PEERGROUP_SUCCESSFUL";
const FETCH_FUNDS_IN_PEERGROUP_REJECTED = "FETCH_FUNDS_IN_PEERGROUP_REJECTED";

export const fetchCompareFunds = (): Action => ({
  type: FETCH_COMPARE_FUNDS_REQUESTED,
});

export const fetchCompareFundsSuccessful = (
  funds: CompareFund[]
): FundsAction => ({
  type: FETCH_COMPARE_FUNDS_SUCCESSFUL,
  payload: { funds },
});

export const fetchCompareFundsRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_COMPARE_FUNDS_REJECTED,
  error,
});

export const fetchSearchFunds = (
  name: string,
  isCompare: boolean
): FundsAction => ({
  type: FETCH_SEARCH_FUNDS_REQUESTED,
  payload: { name, isCompare },
});

const fetchSearchFundsSuccessful = (funds: SearchFund[]): FundsAction => ({
  type: FETCH_SEARCH_FUNDS_SUCCESSFUL,
  payload: { funds },
});

export const fetchSearchFundsCancelled = (): Action => ({
  type: FETCH_SEARCH_FUNDS_CANCELLED,
});

export const fetchNextSearchFunds = (next: string): FundsAction => ({
  type: FETCH_NEXT_SEARCH_FUNDS_REQUESTED,
  payload: { next },
});

const fetchNextSearchFundsSuccessful = (funds: SearchFund[]): FundsAction => ({
  type: FETCH_NEXT_SEARCH_FUNDS_SUCCESSFUL,
  payload: { funds },
});

const fetchNextSearchFundsRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_NEXT_SEARCH_FUNDS_REJECTED,
  error,
});

const fetchSearchFundsRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_SEARCH_FUNDS_REJECTED,
  error,
});

export const fetchAdvancedSearchFunds = (): Action => ({
  type: FETCH_ADVANCED_SEARCH_FUNDS_REQUESTED,
});

const fetchAdvancedSearchFundsSuccessful = (funds: Fund[]): FundsAction => ({
  type: FETCH_ADVANCED_SEARCH_FUNDS_SUCCESSFUL,
  payload: { funds },
});

const fetchAdvancedSearchFundsRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_ADVANCED_SEARCH_FUNDS_REJECTED,
  error,
});

export const clearSearchFunds = (): Action => ({
  type: CLEAR_SEARCH_FUNDS,
});

export const setSelectedFund = (fund: SearchFund): FundsAction => ({
  type: SET_SELECTED_FUND,
  payload: { fund },
});

export const setAdvancedSearchFunds = (funds: Fund[]): FundsAction => ({
  type: SET_ADVANCED_SEARCH_FUNDS,
  payload: { funds },
});

export const setSelectedTableFunds = (
  fund: Fund,
  isChecked: boolean
): FundsAction => ({
  type: SET_SELECTED_TABLE_FUNDS,
  payload: { fund, isChecked },
});

export const resetSelectedTableFunds = (): Action => ({
  type: RESET_SELECTED_TABLE_FUNDS,
});

export const fetchSelectedFund = (id: number): FundsAction => ({
  type: FETCH_SELECTED_FUND_REQUESTED,
  payload: { id },
});

export const fetchSelectedFundSuccessful = (fund: Fund): FundsAction => ({
  type: FETCH_SELECTED_FUND_SUCCESSFUL,
  payload: { fund },
});

export const fetchSelectedFundRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_SELECTED_FUND_REJECTED,
  error,
});

export const fetchFundsInPeergroup = (id: number): FundsAction => ({
  type: FETCH_FUNDS_IN_PEERGROUP_REQUESTED,
  payload: { id },
});

const fetchFundsInPeergroupSuccessful = (value: number): FundsAction => ({
  type: FETCH_FUNDS_IN_PEERGROUP_SUCCESSFUL,
  payload: { value },
});

const fetchFundsInPeergroupRejected = (error?: TypeError): ErrorAction => ({
  type: FETCH_FUNDS_IN_PEERGROUP_REJECTED,
  error,
});

export default (
  state: FundsSearchState = {
    compareFunds: [],
    searchFunds: [],
    advancedSearchFunds: [],
    selectedTableFunds: {},
    selectedFund: null,
    fundsInPeergroup: null,
  },
  { type, payload }: FundsAction
) => {
  switch (type) {
    case FETCH_COMPARE_FUNDS_SUCCESSFUL:
      return {
        ...state,
        compareFunds: payload.funds,
      };
    case FETCH_SEARCH_FUNDS_SUCCESSFUL:
    case FETCH_NEXT_SEARCH_FUNDS_SUCCESSFUL:
      return {
        ...state,
        searchFunds: payload.funds,
      };
    case CLEAR_SEARCH_FUNDS:
      return {
        ...state,
        searchFunds: [],
        advancedSearchFunds: [],
      };
    case SET_SELECTED_FUND:
      return {
        ...state,
        selectedFund: payload.fund,
      };
    case SET_ADVANCED_SEARCH_FUNDS:
      return {
        ...state,
        advancedSearchFunds: payload.funds,
      };
    case SET_SELECTED_TABLE_FUNDS:
      if (payload.isChecked) {
        return {
          ...state,
          selectedTableFunds: {
            ...state.selectedTableFunds,
            [payload.fund.id]: payload.fund,
          },
        };
      } else {
        const selectedTableFunds = state.selectedTableFunds;
        if (selectedTableFunds[payload.fund.id]) {
          delete selectedTableFunds[payload.fund.id];
        }

        return {
          ...state,
          selectedTableFunds: {
            ...selectedTableFunds,
          },
        };
      }
    case RESET_SELECTED_TABLE_FUNDS:
      return {
        ...state,
        selectedTableFunds: {},
      };
    case FETCH_FUNDS_IN_PEERGROUP_REQUESTED:
      return {
        ...state,
        fundsInPeergroup: null,
      };
    case FETCH_FUNDS_IN_PEERGROUP_SUCCESSFUL:
      return {
        ...state,
        fundsInPeergroup: payload.value,
      };
    default:
      return state;
  }
};

export const fetchCompareFundsEpic = (
  action$: Observable<Action>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_COMPARE_FUNDS_REQUESTED),
    mergeMap(() => {
      const { selectedFund } = state.value.domain.funds.search;
      if (selectedFund !== null) {
        return ajax
          .get(
            `${API_URL}${
              selectedFund.is_aif
                ? "/aif/funds/"
                : `/top_funds_for_search/${selectedFund.id}/`
            }`,
            getReqOptions()
          )
          .pipe(
            mergeMap(({ response }) => {
              const res = selectedFund.is_aif ? response : response.results;
              return of(
                setFundsLoading(false),
                setSelectedFundLast(
                  selectedFund.position_vs_peergroup > res.length
                ),
                setSelectedFundPerfPeriod(selectedFund.performance_3y ? 3 : 1),
                setFUndsListModalVisibility(true),
                fetchCompareFundsSuccessful(res)
              );
            }),
            takeUntil(
              action$.pipe(
                filter(({ type }) => type === FETCH_COMPARE_FUNDS_REQUESTED)
              )
            ),
            catchError(error =>
              of(setFundsLoading(false), fetchCompareFundsRejected(error))
            )
          );
      } else {
        return of(setFundsLoading(false), fetchCompareFundsRejected());
      }
    })
  );

export const fetchSearchFundsEpic = (
  action$: Observable<FundsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_SEARCH_FUNDS_REQUESTED),
    debounceTime(500),
    mergeMap(({ payload }) =>
      ajax
        .get(
          `${API_URL}/paginated_simple_search/?page=1&search=${encodeURIComponent(
            payload.name
          )}`
        )
        .pipe(
          mergeMap(({ response }) =>
            of(
              setNextSearch(response.next),
              setSearchLoading(false),
              setComparisonAutocompleteLoading(false, 0),
              setComparisonAutocompleteLoading(false, 1),
              setAutocompleteVisibility(!payload.isCompare),
              fetchSearchFundsSuccessful([]),
              fetchSearchFundsSuccessful(response.results)
            )
          ),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === FETCH_SEARCH_FUNDS_CANCELLED)
            )
          ),
          catchError(error =>
            of(setSearchLoading(false), fetchSearchFundsRejected(error))
          )
        )
    )
  );

export const fetchNextSearchFundsEpic = (
  action$: Observable<FundsAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_NEXT_SEARCH_FUNDS_REQUESTED),
    mergeMap(({ payload }) =>
      ajax.get(`${API_URL}${payload.next}`).pipe(
        mergeMap(({ response }) =>
          of(
            setNextSearch(response.next),
            setSearchLoading(false),
            fetchNextSearchFundsSuccessful(
              state.value.domain.funds.search.searchFunds.concat(
                response.results
              )
            )
          )
        ),
        takeUntil(
          action$.pipe(
            filter(({ type }) => type === FETCH_SEARCH_FUNDS_CANCELLED)
          )
        ),
        catchError(error =>
          of(setSearchLoading(false), fetchNextSearchFundsRejected(error))
        )
      )
    )
  );

export const fetchAdvancedSearchFundsEpic = (
  action$: Observable<FundsAction>,
  state: StateValue
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_ADVANCED_SEARCH_FUNDS_REQUESTED),
    mergeMap(() => {
      const queryParams = getSearchQueryParams(state.value.form);

      return ajax
        .get(`${API_URL}/advanced_search/${queryParams}`, getReqOptions())
        .pipe(
          mergeMap(({ response }) =>
            of(
              setSearchLoading(false),
              setFundsTableLoading(false),
              setFundsTableTotal(response.count),
              setAdvancedSearchFunds(response.results),
              setSearchResultsParams(queryParams),
              fetchAdvancedSearchFundsSuccessful(response.results)
            )
          ),
          takeUntil(
            action$.pipe(
              filter(
                ({ type }) => type === FETCH_ADVANCED_SEARCH_FUNDS_REQUESTED
              )
            )
          ),
          catchError(error =>
            of(
              setSearchLoading(false),
              setFundsTableLoading(false),
              fetchAdvancedSearchFundsRejected(error)
            )
          )
        );
    }),
    catchError(error =>
      of(
        setSearchLoading(false),
        setFundsTableLoading(false),
        fetchAdvancedSearchFundsRejected(error)
      )
    )
  );

export const fetchSelectedFundEpic = (
  action$: Observable<FundsAction>
): Observable<Action> | {} =>
  action$.pipe(
    ofType(FETCH_SELECTED_FUND_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .get(
          `${API_URL}/simple_search/${payload.id}/?lang=${lang.getLanguage()}`
        )
        .pipe(
          mergeMap(({ response }) =>
            of(
              setSelectedFund(response),
              setSearchResultsParams(`?selectedFund=${response.id}`),
              setAutocompleteValue(response.name),
              fetchSelectedFundSuccessful(response),
              fetchFundsInPeergroup(response.peergroup),
              fetchCompareFunds()
            )
          ),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === FETCH_SELECTED_FUND_REQUESTED)
            )
          ),
          catchError(error =>
            of(setFundsLoading(false), fetchSelectedFundRejected(error))
          )
        )
    )
  );

export const fetchFundsInPeergroupEpic = (
  action$: Observable<FundsAction>
): Observable<Action> =>
  action$.pipe(
    ofType(FETCH_FUNDS_IN_PEERGROUP_REQUESTED),
    mergeMap(({ payload }) =>
      ajax
        .get(`${API_URL}/funds_in_peergroup/${payload.id}/`, getReqOptions())
        .pipe(
          mergeMap(({ response }) =>
            of(fetchFundsInPeergroupSuccessful(response.result))
          ),
          takeUntil(
            action$.pipe(
              filter(({ type }) => type === FETCH_FUNDS_IN_PEERGROUP_REQUESTED)
            )
          ),
          catchError(error => of(fetchFundsInPeergroupRejected(error)))
        )
    )
  );
