import aif from "../domain/funds/aif";
import assetManagers from "../domain/assetManagers";
import assetManagersCharts from "../domain/assetManagersCharts";
import auth from "../domain/auth";
import charts from "../domain/charts";
import { combineReducers } from "redux";
import common from "../domain/funds/common";
import conferences from "../domain/conferences";
import contact from "../domain/contact";
import details from "../domain/funds/details";
import filterSearch from "../domain/funds/filterSearch";
import filters from "../domain/filters";
import fundDocuments from "../domain/funds/fundDocuments";
import fundsInFocus from "../domain/funds/fundsInFocus";
import fundsPremium from "../domain/funds/fundsPremium";
import home from "../domain/home";
import licenses from "../domain/licenses";
import news from "../domain/news";
import premium from "../domain/premium";
import ratingInFocus from "../domain/funds/ratingInFocus";
import reports from "../domain/reports";
import route from "../domain/route";
import savedSearches from "../domain/savedSearches";
import search from "../domain/funds/search";
import survey from "../domain/survey";
import watchlist from "../domain/funds/watchlist";

export const domainReducers = combineReducers<any>({
  auth,
  assetManagersCharts,
  assetManagers,
  funds: combineReducers<any>({
    aif,
    common,
    details,
    filterSearch,
    fundDocuments,
    fundsInFocus,
    ratingInFocus,
    fundsPremium,
    search,
    watchlist,
  }),
  charts,
  home,
  news,
  contact,
  conferences,
  filters,
  route,
  premium,
  savedSearches,
  reports,
  licenses,
  survey,
});
